import React, { useState, useEffect } from 'react';
import { usePatientData } from '../../../hooks/ContextPatientData/ContextPatientData';
import { createSetupIntent } from '../../../containers/Models/stripe';

import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import SetupForm from './SetupForm/SetupForm';

import '../../../globalCss/globalCssMuiButton.css';

interface Props {
  callback?: Function;
  showSetDefault?: boolean;
}

let stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeKey as string);

const StripeAddPaymentMethod: React.FC<Props> = props => {

  const patientData = usePatientData();
  const [options, setOptions] = useState<any>(null);

  useEffect(() => {

    if (!options) {
      createSetupIntent(patientData.stripeCustomerId).then((intent:any) => {
        setOptions({
          clientSecret: intent.secret,
          // Fully customizable with appearance API.
          appearance: {/*...*/},   
        });
      });
    }

  }, [patientData, options]);

  if (options && stripePromise) {
    return (
      <Elements stripe={stripePromise} options={options}>
        <SetupForm callback={props.callback} showSetDefault={props.showSetDefault} />
      </Elements>
    );
  }

  else {
    return(
      <Stack spacing={4}>
        <Skeleton variant="rectangular" height={44} />
        <Stack direction="row" spacing={2}>
          <Skeleton variant="rectangular" width="50%" height={44} />
          <Skeleton variant="rectangular" width="50%" height={44} />
        </Stack>
        <Skeleton variant="rectangular" height={44} />
        <Skeleton variant="rectangular" height={44} />
      </Stack>
    );
  }

};

export default StripeAddPaymentMethod;