import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

import TextFieldPassword from '../../ui/TextFieldPassword/TextFieldPassword';

import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import classes from './PatientLogIn.module.css'
import '../../../globalCss/globalCssMuiButton.css';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

interface Props {
}


const PatientLogIn: React.FC<Props> = props => {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleSubmit = (e:React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const auth = getAuth();
    signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      //console.log(userCredential.user);
      //If login is succesful send to home
      setError(false);
      navigate("/");
    })
    .catch(err => {
      //console.log("Error: ", err);
      setLoading(false);
      setError(true);
      // TODO: Show an error
      // const errorCode = err.code;
      // const errorMessage = err.message;
    });
  };

  return(
    <div className={classes.containerPatientLogIn}>

      <Helmet>
        <title>
          Log in - Allminds
        </title>
      </Helmet>
      
      <h4>Welcome to Allminds</h4>

      <form onSubmit={handleSubmit}>

        {error ? (
          <Alert severity="error" style={{marginBottom: '32px'}}>
            This username or password is incorrect. Please try again, reset your password, or contact support@allminds.io.
          </Alert>
        ) : null}

        <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} className={classes.textField} type="email" required fullWidth variant="outlined" />

        <TextFieldPassword password={password} handleSetPassword={setPassword} required />

        <LoadingButton type="submit" loading={loading} variant="contained" fullWidth size='large' className={classes.btnSubmit}>Log in</LoadingButton>

      </form>

      <div className={classes.containerOptions}>
        <Link to={'/forgot-password'}>Forgot password?</Link>
        <p>
          Don't have an account? 
          <Link to={'/matching'}> Find a therapist</Link>
        </p>
      </div>

    </div>
  );
};

export default PatientLogIn;

