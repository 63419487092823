import React from 'react';
import { useEffect, useState } from "react";
import { format } from 'date-fns'
import { usePatientData } from '../../../../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchCoverageData from '../../../../../../../hooks/useFetchCoverageData/useFetchCoverageData';

import AvatarSquare from '../../../../../../ui/AvatarSquare/AvatarSquare';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import BalanceOutlinedIcon from '@mui/icons-material/BalanceOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import LinearProgress from '@mui/material/LinearProgress';
import classes from './ReviewSessionDetailsCard.module.css';

interface Props {
  providerName: string;
  providerPicUrl: string;
  startDate: Date;
  endDate: Date;
  frequency: number;
  setError?: Function;
}

const ReviewSessionDetailsCard: React.FC<Props> = props => {

  const patientData = usePatientData();
  const coverageData = useFetchCoverageData();

  let deductible = 0;
  let deductibleUsed = 0;
  let famDeductible = 0;
  let famDeductibleUsed = 0;
  let copay = 0;
  let coinsurance = 0;
  if (coverageData && !coverageData.error) {
    deductible = coverageData.individual.inNetwork.deductibleTotal;
    deductibleUsed = coverageData.individual.inNetwork.deductibleUsed;
    famDeductible = coverageData.family.inNetwork.deductibleTotal;
    famDeductibleUsed = coverageData.family.inNetwork.deductibleUsed;
    copay = coverageData.individual.inNetwork.copay;
    coinsurance = parseFloat(coverageData.individual.inNetwork.coinsurance) / 100.0;
  }

  return(
    <div className={classes.container}>
      <div className={classes.header}>
        <AvatarSquare imgUrl={props.providerPicUrl} alt={props.providerName} />
        <div>
          <h5>Therapy with {props.providerName.split(' ')[0]}</h5>
          <p>Starting on {format(props.startDate, 'iii, LLLL dd')}</p>
          <div className={classes.appointmentTime}>
            <p>{format(props.startDate, 'p') + " - " + format(props.endDate, 'p')}</p>
            <VideocamOutlinedIcon />
          </div>
        </div>
      </div>

      { patientData ? 
        <div className={classes.content}>

          <div className={classes.row}>
            <HistoryOutlinedIcon />
            <div>
              {props.frequency === 1 ? (
                <h5>One session every week</h5>
              ) 
              : props.frequency === 2 ? (
                <h5>One session every  two weeks</h5>
              )
              : props.frequency === 3 ? (
                <h5>One session every three weeks</h5>
              )
              : props.frequency === 4 ? (
                <h5>One session every four weeks</h5>
              )
              : null
              }
              <p>Your future appointments will get booked automatically.</p>
            </div>
          </div>
          
          { !coverageData && patientData.coverageId ? 
              <div className={classes.row}>
                <HealthAndSafetyIcon color='inherit' />
                <div>
                  <Skeleton variant="text" width={200} height={32} />
                  <Skeleton variant="text" width={244} height={32} />
                </div>
              </div>
            :
            coverageData && !coverageData.error ? 
            <div className={classes.row}>
                {coverageData.isActive ? 
                  <React.Fragment>
                    <HealthAndSafetyIcon className={classes.success} color='inherit' />
                    <div>
                      <h5 className={classes.success}>Covered by insurance</h5>
                      <p>Good news! Your health plan ({coverageData.payerName}) can cover this session.</p>
                    </div>
                  </React.Fragment>
                :
                  <React.Fragment>
                    <HealthAndSafetyIcon className={classes.warning} color='inherit' />
                    <div>
                      <h5 className={classes.warning}>Not covered by your insurance</h5>
                      <p>You may proceed with out-of-pocket pricing.</p>
                    </div>
                  </React.Fragment>
                }
            </div>
          : null}

          {coverageData && !coverageData.error && coverageData.isActive ? 

            <React.Fragment>

              { deductible - deductibleUsed  > 0
                && (!famDeductible || famDeductible - famDeductibleUsed > 0) ? 
                <React.Fragment>
                  <div className={classes.row}>
                    <SavingsOutlinedIcon />
                    <div style={{width: '100%'}}>
                      <h5>Deductible progress</h5>
                      <p style={{textAlign: 'right'}}><small>{
                      (deductibleUsed / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) 
                      + " out of "
                      + (deductible / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})
                      }</small></p>
                      <LinearProgress variant="determinate" value={deductibleUsed / deductible * 100} />
                    </div>
                  </div>
                </React.Fragment>
              : null}

              {copay > 0 ? 
                <React.Fragment>
                  <div className={classes.row}>
                    <PeopleAltOutlinedIcon />
                    <h5>Copay: {(copay / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}</h5>
                  </div>
                </React.Fragment>
              : null} 

              {coinsurance > 0 ? 
                <React.Fragment>
                  <div className={classes.row}>
                    <BalanceOutlinedIcon />
                    <h5>Coinsurance: {coverageData.individual.inNetwork.coinsurance}</h5>
                  </div>
                </React.Fragment>
              : null}  

            </React.Fragment>

          : null}
          
          <div className={classes.row}>
            <PaidOutlinedIcon />
            { !coverageData && patientData.coverageId?    
              <div>
                <Skeleton variant="text" width={180} height={32} />
                <Skeleton variant="text" width={240} height={32} />
              </div>
              :
              coverageData && !coverageData.error && coverageData.isActive ? 
              <div>
                <h5>Amount due: {
                  //For users with pending deductibles
                  deductible - deductibleUsed > 0 
                  && (!famDeductible || famDeductible - famDeductibleUsed > 0) ?
                    (patientData.privatePayRate / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})
                  //For users with copays and/or coinsurance
                  : copay || coinsurance ?
                    (copay / 100 + coinsurance * coverageData.billingFee / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})
                  //For users fully covered
                  : !copay && !coinsurance ?
                    '$0.00'
                  : 'Not available'
                }</h5>
                <p>You won't be charged until the end of each appointment. The amount due might vary if your benefits change. Contact your health plan to confirm your exact rates.</p>
              </div>
            :
              coverageData && coverageData.error ?
              <div style={{color:'red'}}>
                <h5>Amount due: not available</h5>
                <p>Please try again later or contact support@allminds.io. You may proceed booking if you understand your benefits and how much you might get charged after this session.</p>
              </div>         
            :
              <div>
                <h5>Amount due: {(patientData.privatePayRate / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})} per session</h5>
                <p>You will be charged instantly after each appointment.</p>
              </div>
            }
          </div>

          <div className={[classes.row, classes.containerModPolicy].join(' ')}>
            <EventBusyOutlinedIcon />
            <div>
              <h5>Cancelation policy</h5>
              { !coverageData && patientData.coverageId ?
                <React.Fragment>
                  <Skeleton variant="text" width={240} height={32} />
                  <Skeleton variant="text" width={224} height={32} />
                </React.Fragment>
              :
                coverageData && !coverageData.error && coverageData.isActive ? 
                <p>{"Appointments can be canceled early up to 24 hours before start time. Your treatment will be suspended after three late cancelations."}</p>
              :
                <p>{"Appointments can be canceled or changed for free until 24 hrs before start time. Late cancelations will be charged the full session fee."}</p>
              }
            </div>
          </div>

        </div>
      : 
        <div className={classes.content}>
          <Stack spacing="6px">
            <Skeleton variant="text" width={160} height={32} />
            <Skeleton variant="text" width={224} height={32} />
            <Skeleton variant="text" width={288} height={32} />
            <Skeleton variant="text" width={128} height={32} />
            <Skeleton variant="text" width={192} height={32} />
          </Stack>
          <div className={classes.containerModPolicy}>
            <Skeleton variant="text" width={160} height={32} />
            <Skeleton variant="text" width="100%" height={24} />
            <Skeleton variant="text" width="100%" height={24} />
            <Skeleton variant="text" width={128} height={24} />
          </div>
        </div>
      }
    </div>
  );

};

export default ReviewSessionDetailsCard;
