import React from 'react';

import Chip from '@mui/material/Chip';
import Radio from '@mui/material/Radio';

import classes from './PatientSessionSelectPaymentRadio.module.css';

interface Props {
  paymentData: any;
  selectedPaymentMethod: any;
  handleSetSelectedPaymentMethod: Function;
  isDefault: boolean;
  handleClose: Function;
}

const PatientSessionSelectPaymentRadio: React.FC<Props> = props => {

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.handleSetSelectedPaymentMethod(props.paymentData);
    setTimeout(() => {props.handleClose()}, 150)
  };

  return(
    <div className={classes.containerPaymentMethodCard}>
      <div>
        <h5>
          {props.paymentData.card.brand + ' • • • • ' + props.paymentData.card.last4}
          {props.isDefault ? <Chip label="DEFAULT" size="small" style={{marginLeft: '12px'}} /> : null}

        </h5>
        <p>Expiration: {props.paymentData.card.exp_month + '/' + props.paymentData.card.exp_year}</p>
      </div>
      <Radio
        checked={props.paymentData.id === props.selectedPaymentMethod.id}
        onChange={handleChange}
        value="a"
        name="radio-buttons"
        inputProps={{ 'aria-label': 'A' }}
      />
    </div>
  );

};

export default PatientSessionSelectPaymentRadio;