import { getFirestore, addDoc, updateDoc, collection, 
    doc, query, where, getDocs, deleteDoc } from "firebase/firestore";
import firebaseApp from "../Data/Firestore";
const appointmentDb = process.env.REACT_APP_APPOINTMENT_DB;
const db = getFirestore(firebaseApp);
function createAppointmentObject(appointmentData) {
  
  //console.log("Appoinment : ", appointmentData);
  let appointment = {};
  appointment.id = "";
  appointment.canvasId = "";
  appointment.start = appointmentData.start;
  appointment.end = appointmentData.end;
  appointment.service = appointmentData.service;
  appointment.patientId = appointmentData.patientId;
  appointment.patientEmail = appointmentData.patientEmail;
  appointment.providerId = appointmentData.providerId;
  appointment.providerName = appointmentData.providerName;
  appointment.patientCanvasId = appointmentData.patientCanvasId;
  appointment.providerCanvasId = appointmentData.providerCanvasId;
  appointment.progressNoteCanvasId = "";
  appointment.sessionFeedbackId = "";
  appointment.stripePaymentIntentId = "";
  appointment.amountPaid = "";
  appointment.lateCancel = "";
  appointment.excludeNoshow = "";
  appointment.payrollDate = "";
  appointment.type = appointmentData.type;
  if (appointmentData.providerPicUrl === undefined) {
    appointment.providerPicUrl = "";
  } else {
    appointment.providerPicUrl = appointmentData.providerPicUrl;
  }

  /* 
  Status is same as in Canvas:
  proposed, pending, booked, arrived, fulfilled, cancelled, noshow, checked-in
  */

  appointment.status = "proposed";
  appointment.newTherapyTime = false;
  // Following fields should be updated at the end of the appointment
  // Add the Zoom Link / ID to this variable
  appointment.meetingId = appointmentData.meetingId ? appointmentData.meetingId : "";
  // duration in seconds
  appointment.duration = 0;
  return appointment;
}

const createZoomMeeting = async (meetingReq) => {
  const server = process.env.REACT_APP_SERVERURL;
  return await fetch(server + "/create-zoom-meeting", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      therapistEmail: meetingReq.therapistEmail,
      therapistName: meetingReq.therapistName,
      clientEmail: meetingReq.clientEmail,
      clientName: meetingReq.clientName,
      startTime: meetingReq.startTime
    })
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else
        return response.json();
    })
    .then(result => {
      //console.log("Got Meeting Obj as Result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error Creating Meeting ID: ", error);
    });
};

const   updateZoomMeetingTime = async (meetingReq) => {
  const server = process.env.REACT_APP_SERVERURL;
  return await fetch(server + "/update-zoom-meeting-time", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      startTime: meetingReq.startTime,
      meetingId: meetingReq.meetingId
    })
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else
        return response.json();
    })
    .then(result => {
      //console.log("Got Meeting Obj as Result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error Creating Meeting ID: ", error);
    });
};



const addAppointmentToDb = async (appointmentData) => {
  const docRef = await addDoc(collection(db, appointmentDb), appointmentData).then(ref => {
    updateDoc(ref, {id: ref.id });
    return ref.id;
  });

  return docRef;
}

async function updateAppointmentTimeDb(appointmentId, start, end, status, newTherapyTime = false) {
  const docRef = doc(db, appointmentDb, appointmentId);
  updateDoc(docRef, {
    start: start,
    end: end,
    status: status,
    newTherapyTime: newTherapyTime
  });
}

async function cancelAppointmentDb(appointmentId) {
  const docRef = doc(db, appointmentDb, appointmentId);
  updateDoc(docRef, {
    status: "cancelled"
  });
}

async function updateAppointmentCanvasId(appointmentId, appointmentCanvasId) {
  //console.log("Appointment ID: ", appointmentId, " CanvasAppointmentID: ", appointmentCanvasId);
  const docRef = doc(db, appointmentDb, appointmentId);
  updateDoc(docRef, {canvasId: appointmentCanvasId.appointmentId});
}

const getAppointmentsByPatientId = async (patientId) => {
  const q = query(collection(db, appointmentDb), where("patientId", "==", patientId));
  const querySnapshot = await getDocs(q);
  let appointments = [];
  querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let appointmentData = doc.data();
      convertFirebaseDates(appointmentData);
      appointments.push(appointmentData);
      //Sort appointments by date, in chronological order (closest to furthest)
      appointments.sort(function(a,b){
        return a.start - b.start;
      });
    });

  return appointments;
}

async function updateAppointmentPayment(appointmentId, paymentIntentId, amount ) {
  const docRef = doc(db, appointmentDb, appointmentId);
  return updateDoc(docRef, {
    stripePaymentIntentId: paymentIntentId,
    amountPaid: amount
  });
}

//WARNING: PERMANENTLY DELETES APPOINTMENT DOC FROM DB, USE WITH EXTREME CAUTION
async function deleteAppointmentDb(appointmentId) {
  const docRef = doc(db, appointmentDb, appointmentId);
  await deleteDoc(docRef);
}

async function updateAppointmentLateCancel(appointmentId) {
  const docRef = doc(db, appointmentDb, appointmentId);
  return updateDoc(docRef, {
    lateCancel: true
  });
}

async function updateAppointmentExcludeNoshow(appointmentId) {
  const docRef = doc(db, appointmentDb, appointmentId);
  return updateDoc(docRef, {
    excludeNoshow: true
  });
}

//Convert all firebase dates to regular Date objects
const convertFirebaseDates = (obj) => {
  for (let key in obj) {
    if (obj[key] && obj[key].toDate) {
        obj[key] = obj[key].toDate();
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
        convertFirebaseDates(obj[key])
    }
  }
};



export {
    createAppointmentObject,
    addAppointmentToDb,
    updateAppointmentTimeDb,
    cancelAppointmentDb,
    updateAppointmentCanvasId,
    getAppointmentsByPatientId,
    updateAppointmentPayment,
    deleteAppointmentDb,
    updateAppointmentLateCancel,
    updateAppointmentExcludeNoshow,
    createZoomMeeting,
    updateZoomMeetingTime
};


