import React, {useState} from "react";
import { differenceInYears, parse } from "date-fns";

import { usePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchCoverageData from '../../../../../../hooks/useFetchCoverageData/useFetchCoverageData';

import NavBarPatientChat from '../../../../../ui/ChatForm/NavBarPatientChat/NavBarPatientChat';
import ChatForm from "../../../../../ui/ChatForm/ChatForm";
import Skeleton from '@mui/material/Skeleton';

import classes from "./PatientMatchingChat.module.css";

interface Props {
  //setProviderIds: Function;
  handleCloseMatchingChat: Function;
  handleOpenFindProvider: Function;
}

const PatientMatchingChat: React.FC<Props> = props => {

  const handleSwitchToFindProvider = () => {
    props.handleCloseMatchingChat();
    props.handleOpenFindProvider();
  };

  const patientData = usePatientData();
  const coverageData = useFetchCoverageData();

  //Change chatKey to rerender PatientMatchingChat (start over)
  const [chatKey, setChatKey] = useState("1");
  const handleRestartChat = () => {
    let newKey = parseInt(chatKey);
    newKey = newKey + 1;
    setChatKey(newKey.toString());
  };

  //Questions to be included in form
  let questions: any = {
    isInCalifornia:
      "Hi " + patientData.firstName + ", good to see you!" + 
      "&&Do you still live in California?",
    hasInsurance: 
      coverageData?.isPatientEligible ? 
        "Do you wish to change your current " + coverageData?.payerName + " insurance?"
      :
        "Are you looking for a therapist who takes insurance?"
      ,
    insuranceProvider: 
      "Gotcha!" +
      "&&Who's your insurance provider?",
    insuranceIsNotAccepted: 
      "I'm sorry, Allminds doesn't work with your insurance yet..." + 
      "&&But I can still help you find an affordable therapist. Would you like that?",
    memberId: "What's your insurance member ID?",
    continueToMatching: "Gotcha!&&Next I'm going to ask you a few questions to help us match you with the right therapist.",
    gender: "What is your gender?",
    isTransgender: "Do you consider yourself transgender?",
    sexualOrientation: "How do you identify?",
    ethnicities: "Do you identify with any of these groups?",
    isReligious: "Are you religious?",
    religion: "What is your religion?",
    therapyGoals: "Why are you seeking therapy?",
    genderPref: "Do you have any gender preference for your therapist?",
    agePref: "What age would you like your therapist to be?",
    ethnicityPref: "Which ethnic backgrounds would your ideal therapist have?",
    languagePref: "Which languages would you like your therapist to speak?",
  };

  //Input choices for each form question
  const options: any = {
    isInCalifornia: { type: "radio", answers: ["Yes", "Nope"] },
    hasInsurance: { type: "radio", answers: ["No", "Yes"] },
    insuranceProvider: { type: "radio", answers: ["Aetna", "Anthem", "Cigna", "Optum", "Other"] },
    insuranceIsNotAccepted: { type: "radio", answers: ["Sure!", "No thanks"] },
    memberId: { type: "text", placeholder: "Your member ID" },
    continueToMatching: { type: "radio", answers: ["Sounds good!"] },
    gender: {
      type: "radio",
      answers: ["Woman", "Man", "Non-binary", "Other", "Prefer not to say"]
    },
    isTransgender: { type: "radio", answers: ["Yes", "No"] },
    sexualOrientation: {
      type: "radio",
      answers: [
        "Gay",
        "Lesbian",
        "Straight",
        "Asexual",
        "Bisexual",
        "Pansexual",
        "Queer",
        "Other",
        "Prefer not to say"
      ]
    },
    ethnicities: {
      type: "select",
      answers: [
        "Asian",
        "Black",
        "Caucasian",
        "Hispanic",
        "Middle Eastern",
        "Native American",
        "Pacific Islander",
        "South Asian",
        "Other",
        "Prefer not to say"
      ]
    },
    isReligious: { type: "radio", answers: ["Yes", "No"] },
    religion: {
      type: "radio",
      answers: [
        "Buddhist",
        "Christian",
        "Hindu",
        "Jewish",
        "Muslim",
        "Mormon",
        "Sikh",
        "Other"
      ]
    },
    therapyGoals: { 
      type: "select", 
      answers: [
        "ADD/ADHD",
        "Anger Management",
        "Anxiety",
        "Bipolar disorder",
        "Depression",
        "Eating disorders",
        "Family issues",
        "Grief",
        "LGBTQIA+",
        "Maternal mental health",
        "Men's issues",
        "OCD",
        "PTSD",
        "Relationship issues",
        "Sleep disorder",
        "Substance abuse",
        "Trauma",
        "Women's issues",
        "Other"
      ]
    },
    genderPref: { 
      type: "radio", 
      answers: [
        "Any gender",
        "Woman",
        "Man"
      ]
    },
    agePref: { 
      type: "select", 
      answers: [
        "20s",
        "30s",
        "40s",
        "50s",
        "60s+"
      ]
    },
    ethnicityPref: { 
      type: "select", 
      answers: [
        "Any",
        "Asian",
        "Black",
        "Caucasian",
        "Hispanic",
        "Middle Eastern",
        "Native American",
        "Pacific Islander",
        "South Asian",
        "Other"
      ]
    },
    languagePref: { 
      type: "select", 
      answers: [
        "English",
        "Spanish"
      ]
    },
    budget: { type: "number", placeholder: "$150" },
    preferences: {
      type: "text",
      tag: "textarea",
      placeholder: "Optional",
      skippable: true
    },
    email: { type: "email", placeholder: "Type your email" },
    phone: { type: "number", placeholder: "Type your phone" }
  };

  //Conditions required for each question, if any
  const conditions: any = {
    insuranceProvider: [{ hasInsurance: "Yes" }],
    insuranceIsNotAccepted: [{ insuranceProvider: "Other" }],
    memberId: [{ hasInsurance: "Yes" }, { insuranceProvider: "Aetna||Anthem||Cigna||Optum" }],
    religion: [{ isReligious: "Yes" }],
  };

  //Use this function to create a new radio-type tag object
  const createRadioTag = (name:string, question:string, answers:string[]) => {
    const createTagChildren = () => {
      let tagChildren: any = [];
      answers.forEach((answer: any) => {
        let child: any = {
          tag: "input",
          name: name,
          type: "radio",
          "cf-label": answer,
          value: answer
        };
        tagChildren.push(child);
      });
      return tagChildren;
    }
    let radioTag = {
      tag: "fieldset",
      name: name,
      "cf-input-placeholder": " ",
      "cf-questions": question,
      children: createTagChildren()
    }
    return radioTag
  };

  //This function creates a tag prompting the user to start over the chat
  const createStartOverTag = (question:string) => {
    let startOverTag = createRadioTag(
      "startOver",
      question,
      ["Start over"]
    );
    return startOverTag;
  };

  //This function looks for the startOver tag and removes it if found
  const removeStartOverTag = (tags:any[]) => {
    //look for startOver tag
    let startOverTagIndex = tags.findIndex((tag: any) => tag.name === "startOver");
    //Remove startOver tag if found
    if (startOverTagIndex > -1) {
      tags.splice(startOverTagIndex, 1);
    }
  };

  //This function looks for a specific tag and removes it if found
  const removeTag = (tagName:string, tags:any[]) => {
    //look for tag
    let tagIndex = tags.findIndex((tag: any) => tag.name === tagName);
    //Remove tag if found
    if (tagIndex > -1) {
      tags.splice(tagIndex, 1);
    }
  };

  //Function to be called after each question is completed
  const questionCallback = (dto: any, success: any, error: any, cf: any) => {
    removeStartOverTag(cf.tags);
    //After start over question is submitted
    if (dto.tag.name === "startOver") {
      handleRestartChat();
    }
    //After isInCalifornia
    else if (dto.tag.name === "isInCalifornia") {
      //If user is in California, continue
      if (dto.tag.value[0] === "Yes") {
        success();
      }
      // Else stop and send user prompt to start over
      else {
        let message = 
          "Unfortunately Allminds only works in California right now..."
          + "&&But we're expanding quickly, so make sure to come back soon!"
        cf.addTags([createStartOverTag(message)]);
        success();
      }
    } 
    //After dob
    else if (dto.tag.name === "dob") {
      //Compute age of user
      let dob = parse(dto.tag.value, "EEE MMM dd yyyy", new Date());
      let age = Math.round(differenceInYears(new Date(), dob));
      //Check that user is 18+ yrs old
      if (age >= 18) {
        success();
      }
      // Else stop and send user prompt to start over
      else {
        let message = 
          "I'm sorry " + patientData.firstName + ", it looks like you're a little too young to use Allminds..."
          + "&&Please come back when you are 18 years or older."
        cf.addTags([createStartOverTag(message)]);
        success();
      }
    } 
    //After insuranceIsNotAccepted
    else if (dto.tag.name === "insuranceIsNotAccepted" || dto.tag.name === "isNotEligible") {
      if (dto.tag.value[0] === "No thanks") {
        let message = 
          "I'm sorry we couldn't find you a therapist today..."
          + "&&But we're adding more insurances quickly, so make sure to come back soon!"
        cf.addTags([createStartOverTag(message)]);
      }
      success();
    }
    else if (dto.tag.name === "memberId") {
      //TODO: REPLACE CODE BELOW WITH SERVER CALL TO CHECK ELIGIBILITY
      setTimeout(() => {
        let isEligible = true;
        let benefits = "You have a $30.00 copay, which means each session will cost $30." 
        if (isEligible) {
          removeTag("isEligible", cf.tags);
          cf.addTags([createRadioTag(
            "isEligible",
            "Good news, you're eligible for coverage! 🥳&&" + benefits,
            ["Great! Let's continue :)"]
          )]);
        }
        else {
          removeTag("isNotEligible", cf.tags);
          cf.addTags([createRadioTag(
            "isNotEligible",
            patientData.firstName + ", it doesn't seem like you're eligible for coverage unfortunately...&&But I can still help you find an affordable therapist. Would you like that?",
            ["Sure!", "No thanks"]
          )]);
        }
        success();
      }, 2000);
    }
    else {
      success();
    }
  };

  //Function to be called after form is submitted
  const handleSubmit = (formData: any, cf:any) => {
    //Create matchingData object from formData
    const matchingData = {
      firstName: patientData.firstName,
      lastName: patientData.lastName,
      state: formData.isInCalifornia[0] ? 'California' : '',
      dob: patientData.dob,
      gender: formData.gender[0],
      isTransgender: formData.isTransgender[0] === "Yes" ? true : false,
      sexualOrientation: formData.sexualOrientation[0],
      ethnicities: formData.ethnicities[0],
      isReligious: formData.isReligious[0] === "Yes" ? true : false,
      religion: formData.religion[0] ? formData.religion[0] : '',
      therapyGoals: formData.therapyGoals,
      genderPref: formData.genderPref[0],
      agePref: formData.agePref,
      ethnicityPref: formData.ethnicityPref,
      languagePref: formData.languagePref,
    };

    //If new coverage is added
    if (formData.hasInsurance[0] === 'Yes') {
      // const newCoverageData = {
      //   isEligible: formData.isEligible ? true : false,
      //   payerName: formData.insuranceProvider[0] ? formData.insuranceProvider[0] : '',
      //   subscriberId: formData.memberId ? formData.memberId : ''
      // };
      //console.log(newCoverageData);
    }

    //console.log(matchingData);

    //TO-DO: function that submits matchingData to server and fetches ids of top 3 provider matches in array
    const updateDbMatchingData = async (matchingData: any) => {
      setTimeout(() => {
        //After db is updated run code below
        handleSwitchToFindProvider();
      }, 3000);
    };
    //Get provider ids of matches then store them in local storage
    updateDbMatchingData(matchingData);
  };

  const endResponse =
    "Thanks for sharing " + patientData.firstName + "&&I'm looking for therapists who match your needs..."

  return (
    <React.Fragment>
      <NavBarPatientChat 
        handleClose={handleSwitchToFindProvider} 
        handleRestartChat={handleRestartChat}
      />
      <div className={classes.containerMatchingChat}>
        {patientData && coverageData ? 
          <ChatForm
            key={chatKey}
            questions={questions}
            options={options}
            conditions={conditions}
            questionCallback={questionCallback}
            endResponse={endResponse}
            handleSubmit={handleSubmit}
          />
        :
          <div className={classes.containerSkeleton}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={200} height={60} />
          </div>
        }
      </div>
    </React.Fragment>
  );

};

export default PatientMatchingChat;
