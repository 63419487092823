import React from 'react';
import { usePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchPatientAppointments from '../../../../../../hooks/useFetchPatientAppointments/useFetchPatientAppointments';

import PatientPastAppointmentCard from './PatientPastAppointmentCard/PatientPastAppointmentCard';

import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import classes from './PatientPortalAppointmentsHistory.module.css'


interface Props {
}

const PatientPortalAppointmentsHistory: React.FC<Props> = props => {

  const patientData = usePatientData();
  const pastAppointmentsData = useFetchPatientAppointments().past;

  if (patientData && pastAppointmentsData) {
  
    if (pastAppointmentsData.length > 0) {
      return(
        <div className={classes.containerAppointmentHistory}>
          {
            pastAppointmentsData.map((appointment:any, i:number) => {
              return(
                <PatientPastAppointmentCard 
                  key={appointment.id} 
                  appointment={appointment}
                />
              );
            })          
          }
        </div>
      );
    }

    else {

      return(
        <div className={classes.containerAppointmentHistory}>
          <div className={classes.containerNoPastAppointments}>
            <EventBusyOutlinedIcon fontSize="large" color="inherit" />
            <p>You have no past appointments.</p>
          </div>
        </div>
      );

    }

  }

  else {
    return(
      <div className={classes.containerAppointmentHistory}>
        <Stack spacing="32px">
          <Skeleton variant="rectangular" height={87} />
          <Skeleton variant="rectangular" height={87} />
        </Stack>
      </div>
    );
  }

};

export default PatientPortalAppointmentsHistory;
