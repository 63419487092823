import React from 'react';
import { Helmet } from "react-helmet";

import PatientPortalInboxContent from './PatientPortalInboxContent/PatientPortalInboxContent';


interface Props {
  handleShowNavBar: Function; 
  handleHideNavBar: Function; 
  handleEnableSwipe: Function;
  handleDisableSwipe: Function;
}

const PatientPortalInbox: React.FC<Props> = props => {

  return(
    
    <React.Fragment>
      
      <Helmet>
        <title>
          Inbox - Allminds
        </title>
      </Helmet>
      <PatientPortalInboxContent />

    </React.Fragment>

  );
};

export default PatientPortalInbox;
