import React, {useState} from 'react';
import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import {updatePatientPhone} from '../../../../../../containers/Models/PatientModel';
import {canvasUpdatePatient} from '../../../../../../containers/Models/Canvas';
import TextFieldPhone from '../../../../../ui/TextFieldPhone/TextFieldPhone';

import { LoadingButton } from '@mui/lab';

import classes from '../PatientSettingsEditName/PatientSettingsEditName.module.css';
import '../../../../../../globalCss/globalCssMuiButton.css';


interface Props {
  handleClose: Function;
}

const PatientSettingsEditPhone: React.FC<Props> = props => {

  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();

  const [phone, setPhone] = useState(patientData.phone);
  const [phoneIsValid, setPhoneIsValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    if (phoneIsValid && phone !== patientData.phone) {
      setLoading(true);
      let updatedPatientData = {...patientData};
      updatedPatientData.phone = phone;
      updatePatientPhone(patientData.id, phone); // Update patient phone in db
      // Update patient phone in canvas
      canvasUpdatePatient(updatedPatientData).then(() => {
        updateStatePatientData(updatedPatientData); 
        setLoading(false);
        props.handleClose();
      }); 
    }
  };

  return(
    <div className={classes.containerEditSetting}>
      <div className={classes.header}>
        <h2>Phone</h2>
        <p>For notifications, reminders, and help logging in.</p>
      </div>
      <div className={classes.contentElement}>
        <TextFieldPhone 
          phone={phone}
          phoneIsValid={phoneIsValid}
          handleSetPhoneIsValid={setPhoneIsValid}
          handleSetPhone={setPhone}
          required
        />
      </div>
      <LoadingButton onClick={handleSubmit} loading={loading} variant="contained" size='large' className={classes.btnSubmit}>Save</LoadingButton>
    </div>
  );
};

export default PatientSettingsEditPhone;