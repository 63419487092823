import {claimMdGetEligibility} from "./Claim";
import {getPayerByPayerId} from "./payerModel";
const {parse} = require("date-fns");

// Creates a new Eligibility Request in Canvas
const getEligibility = async (
	memberId: string, 
	payerId: string, 
	dob: Date, 
	fdos: Date = new Date()
) => {

  const server = process.env.REACT_APP_SERVERURL;

	const reqBody = JSON.stringify({
		memberId: memberId,
		dob: dob,
		payerId: payerId,
		fdos: fdos 
	});

  return fetch(server + "/get-eligibility-by-payer", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.json().then(error => { throw error })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("Got Eligibility Data: ", result);
      return result;
    })
    .catch(async error => {
			//Create a partial eligibility object to let the user continue
      console.log(error);
      let payerName = '';
      let payerData:any = await getPayerByPayerId(payerId);
      if (payerData)
        payerName = payerData.name;
      let response = {
        error: 'There was an error verifying your insurance benefits. Please try again later or contact support@allminds.io.',
        memberId: memberId,
        payer: payerId,
        payerName: payerName,
        relationshipToSub: 'self',
        coverageStart: new Date('01/01/2022'),
        coverageEnd: new Date('01/01/2032'),
      };
			return response;
    });
};


export {
  getEligibility
};