import React, { useState } from 'react';
import { getAuth, signOut } from "firebase/auth";
import { format } from 'date-fns';

import PatientSettingsInput from './PatientSettingsInput/PatientSettingsInput';

import { LoadingButton } from '@mui/lab';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

import classes from './PatientPortalSettingsContent.module.css'
import '../../../../../globalCss/globalCssMuiButton.css';

interface Props {
  patientData: any;
  paymentMethods: Array<any>;
  handleOpenSlider: Function;
}

const PatientPortalSettingsContent: React.FC<Props> = props => {

  const [loadingLogOut, setLoadingLogOut] = useState(false);

  const handleLogOut = () => {
    setLoadingLogOut(true);
    const auth = getAuth();
    signOut(auth).then(() => {
      // Sign-out successful.
    }).catch((error) => {
      // An error happened.
      console.log("Log out failed");
    });
  };


  if (props.patientData && props.paymentMethods) {
    const defaultPayment = props.paymentMethods.find((payment:any) => payment.id === props.patientData.stripeDefaultPaymentId);

    return(
      <div className={classes.containerPatientPortalSettingsContent}>

        <h2>Account</h2> 
        
        <Grid container rowSpacing="48px" columnSpacing="24px" alignItems="flex-start">

          <Grid item xs={12} sm={6} md={4}>
            <h4>Personal info</h4>
            <div className={classes.section}>

              <PatientSettingsInput 
                label="Legal name"
                value={props.patientData.firstName + " " + props.patientData.lastName}
                clicked={() => props.handleOpenSlider('editName')} 
              />

              <PatientSettingsInput 
                label="Date of birth"
                value={format(props.patientData.dob, 'MM/dd/yyyy')}
                clicked={() => props.handleOpenSlider('editDob')} 
              />
              <PatientSettingsInput 
                label="Phone number" 
                value={props.patientData.phone} 
                clicked={() => props.handleOpenSlider('editPhone')} 
              />

            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <h4>Payments</h4>
            <div className={classes.section}>

              {/*<PatientSettingsInput 
                label="Insurance" 
                value={props.coverageData.payerName} 
                clicked={() => props.handleOpenSlider('editInsurance')} 
              />*/}

              {defaultPayment ? 
                <PatientSettingsInput 
                  label="Payment method"
                  value={defaultPayment.card.brand + " • • • • " + defaultPayment.card.last4} 
                  clicked={() => props.handleOpenSlider('editPaymentMethod')} 
                />
              : props.paymentMethods.length > 0 ?
                <PatientSettingsInput 
                  label="Payment method"
                  value={props.paymentMethods[0].card.brand + " • • • • " + props.paymentMethods[0].card.last4} 
                  clicked={() => props.handleOpenSlider('editPaymentMethod')} 
                />  
              :
                <PatientSettingsInput 
                  label="Payment method" 
                  value="None"
                  clicked={() => props.handleOpenSlider('editPaymentMethod')} 
                />
              }

            </div>
          </Grid>

          {/* <Grid item xs={12} sm={6} md={4}>
            <h4>Login & security</h4>
            <div className={classes.section}>

              <PatientSettingsInput 
                label="Password" 
                clicked={() => props.handleOpenSlider('editPassword')} 
              />

            </div>
          </Grid> */}

          <Grid item xs={12} sm={6} md={4}>
            <h4>Help</h4>
            <div className={classes.section}>
              <PatientSettingsInput 
                  label="Get support by email" 
                  value={"support@allminds.io"} 
                />
            </div>
          </Grid>

        </Grid>

        <LoadingButton onClick={handleLogOut} loading={loadingLogOut} style={{marginTop: '48px'}} variant="contained" size='large' fullWidth color='secondary'>Log out</LoadingButton>

      </div>
    
    );

  }

  else {

    return(
      <div className={classes.containerPatientPortalSettingsContent}>
        <h2>Account</h2>
        <Grid container rowSpacing="48px" columnSpacing="24px" alignItems="flex-start">
          <Grid item xs={12} sm={6} md={4}>
            <Stack spacing="16px">
              <Skeleton variant="text" width={160} height={48} />
              <Skeleton variant="rectangular" height={256} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack spacing="16px">
              <Skeleton variant="text" width={160} height={48} />
              <Skeleton variant="rectangular" height={172} />
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Stack spacing="16px">
              <Skeleton variant="text" width={160} height={48} />
              <Skeleton variant="rectangular" height={68} />
            </Stack>
          </Grid>
        </Grid>
      </div>
    );

  }

};

export default PatientPortalSettingsContent;
