import React, { useState } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import { canvasCreateQuesCalpas12 } from '../../../../../../containers/Models/CanvasQuestionnaires';
import { addCalpas12ToDb } from '../../../../../../containers/Models/assessmentModels/calpas12Model';

import NavBarPatientChat from '../../../../../ui/ChatForm/NavBarPatientChat/NavBarPatientChat';
import ChatFormScreener from '../../../../../ui/ChatForm/ChatFormScreener';
import Skeleton from '@mui/material/Skeleton';

import classes from "../PatientChatPhq15/PatientChatPhq15.module.css";

interface Props {
  appointmentId: string;
  handleCloseChat: any;
}

const PatientChatSessionFeedback: React.FC<Props> = props => {

  const analytics = getAnalytics();
  const patientData = usePatientData();
  const handleUpdateStatePatientData = useUpdatePatientData();
  const [disableBackBtn, setDisableBackBtn] = useState(false);

  //Questions to be included in form
  let questions: any = {
    pcWorthwhile: 
      "Think about your most recent session and decide the degree to which each question best describes your experience." +
      "&&Did you feel that even if you might have moments of doubt, confusion, or mistrust, that overall therapy is worthwhile?",
    pwcSharing: "When important things come to mind, how often did you find yourself keeping them to yourself rather than sharing them with your therapist?",
    tuiAccepted: "Did you feel accepted and respected by your therapist for who you are?",
    pwcFeelings: "How much did you hold back your feelings during this session?",
    wscWorkingTogether: "Did you feel that you were working together with your therapist, that the two of you were joined in a struggle to overcome your problems?",
    tuiDedication: "During this session, how dedicated was your therapist to helping you overcome your difficulties?",
    pcResentment: "How much did you resent the time, cost, or other demands of your therapy?",
    wscGoals: "Did you feel that your therapist understood what you hoped to get out of this session?",
    pcProblems: "How much did you find yourself thinking that therapy was not the best way to get help with your problems?",
    wscExpectations: "Did the treatment you received in this session match with your ideas about what helps people in therapy?",
    pwcProblems: "Did you have the impression that you were unable to deepen your understanding of what is bothering you?",
    tuiProblems: "How much did your therapist help you gain a deeper understanding of your problems?"
  };


  //Object with options for CALPAS and associated scoring value
  const options: any = {
    "1 - Not at all": 1,
    "2 - A little bit": 2,
    "3 - Somewhat": 3,
    "4 - Moderately": 4,
    "5 - Quite a bit": 5,
    "6 - Quite a lot": 6,
    "7 - Very much so": 7,
  };

  const intro = {
    message: 
      "Hey " + patientData.firstName + "!" +
      "&&A cooperative working relationship between you and your provider is an essential aspect of succesful therapy." +
      "&&To help your provider strengthen your relationship, please answer the following questions as honestly as possible.", 
    option: "Start"
  };

  const getScore = (formData: any) => {
    
    let pwc = 0; //Patient Working Capacity score
    let pc = 0; // Patient Commitment score
    let wsc = 0; // Working Strategy Consensus score
    let tui = 0; // Therapist Understanding and Involvement score

    for (let key in formData) {

      if (key === 'pwcSharing' || key === 'pwcFeelings' || key === 'pwcProblems') {
        // scores for these negative questions need to be reflected
        pwc = pwc + ( 8 - options[formData[key][0]] ); 
      }

      else if (key === 'pcWorthwhile') {
        pc = pc + options[formData[key][0]];
      }

      else if (key === 'pcResentment' || key === 'pcProblems') {
        // scores for these negative questions need to be reflected
        pc = pc + ( 8 - options[formData[key][0]] );        
      }

      else if (key === 'wscWorkingTogether' || key === 'wscGoals' || key === 'wscExpectations') {
        wsc = wsc + options[formData[key][0]]; 
      }

      else if (key === 'tuiAccepted' || key === 'tuiDedication' || key === 'tuiProblems') {
        tui = tui + options[formData[key][0]];
      }
    } 

    //Compute mean for every score and round to first decimal place
    pwc = Math.round(pwc / 3 * 10) / 10;
    pc = Math.round(pc / 3 * 10) / 10;
    wsc = Math.round(wsc / 3 * 10) / 10;
    tui = Math.round(tui / 3 * 10) / 10;

    //Compute total mean and round to first decimal place
    let total = (pwc + pc + wsc + tui) / 4;
    total = Math.round(total * 10) / 10;

    let scores = {
      total: total,
      pwc: pwc,
      pc: pc,
      wsc: wsc,
      tui: tui
    };

    return scores

  };

  const getScoreResponse = (formData: any) => {
    let scores = getScore(formData);
    let robotResponse = "";
    if (scores.total < 3)
      robotResponse =
        "Thanks " +
        patientData.firstName +
        ".&&Based on your responses, it seems like you might have some concerns with your therapeutic relationship." + 
        "&&It can take some time to get comfortable and start seeing improvements in your relationship." + 
        "&&Please keep completing this assessment, and feel free to discuss your answers with your therapist.";
    else if (scores.total < 5)
      robotResponse =
        "Thanks " +
        patientData.firstName +
        ".&&Based on your responses, it seems like there might be a few areas of improvement in your therapeutic relationship." + 
        "&&Things are likely to keep progressing naturally as you get more comfortable with your provider." + 
        "&&Please keep completing this assessment, and feel free to discuss your answers with your therapist!";
    else
      robotResponse =
        "Thanks " +
        patientData.firstName +
        ".&&Based on your responses, it seems like you have a strong therapeutic relationship!" + 
        "&&Please keep completing this assessment, and feel free to discuss your answers with your provider.";

    return robotResponse;
  };

  const questionCallback = (dto: any, success: any, error: any, cf: any) => {
    
    const createTag = (name:string, question:string, answers:string[]) => {
      const createTagChildren = () => {
        let tagChildren: any = [];
        answers.forEach((answer: any) => {
          let child: any = {
            tag: "input",
            name: name,
            type: "radio",
            "cf-label": answer,
            value: answer
          };
          tagChildren.push(child);
        });
        return tagChildren;
      }
      let radioTag = {
        tag: "fieldset",
        name: name,
        "cf-input-placeholder": " ",
        "cf-questions": question,
        children: createTagChildren()
      }
      return radioTag
    };

    //At the end of form, submit data to db amd add exit message 
    if ( dto.tag.name === 'tuiProblems') {
      handleSubmit(cf.getFormData(true));
      cf.addTags([createTag(
        "end",
        getScoreResponse(cf.getFormData(true)),
        ["Exit"]
      )]);
      success();
    }
    else if (dto.tag.name === 'end') {
      props.handleCloseChat();
    }
    else success();
  };

  //Function to be called after form is submitted
  const handleSubmit = (formData: any) => {

    setDisableBackBtn(true);

    //Create screenerData object from formData
    const screenerData: any = {};
    for (let key in formData) {
      if (key !== "intro" && key !== "end") {
        screenerData[key] = {
          value: formData[key][0],
          score: options[formData[key][0]]
        };
      }
    }

    let scores = getScore(formData);
    let appointment = patientData.pastAppointments.find((appointment:any) => appointment.id === props.appointmentId);

    //Create object with data
    let calpasData = {
      
      patientId: patientData.id,
      patientCanvasId: patientData.canvasId,
      providerId: patientData.providerData.id,
      providerCanvasId: patientData.providerData.canvasId,
      appointmentId: appointment.id,
      appointmentDate: appointment.start,
      timeStamp: new Date(),

      pcWorthwhile: { value: screenerData.pcWorthwhile.value, score: screenerData.pcWorthwhile.score },
      pwcSharing: { value: screenerData.pwcSharing.value, score: screenerData.pwcSharing.score },
      tuiAccepted: { value: screenerData.tuiAccepted.value, score: screenerData.tuiAccepted.score },
      pwcFeelings: { value: screenerData.pwcFeelings.value, score: screenerData.pwcFeelings.score },
      wscWorkingTogether: { value: screenerData.wscWorkingTogether.value, score: screenerData.wscWorkingTogether.score },
      tuiDedication: { value: screenerData.tuiDedication.value, score: screenerData.tuiDedication.score },
      pcResentment: { value: screenerData.pcResentment.value, score: screenerData.pcResentment.score },
      wscGoals: { value: screenerData.wscGoals.value, score: screenerData.wscGoals.score },
      pcProblems: { value: screenerData.pcProblems.value, score: screenerData.pcProblems.score },
      wscExpectations: { value: screenerData.wscExpectations.value, score: screenerData.wscExpectations.score },
      pwcProblems: { value: screenerData.pwcProblems.value, score: screenerData.pwcProblems.score },
      tuiProblems: { value: screenerData.tuiProblems.value, score: screenerData.tuiProblems.score },

      scorePwc: scores.pwc,
      scorePc: scores.pc,
      scoreWsc: scores.wsc,
      scoreTui: scores.tui,
      scoreTotal: scores.total

    };

    //Upload assessment to db
    addCalpas12ToDb(calpasData).then((res) => {
      //Upload questionnaire to Canvas
      canvasCreateQuesCalpas12(calpasData);
    });

    //Track firebase event
    logEvent(analytics, 'calpasp12_submitted');

    //Update state to update UI
    let updatedPatientData = {...patientData};
    updatedPatientData.lastCalpas = {
      id: '',
      timeStamp: calpasData.timeStamp
    };
    handleUpdateStatePatientData(updatedPatientData);

  };

  return (
    <React.Fragment>
      <NavBarPatientChat 
        handleClose={props.handleCloseChat} 
      />
      <div className={classes.containerChatAssessment}>
        {patientData ? 
          <ChatFormScreener
            questions={questions}
            options={options}
            intro={intro}
            questionCallback={questionCallback}
            disableBackBtn={disableBackBtn}
          />
        :
          <div className={classes.containerSkeleton}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={200} height={60} />
          </div>
        }
      </div>
    </React.Fragment>
  );

};

export default PatientChatSessionFeedback;
