import React from 'react';
import Button from '@mui/material/Button';
import JoinFullOutlinedIcon from '@mui/icons-material/JoinFullOutlined';
import classes from './PatientNoProviderCard.module.css';

interface Props {
  handleOpenFindProvider: React.MouseEventHandler;
}

const PatientNoProviderCard: React.FC<Props> = props => {

  return(
    <div className={classes.containerPatientNoProviderCard}>
      <JoinFullOutlinedIcon fontSize="large" color="inherit" />
      <h5>You don't have a therapist...yet</h5>
      <p>Our therapists are here to help. It's time to find the right fit for you.</p>
      <Button onClick={props.handleOpenFindProvider} variant="contained">Find a therapist</Button>
    </div>
  );

};

export default PatientNoProviderCard;
