import React, { useState } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import { canvasCreateQuesGad7 } from '../../../../../../containers/Models/CanvasQuestionnaires';
import { addGad7ToDb } from '../../../../../../containers/Models/assessmentModels/gad7Model';

import NavBarPatientChat from '../../../../../ui/ChatForm/NavBarPatientChat/NavBarPatientChat';
import ChatFormScreener from '../../../../../ui/ChatForm/ChatFormScreener';
import Skeleton from '@mui/material/Skeleton';

import classes from "../PatientChatPhq15/PatientChatPhq15.module.css";

interface Props {
  handleCloseChat: any;
}

const PatientChatGad7: React.FC<Props> = props => {

  const analytics = getAnalytics();
  const patientData = usePatientData();
  const handleUpdateStatePatientData = useUpdatePatientData();
  const [disableBackBtn, setDisableBackBtn] = useState(false);

  const questions: any = {
    nervous: 
      "For each question, please think about how often you've been bothered <b>in the past two weeks</b>." +
      "&&Have you felt nervous, anxious, or on edge?",
    alwaysWorried: "What about not being able to stop or control worrying?",
    worriedTooMuch: "And worrying too much about different things?",
    troubleRelaxing: "Any trouble relaxing?",
    restless: "Being so restless that it's hard to sit still?",
    irritable: "And becoming easily annoyed or irritable?",
    afraid: "One more! Have you felt afraid as if something awful might happen?"
  };


  //Object with options for gad7 questions and associated scoring value
  const options: any = {
    "Not at all": 0,
    "Several days": 1,
    "More than half the days": 2,
    "Nearly every day": 3
  };

  const intro = {
    message:
      "It's totally normal to feel worried or anxious on occasion." +
      "&&However, sometimes anxiety can become so frequent or so intense it's hard to manage."+
      "&&This quick assessment will help your provider get a better sense of how much anxiety you have been experiencing recently.",
    option: "Okay 👌"
  };

  const getScore = (formData: any) => {
    let score = 0;
    for (let key in formData) {
      if (key !== "intro" && key !== "end") {
        score = score + options[formData[key][0]];
      }
    } 
    return score
  };

  const getScoreResponse = (formData: any) => {
    let score = getScore(formData);
    let response = "";
    if (score < 5)
      response =
        "Thanks " +
        patientData.firstName +
        ".&&It looks like anxiety isn't a concern for you right now." + 
        "&&You can talk to your provider in more detail about your results!";
    else if (score < 10)
      response =
        "Thanks " +
        patientData.firstName +
        ".&&Based on your responses it seems like you are experiencing mild symptoms of anxiety." + 
        "&&You can talk to your provider in more detail about how to best monitor and manage these experiences moving forward!";
    else if (score < 15)
      response =
        "Thanks " +
        patientData.firstName +
        ".&&Based on your responses it seems like you are experiencing moderate symptoms of anxiety." +
        "&&You can talk to your provider in more detail about how to best monitor and manage these experiences moving forward!";
    else
      response =
        "Thanks " +
        patientData.firstName +
        ".&&Based on your responses it seems like you are experiencing significant symptoms of anxiety." +  
        "&&You can talk to your provider in more detail about how to best monitor and manage these experiences moving forward!";

    return response;
  };

  const questionCallback = (dto: any, success: any, error: any, cf: any) => {
    
    const createTag = (name:string, question:string, answers:string[]) => {
      const createTagChildren = () => {
        let tagChildren: any = [];
        answers.forEach((answer: any) => {
          let child: any = {
            tag: "input",
            name: name,
            type: "radio",
            "cf-label": answer,
            value: answer
          };
          tagChildren.push(child);
        });
        return tagChildren;
      }
      let radioTag = {
        tag: "fieldset",
        name: name,
        "cf-input-placeholder": " ",
        "cf-questions": question,
        children: createTagChildren()
      }
      return radioTag
    };

    //At the end of form, submit data to db amd add exit message 
    if (dto.tag.name === 'afraid') {
      handleSubmit(cf.getFormData(true));
      cf.addTags([createTag(
        "end",
        getScoreResponse(cf.getFormData(true)),
        ["Exit"]
      )]);
      success();
    }
    else if (dto.tag.name === 'end') {
      props.handleCloseChat();
    }
    else success();
  };

  //Function to be called after form is submitted
  const handleSubmit = (formData: any) => {

    const getSeverity = (score: number) => {
      if (score < 5) return "Normal";
      else if (score < 10) return "Mild";
      else if (score < 15) return "Moderate";
      else return "Severe";
    };

    setDisableBackBtn(true);

    //Create screenerData object from formData
    const screenerData: any = {};
    for (let key in formData) {
      if (key !== "intro" && key !== "end") {
        screenerData[key] = {
          value: formData[key][0],
          score: options[formData[key][0]]
        };
      }
    }

    let score = getScore(formData);
    let severity = getSeverity(score);

    //Create object with gad7 data
    let gad7Data = {
      
      patientId: patientData.id,
      patientCanvasId: patientData.canvasId,
      providerId: patientData.providerData.id,
      providerCanvasId: patientData.providerData.canvasId,
      timeStamp: new Date(),

      nervous: { value: screenerData.nervous.value, score: screenerData.nervous.score },
      alwaysWorried: { value: screenerData.alwaysWorried.value, score: screenerData.alwaysWorried.score },
      worriedTooMuch: { value: screenerData.worriedTooMuch.value, score: screenerData.worriedTooMuch.score },
      troubleRelaxing: { value: screenerData.troubleRelaxing.value, score: screenerData.troubleRelaxing.score },
      restless: { value: screenerData.restless.value, score: screenerData.restless.score },
      irritable: { value: screenerData.irritable.value, score: screenerData.irritable.score },
      afraid: { value: screenerData.afraid.value, score: screenerData.afraid.score },

      score: score,
      severity: severity

    };

    //Upload assessment to db
    addGad7ToDb(gad7Data).then((res) => {
      //Upload questionnaire to Canvas
      canvasCreateQuesGad7(gad7Data);
    });

    //Track firebase event
    logEvent(analytics, 'gad7_completed');

    //Update state with next due date for assessment
    let updatedPatientData = {...patientData};
    updatedPatientData.lastGad7 = {
      id: '',
      timeStamp: gad7Data.timeStamp
    };
    handleUpdateStatePatientData(updatedPatientData);

  };

  return (
    <React.Fragment>
      <NavBarPatientChat 
        handleClose={props.handleCloseChat} 
      />
      <div className={classes.containerChatAssessment}>
        {patientData ? 
          <ChatFormScreener
            questions={questions}
            options={options}
            intro={intro}
            questionCallback={questionCallback}
            disableBackBtn={disableBackBtn}
          />
        :
          <div className={classes.containerSkeleton}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={200} height={60} />
          </div>
        }
      </div>
    </React.Fragment>
  );

};

export default PatientChatGad7;
