import React, { useState, useEffect } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import { canvasCreateQuesPreIntake } from '../../../../../../containers/Models/CanvasQuestionnaires';
import { addPreIntakeToDb } from '../../../../../../containers/Models/assessmentModels/preIntakeModel';

import NavBarPatientChat from '../../../../../ui/ChatForm/NavBarPatientChat/NavBarPatientChat';
import ChatForm from '../../../../../ui/ChatForm/ChatForm';
import Skeleton from '@mui/material/Skeleton';

import classes from "../../../PatientPortalOutcomes/PatientPortalOutcomesSliders/PatientChatPhq15/PatientChatPhq15.module.css";

interface Props {
  handleCloseChat: any;
  handleScrollTop: Function;
}

const PatientPreIntake: React.FC<Props> = props => {

  const analytics = getAnalytics();
  const patientData = usePatientData();
  const handleUpdateStatePatientData = useUpdatePatientData();
  const [disableBackBtn, setDisableBackBtn] = useState(false);

  let handleScrollTop = props.handleScrollTop;
  useEffect(() => {
    handleScrollTop();
  }, [handleScrollTop])

  const questions: any = {
    intro: "Hi " + patientData.firstName + "! 👋"
            + "&&The following questions will help your provider gather necessary information about your background before your first appointment.",
    //Contact information
    reasonsSeekingTherapy: "What are some of the reasons you seeking therapy now?",
    therapyGoals: "What do you hope to gain from your therapy experience?",
    preferredName: "What is your preferred name?",
    localAddress: "What is your current address?",
    emergencyContactName: "What is the name of your emergency contact?",
    emergencyContactNumber: "What is their phone number?",
    hasPcp: "Do you have a primary care physician?",
    hasPsychiatrist: "Do you have a psychiatrist?",
    //Medication
    ongoingPsychiatricMedication: "Are you currently taking psychiatric medication?",
    psychiatricMedicationTakenInPast: "Have you ever taken psychiatric medication in the past?",
    //Medical History
    chronicHealthCondition: "Are you currently managing a chronic health condition?",
    seriousAccidentOrInjury: "Have you ever been in a serious accident or been seriously injured?",
    //Mental Health History
    diagnosedWithMentalHealthCondition: "Have you ever been diagnosed with a mental health condition?",
    takenPastMentalHealthTherapy: "Have you ever been in therapy in the past?",
    hospitalizedForPsychiatricReason: "Have you ever been hospitalized for a psychiatric reason?",
    //Family
    maritalStatus: "What is your marital status?",
    numberOfChildren: "How many children do you have?",
    familyHistoryOfMentalHealth: "Do you have a family history of mental health conditions?",
    //Occupation
    employmentStatus: "What is your employment status?",
    educationLevel: "What is your highest level of education?",
    //End
    end: "That's a wrap! 🙌" + 
        "&&Please feel free to discuss your answers with your provider. Thanks!"

  };


  //Input choices for each form question
  const options: any = {
    intro: { type: "radio", answers: ["Let's get started 👍"] },
    //Contact information
    reasonsSeekingTherapy: { type: "text" },
    therapyGoals: { type: "text" },
    preferredName: { type: "text" },
    localAddress: { type: "text" },
    emergencyContactName: { type: "text" },
    emergencyContactNumber: { type: "number" },
    hasPcp: { type: "radio", answers: ["Yes", "No"] },
    hasPsychiatrist: { type: "radio", answers: ["Yes", "No"] },
    //Medication
    ongoingPsychiatricMedication: { type: "radio", answers: ["Yes", "No"] },
    psychiatricMedicationTakenInPast: { type: "radio", answers: ["Yes", "No"] },
    //Medical history
    chronicHealthCondition: { type: "radio", answers: ["Yes", "No"] },
    seriousAccidentOrInjury: { type: "radio", answers: ["Yes", "No"] },
    //Mental history
    diagnosedWithMentalHealthCondition: { type: "radio", answers: ["Yes", "No"] },
    takenPastMentalHealthTherapy: { type: "radio", answers: ["Yes", "No"] },
    hospitalizedForPsychiatricReason: { type: "radio", answers: ["Yes", "No"] },
    //Family
    maritalStatus: { type: "radio", answers: ["Never married-Single", "Never married-In relationship", "Married", "Divorced", "Separated", "Widowed"] },
    numberOfChildren: { type: "number" },
    familyHistoryOfMentalHealth: { type: "radio", answers: ["Yes", "No"] },
    //Occupation
    employmentStatus: { type: "radio", answers: ["Employed-part-time", "Employed-full-time", "Unemployed", "Disability/leave status"] },
    educationLevel: { type: "radio", answers: ["Below 8", "9", "10", "11", "12", "Some college", "Graduated college", "Master's or professional degree", "Doctorate"] },
    end: { type: "radio", answers: ["Exit"] },
  };

  //This blank conditions object is no longer used but needs to be passed as obsolete prop
  const conditions = {};

  const questionCallback = (dto: any, success: any, error: any, cf: any) => {
    
    //This function looks for a specific tag and removes it if found
    const removeTag = (tagName:string, tags:any[]) => {
      //look for tag
      let tagIndex = tags.findIndex((tag: any) => tag.name === tagName);
      //Remove tag if found
      if (tagIndex > -1) {
        tags.splice(tagIndex, 1);
      }
    };

    const createTag = (name:string, question:string, answers:string[]) => {
      const createTagChildren = () => {
        let tagChildren: any = [];
        answers.forEach((answer: any) => {
          let child: any = {
            tag: "input",
            name: name,
            type: "radio",
            "cf-label": answer,
            value: answer
          };
          tagChildren.push(child);
        });
        return tagChildren;
      }
      let radioTag = {
        tag: "fieldset",
        name: name,
        "cf-input-placeholder": " ",
        "cf-questions": question,
        children: createTagChildren()
      }
      return radioTag
    };

    const createTextTag = (name:string, question:string) => {
      let child: any = {
        tag: "input",
        name: name,
        type: "text",
        "cf-label": name,
      };      
      let textTag = {
        tag: "fieldset",
        name: name,
        "cf-input-placeholder": " ",
        "cf-questions": question,
        children: [child]
      }
      return textTag
    };

    const createNumberTag = (name:string, question:string) => {
      let child: any = {
        tag: "input",
        name: name,
        type: "number",
        "cf-label": name,
      };      
      let textTag = {
        tag: "fieldset",
        name: name,
        "cf-input-placeholder": " ",
        "cf-questions": question,
        children: [child]
      }
      return textTag
    };

    //Add all conditional questions here
    
    if (dto.tag.name === 'hasPcp') {
      removeTag("primaryPhysicianName", cf.tags);
      if (dto.tag.value[0] == "Yes") 
        cf.addTags([createTextTag("primaryPhysicianName", "What is their name?")]);  
      success();
    }
    
    else if (dto.tag.name === 'primaryPhysicianName') {
      removeTag("primaryPhysicianNumber", cf.tags);
      cf.addTags([createNumberTag("primaryPhysicianNumber", "And what is their phone number?")]);  
      success();
    }

    else if (dto.tag.name === 'hasPsychiatrist') {
      removeTag("psychiatristName", cf.tags);
      if (dto.tag.value[0] == "Yes") 
        cf.addTags([createTextTag("psychiatristName", "What is their name?")]);  
      success();
    }

    else if (dto.tag.name === 'psychiatristName') {
      removeTag("psychiatristNumber", cf.tags);
      cf.addTags([createNumberTag("psychiatristNumber", "What is their phone number?")]);  
      success();
    }

    else if (dto.tag.name === 'ongoingPsychiatricMedication') {
      removeTag("ongoingPsychiatricMedicationDetails", cf.tags);
      if (dto.tag.value[0] == "Yes") 
        cf.addTags([createTextTag("ongoingPsychiatricMedicationDetails", "Please list your current medication and dosage")]);  
      success();
    }

    else if (dto.tag.name === 'psychiatricMedicationTakenInPast') {
      removeTag("pastPsychiatricMedicationDetails", cf.tags);
      if (dto.tag.value[0] == "Yes") 
        cf.addTags([createTextTag("pastPsychiatricMedicationDetails", "Please list your past medication and dosage")]);  
      success();
    }

    else if (dto.tag.name === 'chronicHealthCondition') {
      removeTag("chronicHealthConditionDetails", cf.tags);
      if (dto.tag.value[0] == "Yes") 
        cf.addTags([createTextTag("chronicHealthConditionDetails", "Please describe the condition")]);  
      success();
    }  

    else if (dto.tag.name === 'seriousAccidentOrInjury') {
      removeTag("seriousAccidentOrInjuryDetails", cf.tags);
      if (dto.tag.value[0] == "Yes") 
        cf.addTags([createTextTag("seriousAccidentOrInjuryDetails", "Please describe the accident or injury")]);  
      success();
    } 

    else if (dto.tag.name === 'diagnosedWithMentalHealthCondition') {
      removeTag("diagnosedMentalHealthDetails", cf.tags);
      if (dto.tag.value[0] == "Yes") 
        cf.addTags([createTextTag("diagnosedMentalHealthDetails", "Please describe the condition")]);  
      success();
    } 

    else if (dto.tag.name === 'takenPastMentalHealthTherapy') {
      removeTag("reasonForPastMentalHealthTherapy", cf.tags);
      if (dto.tag.value[0] == "Yes") 
        cf.addTags([createTextTag("reasonForPastMentalHealthTherapy", "What was the reason you sought treatment?")]);  
      success();
    } 

    else if (dto.tag.name === 'hospitalizedForPsychiatricReason') {
      removeTag("whenHospitalizedForPsychiatricTherapy", cf.tags);
      if (dto.tag.value[0] == "Yes") 
        cf.addTags([createTextTag("whenHospitalizedForPsychiatricTherapy", "How recently?")]);  
      success();
    } 

    else if (dto.tag.name === 'familyHistoryOfMentalHealth') {
      removeTag("familyHistoryOfMentalHealthDetails", cf.tags);
      if (dto.tag.value[0] == "Yes") 
        cf.addTags([createTextTag("familyHistoryOfMentalHealthDetails", "Please describe the conditions")]);  
      success();
    } 

    else if (dto.tag.name === 'employmentStatus') {
      removeTag("employmentDetails", cf.tags);
      if (dto.tag.value[0] !== "Unemployed") 
        cf.addTags([createTextTag("employmentDetails", "What do you do for work?")]);  
      success();
    } 
    
    else if (dto.tag.name === 'employmentDetails') {
      removeTag("jobSatisfaction", cf.tags);
      let answers = ["Very satisfied", "Somewhat satisfied", "Neutral", "Disatisfied", "Very disatisfied"]
      cf.addTags([createTag("jobSatisfaction", "How satisfied are you with your job?", answers)]);  
      success();
    } 

    else if (dto.tag.name === 'educationLevel') {
      handleSubmit(cf.getFormData(true));
      success();
    } 

    else if (dto.tag.name === 'end') {
      props.handleCloseChat();
    }

    else success();
  };

  //Function to be called after form is submitted
  const handleSubmit = (formData: any) => {

    setDisableBackBtn(true);

    //Initialize preIntakeData
    let preIntakeData:any = {
      patientId: patientData.id,
      patientCanvasId: patientData.canvasId,
      providerId: patientData.providerData.id,
      providerCanvasId: patientData.providerData.canvasId,
      timeStamp: new Date(),
    };
    //Add values from form to preIntakeData
    for (let key in formData) {
      if (key !== "intro" && key !== "end") {
        if (formData[key][0] == 'Yes') // Convert all Yes's to boolean
          preIntakeData[key] = true;
        else if (formData[key][0] == 'No') // Convert all No's to booleans
          preIntakeData[key] = false;
        else if (formData[key].constructor === Array) // for radio answers
          preIntakeData[key] = formData[key][0];
        else // for string answers
        preIntakeData[key] = formData[key];
      }
    }
    //console.log(preIntakeData);
    addPreIntakeToDb(preIntakeData).then((id) => {
      //Upload questionnaire to Canvas
      canvasCreateQuesPreIntake(preIntakeData); 
      //Update state 
      let updatedPatientData = {...patientData};
      updatedPatientData.preIntakeId = id;
      handleUpdateStatePatientData(updatedPatientData);

    });

  };

  return (
    <React.Fragment>
      <NavBarPatientChat 
        handleClose={props.handleCloseChat} 
      />
      <div className={classes.containerChatAssessment}>
        {patientData ? 
          <ChatForm
            questions={questions}
            options={options}
            conditions={conditions}
            questionCallback={questionCallback}
            //endResponse={endResponse}
            handleSubmit={() => {}} //pass blank function since handleSubmit is now handled in parent
            disableBackBtn={disableBackBtn}
          />
        :
          <div className={classes.containerSkeleton}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={200} height={60} />
          </div>
        }
      </div>
    </React.Fragment>
  );

};

export default PatientPreIntake;
