import React, {useState, useEffect} from 'react';
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { createPatientObject } from "../../../containers/Models/PatientModel";
import { getMatchedProviderIds } from "../../../containers/Models/ProviderModel";
import { getEligibility } from "../../../containers/Models/eligibility";
//Components
import NavBarPatientIntake from './NavBarPatientIntake/NavBarPatientIntake';
import PatientIntake from './PatientIntake/PatientIntake';
import SelectProvider from './SelectProvider/SelectProvider';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
//CSS
import classes from './StartTherapy.module.css';

interface Props {}

const StartTherapy: React.FC<Props> = props => {

  const navigate = useNavigate();
  const [providerIds, setProviderIds] = useState<any>(null);
  const [coverageData, setCoverageData] = useState<any>(null);
  const [loadingCoverage, setLoadingCoverage] = useState(false);

  useEffect(() => {
    
    //Redirect any signed-in users to homepage
    const auth = getAuth();
    if (auth.currentUser) {
      navigate("/");    
    }

    //Search for previous matching data in local storage
    let intakeJSON = localStorage.getItem('intakeData');

    //If matching data is found, fetch matching provider ids
    if (intakeJSON) {
      
      let intakeData = JSON.parse(intakeJSON);
      intakeData.dob = new Date(intakeData.dob);
      let providerData = {id: ""}; //Placeholder var to create patient obj
      let patientObj = createPatientObject(intakeData, providerData);
      
      //If member has insurance fetch eligibility data
      if (intakeData.memberId && intakeData.payerId) {
        setLoadingCoverage(true);
        getEligibility(intakeData.memberId, intakeData.payerId, intakeData.dob)
        .then(data => {
          setCoverageData(data);
          setLoadingCoverage(false)
          getMatchedProviderIds(patientObj, data.payer, data.isMedicare).then((ids) => {
            setProviderIds(ids);
          });
        });
      }
      //Else proceed without insurance information
      else {
        getMatchedProviderIds(patientObj).then((ids) => {
          setProviderIds(ids);
        });
      }
    }

  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>
          Find a Therapist - Allminds
        </title>
      </Helmet>
      {/* <NavBarPatientIntake patientIsMatched={providerIds ? true : false} /> */}
      <div className={classes.container}>
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="warning"><strong>Allminds is undergoing maintenance until Friday 2/16, 6:00 am PST.</strong> If you have any questions reach out to support@allminds.io.</Alert>
      </Stack>
        {/* {providerIds ? 
          <SelectProvider
            providerIds={providerIds}
            coverageData={coverageData}
            loadingCoverage={loadingCoverage}
          />
        : 
        !localStorage.getItem('intakeData') ?
          <PatientIntake
            setProviderIds={setProviderIds}
            setCoverageData={setCoverageData}
          /> 
        :
        null
        } */}

      </div>
    </React.Fragment>
  );
};

export default StartTherapy;
