//require("dotenv").config();

const sgSendMail = async (toEmail:string, templateName:string, data:any) => {
  const server = process.env.REACT_APP_SERVERURL;
  return await fetch(server + "/send-sg-email", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      email: toEmail,
      templateName: templateName,
      templateData: data
    })
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      // console.log("Got result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error sending email: ", error);
    });
};



//HANDLERS FOR PATIENT EMAILS

const sgPatientSignupWelcome = (
  toEmail: string, 
  patientName: string
) => {
    let templateName = "patient_signup_welcome";
    let emailData = {
      first_name: patientName
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgPatientConsultationRequested = (
  toEmail: string, 
  patientName: string,
  patientPhone: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "patient_consultation_requested";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: patientName,
      phone_number: patientPhone,
      therapist_name: providerName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgPatientAppointmentRescheduledByPatient = (
  toEmail: string, 
  patientName: string, 
  providerName: string, 
  appointmentService: string, 
  appointmentDate: Date
) => {
    let templateName = "patient_appointment_rescheduled_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: patientName,
      therapist_name: providerName,
      appointment_service: 
        appointmentService === "individual" ? "therapy" 
        : appointmentService === "intake" ? "therapy" 
        : appointmentService === "consultation" ? "consultation" 
        : "appointment",
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgPatientAppointmentCanceled = (
  toEmail: string, 
  patientName: string, 
  providerName: string, 
  appointmentService: string, 
  appointmentDate: Date
) => {
    let templateName = "patient_appointment_canceled";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: patientName,
      therapist_name: providerName,
      appointment_service: 
        appointmentService === "individual" ? "therapy" 
        : appointmentService === "intake" ? "therapy" 
        : appointmentService === "consultation" ? "consultation" 
        : "appointment",
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgPatientTherapyTimeRequestedByPatient = (
  toEmail: string, 
  patientName: string, 
  providerName: string, 
  therapyFrequency: number,
  appointmentDate: Date
) => {
    let templateName = "patient_therapy_time_requested_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: patientName,
      therapist_name: providerName,
      therapy_frequency: therapyFrequency === 1 ? "Every week" : therapyFrequency === 2 ? "Every two weeks" : therapyFrequency === 3 ? "Every three weeks" : therapyFrequency === 4 ? "Every four weeks" : "" ,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgPatientLateCancelStrike = (
  toEmail: string, 
  patientName: string, 
  providerName: string, 
  appointmentDate: Date,
  strikeCount: number
) => {
    let templateName = "patient_late_cancel_strike";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: patientName,
      therapist_name: providerName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset,
      strike_count: strikeCount
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgPatientStrikeHold = (
  toEmail: string, 
  patientName: string, 
  providerName: string, 
  appointmentDate: Date,
) => {
    let templateName = "patient_strike_hold";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: patientName,
      therapist_name: providerName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgPatientStrikeHoldV2 = (
  toEmail: string, 
  patientName: string, 
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "patient_strike_hold_v2";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: patientName,
      therapist_name: providerName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgPatientFirstSessionChecklist = (
  toEmail: string, 
  patientName: string
) => {
    let templateName = "patient_first_session_checklist";
    let emailData = {
      first_name: patientName
    };
    sgSendMail(toEmail, templateName, emailData);
};



//HANDLERS FOR PROVIDER EMAILS

const sgProviderConsultationRequested = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "provider_consultation_requested";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderTherapyRequested = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "provider_therapy_requested";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderTherapyTimeRequestedByPatient = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "provider_therapy_time_requested_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderTherapyConfirmedByPatient = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "provider_therapy_confirmed_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderTherapyDeclinedByPatient = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "provider_therapy_declined_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderTherapyCanceledByPatient = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "provider_therapy_canceled_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderAppointmentRescheduledByPatient = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentService: string,
  appointmentDate: Date
) => {
    let templateName = "provider_appointment_rescheduled_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      appointment_service: 
        appointmentService === "individual" ? "therapy" 
        : appointmentService === "intake" ? "intake" 
        : appointmentService === "consultation" ? "consultation" 
        : "appointment",
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderSessionConfirmedByPatient = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "provider_session_confirmed_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderSessionCanceledByPatient = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "provider_session_canceled_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderConsultationCanceledByPatient = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "provider_consultation_canceled_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderIntakeCanceledByPatient = (
  toEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "provider_intake_canceled_by_patient";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderNewMessageFromPatient = (
  toEmail: string, 
  providerName: string,
  patientCanvasId: string
) => {
    let templateName = "provider_new_message_from_patient";
    let emailData = {
      first_name: providerName,
      url_chart: 'https://allminds.canvasmedical.com/patient/' + patientCanvasId
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderStrikeClient = (
  toEmail: string, 
  providerName: string, 
  patientName: string, 
  appointmentDate: Date,
  strikeCount: number,
  patientCanvasId: string,
) => {
    let templateName = "provider_strike_client";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset,
      strike_count: strikeCount,
      url_chart: 'https://allminds.canvasmedical.com/patient/' + patientCanvasId
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderStrikeHold = (
  toEmail: string, 
  patientName: string, 
  providerName: string, 
  appointmentDate: Date,
) => {
    let templateName = "provider_strike_hold";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderStrikeHoldV2 = (
  toEmail: string, 
  providerName: string,
  patientName: string, 
  appointmentDate: Date,
  patientCanvasId: string
) => {
    let templateName = "provider_strike_hold_v2";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: providerName,
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset,
      url_chart: 'https://allminds.canvasmedical.com/patient/' + patientCanvasId
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgProviderSuicidalityAlert = (
  toEmail: string,
  patientName: string, 
  appointmentDate: Date,
  patientCanvasId: string
) => {
    let templateName = "provider_suicidality_alert";
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      patient_name: patientName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset,
      url_chart: 'https://allminds.canvasmedical.com/patient/' + patientCanvasId
    };
    sgSendMail(toEmail, templateName, emailData);
};




//HANDLERS FOR ADMIN EMAILS

const sgAllmindsNewUser = (
  patientEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "allminds_new_user";
    let toEmail = "notif-new-signup@allminds.io"
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: patientName,
      patient_email: patientEmail,
      therapist_name: providerName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgAllmindsNewPatient = (
  patientEmail: string, 
  patientName: string,
  providerName: string, 
  appointmentDate: Date
) => {
    let templateName = "allminds_new_patient";
    let toEmail = "notif-new-patient@allminds.io"
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      first_name: patientName,
      patient_email: patientEmail,
      therapist_name: providerName,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const sgAllmindsLateCancel = (
  patientEmail: string, 
  appointmentId: string,
  appointmentDate: Date
) => {
    let templateName = "allminds_late_cancel";
    let toEmail = "notif-late-cancel@allminds.io"
    let timezoneOffset = getSgTimezoneOffset(appointmentDate);
    let emailData = {
      patient_email: patientEmail,
      appointment_id: appointmentId,
      timeStamp:  appointmentDate,
      timezoneOffset: timezoneOffset
    };
    sgSendMail(toEmail, templateName, emailData);
};

const getDstTzOffset = (start: Date) => {
  //Code to get the second Sunday in March when DST begins
  let d = new Date();
  let year = d.getFullYear();
  const March = 2;  // Months start from 0
  const Nov = 10;
  let dateMarch = new Date(year, March, 1);
  let dayOfWeek = dateMarch.getDay();
  let offset = 0;
  if (dayOfWeek === 0) {
    offset = 7;
  } else {
    offset = dayOfWeek;
  }
  let secondSunday = 8 - offset + 7;
  let dstStartDate = new Date(year, March, secondSunday, 9, 0, 0, 0);

  //Code to get the first Sunday in November when DST ends
  let dateNov = new Date(year, Nov, 1);
  dayOfWeek = dateNov.getDay();
  offset = 0;
  if (dayOfWeek === 0) {
    offset = 7;
  } else {
    offset = dayOfWeek;
  }
  let firstSunday = 1 - offset + 7;
  let dstEndDate = new Date(year, Nov, firstSunday, 9, 0, 0, 0);

  let tzoffset = 7;
  if (start.getTime() < dstStartDate.getTime() || start.getTime() > dstEndDate.getTime()) {
    tzoffset = 8;
  }

  return tzoffset;  
}

//Returns timezone offset for sendgrid template 
//Format should be +hhmm or -hhmm (e.g. PDT should be "-0700")
const getSgTimezoneOffset = (date: Date) => {  
  let tzoffset = getDstTzOffset(date);
  if (tzoffset === 8) {
    return "-0800";
  } else {
    return "-0700";
  }
};

export {
  sgPatientSignupWelcome,
  sgPatientConsultationRequested,
  sgPatientAppointmentRescheduledByPatient,
  sgPatientAppointmentCanceled,
  sgPatientTherapyTimeRequestedByPatient,
  sgPatientFirstSessionChecklist,
  sgPatientLateCancelStrike,
  sgPatientStrikeHold,
  sgPatientStrikeHoldV2,
  sgProviderConsultationRequested,
  sgProviderTherapyRequested,
  sgProviderTherapyTimeRequestedByPatient,
  sgProviderTherapyConfirmedByPatient,
  sgProviderTherapyDeclinedByPatient,
  sgProviderTherapyCanceledByPatient,
  sgProviderAppointmentRescheduledByPatient,
  sgProviderSessionConfirmedByPatient,
  sgProviderSessionCanceledByPatient,
  sgProviderConsultationCanceledByPatient,
  sgProviderIntakeCanceledByPatient,
  sgProviderNewMessageFromPatient,
  sgProviderStrikeClient,
  sgProviderStrikeHold,
  sgProviderStrikeHoldV2,
  sgProviderSuicidalityAlert,
  sgAllmindsNewUser,
  sgAllmindsNewPatient,
  sgAllmindsLateCancel
};
