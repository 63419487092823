import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  open: any;
  setOpen: Function;
  label?: string;
}

const ErrorSnackbar: React.FC<Props> = props => {

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    props.setOpen(null);
  };

  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
      <Snackbar
        open={props.open}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        autoHideDuration={10000}
        onClose={handleClose}
        message= {props.label ? props.label : "Yikes! Something went wrong. Please try again later."}
        action={action}
        style={{position: "fixed"}}
      />
  );
};

export default ErrorSnackbar;