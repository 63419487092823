import React, {useState, useEffect} from 'react';
import {useStripe, useElements} from '@stripe/react-stripe-js';

import { usePatientData } from "../../../../../../hooks/ContextPatientData/ContextPatientData";
//import useFetchPatientAppointments from "../../../../../../hooks/useFetchPatientAppointments/useFetchPatientAppointments";
import {getPaymentIntentById} from '../../../../../../containers/Models/stripe';
import {updateAppointmentPayment} from '../../../../../../containers/Models/AppointmentModel';
import {canvasUpdateAppointment, canvasCreateNextPatientAppointment} from '../../../../../../containers/Models/Canvas';

import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';
import '../../../../../../globalCss/globalCssMuiButton.css';


interface Props {
  appointment: any;
  setError: Function;
}

const PaymentAuthAlert: React.FC<Props> = props => {

  const stripe = useStripe();
  const elements = useElements();

  const patientData = usePatientData();
  // const updateStatePatientData = useUpdatePatientData();
  // const appointments = useFetchPatientAppointments();

  const [intent, setIntent] = useState<any>(null);
  //const intent = await getPaymentIntentById(props.appointment.stripePaymentIntentId);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (!intent || intent.id !== props.appointment.stripePaymentIntentId) {
      getPaymentIntentById(props.appointment.stripePaymentIntentId).then(intent => {
        setIntent(intent.paymentIntent);
      });
    }
  }, [props.appointment, intent]);

  const handleAuthStripePayment = async () => {
    if (!stripe || !elements ||!intent) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    else {

      setLoading(true);

      let updatedAppointment = {...props.appointment};

      return stripe.confirmCardPayment(intent.client_secret, {
        // payment_method: intent.paymentIntent.last_payment_error.payment_method.id
        payment_method: patientData.stripeDefaultPaymentId
      })

      .then(async function(res) {

        if (res.error) {
          // Show error to your customer
          setLoading(false);
          console.log(res.error);
          props.setError(res.error);
          throw (res.error);
        } 
        else {
          if (res.paymentIntent.status === 'succeeded') {
            // The payment is complete!
            updatedAppointment.amountPaid = res.paymentIntent.amount;
            //Update appointment in db
            return updateAppointmentPayment(props.appointment.id, res.paymentIntent.id, res.paymentIntent.amount);
          }
        }

      })
      //Update canvas appointment to fulfilled
      .then(() => {
        if (
          updatedAppointment.status === 'booked' 
          || updatedAppointment.status === 'proposed'
          || updatedAppointment.status === 'pending'
        ) {
          updatedAppointment.status = 'fulfilled';
          return canvasUpdateAppointment(updatedAppointment);
        }
        else if (updatedAppointment.status === 'noshow') {
          return canvasCreateNextPatientAppointment(updatedAppointment);
        }
        else {
          return;
        }
      })
      //Update state
      .then(() => {
        // return handleUpdateStateAppointments(updatedAppointment);
        return setSuccess(true);
      });

    }

  };

  //Finds the updated appointment in patientData and updates it
  // const handleUpdateStateAppointments = (newAppointment: any) => {

  //   let updatedUpcomingAppointments = [...appointments.upcoming];
  //   let updatedPastAppointments = [...appointments.past];

  //   let index = appointments.past.findIndex( (appointment: any) => appointment.id === newAppointment.id);
  //   if (index > -1) {
  //     updatedPastAppointments[index] = newAppointment;
  //   }
  //   else {
  //     index = appointments.upcoming.findIndex( (appointment: any) => appointment.id === newAppointment.id);
  //     if (index > -1) {
  //       updatedUpcomingAppointments[index] = newAppointment;
  //     }
  //   }

  //   let updatedPatientData = {...patientData}
  //   updatedPatientData.appointments = updatedUpcomingAppointments;
  //   updatedPatientData.pastAppointments = updatedPastAppointments;

  //   updateStatePatientData(updatedPatientData);

  // };

  if (!success) {
    return(
      <Alert severity="error">
        <h5>Payment declined</h5>
        {intent ? (
          <div>
            <p><strong>{intent.description}</strong></p>
            <p>Your upcoming appointments are on hold until the payment gets resolved.</p>
          </div>
        ) : (
          <Skeleton variant="text" width="100%" height={32} />
        )}
        <LoadingButton onClick={handleAuthStripePayment} disabled={!intent || !stripe || !elements} loading={loading} variant="contained" style={{backgroundColor: 'rgb(95, 33, 32)', marginTop: '16px', marginBottom: '8px'}}>Try payment again</LoadingButton>
      </Alert>
    );
  }
  else {
    return(
      <Alert severity="success">
        <h5>Payment successful</h5>
        <p>Thanks! Your upcoming appointments will resume shortly.</p>
      </Alert>
    );
  }

};

export default PaymentAuthAlert;
