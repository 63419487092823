import React, {useState, useEffect} from 'react';
import { usePatientData, useUpdatePatientData } from "../../../../../../hooks/ContextPatientData/ContextPatientData";
import { getDocLink } from '../../../../../../containers/Models/pandaDoc';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import classes from './PatientSignConsent.module.css';

interface Props {
  closeConsent: any;
  handleScrollTop: Function;
}

const PatientSignConsent: React.FC<Props> = props => {
  
  let handleScrollTop = props.handleScrollTop;
  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();
  const [pandaDocLink, setPandaDocLink] = useState<any>(null);

  useEffect(() => {
    handleScrollTop();
  }, [handleScrollTop])

  useEffect(() => {
    if (patientData && !pandaDocLink)
      getDocLink(patientData.id)
        .then(docLink => {setPandaDocLink(docLink.url)})
  }, [patientData, pandaDocLink]);

  //Set up listener for when pandadoc is signed/completed
  useEffect(() => {
    const onMessage = (event:any) => {
      const type = event.data && event.data.type;
      const payload = event.data && event.data.payload;
      if (type == 'session_view.document.completed') {
        let updatedPatientData = {...patientData};
        //Set pandaDocId as consentId in state temporarily to update state
        updatedPatientData.consentId = payload.uuid;
        updateStatePatientData(updatedPatientData);
      }
    };
    window.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    }
  }, []);

  return(
    <div className={classes.containerPatientSignConsent}>
      
      <div className={classes.header}>
        <IconButton onClick={props.closeConsent} className={classes.closeBtn} aria-label="start over">
          <CloseRoundedIcon />
        </IconButton>
        <h2>Sign consent forms</h2>
        <p>Review and sign your consent forms before your first session.</p>
      </div>

      {patientData && patientData.consentId ? (
        <Alert severity="success" style={{margin: '0px 16px'}}>
          <p>Your consent forms have been signed. Thanks!</p>
        </Alert>
      ) : null}

      {patientData && pandaDocLink ? (
        <div className={classes.containerPandaDoc}>
          <iframe src={pandaDocLink} width="100%" height="100%" frameBorder="0"></iframe>
        </div>
      ) : (
        <Stack spacing={2} style={{padding: '0px 16px 0px 16px'}}>
          <Skeleton variant="rectangular" height={44} />
          <Skeleton variant="rectangular" height={500} />
        </Stack>
      )}

    </div>
  );

};

export default PatientSignConsent;
