import React, { useState, useEffect } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

// import {testServerFetch} from '../../../../../containers/Models/test';
import useWindowDimensions from '../../../../../hooks/useWindowDimensions/useWindowDimensions';
import useFetchPatientAppointments from '../../../../../hooks/useFetchPatientAppointments/useFetchPatientAppointments';

import UpcomingAppointmentCard from './UpcomingAppointmentCard/UpcomingAppointmentCard';
import PatientPortalHomeScheduleSessionCard from './PatientPortalHomeScheduleSessionCard/PatientPortalHomeScheduleSessionCard';
import PatientNoProviderCard from './PatientNoProviderCard/PatientNoProviderCard';
import ProviderCard from './ProviderCard/ProviderCard';
import PaymentAuthAlert from './PaymentAuthAlert/PaymentAuthAlert';
import ConsentAlert from './ConsentAlert/ConsentAlert';
import PreIntakeCard from './PreIntakeCard/PreIntakeCard';
import TreatmentHoldAlert from './TreatmentHoldAlert/TreatmentHoldAlert';
import PatientNextAppointmentPendingCard from '../../PatientPortalAppointments/PatientPortalAppointmentsContent/PatientPortalAppointmentsUpcoming/PatientNextAppointmentPendingCard/PatientNextAppointmentPendingCard';
import ErrorSnackbar from '../../../../ui/ErrorSnackbar/ErrorSnackbar';
import PatientCompleteAssessments from '../../PatientPortalOutcomes/PatientPortalOutcomesContent/PatientCompleteAssessments/PatientCompleteAssessments';

import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import classes from './PatientPortalHomeContent.module.css';

interface Props {
  patientData: any; // See ContextPatientData for object structure
  appointmentsData: any; // See useFetchPatientAppointments for object structure
  handleOpenProviderProfile: Function;
  handleOpenFindProvider: React.MouseEventHandler;
  handleOpenRescheduleAppointment: Function;
  handleOpenScheduleSession: React.MouseEventHandler;
  handleOpenScheduleConsultation: React.MouseEventHandler;
  handleOpenConfirmSchedule: Function;
  handleOpenConsent: Function;
  handleOpenPreIntake: Function;
  handleOpenAssessment: Function;
  handleOpenFeedback: Function;
  landscapeUrl: string;
}

let stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeKey as string);

const PatientPortalHomeContent: React.FC<Props> = props => {

  const patientData = props.patientData;
  const appointmentsData = props.appointmentsData;
  const pastAppointments = useFetchPatientAppointments().past;
  const windowWidth = useWindowDimensions().width;

  const [upcomingAppointmentData, setUpcomingAppointmentData] = useState<any>(null);
  const [appointmentPaymentAuth, setAppointmentPaymentAuth] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [areTasksDue, setAreTasksDue] = useState(false);
  
  //Find data for upcoming appointment
  useEffect(() => {
    if (patientData && appointmentsData) {
      let upcomingAppointment = appointmentsData.find((appointment:any) => appointment.status !== "cancelled" && appointment.status!== "fulfilled" && appointment.status!== "noshow");
      setUpcomingAppointmentData(upcomingAppointment);
    }
  }, [patientData, appointmentsData]);

  //Find data for appointment with pending payment, if any
  useEffect(() => {
    if (appointmentsData && pastAppointments) {
      let appointments = appointmentsData.concat(pastAppointments);
      let pendingAppointment = appointments.find((appointment: any) => appointment.stripePaymentIntentId && !appointment.amountPaid);
      if (pendingAppointment)
        setAppointmentPaymentAuth(pendingAppointment);
      else 
        setAppointmentPaymentAuth(null);
    }
  }, [appointmentsData, pastAppointments]);

  
  if (patientData && appointmentsData && pastAppointments) {

      return (
        <div className={classes.containerPatientPortalHomeContent}>

          <ErrorSnackbar 
            open={error ? true : false} 
            setOpen={setError} 
            label={
              error 
              && ( error.type === 'card_error' || error.code === 'payment_intent_authentication_failure' ) ? 
                'Payment failed. Please update your payment method in your account settings.' 
              : ''} 
          />

          <Grid container rowSpacing="48px" columnSpacing="48px" alignItems="flex-start">

            <Grid item xs={12}>
              <div className={classes.welcomeCard} style={{
                textAlign: 'center', 
                backgroundImage: "url(" + props.landscapeUrl + ")",
                backgroundSize: "cover",
                backgroundPosition: "top"
              }}>
                <div className={classes.titleOverlay}>
                  <h4>Welcome to Allminds</h4>
                  <p>Thanks for taking a step to feel better.</p>
                </div>
              </div>
              {/*<button onClick={() => testServerFetch(patientData, upcomingAppointmentData)}>test</button>*/}
            </Grid>

            { appointmentPaymentAuth ? (
              <Grid item xs={12}>
                <Elements stripe={stripePromise}>
                  <PaymentAuthAlert appointment={appointmentPaymentAuth} setError={setError} />
                </Elements>
              </Grid>
            ) : (null)}

            {patientData.treatmentHold ? 
                <Grid item xs={12}>
                  <TreatmentHoldAlert />
                </Grid>
            :null}

          </Grid>

          <Grid container rowSpacing="48px" columnSpacing="48px" alignItems="flex-start" style={{marginTop: '0px'}}>

            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
              <h4>Before Your Next Appointment</h4>

              {!patientData.consentId ? (
                <div style={{marginTop: '24px'}}>
                  <ConsentAlert openConsent={props.handleOpenConsent} />
                </div>          
              ) : (null)}

              {!patientData.preIntakeId && upcomingAppointmentData && upcomingAppointmentData.service == 'intake' ? (
                <div style={{marginTop: '24px'}}>
                  <PreIntakeCard openPreIntake={props.handleOpenPreIntake} />
                </div>          
              ) : (null)}

              <div style={{marginTop: '24px'}}>
                <PatientCompleteAssessments
                  handleOpenAssessment={props.handleOpenAssessment}
                  handleOpenFeedback={props.handleOpenFeedback}
                  handleChangeTasksDue={setAreTasksDue}
                />
              </div>

            </Grid>  

            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
              <div className={classes.containerUpcomingAppointment}>
              { 
                !patientData.treatmentHold ? 
                  <div>
                    <h4>Your Appointment</h4>
                    {!patientData.providerId && !upcomingAppointmentData ?
                        <PatientNoProviderCard handleOpenFindProvider={props.handleOpenFindProvider} />               
                    : upcomingAppointmentData ? 
                      <div className={classes.containerUpcomingAppointment}>
                        {areTasksDue 
                          || !patientData.consentId 
                          || (!patientData.preIntakeId && upcomingAppointmentData.service == 'intake') ? 
                          <div style={{marginBottom: "24px"}}>
                            <Alert severity="warning">Please complete your tasks before your appointment.</Alert>
                          </div>
                        :null}
                        <UpcomingAppointmentCard
                          appointment={upcomingAppointmentData}
                          handleOpenRescheduleAppointment={props.handleOpenRescheduleAppointment}
                          handleOpenConfirmSchedule={props.handleOpenConfirmSchedule}
                          handleOpenConsent={props.handleOpenConsent}
                          handleOpenPreIntake={props.handleOpenPreIntake}
                          areTasksDue={areTasksDue}
                        />
                      </div>
                    : patientData.therapyFollowDate ?
                      <PatientNextAppointmentPendingCard />
                    : patientData.providerId ?
                      <PatientPortalHomeScheduleSessionCard
                        handleOpenScheduleSession={props.handleOpenScheduleSession}
                        handleOpenFindProvider={props.handleOpenFindProvider}
                      />
                    : null} 
                  </div>
                : null
              }
              </div>
            </Grid>

            <Grid item xs={12} md={6} order={{ xs: 3 }}>
              { patientData.providerId && patientData.providerData ? 
                <div className={classes.containerTherapists}>
                  <h4>Your Care Provider</h4>
                  <ProviderCard 
                    providerData={patientData.providerData}
                    appointments={appointmentsData}
                    handleOpenProviderProfile={() => props.handleOpenProviderProfile(patientData!.providerId)}
                  />
                </div>
              :null}
            </Grid>

          </Grid>

        </div>
      );

  }

  //Render skeleton while loading patientData
  else {
    return (
      <div className={classes.containerPatientPortalHomeContent}>

        <Grid container rowSpacing="48px" columnSpacing="48px" alignItems="flex-start">

        <Grid item xs={12}>
          <Skeleton variant="rectangular" width="100%" height={windowWidth < 900 ? "256px" : windowWidth < 1200 ? "384px" : "512px"} />
        </Grid>

          <Grid item xs={12} md={6}>
            <div className={classes.containerUpcomingAppointment}>
              <Skeleton variant="text" width={164} height={48} style={{marginBottom: '24px'}} />
              <Skeleton variant="rectangular" width="100%" height={246} />
            </div>
          </Grid>

          <Grid item xs={12} md={6}>
            <div className={classes.containerTherapists}>
              <Skeleton variant="text" width={128} height={48} style={{marginBottom: '24px'}} />
              <Skeleton variant="rectangular" width="100%" height={109} />
            </div>
          </Grid>

        </Grid>

      </div>
    );
  }

};

export default PatientPortalHomeContent;
