import React, {useState, useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import { getAnalytics, logEvent } from "firebase/analytics";
import { format, isSameDay } from 'date-fns';
import { usePatientData, useUpdatePatientData } from "../../../../../../hooks/ContextPatientData/ContextPatientData";
import useFetchOpenProviderSlots from '../../../../../../hooks/useFetchOpenProviderSlots/useFetchOpenProviderSlots';
import {sgPatientFirstSessionChecklist, sgPatientTherapyTimeRequestedByPatient, sgAllmindsNewPatient, sgProviderTherapyRequested, sgProviderTherapyTimeRequestedByPatient} from '../../../../../../containers/Models/SgEmailModel';
import {canvasCreateAppointment, canvasCreateTask, canvasCloseTaskByPatient} from '../../../../../../containers/Models/Canvas';
import {addAppointmentToDb, deleteAppointmentDb, createAppointmentObject, updateAppointmentCanvasId, createZoomMeeting} from '../../../../../../containers/Models/AppointmentModel';
import {updatePatientDefaultPayment, updatePatientProvider} from '../../../../../../containers/Models/PatientModel';
import { twilioSendText } from '../../../../../../containers/Models/twilio';

import PatientReviewBooking from './PatientReviewBooking/PatientReviewBooking';
import PatientRescheduleAppointmentCard from '../PatientRescheduleAppointment/PatientRescheduleAppointmentCard/PatientRescheduleAppointmentCard';
import Calendar from '../../../../../ui/Calendar/Calendar';
import TimeSlots from '../../../../../ui/TimeSlots/TimeSlots';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { SelectChangeEvent } from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import classes from './PatientScheduleSession.module.css';
import '../../../../../../globalCss/globalCssMuiButton.css';
import { updatePatientFutureTherapyVars } from '../../../../../../containers/Models/PatientModel';
import { blockProviderSlots } from '../../../../../../containers/Models/ProviderModel';

interface Props {
  providerData: any;
  closeScheduleSession: React.MouseEventHandler;
  closeFindProvider?: React.MouseEventHandler;
  handleScrollTop: Function;
  showBackBtn?: boolean;
}

const PatientScheduleSession: React.FC<Props> = props => {

  const location = useLocation();
  const analytics = getAnalytics();
  const patientData = usePatientData();
  const providerData = props.providerData;
  const updateStatePatientData = useUpdatePatientData();
  let handleScrollTop = props.handleScrollTop;

  const [frequency, setFrequency] = useState(1); //Controls the frequency of appointments
  const [date, setDate] = useState(new Date()); //Controls the active date in the calendar ui
  const [confirmedDate, setConfirmedDate] = useState<null|Date>(null);// New date for appointment to be chosen by user
  const [bookedAppointment, setBookedAppointment] = useState<any>(null); 
  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState(false);

  let today = new Date();
  let maxDate = new Date();
  //Set minDate to 4 hours after today
  let minDate = new Date(today.getTime() + 60 * 60 * 4 * 1000);
  //Set maxDate to 14 days after original appointment day
  maxDate.setDate(maxDate.getDate() + 14);
  let availabilityDays = 44;
  const availableSlots = useFetchOpenProviderSlots(providerData.canvasId, availabilityDays, 60, 60, frequency);

  //Set initial date for calendar once availableSlots are fetched
  useEffect(() => {
    if (availableSlots) {
      let minDate = new Date(new Date().getTime() + 60 * 60 * 4 * 1000);
      let firstAvailableDate = availableSlots.find((slot:any) => new Date(slot.resource.start) > minDate)
      if (firstAvailableDate)
        setDate(new Date(firstAvailableDate.resource.start));
    }
  }, [availableSlots]);

  //Disable calendar dates in which there are no available slots
  const handleDisableDate = ({activeStartDate, date, view }:any) => availableSlots && !availableSlots.find(
    (slot:any) => isSameDay(date, new Date(slot.resource.start)) && new Date(slot.resource.start) > minDate
  );

  const calendarProps = {
    minDate: minDate,
    maxDate: maxDate,
    tileDisabled: handleDisableDate
  };

  //Check if user is coming from account creation with pending session booking
  useEffect(() => {
    if (location.state && location.state.pendingBookSessionDate) {
      setConfirmedDate(location.state.pendingBookSessionDate);
      location.state.pendingBookSessionDate = null;
      window.history.replaceState({}, document.title);
    }
  }, []);

  useEffect(() => {
    handleScrollTop();
  }, [confirmedDate, handleScrollTop]);

  const handleFrequencyChange = (event: SelectChangeEvent) => {
    setFrequency(parseInt(event.target.value));
  };

  // Returns appointments array from patientData with a new appointment
  const getUpdatedAppointments = (newAppointment: {
    start: Date,
    end: Date,
    service: string,
    type: string,
    status: string,
    providerId: string,
    providerName: string,
    providerPicUrl: string
  }) => {
    if (patientData) {
      let updatedAppointments = [...patientData.appointments];
      updatedAppointments.push(newAppointment);
      //Sort array by date of appointment
      updatedAppointments.sort(function(a,b){
        return a.start - b.start;
      });

      return updatedAppointments;
    }
  };

  //UNFINISHED: HANDLE SUBMIT NEW APPOINTMENT REQUEST
  const handleBookAppointment = async() => {
    if (confirmedDate && paymentMethod) {
      setLoadingSubmit(true);
      let providerName = providerData!.firstName + ' ' + providerData.lastName;
      let isIntake = true;
      //This appointment should not be an intake if:
      //If the user has previous apppointments 
      //The most recent therapy session is with the same provider
      if (patientData.pastAppointments) {

        for (let i = 0; i < patientData.pastAppointments.length; i++) {
         
          let appointment = patientData.pastAppointments[i];
          if (
            (appointment.service === 'intake' || appointment.service === 'individual')
            && appointment.status === 'fulfilled'
          ) {
            if (appointment.providerId === providerData.id)
              isIntake = false;
            break;
          }
        }
      }

      //MEETING UPDATE: Change the meeting ID to generate a new one for each appointment
      let meetingReq  = {
        therapistEmail: providerData!.email,
        therapistName: providerName,
        clientEmail: patientData!.email,
        clientName: patientData!.firstName + " " + patientData!.lastName,
        startTime: confirmedDate
      }
      let meetingObj = await createZoomMeeting(meetingReq);
      //console.log(meetingObj);
      if (meetingObj === null || meetingObj === undefined) {
        console.log("WARN: Couldn't get new Meeting ID.");
        meetingObj = "";
      } /*else {
        console.log("INFO: Got new Meeting ID: " + meetingObj.meetingObj.join_url);
      }*/

      let appointmentData = {
        start: confirmedDate,
        end: new Date(confirmedDate.getTime() + 3600000),
        service: isIntake ? 'intake' : 'individual',
        status: 'proposed',
        type: 'video',
        providerId: providerData.id,
        providerCanvasId: providerData.canvasId,
        providerName: providerName,
        patientId: patientData.id,
        patientCanvasId: patientData.canvasId,
        patientEmail: patientData.email,
        providerPicUrl: providerData.picUrl,
        meetingId: meetingObj.meetingObj.join_url
      };

      const appointmentObj = createAppointmentObject(appointmentData);
      //Flag appointment marking a new therapy time
      appointmentObj.newTherapyTime = true;

      let updatedPatientData = {...patientData};

      let appointmentId = "";

      // First add appointment to Db to avoid race condition
      addAppointmentToDb(appointmentObj)

      // Catch error and stop function if db update fails
      .catch(error => {throw error})
      
      // Now create Canvas Appointment
      .then(id => {
        appointmentId = id;
        appointmentObj.id = id;
        return canvasCreateAppointment(appointmentObj);
      })

      //If Canvas API fails, then delete appointment from db and stop function
      .catch(error => {
        if (appointmentId)
          deleteAppointmentDb(appointmentId);
        throw error;
      })

      // Now add canvasAppointmentID
      .then(canvasAppointmentId => {
        appointmentObj.canvasId = canvasAppointmentId.appointmentId;
        return updateAppointmentCanvasId(appointmentId, canvasAppointmentId);
      })

      //If this session is being booked with a new provider, update provider in patient db 
      .then(() => {
        //If patient has a current provider
        if (patientData.providerId && patientData.providerId !== providerData.id) {
          //Close existing canvas tasks for old provider
          canvasCloseTaskByPatient(patientData.canvasId, patientData.providerData.canvasId);
        }
        //Update provider field in patient db
        return updatePatientProvider(patientData.id, providerData.id);
      })

      //Create blocks in gcal
      .then(() => {
        return blockProviderSlots(providerData, patientData, frequency, appointmentObj.start, appointmentObj.end);
      })

      //Add gcal blocks data to patient db
      .then(eventData => {
        updatedPatientData.therapyFrequency = frequency;
        updatedPatientData.therapyFollowDate = new Date(eventData.start.dateTime);
        updatedPatientData.therapySlotsBlockLink = eventData.htmlLink;
        updatedPatientData.therapySlotsBlockId = eventData.id;
        return updatePatientFutureTherapyVars(patientData.id, frequency, new Date(eventData.start.dateTime), eventData.htmlLink, eventData.id); 
      })

      .then(() => {

        //Track firebase events
        logEvent(analytics, 'therapy_booked');
        
        //Create canvas task for provider
        let freqText = '';
        if (frequency === 1) freqText = 'Every week';
        else if (frequency === 2) freqText = 'Every 2 weeks';
        else if (frequency === 3) freqText = 'Every 3 weeks';
        else if (frequency === 4) freqText = 'Every 4 weeks';
        let description = 'New client schedule: ' + freqText + ' on ' + format(appointmentObj.start, 'iiii') + "s at " + format(appointmentObj.start, 'p') + ", starting on " + format(appointmentObj.start, 'MM/dd');
        let note = "Confirm appointment to approve client's schedule, or reschedule to propose a different time."
        canvasCreateTask(patientData.canvasId, providerData.canvasId, appointmentObj.canvasId, appointmentObj.start, description, note);

        //Alerts for intake
        if (isIntake) {

          //Send alerts to provider
          twilioSendText(providerData.phone, "Allminds: A new client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") has booked their first therapy session. Please confirm on allminds.canvasmedical.com.");
          sgProviderTherapyRequested(providerData.email, patientData.firstName, providerData.firstName, appointmentObj.start);

          //Send email to patient
          sgPatientTherapyTimeRequestedByPatient(
            patientData.email, 
            patientData.firstName, 
            providerName, 
            frequency,
            appointmentObj.start
          );
          sgPatientFirstSessionChecklist(
            patientData.email,
            patientData.firstName
          );

          // Send alert to Allminds team
          sgAllmindsNewPatient(patientData.email, patientData.firstName + ' ' + patientData.lastName, providerName, appointmentObj.start); 

        }

        //Alerts for returning patient
        else {
          //Send alerts to provider
          twilioSendText(providerData.phone, "Allminds: A client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") has booked a new therapy schedule. Please confirm on allminds.canvasmedical.com.");
          sgProviderTherapyTimeRequestedByPatient(providerData.email, patientData.firstName, providerData.firstName, appointmentObj.start);

          //Send email to patient
          sgPatientTherapyTimeRequestedByPatient(
            patientData.email, 
            patientData.firstName, 
            providerName, 
            frequency,
            appointmentObj.start
          );
        }

        //Update state
        let updatedAppointments = getUpdatedAppointments(appointmentObj);
        updatedPatientData.appointments = updatedAppointments;

        //Update default payment method if necessary
        if (patientData.stripeDefaultPaymentId !== paymentMethod.id) {
          updatedPatientData.stripeDefaultPaymentId = paymentMethod.id;
          updatePatientDefaultPayment(patientData.id, paymentMethod.id);
        }

        updatedPatientData.providerId = providerData.id;
        updatedPatientData.providerData = {...providerData};

        updateStatePatientData(updatedPatientData);
        setBookedAppointment(appointmentObj);
        setLoadingSubmit(false);
      })

      .catch(error => {
        console.log(error);
        setError(true);
        setLoadingSubmit(false);
      });
    }
  }

  const handleConfirmDate = (date: Date) => {
    setConfirmedDate(date);
  };


  //Ask user to select date and time
  if (!confirmedDate) {
    return(
      <div className={classes.containerScheduleAppointment}>

        <div className={classes.header}>
          <IconButton onClick={props.closeScheduleSession} className={classes.closeBtn} aria-label="exit">
            {props.showBackBtn ? <ArrowBackRoundedIcon /> : <CloseRoundedIcon />}
          </IconButton>
          <h4>Choose when to see {providerData.firstName}</h4>
          {patientData.providerId && providerData.id !== patientData.providerId ? 
            <Alert severity="warning" style={{marginBottom: '64px'}}>
              Once you book an appointment with a new provider, you will no longer be able to see your current therapist.
            </Alert>
          :
            <Alert severity="info" style={{marginBottom: '64px'}}>
              You will see your therapist at the same time and day each week. You'll be able to reschedule appointments if needed.
            </Alert>
          }
        </div>

        <Grid container spacing={{ xs: 1, sm: 2, md: 4, lg: 8 }}>
          <Grid item xs={12} sm={7} md={6}>
            <div className={classes.calendarContainer} style={!availableSlots ? {pointerEvents: "none"} : {}}>
{/*              <div className={classes.frequencyContainer}>
                <h4>I want to have therapy</h4>
                <FormControl fullWidth>
                  <Select
                    labelId="frequency"
                    id="frequency-select"
                    value={frequency.toString()}
                    onChange={handleFrequencyChange}
                  >
                    <MenuItem value={1}>Every week</MenuItem>
                    <MenuItem value={2}>Every two weeks</MenuItem>
                    <MenuItem value={3}>Every three weeks</MenuItem>
                    <MenuItem value={4}>Every four weeks</MenuItem>
                  </Select>
                </FormControl>
              </div> */}
              {/*<h4>Starting on</h4>  */}           
              {availableSlots ? 
                <Calendar 
                  value={date}
                  onChange={setDate}
                  calendarProps={calendarProps} 
                />
              :
                <Stack spacing={2}>
                  <Skeleton variant="rectangular" height={44} />
                  <Skeleton variant="rectangular" height={285} />
                </Stack>            
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={5} md={6}>
            <div className={classes.timeSlotsContainer}>
              <h4>{format(date, 'EEEE, LLLL dd')}</h4>
              {!availableSlots ? 
                <Stack spacing={2}>
                  <Skeleton variant="rectangular" height={44} />
                  <Skeleton variant="rectangular" height={44} />
                  <Skeleton variant="rectangular" height={44} />
                </Stack>
                :
                <TimeSlots 
                  slots={availableSlots} 
                  selectedDate={date}
                  onClickConfirm={handleConfirmDate}
                  disableConfirm={frequency ? false : true}
                />
              }
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  //After ask user to review and confirm booking
  else if (!bookedAppointment) {
    return(
      <div className={classes.containerScheduleAppointment}>
        
        <div className={classes.header}>
          <IconButton onClick={() => setConfirmedDate(null)} className={classes.closeBtn} aria-label="go back">
            <ArrowBackRoundedIcon />
          </IconButton>
        </div>

        <PatientReviewBooking 
          providerData={providerData}
          confirmedDate={confirmedDate}
          frequency={frequency}
          paymentMethod={paymentMethod}
          setPaymentMethod={setPaymentMethod}
          handleBookAppointment={handleBookAppointment}
          loadingSubmit={loadingSubmit}
          error={error}
          setError={setError}
        />

      </div>
    );
  } 
  //After user confirms new time
  else if (bookedAppointment) {
    return(
      <div className={classes.containerScheduleAppointment}>
        <div className={classes.header}>
          <IconButton onClick={props.closeFindProvider ? props.closeFindProvider : props.closeScheduleSession} className={classes.closeBtn} aria-label="exit">
            <CloseRoundedIcon />
          </IconButton>
          <h2>Your request has been submitted!</h2>
          <Alert severity="success">Please plan on attending your appointment unless your provider declines your request.</Alert>
        </div>
        <div>
          <PatientRescheduleAppointmentCard appointment={bookedAppointment} />
        </div>
      </div>
    );
  }
  else return null;
};

export default PatientScheduleSession;
