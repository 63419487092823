import { useEffect, useState } from "react";
import { usePatientData, useUpdatePatientData } from '../ContextPatientData/ContextPatientData';
import { getProviderById } from "../../containers/Models/ProviderModel";

export default function useFetchProviderData() {

  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();

  const [providerData, setProviderData] = useState<any>(null);

  //Fetch provider data if state doesn't have it
  useEffect(() => {
    if (patientData && patientData.providerId) {
      if (!patientData.providerData) {
        getProviderById(patientData.providerId).then(providerData => {
          setProviderData(providerData)
        });
      }
      else {
        setProviderData(patientData.providerData);
      }
    }
  }, [patientData]);

  //Add canvas provider data to state after initial fetch
  useEffect(() => {
    if (providerData && patientData && !patientData.providerData) {
      let updatedPatientData = {...patientData};
      updatedPatientData.providerData = {...providerData};
      updateStatePatientData(updatedPatientData);
    }
  }, [patientData, providerData, updateStatePatientData]);

  return providerData;
}

