import React from 'react';
import { PatientDataProvider} from '../../../hooks/ContextPatientData/ContextPatientData';
import PatientPortal from './PatientPortal';


interface Props {
}

const PatientPortalWrapper: React.FC<Props> = props => {

  return(
    <PatientDataProvider>
      <PatientPortal />
    </PatientDataProvider>
  );
};

export default PatientPortalWrapper;

