import React, { useState } from 'react';
import { usePatientData, useUpdatePatientData } from '../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchPaymentMethods from '../../../../hooks/useFetchPaymentMethods/useFetchPaymentMethods';
import { updatePatientDefaultPayment } from '../../../../containers/Models/PatientModel';
import { getPaymentMethodById } from '../../../../containers/Models/stripe';

import Checkbox from '@mui/material/Checkbox';
import { LoadingButton } from '@mui/lab';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

import {PaymentElement, useStripe, useElements} from '@stripe/react-stripe-js';

interface Props {
  callback?: Function;
  showSetDefault?: boolean;
}

const SetupForm: React.FC<Props> = props => {

  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();
  const paymentMethods = useFetchPaymentMethods();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [mounted, setMounted] = useState(false);

  const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };


  const handleSubmit = async (event: React.FormEvent) => {

    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    stripe.confirmSetup({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect: 'if_required',
      confirmParams: {
        //TODO: Replace with final production url for app
        return_url: 'https://app.getallminds.com'
      }
    }).then((result: any) => {

      if (result.error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        //console.log(result.error);
        setErrorMessage(result.error.message);
        setLoading(false);
      } else {
        //console.log(result);
        //Retrieve new payment method data from Stripe, then update state
        getPaymentMethodById(result.setupIntent.payment_method).then(newPaymentMethod => {
          let updatedPatientData = {...patientData};
          //Make new card default if needed
          if (checked || paymentMethods.length === 0) {
            updatePatientDefaultPayment(patientData.id, result.setupIntent.payment_method); // set card as default in db
            updatedPatientData.stripeDefaultPaymentId = result.setupIntent.payment_method; // set card as default in state
          }
          let updatedPaymentMethods = [...updatedPatientData.paymentMethods];
          updatedPaymentMethods.push(newPaymentMethod.paymentMethod);
          updatedPatientData.paymentMethods = updatedPaymentMethods;
          updateStatePatientData(updatedPatientData);
          if (props.callback) props.callback(newPaymentMethod.paymentMethod);
          setLoading(false);
        });
      }

    });

  };

  return(
    <form onSubmit={handleSubmit}>
      
      {!mounted ? (
        <Stack spacing={4}>
          <Skeleton variant="rectangular" height={44} />
          <Stack direction="row" spacing={2}>
            <Skeleton variant="rectangular" width="50%" height={44} />
            <Skeleton variant="rectangular" width="50%" height={44} />
          </Stack>
          <Skeleton variant="rectangular" height={44} />
          <Skeleton variant="rectangular" height={44} />
        </Stack>
      ) : (null)}

      {errorMessage ? (
        <p style={{color: 'red', fontSize: '0.8rem', marginBottom: '16px'}}>{errorMessage}</p>
      ) : (null)}

      <PaymentElement onReady={() => setMounted(true)} />

      {props.showSetDefault && paymentMethods && paymentMethods.length > 0 && mounted ?
        <div style={{display: 'flex', alignItems: 'center', marginTop: '16px'}}>
          <Checkbox
            checked={checked}
            onChange={handleCheckChange}
            inputProps={{ 'aria-label': 'controlled' }}
            style={{paddingLeft: 0}}
          />
          <p>Make this my default payment method</p>
        </div>
      : null}

      <LoadingButton 
        onClick={handleSubmit}
        color="secondary" 
        variant="contained" 
        size='large' 
        startIcon={<LockOutlinedIcon />}
        loading={loading}
        style={{marginTop: '24px'}}
        disabled={!mounted}
      >
          Add
      </LoadingButton>
    </form>
  );


};

export default SetupForm;