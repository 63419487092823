import React, { useState } from 'react';
import { Helmet } from "react-helmet";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import { LoadingButton } from '@mui/lab';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';

import classes from './PatientForgotPassword.module.css'
import '../../../globalCss/globalCssMuiButton.css';
import '../../../globalCss/globalCssMuiTextField.css';
import '../../../globalCss/globalCssMuiAlert.css';

interface Props {
}


const PatientForgotPassword: React.FC<Props> = props => {

  const [email, setEmail] = useState("");
  const [linkSent, setLinkSent] = useState(false);
  const [loading, setLoading] = useState(false);


  const handleSubmit = (e:React.FormEvent) => {
    e.preventDefault();
    setLoading(true);

    const auth = getAuth();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // Password reset email sent!
        setLinkSent(true);
        setLoading(false);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        //Do not tell user if error happens for safety
        setLinkSent(true);
        setLoading(false);
      });

  };

  return(
    <div className={classes.containerPatientForgotPassword}>

      <Helmet>
        <title>
          Forgot password - Allminds
        </title>
      </Helmet>
      
      <h4>Forgot password?</h4>
      
      {linkSent ? 
        <Alert severity="success" style={{marginBottom: '32px'}}>Your reset link will soon be on its way, if we can find an account. Please check your inbox (and spam) in a few minutes.</Alert>
      :
        <p>Enter the email address associated with your account, and we’ll email you a link to reset your password.</p>
      }

      <form onSubmit={handleSubmit}>

        <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} className={classes.textField} type="email" required fullWidth variant="outlined" />

        <LoadingButton type="submit" loading={loading} variant="contained" fullWidth size='large' className={classes.btnSubmit}>Send reset link</LoadingButton>

      </form>

    </div>
  );
};

export default PatientForgotPassword;

