import React, { useState } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import { canvasCreateQuesPhq15 } from '../../../../../../containers/Models/CanvasQuestionnaires';
import { addPhq15ToDb } from '../../../../../../containers/Models/assessmentModels/phq15Model';

import NavBarPatientChat from '../../../../../ui/ChatForm/NavBarPatientChat/NavBarPatientChat';
import ChatFormScreener from '../../../../../ui/ChatForm/ChatFormScreener';
import Skeleton from '@mui/material/Skeleton';

import classes from "./PatientChatPhq15.module.css";

interface Props {
  handleCloseChat: any;
}

const PatientChatPhq15: React.FC<Props> = props => {

  const analytics = getAnalytics();
  const patientData = usePatientData();
  const handleUpdateStatePatientData = useUpdatePatientData();
  const [disableBackBtn, setDisableBackBtn] = useState(false);

  //Questions to be included in form
  let questions: any = {
    stomachPain:
      "How often have you been bothered by the following problems <b>in the past four weeks</b>:" +
      "&&Stomach pain?",
    backPain: "How about back pain?",
    bodyPain: "Pain in your arms, legs, or joints (knees, hips, etc.)?",
    sexPain: "Any pain or problems during sexual intercourse?",
    headache: "What about headaches?",
    chestPain: "and chest pain?",
    dizziness: "Dizziness?",
    faintingSpells: "Fainting spells?",
    heart: "What about feeling your heart pound or race?",
    breath: "And shortness of breath?",
    digestion: "Any constipation, loose bowels, or diarrhea?",
    nausea: "Nausea, gas or indigestion?",
    tired: "Have you been feeling tired or having low energy?",
    sleep: "Any trouble sleeping?"
  };

  //Add menstrual cramp questions if client does not identify as Man
  if (patientData.gender !== "Man") {
    questions["menstrualCramps"] =
      "Lastly, any menstrual cramps or other problems with your periods?";
  }

  //Object with options for phq15 questions and associated scoring value
  const options: any = {
    "Not bothered": 0,
    "Bothered a little": 1,
    "Bothered a lot": 2
  };

  const intro = {
    message: 
      "Hey " + patientData.firstName + "!" +
      "&&Our mental and physical health are connected, meaning that if you are experiencing physical health concerns, it can have an impact on your mental health, too." +
      "&&Similarly, struggling with mental health can sometimes result in physical changes in your body." +
      "&&This assessment will help your provider get a better sense of how you have been feeling physically.",
    option: "Let's do it"
  };

  const getScore = (formData: any) => {
    let score = 0;
    for (let key in formData) {
      if (key !== "intro" && key !== "end") {
        score = score + options[formData[key][0]];
      }
    } 
    return score
  };

  const getScoreResponse = (formData: any) => {
    let score = getScore(formData);
    let robotResponse = "";
    if (score < 5)
      robotResponse =
        "Thanks " +
        patientData.firstName +
        ". It looks like physical health isn't a concern for you right now." +
        "&&You can talk to your provider in more detail about your results!";
    else if (score < 10)
      robotResponse =
        "Thanks " +
        patientData.firstName +
        ". Based on your responses it seems like your physical health is causing you slight distress." +
        "&&Your provider can discuss with you in more detail how your physical health symptoms are related to your mental health.";
    else if (score < 15)
      robotResponse =
        "Thanks " +
        patientData.firstName +
        ". Based on your responses it seems like your physical health is causing you moderate distress." +
        "&&Your provider can discuss with you in more detail how your physical health symptoms are related to your mental health.";
    else
      robotResponse =
        "Thanks " +
        patientData.firstName +
        ". Based on your responses it seems like your physical health is causing you a lot of distress." +
        "&&Your provider can discuss with you in more detail how your physical health symptoms are related to your mental health.";

    return robotResponse;
  };

  const questionCallback = (dto: any, success: any, error: any, cf: any) => {
    
    const createTag = (name:string, question:string, answers:string[]) => {
      const createTagChildren = () => {
        let tagChildren: any = [];
        answers.forEach((answer: any) => {
          let child: any = {
            tag: "input",
            name: name,
            type: "radio",
            "cf-label": answer,
            value: answer
          };
          tagChildren.push(child);
        });
        return tagChildren;
      }
      let radioTag = {
        tag: "fieldset",
        name: name,
        "cf-input-placeholder": " ",
        "cf-questions": question,
        children: createTagChildren()
      }
      return radioTag
    };

    //At the end of form, submit data to db amd add exit message 
    if (
      (patientData.gender === "Man" && dto.tag.name === 'sleep')
      || (patientData.gender !== "Man" && dto.tag.name === 'menstrualCramps')
    ) {
      handleSubmit(cf.getFormData(true));
      cf.addTags([createTag(
        "end",
        getScoreResponse(cf.getFormData(true)),
        ["Exit"]
      )]);
      success();
    }
    else if (dto.tag.name === 'end') {
      props.handleCloseChat();
    }
    else success();
  };

  //Function to be called after form is submitted
  const handleSubmit = (formData: any) => {

    const getSeverity = (score: number) => {
      if (score < 5) return "Normal";
      else if (score < 10) return "Mild";
      else if (score < 15) return "Moderate";
      else return "Severe";
    };

    setDisableBackBtn(true);

    //Create screenerData object from formData
    const screenerData: any = {};
    for (let key in formData) {
      if (key !== "intro" && key !== "end") {
        screenerData[key] = {
          value: formData[key][0],
          score: options[formData[key][0]]
        };
      }
    }

    let score = getScore(formData);
    let severity = getSeverity(score);

    //Create object with phq15 data
    let phq15Data = {
      
      patientId: patientData.id,
      patientCanvasId: patientData.canvasId,
      providerId: patientData.providerData.id,
      providerCanvasId: patientData.providerData.canvasId,
      timeStamp: new Date(),

      stomachPain: { value: screenerData.stomachPain.value, score: screenerData.stomachPain.score },
      backPain: { value: screenerData.backPain.value, score: screenerData.backPain.score },
      bodyPain: { value: screenerData.bodyPain.value, score: screenerData.bodyPain.score },
      sexPain: { value: screenerData.sexPain.value, score: screenerData.sexPain.score },
      headache: { value: screenerData.headache.value, score: screenerData.headache.score },
      chestPain: { value: screenerData.chestPain.value, score: screenerData.chestPain.score },
      dizziness: { value: screenerData.dizziness.value, score: screenerData.dizziness.score },
      faintingSpells: { value: screenerData.faintingSpells.value, score: screenerData.faintingSpells.score },
      heart: { value: screenerData.heart.value, score: screenerData.heart.score },
      breath: { value: screenerData.breath.value, score: screenerData.breath.score },
      digestion: { value: screenerData.digestion.value, score: screenerData.digestion.score },
      nausea: { value: screenerData.nausea.value, score: screenerData.nausea.score },
      tired: { value: screenerData.tired.value, score: screenerData.tired.score },
      sleep: { value: screenerData.sleep.value, score: screenerData.sleep.score },
      //Question below is skippable, so check to make sure answer exists
      menstrualCramps: screenerData.menstrualCramps ?
                    { value: screenerData.menstrualCramps.value, score: screenerData.menstrualCramps.score }
                    : null,

      score: score,
      severity: severity

    };

    //Upload assessment to db
    addPhq15ToDb(phq15Data).then((res) => {
      //Upload questionnaire to Canvas
      canvasCreateQuesPhq15(phq15Data);
    });

    //Track firebase event
    logEvent(analytics, 'phq15_completed');

    //Update state with next due date for assessment
    let updatedPatientData = {...patientData};
    updatedPatientData.lastPhq15 = {
      id: '',
      timeStamp: phq15Data.timeStamp
    };
    handleUpdateStatePatientData(updatedPatientData);

  };

  return (
    <React.Fragment>
      <NavBarPatientChat 
        handleClose={props.handleCloseChat} 
      />
      <div className={classes.containerChatAssessment}>
        {patientData ? 
          <ChatFormScreener
            questions={questions}
            options={options}
            intro={intro}
            questionCallback={questionCallback}
            disableBackBtn={disableBackBtn}
          />
        :
          <div className={classes.containerSkeleton}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={200} height={60} />
          </div>
        }
      </div>
    </React.Fragment>
  );

};

export default PatientChatPhq15;
