import React, { useState } from 'react';
import { Helmet } from "react-helmet";

import { usePatientData } from '../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchPatientAppointments from '../../../../hooks/useFetchPatientAppointments/useFetchPatientAppointments';

import PatientPortalAppointmentsContent from './PatientPortalAppointmentsContent/PatientPortalAppointmentsContent';
import PatientPortalAppointmentsSliders from './PatientPortalAppointmentsSliders/PatientPortalAppointmentsSliders';


interface Props {
  handleShowNavBar: Function; 
  handleHideNavBar: Function; 
  handleEnableSwipe: Function;
  handleDisableSwipe: Function;
}

const PatientPortalAppointments: React.FC<Props> = props => {

  const patientData = usePatientData();
  const appointmentsData = useFetchPatientAppointments().upcoming;

  const [showReschedule, setShowReschedule] = useState(false);
  const [showScheduleSession, setShowScheduleSession] = useState(false);
  const [showConfirmSchedule, setShowConfirmSchedule] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);
  const [showConsent, setShowConsent] = useState(false);

  const handleOpenReschedule = (appointmentId: string) => {
    //Get data for appointment to reschedule
    if (appointmentsData && (!selectedAppointment || selectedAppointment.id !== appointmentId)) {
      let appointment = appointmentsData.find((appointment:any) => appointment.id === appointmentId);
      if (appointment) {
        setSelectedAppointment(appointment);
      }
    }
    setShowReschedule(true);
    props.handleHideNavBar();
    props.handleDisableSwipe();     
  };

  const handleCloseReschedule = () => {
    setShowReschedule(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();        
  };

  const handleOpenScheduleSession = () => {
    setShowScheduleSession(true);
    props.handleHideNavBar();
    props.handleDisableSwipe();      
  };

  const handleCloseScheduleSession = () => {
    setShowScheduleSession(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();        
  };

  const handleOpenConfirmSchedule = (appointmentId: string) => {
    //Get data for appointment to reschedule
    if (appointmentsData && (!selectedAppointment || selectedAppointment.id !== appointmentId)) {
      let appointment = appointmentsData.find((appointment:any) => appointment.id === appointmentId);
      if (appointment) {
        setSelectedAppointment(appointment);
      }
    }
    setShowConfirmSchedule(true);
    props.handleHideNavBar();
    props.handleDisableSwipe();      
  };

  const handleCloseConfirmSchedule = () => {
    setShowConfirmSchedule(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();        
  };

  const handleOpenConsent = () => {
    setShowConsent(true);
    props.handleHideNavBar();
    props.handleDisableSwipe();      
  };

  const handleCloseConsent = () => {
    setShowConsent(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();        
  };


  return(
    
    <React.Fragment>

      <Helmet>
        <title>
          Appointments - Allminds
        </title>
      </Helmet>
      
      <PatientPortalAppointmentsContent
        appointmentsData={appointmentsData}
        handleOpenScheduleSession={handleOpenScheduleSession}
        handleOpenReschedule={handleOpenReschedule}
        handleOpenConfirmSchedule={handleOpenConfirmSchedule}
        handleOpenConsent={handleOpenConsent}
      />

      <PatientPortalAppointmentsSliders
        patientData={patientData}
        selectedAppointment={selectedAppointment}
        showReschedule={showReschedule}
        showScheduleSession={showScheduleSession}
        showConfirmSchedule={showConfirmSchedule}
        showConsent={showConsent}
        handleCloseReschedule={handleCloseReschedule}
        handleCloseScheduleSession={handleCloseScheduleSession}
        handleCloseConfirmSchedule={handleCloseConfirmSchedule}
        handleOpenConsent={handleOpenConsent}
        handleCloseConsent={handleCloseConsent}
      />

    </React.Fragment>

  );
};

export default PatientPortalAppointments;
