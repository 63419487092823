import React, {useState} from 'react';
import { getAnalytics, logEvent } from "firebase/analytics";
import { usePatientData, useUpdatePatientData } from "../../../../../../hooks/ContextPatientData/ContextPatientData";
import {sgPatientFirstSessionChecklist, sgAllmindsNewPatient, sgProviderTherapyConfirmedByPatient} from '../../../../../../containers/Models/SgEmailModel';
import {canvasUpdateAppointment} from '../../../../../../containers/Models/Canvas';
import {updatePatientDefaultPayment} from '../../../../../../containers/Models/PatientModel';
import { twilioSendText } from '../../../../../../containers/Models/twilio';

import PatientReviewBooking from '../PatientScheduleSession/PatientReviewBooking/PatientReviewBooking';

import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import classes from './PatientConfirmSchedule.module.css';
import '../../../../../../globalCss/globalCssMuiButton.css';


interface Props {
  closeConfirmSchedule: any;
  appointment: any;
}

const PatientConfirmSchedule: React.FC<Props> = props => {

  const analytics = getAnalytics();
  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();

  const [paymentMethod, setPaymentMethod] = useState<any>(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState(false);


  const handleBookAppointment = () => {
    
    if (paymentMethod) {

      setLoadingSubmit(true);
      let updatedAppointment = {...props.appointment};
      updatedAppointment.status = 'booked';

      // First update canvas appointment to booked
      canvasUpdateAppointment(updatedAppointment)

      .then(() => {

        let updatedPatientData = {...patientData};
        let providerName = patientData.providerData!.firstName + ' ' + patientData.providerData.lastName;

        let isIntake = true;
        //This appointment should not be an intake if:
        //If the user has previous apppointments 
        //The most recent therapy session is with the same provider
        if (patientData.pastAppointments) {

          for (let i = 0; i < patientData.pastAppointments.length; i++) {
           
            let pastAppointment = patientData.pastAppointments[i];
            if (pastAppointment.service === 'intake' || pastAppointment.service === 'individual') {
              if (pastAppointment.providerId === props.appointment.providerId)
                isIntake = false;
              break;
            }
          }
        }

        //Send alerts to provider
        twilioSendText(patientData.providerData.phone, "Allminds: Your client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") accepted their new schedule. See details on allminds.canvasmedical.com.");
        sgProviderTherapyConfirmedByPatient(patientData.providerData.email, patientData.firstName, patientData.providerData.firstName, updatedAppointment.start);

        if (isIntake && updatedAppointment.service === 'intake') {

          logEvent(analytics, 'therapy_booked'); //Track firebase event
          sgAllmindsNewPatient(patientData.email, patientData.firstName + ' ' + patientData.lastName, providerName, updatedAppointment.start); // Send alert to Allminds team

          sgPatientFirstSessionChecklist(
            patientData.email,
            patientData.firstName
          );

        }

        //Update state
        updatedAppointment.newTherapyTime = false;
        let updatedAppointments = [...updatedPatientData.appointments];
        let indexToChange = updatedAppointments.findIndex(appointment => appointment.id === updatedAppointment.id);
        if (indexToChange > -1) {
          updatedAppointments[indexToChange] = updatedAppointment;
        }
        updatedPatientData.appointments = updatedAppointments;

        //Update default payment method if necessary
        if (patientData.stripeDefaultPaymentId !== paymentMethod.id) {
          updatedPatientData.stripeDefaultPaymentId = paymentMethod.id;
          updatePatientDefaultPayment(patientData.id, paymentMethod.id);
        }

        updateStatePatientData(updatedPatientData);
        setLoadingSubmit(false);
        props.closeConfirmSchedule();
      })

      .catch(error => {
        console.log(error);
        setError(true);
        setLoadingSubmit(false);
      });
    }

  }

  return(
    <div className={classes.containerConfirmSchedule}>
      
      <div className={classes.header}>
        <IconButton onClick={props.closeConfirmSchedule} className={classes.closeBtn} aria-label="go back">
          <CloseRoundedIcon />
        </IconButton>
      </div>

      <PatientReviewBooking 
        providerData={patientData.providerData}
        confirmedDate={props.appointment.start}
        frequency={patientData.therapyFrequencyy}
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        handleBookAppointment={handleBookAppointment}
        loadingSubmit={loadingSubmit}
        error={error}
        setError={setError}
      />

    </div>
  );

};

export default PatientConfirmSchedule;
