import React, {useState, useEffect} from 'react';
import { getAnalytics, logEvent } from "firebase/analytics";
import { format, isSameDay, differenceInHours, startOfWeek, differenceInCalendarDays  } from 'date-fns';
import {useStripe, useElements} from '@stripe/react-stripe-js';

import { usePatientData, useUpdatePatientData } from "../../../../../../hooks/ContextPatientData/ContextPatientData";
import useFetchProviderData from "../../../../../../hooks/useFetchProviderData/useFetchProviderData";
import useFetchCoverageData from "../../../../../../hooks/useFetchCoverageData/useFetchCoverageData";
import useFetchOpenProviderSlots from '../../../../../../hooks/useFetchOpenProviderSlots/useFetchOpenProviderSlots';
import { updateZoomMeetingTime } from '../../../../../../containers/Models/AppointmentModel';
import {removePatientProvider, updatePatientFutureTherapyVars, addPatientLateCancel, addPatientTreatmentHold} from '../../../../../../containers/Models/PatientModel';
import {canvasUpdateAppointment, canvasCreateTask, canvasCloseTaskByAppointment} from '../../../../../../containers/Models/Canvas';
import {updateAppointmentTimeDb, updateAppointmentPayment, updateAppointmentLateCancel, updateAppointmentExcludeNoshow} from '../../../../../../containers/Models/AppointmentModel';
import {twilioSendText} from '../../../../../../containers/Models/twilio';
import {
  sgPatientTherapyTimeRequestedByPatient, 
  sgPatientAppointmentRescheduledByPatient, 
  sgPatientLateCancelStrike,
  sgPatientStrikeHoldV2,
  sgProviderTherapyTimeRequestedByPatient, 
  sgProviderAppointmentRescheduledByPatient,
  sgProviderSessionCanceledByPatient,
  sgProviderConsultationCanceledByPatient,
  sgProviderIntakeCanceledByPatient,
  sgProviderTherapyCanceledByPatient,
  sgProviderStrikeHoldV2,
  sgProviderStrikeClient
} from '../../../../../../containers/Models/SgEmailModel';
import {chargePaymentMethod} from '../../../../../../containers/Models/stripe';

import PatientRescheduleAppointmentCard from './PatientRescheduleAppointmentCard/PatientRescheduleAppointmentCard';
import Calendar from '../../../../../ui/Calendar/Calendar';
import TimeSlots from '../../../../../ui/TimeSlots/TimeSlots';
import ErrorSnackbar from '../../../../../ui/ErrorSnackbar/ErrorSnackbar';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import classes from './PatientRescheduleAppointment.module.css';
import '../../../../../../globalCss/globalCssMuiButton.css';
import { updateProviderGCalSlots, removeProviderGCalSlots } from '../../../../../../containers/Models/ProviderModel';

interface Props {
appointment: {
    id: string;
    canvasId: string;
    start: Date;
    end: Date;
    service: string;
    status: string;
    type: string;
    providerId: string;
    providerCanvasId: string;
    providerName: string;
    providerPicUrl: string;
    newTherapyTime?: boolean;
    meetingId: string;
  }|null;
  closeSchedule: React.MouseEventHandler;
}

const PatientRescheduleAppointment: React.FC<Props> = props => {

  const analytics = getAnalytics();
  const patientData = usePatientData();
  const providerData = useFetchProviderData();
  const coverageData = useFetchCoverageData();
  const updateStatePatientData = useUpdatePatientData();
  const stripe = useStripe();
  const elements = useElements();

  const today = new Date();
  //Set minDate to 4 hours after today
  const minDate = new Date(today.getTime() + 60 * 60 * 4 * 1000);
  let maxDate = new Date(today);
  //By default, set maxDate to 7 days after today
  maxDate.setDate(maxDate.getDate() + 7);

  const [date, setDate] = useState(minDate); //Controls the active date in the calendar ui
  const [confirmedDate, setConfirmedDate] = useState<null|Date>(null);// New date for appointment to be chosen by user
  const [newAppointment, setNewAppointment] = useState<any>(null); // Same structure as props.appointment
  const [appointmentChanged, setAppointmentChanged] = useState(false); // Will be true after user requests new appointment time
  const [appointmentCanceled, setAppointmentCanceled] = useState(false); // Will be true after user cancels appointment 
  const [radioValue, setRadioValue] = useState("changeNextOnly"); //changeNextOnly changes only this appointment, changeAll changes all future appointments
  const [disableReschedule, setDisableReschedule] = useState(false);
  const [openDialogCancel, setOpenDialogCancel] = useState(false);
  const [openDialogReschedule, setOpenDialogReschedule] = useState(false);
  const [lateCancel, setLateCancel] = useState(false);
  const [dialogMessage, setDialogMessage] = useState<any>("");
  const [dialogBtnLabel, setDialogBtnLabel] = useState("confirm");
  const [frequency, setFrequency] = useState(0); //Controls the frequency of appointments
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [error, setError] = useState<any>(null);

  //For therapy sessions, set maxDate to week before next appointment
  if (patientData.therapyFollowDate && (props.appointment!.service === 'intake' || props.appointment!.service === 'individual'))
    maxDate = startOfWeek(patientData.therapyFollowDate);
  let availabilityDays = 
    radioValue === "changeNextOnly" ?
      differenceInCalendarDays(maxDate, minDate)
    : differenceInCalendarDays(maxDate, minDate) + 30;

  const availableSlots = useFetchOpenProviderSlots(props.appointment!.providerCanvasId, availabilityDays, 60, 60, frequency);

  useEffect(() => {
    //Disable reschedule if appointment is a therapy session happening within 24 hours
    if ( 
      props.appointment
      && patientData
      && (props.appointment.service === "individual" || props.appointment.service === "intake")
      && differenceInHours(props.appointment.start, new Date()) < 24 
    ) {
      setDisableReschedule(true);
      setRadioValue("");
      setLateCancel(true);
      //For clients excluded from noshow policy
      if (patientData.excludeNoshows) {
        if (props.appointment && props.appointment.service === 'individual' && !props.appointment.newTherapyTime) 
        setDialogMessage("This cannot be undone. Your following appointment will get booked automatically.")      
        else
          setDialogMessage("This cannot be undone. Your scheduled time will no longer be reserved for your future appointments.")  
      }
      //For insurance clients
      else if (coverageData && (coverageData.isActive || coverageData.error)) {
        let passesLeft = patientData.lateCancels ? 2 - patientData.lateCancels.length : 2;
        if (passesLeft < 0)
          passesLeft = 0;
        setDialogMessage(
          patientData.lateCancels && patientData.lateCancels.length > 1 ? (
            <React.Fragment>
              <p>Your care will be put on hold if you cancel or miss this appointment.</p>
              <p><strong>Passes left: {passesLeft}</strong></p>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <p>You will use a pass for canceling late because this appointment is happening within the next 24 hours.</p>
              <p><strong>Passes left: {passesLeft}</strong></p>
              <p>A third late cancellation or missed appointment will result in a hold on your care.</p>
            </React.Fragment>
          )
        );
        setDialogBtnLabel("Accept")       
      }
      //For private pay clients
      else {
        setDialogMessage("You will be charged a " + (patientData.privatePayRate / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) + " fee because this appointment is happening within the next 24 hours.");
        setDialogBtnLabel("Accept fee")
      }
    }
    else if (props.appointment && props.appointment.service === "consultation") {
      setDialogMessage("You will have to choose a new therapist if you cancel this consultation.")   
    }
    else {
      setLateCancel(false);
      if (props.appointment && props.appointment.service === 'individual' && !props.appointment.newTherapyTime) 
        setDialogMessage("This cannot be undone. Your following appointment will get booked automatically.")      
      else
        setDialogMessage("This cannot be undone. Your scheduled time will no longer be reserved for your future appointments.")  
    }
  }, [props.appointment, patientData, coverageData]);

  //Set initial date for calendar once availableSlots are fetched
  useEffect(() => {
    if (availableSlots) {
      let firstAvailableDate = availableSlots.find((slot:any) => new Date(slot.resource.start) > minDate)
      if (firstAvailableDate)
        setDate(new Date(firstAvailableDate.resource.start));
    }
  }, [availableSlots]);


  const handleDisableDate = ({activeStartDate, date, view }:any) => {
    if (disableReschedule) {
      return true;
    }
    else {
      return(
        availableSlots && !availableSlots.find(
          (slot:any) => isSameDay(date, new Date(slot.resource.start)) && new Date(slot.resource.start) > minDate
        )
      );
    }
  };

  const calendarProps = {
    minDate: minDate,
    maxDate: maxDate,
    tileDisabled: handleDisableDate
  };

  // Updates a specific apointment and returns updated appointments array
  const getUpdatedAppointments = (newAppointment: {
    id: string,
    start: Date,
    end: Date,
    service: string,
    type: string,
    status: string,
    providerId: string,
    providerName: string,
    providerPicUrl: string  
  }) => {
    if (patientData) {
      let updatedAppointments = [...patientData.appointments];
      let indexToChange = updatedAppointments.findIndex(appointment => appointment.id === newAppointment.id);
      if (indexToChange > -1) {
        updatedAppointments[indexToChange] = newAppointment;
        //Sort array by date of appointment
        updatedAppointments.sort((a,b) => (a.start > b.start) ? -1 : ((b.start > a.start) ? 1 : 0))
      }
      return updatedAppointments;
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'changeNextOnly')
      setFrequency(0);
    else 
      setFrequency(1);
    setRadioValue((event.target as HTMLInputElement).value);
  };

  const handleFrequencyChange = (event: SelectChangeEvent) => {
    setFrequency(parseInt(event.target.value));
  };

  const handleUpdateAppointment = (newAppointmentDate: Date) => {
    
    if (props.appointment) {
      
      //Create an update new appointment object
      let updatedAppointment = {...props.appointment};
      updatedAppointment.start = newAppointmentDate;
      //Compute appointment length to determine new end date
      let appointmentLength = new Date(props.appointment!.end).getTime() - new Date(props.appointment!.start).getTime();
      updatedAppointment.end = new Date(newAppointmentDate.getTime() + appointmentLength);
      updatedAppointment.status = 'proposed';
      if (radioValue === "changeAll")
        updatedAppointment.newTherapyTime = true;
      setNewAppointment(updatedAppointment);
      let updatedPatientData = {...patientData};
      
      //Update canvas appointment
      canvasUpdateAppointment(updatedAppointment)

        .then(async () => {
      
          //If user selects to change all future appointments
          if (radioValue === "changeAll") {

            //Track firebase event
            logEvent(analytics, 'therapy_rescheduled');

            //Update appointment in db
            updateAppointmentTimeDb(updatedAppointment.id, updatedAppointment.start, updatedAppointment.end, updatedAppointment.status, updatedAppointment.newTherapyTime);

            //Remove any existing canvas task linked with appointment
            await canvasCloseTaskByAppointment(patientData.canvasId, providerData.canvasId, updatedAppointment.canvasId);
            
            const eventId = patientData.therapySlotsBlockId;

            updateProviderGCalSlots(patientData, providerData, eventId, providerData.calendarId, frequency, updatedAppointment.start, updatedAppointment.end)
              .then(eventData =>{
                updatePatientFutureTherapyVars(patientData.id, frequency, new Date(eventData.start.dateTime), eventData.htmlLink, eventData.id);
                updatedPatientData.therapyFrequency = frequency;
                updatedPatientData.therapyFollowDate = new Date(eventData.start.dateTime);
                updatedPatientData.therapySlotsBlockLink = eventData.htmlLink;
                updatedPatientData.therapySlotsBlockId = eventData.id;
              });

            //Create canvas task for provider
            let freqText = '';
            if (frequency === 1) freqText = 'Every week';
            else if (frequency === 2) freqText = 'Every 2 weeks';
            else if (frequency === 3) freqText = 'Every 3 weeks';
            else if (frequency === 4) freqText = 'Every 4 weeks';
            let description = 'New therapy time requested: ' + freqText + ' on ' + format(updatedAppointment.start, 'iiii') + "s at " + format(updatedAppointment.start, 'p') + ", starting on " + format(updatedAppointment.start, 'MM/dd');
            let note = 'Confirm the next appointment in your schedule to approve this change, or request a different time.'
            canvasCreateTask(patientData.canvasId, providerData.canvasId, updatedAppointment.canvasId, updatedAppointment.start, description, note);

            //Send alerts to provider
            twilioSendText(providerData.phone, "Allminds: Your client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") requested a new therapy schedule. Please confirm on allminds.canvasmedical.com.");
            sgProviderTherapyTimeRequestedByPatient(providerData.email, patientData.firstName, providerData.firstName, updatedAppointment.start);

            //Send alerts to patient
            sgPatientTherapyTimeRequestedByPatient(
              patientData.email, 
              patientData.firstName, 
              updatedAppointment.providerName, 
              patientData.frequency, 
              updatedAppointment.start
            );
          
          } 

          //If user is just changing single appointment
          else {

            //Track firebase event
            logEvent(analytics, 'appointment_rescheduled');

            //Update appointment in db
            updateAppointmentTimeDb(updatedAppointment.id, updatedAppointment.start, updatedAppointment.end, updatedAppointment.status, updatedAppointment.newTherapyTime);

            //Create canvas task for provider
            let description = 'Appointment rescheduled:\n' + format(updatedAppointment.start, 'MM/dd/yy, p') ;
            let note = 'Confirm appointment in your schedule, or request a different time.'
            canvasCreateTask(patientData.canvasId, providerData.canvasId, updatedAppointment.canvasId, updatedAppointment.start, description, note);

            //Send alerts to provider
            twilioSendText(providerData.phone, "Allminds: Your client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") has rescheduled their upcoming appointment. Please confirm on allminds.canvasmedical.com.");
            sgProviderAppointmentRescheduledByPatient(providerData.email, patientData.firstName, providerData.firstName, updatedAppointment.service, updatedAppointment.start);

            //Send alert to patient
            sgPatientAppointmentRescheduledByPatient(
              patientData.email, 
              patientData.firstName, 
              updatedAppointment.providerName, 
              updatedAppointment.service, 
              updatedAppointment.start
            );

          }

          //Update Zoom Meeting Appointment Time
          let meetingReq  = {
            startTime: updatedAppointment.start,
            meetingId: updatedAppointment!.meetingId
          }

          let meetingObj = await updateZoomMeetingTime(meetingReq);
          if (meetingObj === null || meetingObj === undefined) {
            console.log("WARN: Couldn't Uodate Meeting");
          } else {
            console.log("INFO: Updated Zoom Meeting.");
          }

          //Update appointment in state
          let updatedAppointments = getUpdatedAppointments(updatedAppointment);
          updatedPatientData.appointments = updatedAppointments;
          updateStatePatientData(updatedPatientData);
          if (openDialogReschedule) {
            setLoadingDialog(false);
            setOpenDialogReschedule(false);
          }
          setAppointmentChanged(true);
        
        })

        .catch(error => {
          console.log(error);
          setError(true);
        });

  };
}

  //HANDLE CANCEL APPOINTMENT REQUEST
  const handleCancelAppointment = async () => {
    if (props.appointment) {
      let canceledAppointment:any = {...props.appointment};
      canceledAppointment.status = "cancelled"; 
      canceledAppointment.newTherapyTime = false;
      setNewAppointment(canceledAppointment);

      //Charge the patient the full amount of the session if cancelation policy applies
      handleChargeLateCancel(canceledAppointment)

        //Update appointment in canvas (db will get updated server side)
        .then(() => {
          return canvasUpdateAppointment(canceledAppointment)
        })

        .then(() => {

          //Track firebase event
          logEvent(analytics, 'appointment_canceled');

          //Send alerts to provider 
          if (canceledAppointment.service === 'consultation') {
            twilioSendText(providerData.phone, "Allminds: Your referral (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") canceled their consultation. No further action is required.");
            sgProviderConsultationCanceledByPatient(providerData.email, patientData.firstName, providerData.firstName, canceledAppointment.start);
          }
          else if (canceledAppointment.service === 'intake') {
            twilioSendText(patientData.providerData.phone, "Allminds: Your client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") canceled their intake happening on " + format(canceledAppointment.start, 'iiii MM/dd') + " at " + format(canceledAppointment.start, 'p') + ".");
            sgProviderIntakeCanceledByPatient(providerData.email, patientData.firstName, providerData.firstName, canceledAppointment.start);
          }
          else if (canceledAppointment.newTherapyTime) {
            twilioSendText(patientData.providerData.phone, "Allminds: Your client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") canceled their therapy schedule. You can request a new schedule through allminds.canvasmedical.com.");
            sgProviderTherapyCanceledByPatient(providerData.email, patientData.firstName, providerData.firstName, canceledAppointment.start)
          }
          else {
            twilioSendText(patientData.providerData.phone, "Allminds: Your client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") canceled their session happening on " + format(canceledAppointment.start, 'iiii MM/dd') + " at " + format(canceledAppointment.start, 'p') + ".");
            sgProviderSessionCanceledByPatient(providerData.email, patientData.firstName, providerData.firstName, canceledAppointment.start);
          }

          //Update appointment in state
          let updatedAppointments = getUpdatedAppointments(canceledAppointment);
          let updatedPatientData = {...patientData};
          updatedPatientData.appointments = updatedAppointments;

          //OBSOLETE SINCE CONSULTATIONS WERE REMOVED
          //Remove assigned provider from patient db if appointment is with consulting provider
          // if (
          //   patientData.provider
          //   && canceledAppointment.providerId === patientData.provider.id 
          //   && patientData.provider.status === 'pendingApproval'
          // ) {
          //   removePatientProvider(patientData.id);
          //   updatedPatientData.provider = null;
          // }

          //Clear therapyFollowDate from state if canceled appointment was a new therapy time or intake
          if (props.appointment!.newTherapyTime || props.appointment!.service === 'intake') {
            updatedPatientData.therapyFollowDate = null;
            updatedPatientData.therapyFrequency = 0;
            updatedPatientData.therapySlotsBlockId = '';
            updatedPatientData.therapySlotsBlockLink = '';
          }

          updateStatePatientData(updatedPatientData);

          if (openDialogCancel) {
            setLoadingDialog(false);
            setOpenDialogCancel(false);
          }
          setAppointmentCanceled(true);
        })

        .catch(error => {
          console.log(error);
          setLoadingDialog(false);
          setOpenDialogCancel(false);
          setError(error);
        });

    }
  };

  const handleChargeLateCancel = async (appointment:any) => {
    if (lateCancel) {

      //Add lateCancel flag to db
      updateAppointmentLateCancel(appointment.id);
      //Add flag to db if client is excluded from noshow policy
      if (patientData.excludeNoshows) {
        updateAppointmentExcludeNoshow(appointment.id);
      }

      //For insurance clients add strike to account
      if (coverageData && (coverageData.isActive || coverageData.error)) {

        //Check that appointment ID isn't already in patientData lateCancels
        if (!patientData.lateCancels || !patientData.lateCancels.includes(appointment.id)) {

          let updatedPatientData = {...patientData};
          let updatedLateCancels:any = [];
          if (updatedPatientData.lateCancels)
            updatedLateCancels = [...updatedPatientData.lateCancels];
            updatedLateCancels.push(appointment.id);
            //Update state
            updatedPatientData.lateCancels = updatedLateCancels;
            updateStatePatientData(updatedPatientData);
            //Add strike in db
            return addPatientLateCancel(patientData.id, updatedLateCancels)
              .then(() => {
                let patientInitials = patientData.firstName.charAt(0) + ' ' + patientData.lastName.charAt(0);
                if (!patientData.excludeNoshows && updatedLateCancels.length > 2) {
                  addPatientTreatmentHold(patientData.id);
                  sgPatientStrikeHoldV2(patientData.email, patientData.firstName, appointment.providerName, appointment.start);
                  sgProviderStrikeHoldV2(providerData.email, appointment.providerName.split(' ')[0], patientInitials, appointment.start, appointment.patientCanvasId);
                }
                else if (!patientData.excludeNoshows) {
                  sgPatientLateCancelStrike(patientData.email, patientData.firstName, appointment.providerName, appointment.start, updatedLateCancels.length);
                  sgProviderStrikeClient(providerData.email, appointment.providerName.split(' ')[0], patientInitials, appointment.start, updatedLateCancels.length, appointment.patientCanvasId)
                }
              });

        }

        else return;

      }

      //For private pay clients charge late cancelation fee
      else {

        let chargeOptions = {
          paymentMethodId: patientData.stripeDefaultPaymentId,
          customerId: patientData.stripeCustomerId,
          amount: patientData.privatePayRate,
          email: patientData.email,
          description: "Late cancelation: " + format(appointment.start, 'MM/dd/yy, p')
        };

        return chargePaymentMethod(chargeOptions)

          //Update appointment db with payment info
          .then(res => {
            return updateAppointmentPayment(appointment.id, res.paymentIntent.id, res.paymentIntent.amount)
          })

          .catch(error => {

            if (error.code === 'authentication_required') {

              if (!stripe || !elements) {
                // Stripe.js has not yet loaded.
                // Make sure to disable form submission until Stripe.js has loaded.
                return;
              }

              let intent = error.payment_intent;

              return stripe.confirmCardPayment(intent.client_secret, {
                payment_method: intent.last_payment_error.payment_method.id
              }).then(function(res) {

                if (res.error) {
                  // Show error to your customer
                  throw (res.error);
                } 
                else {
                  if (res.paymentIntent.status === 'succeeded') {
                    // The payment is complete!
                    return updateAppointmentPayment(appointment.id, res.paymentIntent.id, res.paymentIntent.amount);
                  }
                }

                });

            }

            else {
              throw error;
            }

          });

      }

    }

    else return;  
  };

  const handleOpenDialogCancel = () => {
    setOpenDialogCancel(true);
  };
  const handleCloseDialogCancel = () => {
    setOpenDialogCancel(false);
  };
  const handleConfirmDialogCancel = () => {
    setLoadingDialog(true);
    handleCancelAppointment();
  };

  const handleOpenDialogReschedule = () => {
    setOpenDialogReschedule(true);
  };
  const handleCloseDialogReschedule = () => {
    setConfirmedDate(null);
    setOpenDialogReschedule(false);
  };
  const handleConfirmDialogReschedule = () => {
    if (confirmedDate) {
      setLoadingDialog(true);
      handleUpdateAppointment(confirmedDate);
    }
  };

  const handleConfirmDate = (date: Date) => {
    if (date && props.appointment) {
      //If appointment is happening within the next 24 hours open dialog to alert of fees
      if (props.appointment.service === "individual" && differenceInHours(props.appointment.start, new Date()) < 24) {
        handleOpenDialogReschedule();
      }
      //Else proceed with rescheduling
      else {
        handleUpdateAppointment(date);
      }
    }
  };

  if (props.appointment && !appointmentChanged && !appointmentCanceled) {
    return(
      <div className={classes.containerRescheduleAppointment}>

        <Dialog open={openDialogCancel} onClose={handleCloseDialogCancel} className={classes.dialog}>
          <DialogTitle>
            {"Cancel appointment?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogCancel}>Go back</Button>
            <LoadingButton onClick={handleConfirmDialogCancel} loading={loadingDialog} autoFocus>
              {dialogBtnLabel}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <Dialog open={openDialogReschedule} onClose={handleCloseDialogReschedule}>
          <DialogTitle>
            {"Reschedule appointment?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              {dialogMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDialogReschedule}>Go back</Button>
            <LoadingButton onClick={handleConfirmDialogReschedule} loading={loadingDialog} autoFocus>
              {dialogBtnLabel}
            </LoadingButton>
          </DialogActions>
        </Dialog>

        <div className={classes.header}>
          <IconButton onClick={props.closeSchedule} className={classes.closeBtn} aria-label="start over">
            <CloseRoundedIcon />
          </IconButton>
            <PatientRescheduleAppointmentCard 
              appointment={props.appointment} 
              handleOpenDialog={handleOpenDialogCancel} 
              lateCancel={lateCancel}
              showPolicy={!patientData.excludeNoshows}
            />
        </div>

        <h2>Reschedule</h2>
        {
          props.appointment.service === "individual" || props.appointment.service === "intake" ? (
            <FormControl className={classes.radioGroup} disabled={disableReschedule}>
              <RadioGroup
                aria-labelledby="reschedule-options"
                name="reschedule-options"
                value={radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel value="changeNextOnly" control={<Radio />} label="Change this appointment only" />
                <FormControlLabel value="changeAll" control={<Radio />} label="Change this and all future appointments" />
                {radioValue === 'changeAll' ? (
                  <div className={classes.frequencyContainer}>
                    <FormControl fullWidth>
                      <InputLabel id="frequency">Frequency</InputLabel>
                      <Select
                        labelId="frequency"
                        id="frequency-select"
                        value={frequency.toString()}
                        label="Frequency"
                        onChange={handleFrequencyChange}
                      >
                        <MenuItem value={1}>Every week</MenuItem>
                        <MenuItem value={2}>Every two weeks</MenuItem>
                        <MenuItem value={3}>Every three weeks</MenuItem>
                        <MenuItem value={4}>Every four weeks</MenuItem>
                      </Select>
                    </FormControl>
                  </div>  
                ) : (null)}
              </RadioGroup>
            </FormControl>
          ) : (
            <p>Choose a new date and time for your appointment.</p>
          )
        }
        <Grid container spacing={{ xs: 1, sm: 2, md: 4, lg: 8 }}>
          <Grid item xs={12} sm={7} md={6}>
            <div className={classes.calendarContainer} style={!availableSlots ? {pointerEvents: "none"} : {}}>
              {availableSlots ? 
                <Calendar 
                  value={date}
                  onChange={setDate}
                  calendarProps={calendarProps} 
                />
              :
                <Stack spacing={2}>
                  <Skeleton variant="rectangular" height={44} />
                  <Skeleton variant="rectangular" height={285} />
                </Stack>            
              }
            </div>
          </Grid>
          {!disableReschedule ? (
            <Grid item xs={12} sm={5} md={6}>
              <div className={classes.timeSlotsContainer}>
                <h4>{format(date, 'EEEE, LLLL dd')}</h4>
                {!availableSlots ? 
                  <Stack spacing={2}>
                    <Skeleton variant="rectangular" height={44} />
                    <Skeleton variant="rectangular" height={44} />
                    <Skeleton variant="rectangular" height={44} />
                  </Stack>
                  :
                  <TimeSlots 
                    slots={availableSlots} 
                    selectedDate={date}
                    onClickConfirm={handleConfirmDate}
                    label="confirm"
                  />
                }
              </div>
            </Grid>
          ) : (null)}
        </Grid>
        <ErrorSnackbar 
          open={error ? true : false} 
          setOpen={setError} 
          label={
            error 
            && ( error.type === 'card_error' || error.code === 'payment_intent_authentication_failure' ) ? 
              'Payment failed. Please update your payment method in your account settings.' 
            : ''} 
        />
      </div>
    );
  }
  //After user confirms new time
  else if (appointmentChanged) {
    return(
      <div className={classes.containerRescheduleAppointment}>
        <div className={classes.header}>
          <IconButton onClick={props.closeSchedule} className={classes.closeBtn} aria-label="start over">
            <CloseRoundedIcon />
          </IconButton>
          <h2>Request submitted</h2>
          <p>Your appointment isn't confirmed yet. We'll let you know when your therapist accepts your request.</p>
          <PatientRescheduleAppointmentCard appointment={newAppointment} />
        </div>
      </div>
    );
  }
  else if (appointmentCanceled) {
    return(
      <div className={classes.containerRescheduleAppointment}>
        <div className={classes.header}>
          <IconButton onClick={props.closeSchedule} className={classes.closeBtn} aria-label="start over">
            <CloseRoundedIcon />
          </IconButton>
          <h2>Appointment cancelled</h2>
          <p>Your appointment with {props.appointment!.providerName} has been cancelled.</p>
          <PatientRescheduleAppointmentCard appointment={newAppointment} />
        </div>
      </div>
    );
  }
  else return null;
};

export default PatientRescheduleAppointment;
