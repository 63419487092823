import React, {useState} from 'react';

import TextFieldPassword from '../../../../../ui/TextFieldPassword/TextFieldPassword';

import Alert from '@mui/material/Alert';
import { LoadingButton } from '@mui/lab';
import classes from '../PatientSettingsEditName/PatientSettingsEditName.module.css';
import '../../../../../../globalCss/globalCssMuiTextField.css';
import '../../../../../../globalCss/globalCssMuiButton.css';
import '../../../../../../globalCss/globalCssMuiAlert.css';

interface Props {
  handleClose: Function;
}

const PatientSettingsEditPassword: React.FC<Props> = props => {

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordIsValid, setNewPasswordIsValid] = useState(false);

  const [loading, setLoading] = useState(false);
  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const handleSubmit = (e:React.FormEvent) => {
    
    e.preventDefault();

    if (oldPassword && newPassword && newPasswordIsValid) {
      
      setLoading(true);

      //TODO: Update password to firebase

      //After db update (REMOVE TIMEOUT)
      setTimeout(() => {
        setLoading(false);
        setOldPassword('');
        setNewPassword('');
        setPasswordUpdated(true);
      }, 500);
    }
  };

  return(
    <div className={classes.containerEditSetting}>
      <div className={classes.header}>
        <h2>Password</h2>
        {passwordUpdated ? 
          <Alert severity="success">
            Your password was updated succesfully.
          </Alert>
        : null}
      </div>

      <form onSubmit={handleSubmit}>

        <div className={classes.contentElement}>
          <TextFieldPassword label="Current password" password={oldPassword} handleSetPassword={setOldPassword} required />
        </div>

        <div className={classes.contentElement}>
          <TextFieldPassword 
            label="New password"
            password={newPassword} 
            handleSetPassword={setNewPassword} 
            handleSetPasswordIsValid={setNewPasswordIsValid}
            required 
          />
        </div>

        <LoadingButton type="submit" loading={loading} variant="contained" size='large' className={classes.btnSubmit}>Save</LoadingButton>

      </form>
    </div>
  );
 
};

export default PatientSettingsEditPassword;