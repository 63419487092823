import React from 'react';

import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';

import Button from '@mui/material/Button';

import classes from '../PatientCompletePhq15Card/PatientCompletePhq15Card.module.css';
import '../../../../../../../globalCss/globalCssMuiButton.css';

interface Props {
  handleStart: React.MouseEventHandler;
}

const PatientCompletePhq9Card: React.FC<Props> = props => {

  return(
    <div className={classes.containerCompleteAssessment}>     
      <div className={classes.header}>
        <ThumbUpAltOutlinedIcon />
        <div style={{flexGrow: 1}}>
          <h5>Motivation & Mood</h5>
          <div className={classes.timeToComplete}>
            <p>2 min</p>
          </div>
        </div>
        <Button onClick={props.handleStart} variant="outlined" color='inherit' size='small'>Start</Button>
      </div>
    </div>
  );
};

export default PatientCompletePhq9Card;
