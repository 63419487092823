import React, { useState } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import { canvasCreateQuesPhq9 } from '../../../../../../containers/Models/CanvasQuestionnaires';
import { addPhq9ToDb } from '../../../../../../containers/Models/assessmentModels/phq9Model';
import { sgProviderSuicidalityAlert } from '../../../../../../containers/Models/SgEmailModel';
import { twilioSendText } from '../../../../../../containers/Models/twilio';

import NavBarPatientChat from '../../../../../ui/ChatForm/NavBarPatientChat/NavBarPatientChat';
import ChatFormScreener from '../../../../../ui/ChatForm/ChatFormScreener';
import Skeleton from '@mui/material/Skeleton';

import classes from "../PatientChatPhq15/PatientChatPhq15.module.css";

interface Props {
  handleCloseChat: any;
}

const PatientChatPhq9: React.FC<Props> = props => {

  const analytics = getAnalytics();
  const patientData = usePatientData();
  const handleUpdateStatePatientData = useUpdatePatientData();
  const [disableBackBtn, setDisableBackBtn] = useState(false);

  const questions: any = {
    uninterested: 
      "For the following situations, I'd like you to think about how often these have bothered you <b>in the past two weeks</b>." +
      "&&Have you felt little interest or pleasure in doing things?",
    depressed: "How about feeling down, depressed or hopeless?",
    sleepIssues: "Any trouble falling or staying asleep, or sleeping too much?",
    lowEnergy: "And feeling tired or having little energy?",
    eatingIssues: "Poor appetite or overeating?",
    feelingOfFailure:
      "Have you felt bad about yourself?" +
      "&&Or that you are a failure or have let yourself or your family down?",
    lowFocus:
      "Have you had trouble concentrating on things, such as reading the newspaper or watching television?",
    movementIssues:
      "What about moving or speaking so slowly that other people could have noticed?" +
      "&&Or so fidgety or restless that you have been moving a lot more than usual?",
    selfHarmThoughts:
      "Lastly, have you had thoughts that you would be better off dead?" +
      "&&Or thoughts of hurting yourself in some way?"
  };


  //Object with options for phq9 questions and associated scoring value
  const options: any = {
    "Not at all": 0,
    "Several days": 1,
    "More than half the days": 2,
    "Nearly every day": 3
  };

  const intro = {
    message:
      "Feeling sad, disinterested in activities or unmotivated is totally normal from time to time." +
      "&&But if these feelings stick around for a long time it may be a sign of depression." +
      "&&These questions will help your provider better evaluate how your mood has been recently.",
    option: "Sounds good 👍"
  };

  const getScore = (formData: any) => {
    let score = 0;
    for (let key in formData) {
      if (key !== "intro" && key !== "end" && key !== "suicidePlans" && key !== "isInCrisis") {
        score = score + options[formData[key][0]];
      }
    } 
    return score
  };

  const getScoreResponse = (formData: any) => {
    let score = getScore(formData);
    let robotResponse = "";
    if (score < 5)
      robotResponse =
        "Thanks for sharing " +
        patientData.firstName +
        "!&&It looks like depression isn't a concern for you right now." + 
        "&&You can talk to your provider in more detail about your results!" 
    else if (score < 10)
      robotResponse =
        "Thanks for sharing " +
        patientData.firstName +
        ".&&Your responses indicate that you might be experiencing mild symptoms of depression." +
        "&&You can talk to your provider in more detail about how to best monitor and manage these symptoms moving forward!" ;
    else if (score < 15)
      robotResponse =
        "Thanks for sharing " +
        patientData.firstName +
        ".&&Your responses indicate that you might be experiencing moderate symptoms of depression." +
        "&&You can talk to your provider in more detail about how to best monitor and manage these symptoms moving forward!" ;
    else if (score < 20)
      robotResponse =
        "Thanks for sharing " +
        patientData.firstName +
        ".&&Your responses indicate that you might be experiencing moderately severe symptoms of depression." +
        "&&You can talk to your provider in more detail about how to best monitor and manage these symptoms moving forward!" ;

    else
      robotResponse =
        "Thanks for sharing " +
        patientData.firstName +
        ".&&Your responses indicate that you might be experiencing severe symptoms of depression." +
        "&&You can talk to your provider in more detail about how to best monitor and manage these symptoms moving forward!" ;

    return robotResponse;
  };

  //This function looks for a specific tag and removes it if found
  const removeTag = (tagName:string, tags:any[]) => {
    //look for tag
    let tagIndex = tags.findIndex((tag: any) => tag.name === tagName);
    //Remove tag if found
    if (tagIndex > -1) {
      tags.splice(tagIndex, 1);
    }
  };

  //Send alerts to provider
  const handleReportCrisis = () => {
    let patientInitials = patientData.firstName.charAt(0) + ' ' + patientData.lastName.charAt(0);
    sgProviderSuicidalityAlert(patientData.providerData.email, patientInitials, new Date(), patientData.canvasId);
    let text = 'Allminds: Your client endorsed some level of suicidal ideation on the PHQ-9: allminds.canvasmedical.com/patient/' + patientData.canvasId;
    twilioSendText(patientData.providerData.phone, text);
  };

  const questionCallback = (dto: any, success: any, error: any, cf: any) => {
    
    const createTag = (name:string, question:string, answers:string[]) => {
      const createTagChildren = () => {
        let tagChildren: any = [];
        answers.forEach((answer: any) => {
          let child: any = {
            tag: "input",
            name: name,
            type: "radio",
            "cf-label": answer,
            value: answer
          };
          tagChildren.push(child);
        });
        return tagChildren;
      }
      let radioTag = {
        tag: "fieldset",
        name: name,
        "cf-input-placeholder": " ",
        "cf-questions": question,
        children: createTagChildren()
      }
      return radioTag
    };

    if (dto.tag.name === 'selfHarmThoughts' && dto.tag.value[0] !== "Not at all") {
      removeTag("suicidePlans", cf.tags);
      cf.addTags([createTag(
        "suicidePlans",
        "Have you made specific plans for suicide?",
        ["No, it's a general thought or feeling", "Yes, I have made specific plans"]
      )]);  
      success();
    }

    else if (dto.tag.name === 'suicidePlans') {
      handleSubmit(cf.getFormData(true));
      handleReportCrisis();
      if (dto.tag.value[0] === "Yes, I have made specific plans") {
        removeTag("isInCrisis", cf.tags);
        cf.addTags([createTag(
          "isInCrisis",
          "If you're in a crisis, it's important to get help right away." +
          "&&Please dial 911 immediately, or text HOME to 741741 to be connected to a Crisis Counselor.",
          ["View more resources"]
        )]); 
      }
      else {
        removeTag("end", cf.tags);
        cf.addTags([createTag(
          "end",
          getScoreResponse(cf.getFormData(true)),
          ["Exit"]
        )]);
      }
      success();
    }

    else if (dto.tag.name === 'isInCrisis') {
      //REDIRECT TO CRISIS RESOURCES PAGE
      window.location.href = "https://www.apa.org/topics/crisis-hotlines";
    }

    //At the end of form, submit data to db and add exit message 
    else if (dto.tag.name === 'selfHarmThoughts' && dto.tag.value[0] === "Not at all") {
      handleSubmit(cf.getFormData(true));
      removeTag("end", cf.tags);
      cf.addTags([createTag(
        "end",
        getScoreResponse(cf.getFormData(true)),
        ["Exit"]
      )]);
      success();
    }
    else if (dto.tag.name === 'end') {
      props.handleCloseChat();
    }
    else success();
  };

  //Function to be called after form is submitted
  const handleSubmit = (formData: any) => {

    const getSeverity = (score: number) => {
      if (score < 5) return "Normal";
      else if (score < 10) return "Mild";
      else if (score < 15) return "Moderate";
      else if (score < 20) return "Moderately Severe";
      else return "Severe";
    };

    setDisableBackBtn(true);

    //Create screenerData object from formData
    const screenerData: any = {};
    for (let key in formData) {
      if (key !== "intro" && key !== "end") {
        screenerData[key] = {
          value: formData[key][0],
          score: options[formData[key][0]]
        };
      }
    }

    let score = getScore(formData);
    let severity = getSeverity(score);

    //Create object with phq9 data
    let phq9Data = {
      
      patientId: patientData.id,
      patientCanvasId: patientData.canvasId,
      providerId: patientData.providerData.id,
      providerCanvasId: patientData.providerData.canvasId,
      timeStamp: new Date(),

      uninterested: { value: screenerData.uninterested.value, score: screenerData.uninterested.score },
      depressed: { value: screenerData.depressed.value, score: screenerData.depressed.score },
      sleepIssues: { value: screenerData.sleepIssues.value, score: screenerData.sleepIssues.score },
      lowEnergy: { value: screenerData.lowEnergy.value, score: screenerData.lowEnergy.score },
      eatingIssues: { value: screenerData.eatingIssues.value, score: screenerData.eatingIssues.score },
      feelingOfFailure: { value: screenerData.feelingOfFailure.value, score: screenerData.feelingOfFailure.score },
      lowFocus: { value: screenerData.lowFocus.value, score: screenerData.lowFocus.score },
      movementIssues: { value: screenerData.movementIssues.value, score: screenerData.movementIssues.score },
      selfHarmThoughts: { value: screenerData.selfHarmThoughts.value, score: screenerData.selfHarmThoughts.score },

      score: score,
      severity: severity

    };

    //Upload assessment to db
    addPhq9ToDb(phq9Data).then((res) => {
      //Upload questionnaire to Canvas
      canvasCreateQuesPhq9(phq9Data);
    });

    //Track firebase event
    logEvent(analytics, 'phq9_completed');

    //Update state with next due date for assessment
    let updatedPatientData = {...patientData};
    updatedPatientData.lastPhq9 = {
      id: '',
      timeStamp: phq9Data.timeStamp
    };
    handleUpdateStatePatientData(updatedPatientData);

  };

  return (
    <React.Fragment>
      <NavBarPatientChat 
        handleClose={props.handleCloseChat} 
      />
      <div className={classes.containerChatAssessment}>
        {patientData ? 
          <ChatFormScreener
            questions={questions}
            options={options}
            intro={intro}
            questionCallback={questionCallback}
            disableBackBtn={disableBackBtn}
          />
        :
          <div className={classes.containerSkeleton}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={200} height={60} />
          </div>
        }
      </div>
    </React.Fragment>
  );

};

export default PatientChatPhq9;
