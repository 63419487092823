import React from 'react';
import { usePatientData } from '../../../../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchCoverageData from "../../../../../../../hooks/useFetchCoverageData/useFetchCoverageData";
import { format, parseISO } from 'date-fns';
import AvatarSquare from '../../../../../../ui/AvatarSquare/AvatarSquare';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import classes from './PatientRescheduleAppointmentCard.module.css';
import '../../../../../../../globalCss/globalCssMuiButton.css';
import '../../../../../../../globalCss/globalCssMuiChip.css';

interface Props {
  appointment: {
    id?: string;
    start: Date;
    end: Date;
    service: string;
    status: string;
    type: string;
    providerId?: string;
    providerName: string;
    providerPicUrl: string;
    newTherapyTime?: boolean;
  }|null;
  lateCancel?: boolean;
  handleOpenDialog?: React.MouseEventHandler;
  showPolicy?: boolean;
}

const PatientRescheduleAppointmentCard: React.FC<Props> = props => {
  
  const patientData = usePatientData();
  const coverageData = useFetchCoverageData();

  if (props.appointment && patientData) {
    let start = props.appointment.start;
    let end = props.appointment.end;
    if (typeof start !== 'string') {
      if ('seconds' in props.appointment.start)
        start = (props.appointment.start as any).toDate();
    } else {
      console.log("WARN: Start Time was string: ", start);
      start = parseISO(start);
    }
    if (typeof end !== 'string') {
      if ('seconds' in props.appointment.end)
        end = (props.appointment.end as any).toDate();
    } else {
      end = parseISO(end);
    }

    let cancellationClasses = [classes.content];
    if (props.lateCancel) cancellationClasses.push(classes.red);
    let sessionFee = (patientData.privatePayRate / 100).toLocaleString("en-US", {style:"currency", currency:"USD"});

    return(
      <div className={classes.container}>
        <div className={classes.header}>
          <AvatarSquare imgUrl={props.appointment.providerPicUrl} alt={props.appointment.providerName} />
          <div>
            <h5>{ 
                props.appointment.service === "consultation" ? "Consultation with " + props.appointment.providerName.split(' ')[0]
              : props.appointment.service === "intake" ? "Individual Therapy"
              : props.appointment.service === "individual" ? "Individual Therapy"
              : props.appointment.service + " with " + props.appointment.providerName.split(' ')[0]}</h5>
            <p>{format(start, 'EE, LLLL dd')}</p>
            <div className={classes.appointmentTime}>
              <p>
                {format(start, 'p') 
                + " - " + format(end, 'p')}
              </p>
              {props.appointment.type === "video" ? 
                <VideocamOutlinedIcon />
              : props.appointment.type === "phone" ?
                <PhoneEnabledOutlinedIcon />
              :null}
            </div>
            <div className={classes.chipsContainer}>
              {props.appointment.newTherapyTime ? (
                <Chip
                  label="New schedule"
                  icon={<AccessTimeOutlinedIcon />}
                  color="warning" 
                  size="small"
                  className={classes.chip} 
                />
              ) : (null)}

              <Chip 
                label={
                  props.appointment.status === 'proposed' ? "Unconfirmed" 
                  : props.appointment.status === 'booked' ? "Confirmed" 
                  : props.appointment.status === 'cancelled' ? "Canceled" 
                  : props.appointment.status === 'pending' ? "Requested by therapist"
                  : props.appointment.status === 'fulfilled' ? "Fulfilled"
                  : props.appointment.status} 
                icon={
                  props.appointment.status === 'booked' ? <DoneRoundedIcon /> 
                  : props.appointment.status === 'cancelled' ? <EventBusyOutlinedIcon /> 
                  : props.appointment.status === 'fulfilled' ? <DoneRoundedIcon /> 
                  : undefined} 
                color={
                  props.appointment.status === 'proposed' ? "primary" 
                  : props.appointment.status === 'booked' ? "success" 
                  : props.appointment.status === 'cancelled' ? "error" 
                  : props.appointment.status === 'pending' ? "error"
                  : "primary"}
                  size="small"
                className={classes.chip} 
              />
            </div>
          </div>
        </div>
        {props.showPolicy && (props.appointment.service === 'individual' || props.appointment.service === 'intake') ? 
          <div className={cancellationClasses.join(' ')}>
            <div className={classes.row} style={{marginBottom: '8px'}}>
              <EventBusyOutlinedIcon />
              <h5>Cancellation policy</h5>
            </div>
            {
              
              //For insurance clients
              patientData.coverageId && !coverageData ? (
                <div style={{"marginTop": '8px'}}>
                  <Skeleton variant="text" width='100%' height={32} />
                  <Skeleton variant="text" width={200} height={32} />
                </div>
              ) : coverageData && (coverageData.isActive || coverageData.error) && !patientData.excludeNoshows ? (
                <p>{"Can be canceled up to 24 hours before start time. You will use a pass for canceling late otherwise."}</p>
              //For private pay clients
              ) : coverageData && (coverageData.isActive || coverageData.error) && patientData.excludeNoshows ? (
                <p>{"Can be canceled up to 24 hours before start time."}</p>
              ) : (
                <p>{"Free if cancelled 24 hours before start time. You will be charged a " + sessionFee + " fee otherwise."}</p>
              )

            }
          </div>
        : null}
        {props.handleOpenDialog ?
          <Button onClick={props.handleOpenDialog} size="small" className={classes.cancelBtn} color="secondary" variant="outlined">Cancel appointment</Button>
        : null}
      </div>
    );
  }
  else {
    return null;
  }
};

export default PatientRescheduleAppointmentCard;
