import React, {useState, useEffect} from 'react';
import { usePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchCoverageData from '../../../../../../hooks/useFetchCoverageData/useFetchCoverageData';
import { getProviderById } from '../../../../../../containers/Models/ProviderModel';
import { getMatchedProviderIds } from '../../../../../../containers/Models/ProviderModel';


import FindProviderProfile from './FindProviderProfile/FindProviderProfile';
import AvatarSquare from '../../../../../ui/AvatarSquare/AvatarSquare';

import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import classes from './FindProvider.module.css';
import '../../../../../../globalCss/globalCssMuiButton.css';

interface Props {
  handleCloseFindProvider: any;
  handleOpenMatchingChat: any;
  handleScrollTop: Function;
}

const FindProvider: React.FC<Props> = props => {

  const [showProfile, setShowProfile] = useState(false);
  const [selectedProviderId, setSelectedProviderId] = useState<string|null>(null);  
  
  const patientData = usePatientData();
  const coverageData = useFetchCoverageData();
  const [selectedProviderData, setSelectedProviderData] = useState<any>(null);
  const [patientMatches, setPatientMatches] = useState<any>(null);

  let handleScrollTop = props.handleScrollTop;

  //Fetch data of matched providers
  useEffect(() => {

    const getProvidersData = async () => {
      //Wait until coverage data is fetched
      if (patientData && patientData.coverageId && !coverageData) {
        return
      }
      let providerIds = await getMatchedProviderIds(patientData, coverageData ? coverageData.payer : '', coverageData ? coverageData.isMedicare : false);
      let data = [] as any;
      for (let i = 0; i < providerIds.length; i++) {
        let providerInfo = await getProviderById(providerIds[i]);
        if (providerInfo) 
          data.push(providerInfo); 
      }
      setPatientMatches(data);
    };

    if (patientData && !patientMatches)
      getProvidersData();

  }, [patientData, coverageData, patientMatches]);

  useEffect(() => {
    //Fetch provider data when user clicks on profile
    if (
      patientMatches 
      && (!selectedProviderData || selectedProviderData.id !== selectedProviderId)
    ) {
      let providerData = patientMatches.find((provider: any) => provider.id === selectedProviderId);
      if (providerData)
        setSelectedProviderData(providerData);
    }
  }, [selectedProviderId, selectedProviderData, patientMatches]);

  useEffect(() => {
    handleScrollTop();
  }, [selectedProviderData, handleScrollTop])

  const handleOpenProviderProfile = (providerId: string) => {
    if (!selectedProviderData || selectedProviderData.id !== providerId) {
      setSelectedProviderData(null);
      setSelectedProviderId(providerId);
    }
    setShowProfile(true)
  };

  const handleCloseProviderProfile = () => {
    setShowProfile(false);
  };

  // const handleSwitchToMatchingChat = () => {
  //   props.handleCloseFindProvider();
  //   props.handleOpenMatchingChat();
  // };

  //Show all provider matches
  if (!showProfile) {
    return(
      <div className={classes.containerFindProvider}>
        <div className={classes.header}>
          <IconButton onClick={props.handleCloseFindProvider} className={classes.closeBtn} aria-label="start over">
            <CloseRoundedIcon />
          </IconButton>
          <h2>Choose a new therapist</h2>
          <p>The providers listed below are currently available to work with you.</p>
        </div>
        {patientMatches ? 
          <div className={classes.containerMatches}>
            {patientMatches.map((provider:any) => {
              return(
                <div className={classes.card} key={provider.canvasId}>
                  <div className={classes.cardHeader}>
                    <AvatarSquare imgUrl={provider.picUrl} alt={provider.name} />
                    <div>
                      <h5>{provider.firstName + ' ' + provider.lastName}</h5>
                      <p>{provider.aboutMe}</p>
                      <h5 onClick={() => handleOpenProviderProfile(provider.id)} className={classes.profileBtn}>View profile</h5>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        :
          <div className={classes.loadingFindProvider}>
            <Skeleton variant="rectangular" width="100%" height={182} />
            <Skeleton variant="rectangular" width="100%" height={182} />
            <Skeleton variant="rectangular" width="100%" height={182} />
          </div>
        }
        {/*<div className={classes.footer}>
          <p>Want to change your therapist preferences?</p>
          <h5 onClick={handleSwitchToMatchingChat} className={classes.retakeSurvey}>Retake the matching survey</h5>
        </div>*/}
      </div>
    );
  }

  //Show provider profile
  else {
    return(
      <FindProviderProfile 
        providerData={selectedProviderData}
        handleCloseProviderProfile={handleCloseProviderProfile}
        handleCloseFindProvider={props.handleCloseFindProvider}
        handleScrollTop={handleScrollTop}
        showBackBtn
      />
    );
  }

};

export default FindProvider;
