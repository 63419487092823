import { getFirestore, addDoc, updateDoc, collection, doc, query, where, getDoc, getDocs } from "firebase/firestore";
import firebaseApp from "../Data/Firestore";
const payerDb = process.env.REACT_APP_PAYER_DB;
const db = getFirestore(firebaseApp);



const getPayerByPayerId = async (payerId: string) => {
  const q = query(collection(db, payerDb as any), where("payerId", "==", payerId));
  const querySnapshot = await getDocs(q);
  let payerData = null;
  querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      payerData = doc.data();
      convertFirebaseDates(payerData);
    });

  return payerData;
};

//Convert all firebase dates to regular Date objects
const convertFirebaseDates = (obj: any) => {
  for (let key in obj) {
    if (obj[key] && obj[key].toDate) {
        obj[key] = obj[key].toDate();
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
        convertFirebaseDates(obj[key])
    }
  }    
};

export {
  getPayerByPayerId
};