import React from 'react';
import Button from '@mui/material/Button';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import classes from './PatientScheduleSessionCard.module.css';

interface Props {
  handleOpenScheduleSession: React.MouseEventHandler;
}

const PatientScheduleSessionCard: React.FC<Props> = props => {

  return(
    <div className={classes.containerPatientScheduleSessionCard}>
      <EventBusyOutlinedIcon fontSize="large" color="inherit" />
      <p>You have no upcoming sessions.</p>
      <Button onClick={props.handleOpenScheduleSession} size="large" fullWidth variant="contained">Start therapy</Button>
    </div>
  );

};

export default PatientScheduleSessionCard;
