import React, { useContext, useEffect, useState } from "react";
import { getAuth } from "firebase/auth";
import {getPatientByEmail} from "../../containers/Models/PatientModel"

const ContextPatientData = React.createContext<any>(null);
const ContextUpdatePatientData = React.createContext<any>(null);

export function usePatientData() {
  return useContext(ContextPatientData);
}

export function useUpdatePatientData() {
  return useContext(ContextUpdatePatientData);
}

export function PatientDataProvider({ children }: any) {

  const [patientData, setPatientData] = useState<any>(null);

  useEffect(() => {
    const getPatientData = () => {
      
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (currentUser?.email === null) {
        return null;
      }
      //To-Do: edit getPatientData to fetch from db
      getPatientByEmail(currentUser?.email).then(currentPatientData => {
        if (currentPatientData === null) {
          //TODO - Juan: Show UI to redirect to create an account.
          //console.log("No Patient Found with this email. Please create an account.");
          return null;
        }

        let newPatientData:any = {...currentPatientData as object};

        newPatientData.providerData = null; //Fetch via useFecthProviderData when needed
        newPatientData.appointments = null; //Fetch via useFetchPatientAppointments when needed
        newPatientData.pastAppointments = null; //Fetch via useFetchPatientPastAppointments when needed
        newPatientData.messages = null; // Fetch via useFetchPatientMessages when needed
        newPatientData.paymentMethods = null; // Fetch via useFetchPaymentMethods when needed
        newPatientData.coverage = null; // Fetch via useFetchCoverageData when needed

        newPatientData.dateSignedUp = currentUser!.metadata.creationTime;

        setPatientData(newPatientData);
      });
    }
    getPatientData()
  }, []);

  const handleUpdatePatientData = (newPatientData: {
    canvasId: string;
    id: string;
    firstName: string;
    lastName: string;
    provider: {
      id: string;
      name: string;
      picUrl: string;
    } | null;
    appointments: Array<{
      id: string;
      start: Date;
      end: Date;
      service: string;
      status: string;
      type: string;
      providerId: string;
      providerName: string;
      providerPicUrl: string;
    }>;
  }) => {
    setPatientData(newPatientData)
  };

  return (
    <ContextPatientData.Provider value={patientData}>
      <ContextUpdatePatientData.Provider value={handleUpdatePatientData}>
        {children}
      </ContextUpdatePatientData.Provider>
    </ContextPatientData.Provider>
  );

}
