import React from 'react';
import { usePatientData } from '../../../../../hooks/ContextPatientData/ContextPatientData';

import PatientInboxMessages from './PatientInboxMessages/PatientInboxMessages';

// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';

import Skeleton from '@mui/material/Skeleton';

import classes from './PatientPortalInboxContent.module.css'

interface Props {
}

// interface TabPanelProps {
//   children?: React.ReactNode;
//   index: number;
//   value: number;
// }

// const TabPanel = (props: TabPanelProps) => {
//   const { children, value, index, ...other } = props;

//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`simple-tabpanel-${index}`}
//       aria-labelledby={`simple-tab-${index}`}
//       {...other}
//     >
//       {value === index && (
//           <div>{children}</div>
//       )}
//     </div>
//   );
// };

const PatientPortalMessagesContent: React.FC<Props> = props => {

  const patientData = usePatientData();

  // const [tabIndex, setTabIndex] = useState(0);

  // const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setTabIndex(newValue);
  // };
  
  if (patientData && patientData.providerId) {
    
    // return(
    //   <div className={classes.containerPatientPortalMessagesContent}>
    //     <div className={classes.inboxHeader}>
    //       <h2>Inbox</h2>
    //       <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" className={classes.tabs}>
    //         <Tab 
    //           label="Messages"
    //           id="tab-messages" 
    //           aria-controls="tabpanel-messages"
    //         />
    //         <Tab 
    //           label="Documents"
    //           id="tab-documents" 
    //           aria-controls="tabpanel-documents"
    //         />
    //       </Tabs>
    //     </div>
          
    //     <div className={classes.panelMessages}>
    //       <TabPanel key={"tab-panel-messages"} value={tabIndex} index={0}>
    //         <PatientInboxMessages />
    //       </TabPanel>
    //     </div>

    //     <TabPanel key={"tab-panel-documents"} value={tabIndex} index={1}>
    //     </TabPanel>

    //   </div>
    // );

    return(
      <div className={classes.containerPatientPortalMessagesContent}>
        <div className={classes.inboxHeader}>
          <h2>Inbox</h2>
        </div>
          
        <div className={classes.panelMessages}>
          <PatientInboxMessages />
        </div>

      </div>
    );

  }

  else if (patientData) {
    return(
      <div className={classes.containerMessagesNoProvider}>
        <h2>Inbox</h2>
        <div className={classes.noProviderCard}>
          <ChatBubbleOutlineOutlinedIcon fontSize="large" color="inherit" />
          <h5>You don't have any messages</h5>
          <p>You'll be able to communicate with your therapist after you start therapy.</p>
        </div>
      </div>
    ); 
  }

  else return (
      <div className={classes.containerMessagesNoProvider}>
        <h2>Inbox</h2>
        <Skeleton variant="rectangular" height={208.5} width="100%"/>
      </div>
  );


};

export default PatientPortalMessagesContent;
