import React, {useState} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ReplayRoundedIcon from '@mui/icons-material/ReplayRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import classes from './NavBarPatientChat.module.css';
import '../../../../globalCss/globalCssMuiDialog.css';
import '../../../../globalCss/globalCssMuiIconButton.css';

interface Props {
  handleClose: React.MouseEventHandler;
  handleRestartChat?: Function;
}

const NavBarPatientChat: React.FC<Props> = props => {

  const [openStartOverDialog, setOpenStartOverDialog] = useState(false);

  const handleOpenStartOverDialog = () => {
    setOpenStartOverDialog(true);
  };

  const handleCloseStartOverDialog = () => {
    setOpenStartOverDialog(false);
  };

  const handleStartOver = () => {
    if (props.handleRestartChat) {
      props.handleRestartChat();
    }
    handleCloseStartOverDialog();
  };

  return (
    <nav className={classes.container}>

      {props.handleRestartChat ? 
        <Dialog
          open={openStartOverDialog}
          onClose={handleCloseStartOverDialog}
        >
          <DialogTitle id="alert-dialog-title">
            {"Want to start over?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              This will clear your answers and start from the beginning.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseStartOverDialog}>Cancel</Button>
            <Button onClick={handleStartOver} autoFocus>
              Start over
            </Button>
          </DialogActions>
        </Dialog>
      :null}

      <div className={classes.main}>
        <div className={classes.btnClose}>
          <IconButton onClick={props.handleClose} aria-label="start over">
            <CloseRoundedIcon />
          </IconButton>
        </div>        
        <h1>
          <div className={classes.dot}>
            <FiberManualRecordIcon color="inherit" fontSize="inherit"/>
          </div>
          Allminds
        </h1>

        {props.handleRestartChat ? 
          <div className={classes.btnStartOver}>
            <IconButton onClick={handleOpenStartOverDialog} aria-label="start over">
              <ReplayRoundedIcon />
            </IconButton>
          </div>
        :null}

      </div>
    </nav>
  );
};

export default NavBarPatientChat;
