import React from 'react';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Badge from '@mui/material/Badge';
import { ReactComponent as Logo } from '../../../../assets/svg/logo.svg';
import EventNoteOutlinedIcon from '@mui/icons-material/EventNoteOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

import classes from './NavBarPatientPortal.module.css';

interface Props {
  tabIndex: number;
  handleTabChange: any;
  showInboxBadge?: boolean;
  showOutcomesBadge?: boolean;
}

const NavBarPatientPortal: React.FC<Props> = props => {

  return (
    <nav className={classes.container}>
      <Tabs value={props.tabIndex} onChange={props.handleTabChange} variant="fullWidth" className={classes.tabs}>
        <Tab 
          icon={<Logo className={classes.logo} />} 
          id="tab-home" 
          aria-controls="tabpanel-home"
        />
        <Tab 
          icon={<EventNoteOutlinedIcon />} 
          id="tab-appointments" 
          aria-controls="tabpanel-appointments"
        />
        <Tab 
          icon={
            <Badge color="error" variant="dot" invisible={!props.showInboxBadge}>
              <ChatBubbleOutlineOutlinedIcon  />
            </Badge>
          } 
          id="tab-inbox" 
          aria-controls="tabpanel-settings"
        />
        <Tab 
          icon={<SettingsOutlinedIcon />} 
          id="tab-settings" 
          aria-controls="tabpanel-settings"
        />
      </Tabs>
    </nav>
  );
};

export default NavBarPatientPortal;
