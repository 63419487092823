import React, {useState} from 'react';
import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import {updatePatientName} from '../../../../../../containers/Models/PatientModel';
import TextField from '@mui/material/TextField';
import { LoadingButton } from '@mui/lab';
import classes from './PatientSettingsEditName.module.css';
import '../../../../../../globalCss/globalCssMuiTextField.css';
import '../../../../../../globalCss/globalCssMuiButton.css';

interface Props {
  handleClose: Function;
}

const PatientSettingsEditName: React.FC<Props> = props => {

  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();

  const [firstName, setFirstName] = useState(patientData.firstName);
  const [lastName, setLastName] = useState(patientData.lastName);
  const [loading, setLoading] = useState(false);

  const handleChangeFirst = (e:React.ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleChangeLast = (e:React.ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleSubmit = () => {
    if (firstName !== patientData.firstName || lastName !== patientData.lastName) {
      setLoading(true);
      updatePatientName(patientData.id, firstName, lastName);
      let updatedPatientData = {...patientData};
      updatedPatientData.firstName = firstName;
      updatedPatientData.lastName = lastName;
      updateStatePatientData(updatedPatientData);
      setLoading(false);
      props.handleClose();
    }
  };

  return(
    <div className={classes.containerEditSetting}>
      <div className={classes.header}>
        <h2>Legal name</h2>
        <p>This is the name on your insurance card.</p>
      </div>
      <TextField label="First name" value={firstName} onChange={handleChangeFirst}  variant="outlined" fullWidth className={classes.contentElement} />
      <TextField label="Last name" value={lastName} onChange={handleChangeLast} variant="outlined" fullWidth className={classes.contentElement} />
      <LoadingButton onClick={handleSubmit} loading={loading} variant="contained" size='large' className={classes.btnSubmit}>Save</LoadingButton>
    </div>
  );
};

export default PatientSettingsEditName;