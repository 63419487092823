import React from 'react';
import PhoneInput from 'react-phone-input-2';

import '../../../globalCss/globalCssReactPhoneInput.css';
import 'react-phone-input-2/lib/material.css';


interface Props {
  phone: string;
  phoneIsValid?: boolean;
  handleSetPhone: Function;
  handleSetPhoneIsValid?: Function;
  required?: boolean;
}


const TextFieldPhone: React.FC<Props> = props => {

  //Validates phone if format is valid or if empty
  const validatePhone = (p:string) => {
    let errors = [];
    if (
      p.search(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im) < 0 
    ) {
      errors.push("Please enter a valid phone number");
    }
    if (errors.length > 0) {
      return false;
    }
    return true;
  };

  const handleChange = (e:any) => {
    props.handleSetPhone(e.substring(1));
    if (validatePhone(e)) {
      if (props.handleSetPhoneIsValid)
        props.handleSetPhoneIsValid(true);
    }
    else {
      if (props.handleSetPhoneIsValid)
        props.handleSetPhoneIsValid(false);
    }
  };


  let inputClass = "";

  if (props.phoneIsValid && props.phone.length > 1) 
    inputClass = 'valid';
  
  else if (props.phone.length > 1)
    inputClass = 'invalid';

  let phone = "1" + props.phone;

  return(
    <PhoneInput
      placeholder="(000) 000-0000"
      country={'us'}
      countryCodeEditable={false}
      // disableCountryGuess
      // disableCountryCode
      disableDropdown
      value={phone}
      onChange={handleChange}
      inputStyle={{width: '100%'}}
      inputClass={inputClass}
      inputProps={{ required: props.required ? true : false }}
    />
  );
};

export default TextFieldPhone;
