import React from 'react';

import Button from '@mui/material/Button';

import classes from './PatientSettingsInput.module.css'


interface Props {
  label: string;
  value?: string;
  clicked?: React.MouseEventHandler;
}

const PatientSettingsInput: React.FC<Props> = props => {

  return(

    <div className={classes.containerPatientSettingsInput}>

      <div className={classes.header}>
        <div>
          <h5>{props.label}</h5> 
          {props.value ? 
            <p>{props.value}</p> 
          : null}
        </div>
        {props.clicked ? 
          <Button onClick={props.clicked} variant="outlined" size="small">Edit</Button>
          : null
        }
      </div>

    </div>
  
  );

};

export default PatientSettingsInput;
