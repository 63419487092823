import React from 'react';

import AvatarSquare from '../../../../../ui/AvatarSquare/AvatarSquare';

// import Tooltip from '@mui/material/Tooltip';
// import IconButton from '@mui/material/IconButton';
// import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';

import classes from './ProviderCard.module.css';

interface Props {
  providerData: any;
  appointments: Array<{
    id: string;
    start: Date;
    end: Date;
    service: string;
    status: string;
    type: string;
    providerId: string;
    providerName: string;
    providerPicUrl: string;
  }>;
  handleOpenProviderProfile: React.MouseEventHandler;
}

const ProviderCard: React.FC<Props> = props => {


  return(
    <div className={classes.container}>

      <div className={classes.header} onClick={props.handleOpenProviderProfile}>
        <AvatarSquare imgUrl={props.providerData.picUrl} alt={props.providerData.firstName} width={73} height={73} />
        <div>
          
          <React.Fragment>
            <h5>{props.providerData.firstName + ' ' + props.providerData.lastName}</h5>
            <p>Approved</p>
          </React.Fragment>

        </div>
      </div>
    </div>
  );
};

export default ProviderCard;
