import { useEffect, useState } from "react";
import { usePatientData, useUpdatePatientData } from '../ContextPatientData/ContextPatientData';
import { canvasGetPatientMessages } from '../../containers/Models/Canvas';


export default function useFetchPatientMessages() {

  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();  

  const [messagesList, setMessagesList] = useState<any>(null);

  //Fetch messages if state doesn't have them
  useEffect(() => {
    if (patientData && patientData.providerData) {
      if (!patientData.messages) {
        //Get sorted array of patient messages, in reverse chronological order
        canvasGetPatientMessages(patientData.canvasId, patientData.providerData.canvasId).then((messagesData:any) => {
          //Add intro messages to fetched array from Canvas
          let introMessages = [
            {
              "resource": {
                "resourceType": "Communication",
                "id": "intro-000",
                "sent": patientData.dateSignedUp.toString(),
                "received": patientData.dateSignedUp.toString(),
                "recipient": [
                  {
                    "reference": "Patient/" + patientData.canvasId
                  }
                ],
                "sender": {
                  "reference": "Practitioner/" + patientData.providerData.canvasId
                },
                "payload": [
                  {
                    "contentString": "Hi " + patientData.firstName + " 👋"
                  }
                ]
              }     
            },
            {
              "resource": {
                "resourceType": "Communication",
                "id": "intro-001",
                "sent": patientData.dateSignedUp.toString(),
                "received": patientData.dateSignedUp.toString(),
                "recipient": [
                  {
                    "reference": "Patient/" + patientData.canvasId
                  }
                ],
                "sender": {
                  "reference": "Practitioner/" + patientData.providerData.canvasId
                },
                "payload": [
                  {
                    "contentString": "We can use this chat to exchange secure messages. Take care!"
                  }
                ]
              }     
            }
          ]
          let messages = messagesData.messages.concat(introMessages.reverse());
          setMessagesList(messages);
        });
      }
      else {
        setMessagesList(patientData.messages);
      }
    }
  }, [patientData]);

  //Add canvas messages to state after initial fetch
  useEffect(() => {
    if (messagesList && patientData && !patientData.messages) {
      let updatedPatientData = {...patientData};
      updatedPatientData.messages = [...messagesList];
      updateStatePatientData(updatedPatientData);
    }
  }, [messagesList, patientData, updateStatePatientData]);

  return messagesList;

}