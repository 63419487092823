import React from 'react';
import { format } from 'date-fns';

import AvatarSquare from '../../../../../../ui/AvatarSquare/AvatarSquare';
import Chip from '@mui/material/Chip';

import classes from '../PatientAppointmentCard/PatientAppointmentCard.module.css'

interface Props {
  appointment: {
    id: string;
    start: Date;
    end: Date;
    service: string;
    status: string;
    type: string;
    providerId: string;
    providerName: string;
    providerPicUrl: string;
    newTherapyTime: boolean;
  };
}

const PatientAppointmentPlaceholderCard: React.FC<Props> = props => {

  let start = props.appointment.start;

  return(
    <div className={classes.containerPatientAppointmentCard} style={{opacity: props.appointment.status === 'cancelled' ? 0.5 : 1}}>
      <div className={classes.card}>
        <div className={classes.placeholderAppointment}>
          <h5>Individual Therapy</h5>
          <div className={classes.row}>
            <div>
              <p>{format(start, 'iii, LLLL dd')}</p>
              <p>
                {
                  format(start, 'p')
                }
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default PatientAppointmentPlaceholderCard;
