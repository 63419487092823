import React from 'react';
import { usePatientData } from "../../../../../../hooks/ContextPatientData/ContextPatientData";
import ProviderProfile from '../../../../StartTherapy/SelectProvider/ProviderProfiles/ProviderProfile/ProviderProfile';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import '../../../../../../globalCss/globalCssMuiButton.css';
import classes from './PatientPortalProviderProfile.module.css';

interface Props {
  providerData: {
        id: string;
        firstName: string;
        lastName: string;
        pronouns: string;
        picUrl: string;
        dob: Date;
        ethnicities: Array<string>;
        licenseType: string;
        specialties: Array<string>;
        clientPopulations: Array<string>;
        languages: Array<string>;
        aboutMe: string;
        aboutClients: string;
        myApproach: string;
        myBeliefs: string;
  };
  handleCloseProviderProfile: any;
}

const PatientPortalProviderProfile: React.FC<Props> = props => {

  const patientData = usePatientData();

  return(
    <div className={classes.container}>
      <div className={classes.header}>
        <IconButton onClick={props.handleCloseProviderProfile} className={classes.closeBtn} color="inherit" aria-label="close">
          <CloseRoundedIcon />
        </IconButton>
      </div>
      <ProviderProfile 
        provider={props.providerData} 
        patientGoals={patientData.therapyGoals} 
        isPatientLgbtq={patientData.isLgbtq}
        loading={props.providerData ? false : true}
        />
    </div>
  );
};

export default PatientPortalProviderProfile;