import React from 'react';
import { format } from 'date-fns'

import AvatarSquare from '../../../../../../ui/AvatarSquare/AvatarSquare';

import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import classes from './ReviewConsultationDetailsCard.module.css';

interface Props {
  providerName: string;
  providerPicUrl: string;
  startDate: Date;
  endDate: Date;
}

const ReviewConsultationDetailsCard: React.FC<Props> = props => {

  return(
    <div className={classes.container}>
      <div className={classes.header}>
        <AvatarSquare imgUrl={props.providerPicUrl} alt={props.providerName} />
        <div>
          <h5>Consultation with {props.providerName.split(' ')[0]}</h5>
          <p>{format(props.startDate, 'iii, LLLL dd')}</p>
          <div className={classes.appointmentTime}>
            <p>{format(props.startDate, 'p') + " - " + format(props.endDate, 'p')}</p>
            <PhoneEnabledOutlinedIcon />
          </div>
        </div>
      </div>

      <div className={classes.content}>
        
        <div className={classes.row}>
          <PaidOutlinedIcon />
          <div>
            <h5>Amount to pay: $0.00</h5>
            <p>Your consultation is free of charge.</p>
          </div>
        </div>

      </div>

    </div>
  );

};

export default ReviewConsultationDetailsCard;
