import React from 'react';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import classes from './ProviderProfileBookSession.module.css';
import '../../../../../../globalCss/globalCssMuiButton.css';


interface Props {
  providerData: any;
  openScheduleSession: React.MouseEventHandler;
  openScheduleConsultation: React.MouseEventHandler;
  coverageData: any;
  loadingCoverage?: boolean;
}

const ProviderProfileBookSession: React.FC<Props> = props => {

  let privatePayRate = 13000;
  let fee = privatePayRate;
  let feeElement = <h5>{(fee / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) + ' per session'}</h5>;
  let deductibleFee = null;
  
  //Compute insurance fee
  if (props.coverageData && !props.coverageData.error && props.coverageData.isActive) {
    let deductible = props.coverageData.individual.inNetwork.deductibleTotal;
    let deductibleUsed = props.coverageData.individual.inNetwork.deductibleUsed;
    let famDeductible = props.coverageData.family.inNetwork.deductibleTotal;
    let famDeductibleUsed = props.coverageData.family.inNetwork.deductibleUsed;
    let copay = props.coverageData.individual.inNetwork.copay;
    let coinsurance = parseFloat(props.coverageData.individual.inNetwork.coinsurance) / 100.0;
    let billingFee = props.coverageData.billingFee;

    if (copay || coinsurance) {
      fee = copay + coinsurance * billingFee;
      feeElement = <h5>{(fee / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) + ' per session'}</h5>;
    }

    if (!copay && !coinsurance) {
      fee = 0;
      feeElement = <h5>{(fee / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) + ' per session'}</h5>;
    }

    if (
      deductible - deductibleUsed > 0
      && (!famDeductible || famDeductible - famDeductibleUsed > 0)
    ) {
      feeElement = 
      <React.Fragment>
        <h5 style={{textTransform: 'uppercase', fontSize: '0.8rem', color:  '#757575'}}>After deductible</h5>
        <h5>{(fee / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) + ' per session'}</h5>
      </React.Fragment>
      deductibleFee = (
          <p>
            <small>{(privatePayRate / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})} per session before.</small>
          </p>
      );
    } 

  }

  else if (props.coverageData && props.coverageData.error) {
    feeElement = <h5>Amount due not available</h5>;
    deductibleFee = <p><small>You may be charged for this session.</small></p>
  }

  return (
    <div className={classes.containerProviderProfileBook}>
      <div className={classes.row} style={{marginBottom: '0'}}>
        {props.loadingCoverage ? (
          <Skeleton width={200} height={32} /> 
        ) : (
          <div>
            {feeElement}
            {deductibleFee}
          </div>
        )}
        <Button onClick={props.openScheduleSession} variant="contained" disabled={props.loadingCoverage}>Book session</Button>
      </div>
      {//Removed consultations on 02/10/2023
      /*<p><small>
        Questions for {props.providerData.firstName}?
      </small></p>
      <p onClick={props.openScheduleConsultation} className={classes.bookConsultation}>
        <small>Book a free consultation</small>
      </p>*/}
    </div>
  );

};

export default ProviderProfileBookSession;
