const createCustomerPatient = async (patientId: string, email:string) => {
  //console.log("Calling stripe createCustomerPatient");
  const server = process.env.REACT_APP_SERVERURL;

  const reqBody = JSON.stringify({
    patientId: patientId,
    email: email
  });
     

  return fetch(server + "/stripe-create-customer-patient", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("Got result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error with calling /stripe-create-customer-patient: ", error);
      throw error;
    });
};

const createSetupIntent = async (stripeCustomerId: string) => {
  //console.log("Calling stripe createSetupIntent");
  const server = process.env.REACT_APP_SERVERURL;

  const reqBody = JSON.stringify({
    customerId: stripeCustomerId
  });

  return fetch(server + "/stripe-create-setup-intent", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("Got result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error with calling /stripe-create-setup-intent: ", error);
      throw error;
    });
};

const getPaymentMethods = async (stripeCustomerId: string) => {
  //console.log("Calling stripe getPaymentMethods");
  const server = process.env.REACT_APP_SERVERURL;

  const reqBody = JSON.stringify({
    customerId: stripeCustomerId
  });

  return fetch(server + "/stripe-get-payment-methods", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("Got result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error with calling /stripe-get-payment-methods: ", error);
      throw error;
    });
};

const getPaymentMethodById = async (paymentMethodId: string) => {
  //console.log("Calling stripe getPaymentMethodById");
  const server = process.env.REACT_APP_SERVERURL;

  const reqBody = JSON.stringify({
    paymentMethodId: paymentMethodId
  });

  return fetch(server + "/stripe-get-payment-method", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("Got result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error with calling /stripe-get-payment-method: ", error);
      throw error;
    });
};

const detachPaymentMethod = async (paymentMethodId: string) => {
  //console.log("Calling stripe detachPaymentMethod");
  const server = process.env.REACT_APP_SERVERURL;

  const reqBody = JSON.stringify({
    paymentMethodId: paymentMethodId
  });

  return fetch(server + "/stripe-detach-payment-method", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("Got result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error with calling /stripe-detach-payment-method: ", error);
      throw error;
    });
};

const chargePaymentMethod = async ( options: {
  paymentMethodId: string; 
  customerId: string; 
  amount: number; 
  email: string;
  description: string;
}) => {

  const server = process.env.REACT_APP_SERVERURL;

  const reqBody = JSON.stringify({
    paymentMethodId: options.paymentMethodId,
    customerId: options.customerId,
    amount: options.amount,
    email: options.email,
    description: options.description
  });

  return fetch(server + "/stripe-charge-payment-method-from-app", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.json().then(error => {throw error})
      else  
        return response.json();
    })
    .then(result => {
      //console.log("Got result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error with calling /stripe-charge-payment-method-from-app: ", error);
      throw error;
    });
};

const getPaymentIntentById = async (intentId: string) => {
  //console.log("Calling stripe getPaymentIntentById");
  const server = process.env.REACT_APP_SERVERURL;

  const reqBody = JSON.stringify({
    paymentIntentId: intentId
  });

  return fetch(server + "/stripe-get-payment-intent", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("Got result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error with calling /stripe-get-payment-intent: ", error);
      throw error;
    });
};

export {
  createCustomerPatient,
  createSetupIntent,
  getPaymentMethods,
  getPaymentMethodById,
  detachPaymentMethod,
  chargePaymentMethod,
  getPaymentIntentById
};