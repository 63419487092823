import React, { useState, useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";
//Import components
import Layout from './components/ui/Layout/Layout';
import StartTherapy from './components/patient/StartTherapy/StartTherapy';
import PatientLogIn from './components/patient/PatientLogIn/PatientLogIn';
import PatientForgotPassword from './components/patient/PatientForgotPassword/PatientForgotPassword';
import PatientPortalWrapper from './components/patient/PatientPortal/PatientPortalWrapper';

function App() {

  const [checkedAuth, setCheckedAuth] = useState(false);
  const [authUser, setAuthUser] = useState<any>(null);

  //Check authentication of user
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        setAuthUser(user);
      }
      else {
        setAuthUser(false);
        setCheckedAuth(true);
      }
    });
  }, []);

  useEffect(() => {
    if (authUser) {
      setCheckedAuth(true);
    }
  }, [authUser]);

  //Render routes after authentication has been checked
  if (checkedAuth) {
    return (
      <Layout>
        <Routes>
            <Route path ="/">
              <Route 
                path="/login"
                element={!authUser ? <PatientLogIn /> : <Navigate to="/" />}
              />
              <Route 
                path="/forgot-password"
                element={!authUser ? <PatientForgotPassword /> : <Navigate to="/" />}
              />
              <Route 
                path="/matching"
                element={<StartTherapy />}
              />
              <Route 
                path="/"
                element={authUser ? <PatientPortalWrapper /> : <Navigate to="/login" />}
              />
            </Route>
        </Routes>
      </Layout>
    );
  }
  //Do not render anything before authentication has been checked
  else {
    return null;
  }


}

export default App;
