import React, {useState} from 'react';
import { format, isSameDay} from 'date-fns'
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import classes from './TimeSlots.module.css';

interface Props {
  slots: Array<any>;
  onClickConfirm: Function;
  selectedDate: Date;
  disableConfirm?: boolean;
  label?: string;
}

const TimeSlots: React.FC<Props> = props => {

  const [showConfirm, setShowConfirm] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const handleShowConfirm = (key:string) => {
    setShowConfirm(key);
  };

  const handleClickConfirm = (date: Date) => {
    setLoading(true);
    props.onClickConfirm(date)
  };

  return (
    <div className={classes.container}>
      {props.slots.map(slot => {
        
        let confirmClasses;
        if (slot.resource.start === showConfirm) {
          confirmClasses = [classes.confirmBtn, classes.showConfirmBtn];
        }
        else {
          confirmClasses  = [classes.confirmBtn];
        }

        let today = new Date();
        //Set minDate to 4 hours after today
        let minDate = new Date(today.getTime() + 60 * 60 * 4 * 1000);

        if ( 
          props.selectedDate &&
          isSameDay(props.selectedDate, new Date(slot.resource.start))
          && new Date(slot.resource.start) > minDate
        ) {
          return(
            <div key={slot.resource.start} className={classes.slot}>

                <Button onClick={() => handleShowConfirm(slot.resource.start)} variant="outlined" size="large" fullWidth>
                  {format(new Date(slot.resource.start), 'p')}
                </Button>

                <LoadingButton
                  onClick={() => handleClickConfirm(new Date(slot.resource.start))}
                  loading={loading}
                  className={confirmClasses.join(' ')} 
                  variant="contained" 
                  size="large" 
                  fullWidth
                  disabled={props.disableConfirm ? props.disableConfirm : false}
                > 
                  {props.label ? props.label : "select"}
                </LoadingButton>

            </div>
          );
        }

        else {
          return null;
        }

      })}
    </div>
  );
};

export default TimeSlots;
