import { useEffect, useState } from "react";
import { isSameDay } from 'date-fns'
import { canvasGetProviderSlots } from "../../containers/Models/Canvas";

export default function useFetchOpenProviderSlots(providerCanvasId: string|null, days: number, duration: number, increments: 15|30|60 = 60, frequency:number = 0) {
  
  const [fetching, setFetching] = useState(false);
  const [availableSlots, setAvailableSlots] = useState<any>(null);

  //Reset available slots every time appointment frequency is changed
  useEffect(() => {
    setFetching(false);
    setAvailableSlots(null);
  }, [frequency]);

  useEffect(() => {

    const getAvailableSlots = async () => {

      setFetching(true);

      canvasGetProviderSlots(providerCanvasId, duration, days).then(canvasSlots => {

        if (canvasSlots) {

          let slots = canvasSlots.slots;
          
          //Start of logic to remove ineligible time slots
          
          let removeValFromIndex: Array<number> = []; //Array to hold indexes of slots to remove
          
          //Start iterating through each slot
          let startingDaySlot = new Date(slots[0].resource.start);
          slots.forEach((slot:any, i:number) => {
            
            let start = new Date(slot.resource.start);

            if (!isSameDay(start, startingDaySlot)) {
              startingDaySlot = start;
            }

            //Remove slot if it is not in desired increment for this day
            if (startingDaySlot.getMinutes() === 0) {
              if (start.getMinutes() !== 0 && !removeValFromIndex.includes(i)) {
                removeValFromIndex.push(i);
              }
            }
            else if (startingDaySlot.getMinutes() === 30) {
              if (start.getMinutes() !== 30 && !removeValFromIndex.includes(i)) {
                removeValFromIndex.push(i);
              }
            }

            //If frequency number is given, remove slots that don't have recurrent availability
            if (frequency) {

              let nextSlots = slots.slice(i);

              //For a given slot, search for an available slot 1, 2, 3 in the future
              let jMax = 4;
              if (frequency == 2)
                jMax = 3
              if (frequency > 2 )
                jMax = 2

              for (let j = 1; j < jMax; j++) {

                const findNextSlot = (obj: any) => {

                  let date = new Date(obj.resource.start); // Date of future slot being iterated
                  let target = new Date(start); // Future date being searched for
                  target.setDate(target.getDate() + frequency * 7 * j);

                  if (date.getTime() === target.getTime() )
                    return true;
                  else
                    return false;
                };

                let nextSlot = nextSlots.findIndex(findNextSlot)
                if (nextSlot === -1) {
                  if (!removeValFromIndex.includes(i))
                    removeValFromIndex.push(i);
                }
              }

            }

          });
          removeValFromIndex.reverse().forEach( (i:number) => {
            slots.splice(i, 1);
          });

          setAvailableSlots(slots);

        }

            /* Mock Data Format: 
            [
            {
              "resource": {
                "resourceType": "Slot",
                "schedule": {
                  "reference": "Schedule/Location.2-Staff.5a9b4006a307403793682a47eeecface"
                },
                "status": "free",
                "start": "2022-04-22T18:00:00-08:00",
                "end": "2022-04-22T18:15:00-08:00"
              }
            }
          ]
        }*/
      })

      .catch(error => {
        setAvailableSlots([]);
      });

    };

    // setAvailableSlots(null);
    if (!fetching && providerCanvasId && days) {
     getAvailableSlots();
    }
  }, [providerCanvasId, days, availableSlots, fetching, increments, duration, frequency]);

  return availableSlots;
}