import firebaseApp from "../../Data/Firestore";
import { 
  getFirestore, 
  addDoc, 
  updateDoc, 
  collection, 
  doc, 
  query, 
  where, 
  getDocs
} from "firebase/firestore";

const db = getFirestore(firebaseApp);
const assessmentDb = process.env.REACT_APP_PHQ9_DB;
const patientDb = process.env.REACT_APP_PATIENT_DB;

const addPhq9ToDb = async (data:{
  patientId: string,
  patientCanvasId: string,
  providerId: string,
  providerCanvasId: string,
  timeStamp: Date,
  uninterested: { value: string, score: number }, 
  depressed: { value: string, score: number },
  sleepIssues: { value: string, score: number },
  lowEnergy: { value: string, score: number },
  eatingIssues: { value: string, score: number },
  feelingOfFailure: { value: string, score: number },
  lowFocus: { value: string, score: number },
  movementIssues: { value: string, score: number },
  selfHarmThoughts: { value: string, score: number },
  score: number,
  severity: string
}) => {

  let assessmentId = '';

  //Write assessment data to db
  const docRef = await addDoc(collection(db, assessmentDb!), data).then(ref => {
    assessmentId = ref.id;
    updateDoc(ref, {id: assessmentId });
  //Then update patient doc in db
  }).then(() => {
    const patientDoc = doc(db, patientDb!, data.patientId);
    updateDoc(patientDoc, { lastPhq9: {id: assessmentId, timeStamp: data.timeStamp} });
  });

  return docRef;
}

async function getPhq9ByIdFromDb(id: string) {
  const q = query(collection(db, assessmentDb!), where("id", "==", id));
  const querySnapshot = await getDocs(q);
  let assessmentData = null;
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    assessmentData = doc.data();
    //Convert all firebase dates to regular Date objects
    convertFirebaseDates(assessmentData);
    return assessmentData;
  });

  return assessmentData;
}

const getAllPhq9ByPatientId = async (id: string) => {
  const q = query(collection(db, assessmentDb!), where("patientId", "==", id));
  const querySnapshot = await getDocs(q);
  let assessments: Array<any> = [];
  querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      let assessmentData = doc.data();
      //Convert all firebase dates to regular Date objects
      convertFirebaseDates(assessmentData);
      assessments.push(assessmentData);
      //Sort appointments by date, in reverse chronological order (newest to oldest)
      assessments.sort(function(a,b){
        return b.timeStamp - a.timeStamp;
      });
    });

  return assessments;
}

//Convert all firebase dates to regular Date objects
const convertFirebaseDates = (obj: any) => {
  for (let key in obj) {
    if (obj[key] && obj[key].toDate) {
        obj[key] = obj[key].toDate();
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
        convertFirebaseDates(obj[key])
    }
  }    
};

export {
  getPhq9ByIdFromDb,
  getAllPhq9ByPatientId,
  addPhq9ToDb
};
