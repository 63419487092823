import React, { useState } from 'react';
import { Helmet } from "react-helmet";

import { usePatientData } from '../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchPaymentMethods from '../../../../hooks/useFetchPaymentMethods/useFetchPaymentMethods';

import PatientPortalSettingsContent from './PatientPortalSettingsContent/PatientPortalSettingsContent';
import PatientPortalSettingsSliders from './PatientPortalSettingsSliders/PatientPortalSettingsSliders';


interface Props {
  handleShowNavBar: Function; 
  handleHideNavBar: Function; 
  handleEnableSwipe: Function;
  handleDisableSwipe: Function;
}

const PatientPortalInbox: React.FC<Props> = props => {

  const patientData = usePatientData();
  const paymentMethods = useFetchPaymentMethods();

  const [showEditName, setShowEditName] = useState(false);
  const [showEditDob, setShowEditDob] = useState(false);
  const [showEditPhone, setShowEditPhone] = useState(false);
  const [showEditInsurance, setShowEditInsurance] = useState(false);
  const [showEditPaymentMethod, setShowEditPaymentMethod] = useState(false);
  const [showEditPassword, setShowEditPassword] = useState(false);

  const handleOpenSlider = (slider:string) => {
    
    if (slider === 'editName') setShowEditName(true);
    else if (slider === 'editDob') setShowEditDob(true);
    else if (slider === 'editPhone') setShowEditPhone(true);
    else if (slider === 'editInsurance') setShowEditInsurance(true);
    else if (slider === 'editPaymentMethod') setShowEditPaymentMethod(true);
    else if (slider === 'editPassword') setShowEditPassword(true);

    props.handleHideNavBar();
    props.handleDisableSwipe();

  };  

  const handleCloseSlider = (slider:string) => {

    if (slider === 'editName') setShowEditName(false);
    else if (slider === 'editDob') setShowEditDob(false);
    else if (slider === 'editPhone') setShowEditPhone(false);
    else if (slider === 'editInsurance') setShowEditInsurance(false);
    else if (slider === 'editPaymentMethod') setShowEditPaymentMethod(false);
    else if (slider === 'editPassword') setShowEditPassword(false);

    props.handleShowNavBar();
    props.handleEnableSwipe();

  };

  return(
    
    <React.Fragment>

      <Helmet>
        <title>
          Settings - Allminds
        </title>
      </Helmet>
      
      <PatientPortalSettingsContent 
        handleOpenSlider={handleOpenSlider}
        patientData={patientData}
        paymentMethods={paymentMethods}
      />
      
      <PatientPortalSettingsSliders
        patientData={patientData}
        paymentMethods={paymentMethods}
        showEditName={showEditName}
        showEditDob={showEditDob}
        showEditPhone={showEditPhone}
        showEditInsurance={showEditInsurance}
        showEditPaymentMethod={showEditPaymentMethod}
        showEditPassword={showEditPassword}
        handleCloseSlider={handleCloseSlider}
      />

    </React.Fragment>

  );
};

export default PatientPortalInbox;

