import React, {useState, useEffect} from 'react';

import { usePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import { getProviderGCalSlots } from '../../../../../../containers/Models/ProviderModel';

import PatientAppointmentCard from './PatientAppointmentCard/PatientAppointmentCard';
import PatientAppointmentPlaceholderCard from './PatientAppointmentPlaceholderCard/PatientAppointmentPlaceholderCard';
import PatientScheduleSessionCard from './PatientScheduleSessionCard/PatientScheduleSessionCard';
import PatientNextAppointmentPendingCard from './PatientNextAppointmentPendingCard/PatientNextAppointmentPendingCard';
import TreatmentHoldAlert from '../../../PatientPortalHome/PatientPortalHomeContent/TreatmentHoldAlert/TreatmentHoldAlert';

import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';

import classes from './PatientPortalAppointmentsUpcoming.module.css'


interface Props {
  appointmentsData: Array<any>; // See useFetchPatientAppointments for object structure
  handleOpenScheduleSession: React.MouseEventHandler;
  handleOpenReschedule: Function;
  handleOpenConfirmSchedule: Function;
  handleOpenConsent: Function;
}

const PatientPortalAppointmentsUpcoming: React.FC<Props> = props => {

  const patientData = usePatientData();

  //Create placeholders for future, unscheduled appointments
  const [placeholders, setPlaceholders] = useState<any>(null);
  useEffect(() => {
    if (
      patientData
      && patientData.therapySlotsBlockId
      && patientData.providerData
    ) {

      let appointmentPlaceholders:any = [];
      //get event slots from gcal to determine future appointment placeholders
      getProviderGCalSlots(patientData.therapySlotsBlockId, patientData.providerData.calendarId)
        .then(eventSlots => {
          if (eventSlots && eventSlots.items && eventSlots.items.length > 0) {
            let slots = eventSlots.items;
            let appointment:any = {};
            //Extract following 6 appointments
            for (let i = 0; i < 6; i++) {
              if (i < slots.length) {
                appointment.start = new Date(slots[i].start.dateTime);
                appointment.status = "proposed";
                appointmentPlaceholders.push({...appointment});
              }
            }
            setPlaceholders(appointmentPlaceholders);
          }
        });
    }
    else {
      setPlaceholders(null);
    }
  }, [patientData]);

  if (patientData && props.appointmentsData) {
    //Search for upcoming therapy session with active therapist (excluding consultations)
    let upcomingSession;
    if (patientData.providerId) {
      upcomingSession = props.appointmentsData.find((appointment:any) => 
        appointment.providerId === patientData!.providerId
        && appointment.status !== 'cancelled'
        && appointment.status !== 'fulfilled');
    }
  
    return(
      <div className={classes.containerPatientPortalAppointmentsUpcoming}>
        <h3>Booked</h3>
        {
          patientData.treatmentHold ? 
            <TreatmentHoldAlert />
          : !upcomingSession && patientData.therapyFollowDate ?
            <PatientNextAppointmentPendingCard />
          : !upcomingSession && patientData.providerId ?
            <PatientScheduleSessionCard handleOpenScheduleSession={props.handleOpenScheduleSession}/>
            //Obsolete b/c consultations were removed
            // : !upcomingSession && patientData.provider && patientData.provider.status === 'pendingApproval' ?
            // <PatientNoAppointmentsCard />
          : null  
        }

        {props.appointmentsData.length > 0 ? (
          <React.Fragment>
            {
              props.appointmentsData.map((appointment:any, i:number) => {
                if (appointment.status === 'cancelled') {
                  return null;
                }
                else {
                  return(
                    <PatientAppointmentCard 
                      key={appointment.id} 
                      appointment={appointment}
                      openSchedule={props.handleOpenReschedule}
                      handleOpenConfirmSchedule={props.handleOpenConfirmSchedule}
                      handleOpenConsent={props.handleOpenConsent}
                    />
                  );
                }
              })          
            }
          </React.Fragment>
        ) : (null)}


        {patientData.therapySlotsBlockId ? (

            <div className={classes.futureAppointments}>
              <h3>Upcoming</h3>
              
              {placeholders ? (
                placeholders.map((appointment:any, i:number) => {
                  return(
                    <PatientAppointmentPlaceholderCard 
                      key={"placeholder-" + i} 
                      appointment={appointment}
                    />
                  );
                })          
              ) : (
                <Stack spacing="24px">
                  <Skeleton variant="rectangular" height={102} />
                  <Skeleton variant="rectangular" height={102} />
                  <Skeleton variant="rectangular" height={102} />
                </Stack>
              )}

            </div>

        ) : (null)}

      </div>
    );
  }

  else {
    return (
      <div className={classes.containerPatientPortalAppointmentsUpcoming}>
        <Stack spacing="24px">
          <Skeleton variant="text"  width={160} height={48} />
          <Skeleton variant="rectangular" height={249} />
        </Stack>
        <Stack spacing="24px">
          <Skeleton variant="text"  width={160} height={48} />
          <Skeleton variant="rectangular" height={249} />
        </Stack>
      </div>
    );
  }

};

export default PatientPortalAppointmentsUpcoming;
