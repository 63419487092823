import React, { useState } from 'react';
import { usePatientData } from '../../../../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchPaymentMethods from '../../../../../../../hooks/useFetchPaymentMethods/useFetchPaymentMethods';

import PatientSessionSelectPaymentRadio from './PatientSessionSelectPaymentRadio/PatientSessionSelectPaymentRadio';
import StripeAddPaymentMethod from '../../../../../../ui/StripeAddPaymentMethod/StripeAddPaymentMethod';

import Button from '@mui/material/Button';

import '../../../../../../../globalCss/globalCssMuiButton.css';
import classes from './PatientSessionSelectPayment.module.css';

interface Props {
  handleClose: Function;
  paymentMethod: any;
  handleSetPaymentMethod: Function;
}

const PatientSessionSelectPayment: React.FC<Props> = props => {

  const patientData = usePatientData();
  const paymentMethods = useFetchPaymentMethods();

  const [showAddPayment, setShowAddPayment] = useState(false);

  const handleShowAddPayment = () => {
    setShowAddPayment(true);
  };
  const handleHideAddPayment = () => {
    setShowAddPayment(false);
  };

  const handleFinishAddPayment = (newStripePaymentMethod:any) => {
    props.handleSetPaymentMethod(newStripePaymentMethod);
    handleHideAddPayment();
  };

  if (patientData && paymentMethods) {

    return(
      <div className={classes.containerPayment}>
        <div className={classes.header}>
          <h2>Payment methods</h2>
          <p>Choose a default payment method for your future sessions.</p>
        </div>
        {
          paymentMethods.map((paymentMethod: any) => 
            <div className={classes.contentElement} key={paymentMethod.id}>
              <PatientSessionSelectPaymentRadio 
                paymentData={paymentMethod}
                selectedPaymentMethod={props.paymentMethod}
                handleSetSelectedPaymentMethod={props.handleSetPaymentMethod}
                isDefault={paymentMethod.id === patientData.stripeDefaultPaymentId }  
                handleClose={props.handleClose}      
              />
            </div>
          )
        }

        {showAddPayment ? (
          <div className={classes.containerAddPayment}>
            <h4>Add payment method</h4>
            <StripeAddPaymentMethod callback={handleFinishAddPayment} />
          </div>
        ) : (
          <Button 
            onClick={handleShowAddPayment} 
            variant="outlined" 
            color="secondary"
            size='large' 
            className={classes.btnSubmit}
          >
              Add new payment method
          </Button>
        )}

      </div>
    );

  }

  else return null;

};

export default PatientSessionSelectPayment;