import { AnyRecord } from "dns";
import { getFirestore, addDoc, updateDoc, collection, doc, query, where, getDoc, getDocs } from "firebase/firestore";
import firebaseApp from "../Data/Firestore";
const kaiserReferralsDb = process.env.REACT_APP_KAISER_REFERRALS_DB;
const patientDb = process.env.REACT_APP_PATIENT_DB;
const db = getFirestore(firebaseApp);

let mockCoverage = {  
   id: '',
   canvasId: '',
   patientId: '',
   isActive: true,
   
   firstName: 'Barry',
   lastName: 'Tone',
   dob: new Date('1993-04-02T00:00:00'),

   email: 'barrytone@dispostable.com',
   phone: '7742326921',

   sex: 'Male',
   street: '136 Metropolitan Ave',
   city: 'Brooklyn',
   state: 'NY',
   zip: '11249',
   //PCP

   pcp: 'Dr Phil',
   pcpPhone: '7742326921',

   memberId: '12345678',
   payer: '94135',
   payerName: 'Kaiser',
   coverageStart: new Date('1993-04-02T00:00:00'),
   coverageEnd: new Date('2023-04-03T00:00:00'),

   relationshipToSub: 'self', //All codes: self, child, parent, spouse, common, other, injured
   subId: '',
   subFirstName: '',
   subLastName: '',
   subDob: '', // dob doesn't seem to be available through Kaiser portal, but it doesn't seem to be needed for claims, just for Canvas, maybe we can add a placeholder
   subStreet: '', // not available through Kaiser portal
   subCity: '', // not available through Kaiser portal
   subState: '', // not available through Kaiser portal
   subZip: '', // not available through Kaiser portal

   individual : {
     inNetwork: {
       copay: 2500 ,// number in cents
       coinsurance: '0%', // percentage 0 to 100
       deductibleTotal: 100000, // number in cents
       deductibleUsed: 60000, // number in cents
       oopTotal: 99999, // number in cents
       oopUsed: 0 // number in cents // number in cents
     },
     outOfNetwork: {
       deductibleTotal: 1500, // number in cents
       deductibleUsed: 0, // number in cents
       oopTotal: 3000, // number in cents
       oopUsed: 0 // number in cents
     }
   },
   family: {
     inNetwork: {
       deductibleTotal: 0, // number in cents
       deductibleUsed: 0, // number in cents
       oopTotal: 99999, // number in cents
       oopUsed: 0  // number in cents
     },
     outOfNetwork: {
       deductibleTotal: 3000, // number in cents
       deductibleUsed: 0, // number in cents
       oopTotal: 6000, // number in cents
       oopUsed: 0 // number in cents
     }
   },
   billingFee: 17720 //Fee we charge insurance
};

const createCoverageObject = (data:any) => {
  let coverage = {
    authNumber: '',
    authExpDate: null,
    billingFee: 17720,
    canvasId: '',
    email: '',
    id: '',
    memberId: data.memberId ? data.memberId : '',
    patientId: '',
    payer: data.payerId ? data.payerId : '',
    phone: ''
  }
  return coverage;
};

const getCoverageByMemberId = async (memberId: string) => {
  if (!memberId)
    return null;

  else {
    //For Kaiser: if MRN number has less than 8 digits, prepend zeroes
    while (memberId.length < 10) {
      memberId = '0' + memberId;
    }

    const q = query(collection(db, kaiserReferralsDb as any), where("memberId", "in", [memberId, '11' + memberId]));
    const querySnapshot = await getDocs(q);
    let coverageData = null;
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        coverageData = doc.data();
        convertFirebaseDates(coverageData);
      });

    return coverageData;
  }

  //return mockCoverage;
  //return null;
};

const getCoverageById = async (coverageId: string) => {
  if (!coverageId)
    return null;

  const docRef = doc(db, kaiserReferralsDb as any, coverageId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    let coverageData = docSnap.data();
    convertFirebaseDates(coverageData);
    return coverageData
  } else {
    // doc.data() will be undefined in this case
    //console.log("No coverage data found");
    return null;
  }

  //return mockCoverage;
  //return null;
};

//Convert all firebase dates to regular Date objects
const convertFirebaseDates = (obj: any) => {
  for (let key in obj) {
    if (obj[key] && obj[key].toDate) {
        obj[key] = obj[key].toDate();
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
        convertFirebaseDates(obj[key])
    }
  }    
};

const updateCoveragePatientId = async (coverageId: string, patientId: string) => {
  const docRef = doc(db, kaiserReferralsDb as any, coverageId);
  updateDoc(docRef, {patientId: patientId});
}

const updateCanvasCoverageId = async(coverageId: string, patientId: string, coverageCanvasId: string) => {
  const docRef = doc(db, kaiserReferralsDb as any, coverageId);
  updateDoc(docRef, {canvasId: coverageCanvasId});
  const docRef2 = doc(db, patientDb as any, patientId);
  updateDoc(docRef2, {coverageCanvasId: coverageCanvasId});
}

export {
  getCoverageByMemberId,
  getCoverageById,
  updateCoveragePatientId,
  convertFirebaseDates,
  updateCanvasCoverageId,
  createCoverageObject
};