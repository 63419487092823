import React, { useState, useEffect, useRef } from "react";
//External libraries
import {
  ConversationalForm,
  EventDispatcher,
  FlowEvents,
  ChatResponseEvents,
  OptionButtonEvents
} from "conversational-form";
//External components
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
//Assets
import avatarPng from '../../../assets/png/logoBlueBg.png';
import iconUndo from '../../../assets/svg/icon_undo.svg';
//CSS
import classes from './ChatForm.module.css';
import './ChatFormStyling.css';

interface Props {
  //Object with screener questions
  questions: any;
  //Object with options for screener questions
  options: any;
  //Object with conditionals for screener questions
  conditions: any;
  //Optional function to run after every step
  questionCallback?: any;
  //Optional string for final robot response
  endResponse?: string;
  //Function to submit screener dat to db
  handleSubmit: any;
  disableBackBtn?: boolean
}

const ChatForm: React.FC<Props> = props => {
  
  const ref = useRef<HTMLDivElement>(null);

  //START OF CONVERSATIONAL FORM INITIALIZATION
  
  let cf: any = null;

  //This functions creates tags for all screener questions and appends them to the array of form tags
  const createFormTags = () => {
    let tags = [];
    for (let tagName in props.questions) {
      let tag: any = {};
      if (props.options[tagName].type === "radio") {
        tag = {
          tag: "fieldset",
          name: tagName,
          "required": true,
          "cf-input-placeholder": " ",
          "cf-questions": props.questions[tagName],
          children: getTagOptions(tagName),
        };
      } 
      else if (props.options[tagName].type === "select") {
        tag = {
          tag: "select",
          name: tagName,
          "required": true,
          "cf-input-placeholder": " ",
          "cf-questions": props.questions[tagName],
          "multiple": true,
          children: getTagOptions(tagName),
        };       
      }

      else {
        tag = {
          tag: props.options[tagName].tag
            ? props.options[tagName].tag
            : "input",
          name: tagName,
          "cf-input-placeholder": props.options[tagName].placeholder
            ? props.options[tagName].placeholder
            : " ",
          type: props.options[tagName].type
            ? props.options[tagName].type
            : "text",
          "cf-questions": props.questions[tagName]
        };
        if (!props.options[tagName].skippable) tag.required = true;
      }
      //Add condition if present
      if (props.conditions[tagName]) {
        props.conditions[tagName].forEach((condition: any) => {
          let key = "cf-conditional-" + Object.keys(condition)[0];
          tag[key] = condition[Object.keys(condition)[0]];
        });
      }
      tags.push(tag);
    }
    return tags
  };

  //This function creates the input choices for each form tag.
  const getTagOptions = (tagName: string) => {
    let tagOptions: any = [];
    if (props.options[tagName].answers) {
      props.options[tagName].answers.forEach((answer: any) => {
        let option: any = {
          tag: props.options[tagName].type === "select" ? "option" : "input",
          name: tagName,
          type: props.options[tagName].type,
          "cf-label": answer,
          value: answer
        };
        //Check if tag needs a condition
        if (props.conditions[tagName]) {
          props.conditions[tagName].forEach((condition: any) => {
            let key = "cf-conditional-" + Object.keys(condition)[0];
            option[key] = condition[Object.keys(condition)[0]];
          });
        }
        tagOptions.push(option);
      });
    }
    return tagOptions;
  };

  //Create array to hold all the form tags (questions)
  const formTags = createFormTags();

  useEffect(() => {
    //Set-up event listeners for chat form
    var dispatcher = new EventDispatcher();

    //Events triggering at the start of each question
    dispatcher!.addEventListener(
      FlowEvents.FLOW_UPDATE,
      (event: any) => {
        //The code below disables previous answer click when question is conditional to avoid cf bug
        let chatResponses = document.querySelectorAll(
          "cf-chat-response.user.can-edit"
        ) as NodeListOf<Element>;
        let thumbs = document.querySelectorAll(
          "cf-chat-response.user.can-edit thumb"
        ) as NodeListOf<Element>;
        let lastChatResponse = (chatResponses[chatResponses.length- 1]) as HTMLInputElement;
        let lastThumb = (thumbs[thumbs.length- 1]) as HTMLInputElement;
        let previousTagIndex = formTags.findIndex((tag: any) => tag.name === event.detail.tag.name) - 1;
        let previousTagName;
        if (previousTagIndex > -1) {
           previousTagName = formTags[previousTagIndex].name;
        }
        if (
          props.conditions[event.detail.tag.name] 
          || props.conditions[previousTagName]
          || event.detail.tag.name === 'startOver'
        ) {
          if (lastChatResponse)
            lastChatResponse.style.pointerEvents = "none";
          if (lastThumb)
            lastThumb.style.display = "none";
        }
        //End of code to disable previous clicks

        let textarea = document.querySelector(
          "cf-input textarea"
        ) as HTMLInputElement;
        let inputWrapper = document.querySelector(
          ".inputWrapper"
        ) as HTMLInputElement;

        //Show input 
        inputWrapper.classList.remove("disableInput", "disableInputButton", "hideInput");
        if (textarea) {
          // textarea.disabled = false;
          textarea.readOnly = false;
        }

        //Focus on text input if present
        setTimeout(() => {
          let textinput = document.querySelector("cf-input input") as HTMLInputElement;
          if (textinput) {
            textinput.focus();
          }
        }, 100);

        //Disable input when question has radio tags
        if (event.detail.tag.elements) {
          inputWrapper.classList.add("disableInput", "disableInputButton", "hideInput");
          if (textarea) {
            // textarea.disabled = true;
            textarea.readOnly = true;
          }
        } 
        //Disable input button when question has checkboxes
        else if (event.detail.tag.optionTags) {
          inputWrapper.classList.add("disableInput", "disableInputButton");
          if (textarea) {
            // textarea.disabled = true;
            textarea.readOnly = true;
          }
        } 

        if (event.detail.tag.name === "dob") {
          //Show date picker
          let datePicker = document.getElementById(
            "DatePicker"
          ) as HTMLInputElement;
          datePicker.style.display = "block";
          let datePickerInput = document.querySelector(
            "#DatePicker input"
          ) as HTMLInputElement;
          setTimeout(() => {datePickerInput.focus()}, 100);
          //Disable input if date picker value is empty
          inputWrapper.classList.add("disableInput", "disableInputButton");
        } else {
          //Hide date picker
          let datePicker = document.getElementById(
            "DatePicker"
          ) as HTMLInputElement;
          datePicker.style.display = "none";
        }
      },
      false
    );

    //Event triggered after clicking a checkbox
    dispatcher!.addEventListener(OptionButtonEvents.CLICK, (event:any) => {
      let inputWrapper = document.querySelector(".inputWrapper") as HTMLInputElement;
      //remove disable button after 1st checkbox click
      inputWrapper.classList.remove("disableInputButton");
    }, false);

    //Event triggered after clicking a previous answer
    dispatcher!.addEventListener(ChatResponseEvents.USER_ANSWER_CLICKED, (event:any) => {
      if (!event.detail.elements) {
        let inputWrapper = document.querySelector(
          ".inputWrapper"
        ) as HTMLInputElement;

        let textarea = document.querySelector(
          "cf-input textarea"
        ) as HTMLInputElement;

        let input = document.querySelector("cf-input input") as HTMLInputElement;

        setTimeout(() => {
          //Enable date input button
          inputWrapper.classList.remove("disableInputButton");
          //Clear textarea
          if (textarea) {
            textarea.value = "";
          }
          //Clear input
          if (input) {
            input.value = "";
          }
        }, 100);
      }

    }, false);

    //Initialize the conversational form
    cf = ConversationalForm.startTheConversation({
      options: {
        theme: "blue",
        flowStepCallback: flowCallback,
        submitCallback: submitCallback,
        preventAutoFocus: true,
        userImage: iconUndo,
        robotImage: avatarPng,
        eventDispatcher: dispatcher,
        showProgressBar: true,
        dictionaryData: {
          "user-image": 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAwIiBoZWlnaHQ9IjIwMCIgdmlld0JveD0iMCAwIDIwMCAyMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxjaXJjbGUgY3g9IjEwMCIgY3k9IjEwMCIgcj0iMTAwIiBmaWxsPSIjMzAzMDMwIi8+CjxwYXRoIGQ9Ik0xMDAgNTVMMTM4Ljk3MSAxMjIuNUg2MS4wMjg5TDEwMCA1NVoiIGZpbGw9IiNFNUU2RUEiLz4KPC9zdmc+Cg==',
          "entry-not-found": "Dictionary item not found.",
          "awaiting-mic-permission": "Awaiting mic permission",
          "user-audio-reponse-invalid": "I didn't get that, try again.",
          "microphone-terminal-error": "Audio input not supported",
          "input-placeholder": "Type your answer here ...",
          "group-placeholder": "Type to filter ...",
          "input-placeholder-error": "Your input is not correct ...",
          "input-placeholder-required": "Invalid answer",
          "input-placeholder-file-error": "File upload failed ...",
          "input-placeholder-file-size-error": "File size too big ...",
          "input-no-filter": "No results found for ‛{input-value}‛",
          "user-reponse-and": " and ",
          "user-reponse-missing": "Missing input ...",
          "user-reponse-missing-group": "Nothing selected ...",
          "general": "General type1||General type2",
          "icon-type-file": "<svg class='cf-icon-file' viewBox='0 0 10 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><g stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g transform='translate(-756.000000, -549.000000)' fill='#0D83FF'><g transform='translate(736.000000, 127.000000)'><g transform='translate(0.000000, 406.000000)'><polygon points='20 16 26.0030799 16 30 19.99994 30 30 20 30'></polygon></g></g></g></g></svg>",
        }
        // loadExternalStyleSheet: false,
      },
      tags: formTags.slice(0, formTags.length)
    });

    ref.current!.appendChild(cf.el);

    return () => {
      cf.remove();
      dispatcher!.removeEventListener(FlowEvents.FLOW_UPDATE);
    };
  }, []);

  //Set-up datePicker to replace the default cf input when date inputs are needed
  const [date, setDate] = useState<any>(null);
  const handleDateChange = (event: any) => {
    setDate(event['$d'] ? event['$d'] : null);
  };
  useEffect(() => {
    //Pipe date from datepicker to original cf input if dob is valid
    if (date && date !== "Invalid Date" && date < new Date() && date.getYear() > 0 ) {
      (ref.current!.querySelector(
        "cf-input input"
      ) as HTMLInputElement).value = date.toDateString();
      //Enable input
      (ref.current!.querySelector(
        ".inputWrapper"
      ) as HTMLInputElement).classList.remove("disableInput", "disableInputButton");
    } else {
      //Disable input
      (ref.current!.querySelector(
        ".inputWrapper"
      ) as HTMLInputElement).classList.add("disableInput", "disableInputButton");
    }
  }, [date]);


  //This function is called right after every step of the flow
  const flowCallback = (dto: any, success: any, error: any) => {
    props.questionCallback(dto, success, error, cf);
  };

  //This function runs when the form is completed
  const submitCallback = () => {
    //Add final robot reponse
    if (props.endResponse) cf.addRobotChatResponse(props.endResponse);

    //Empty and disable input
    (document.querySelector(".inputWrapper") as HTMLInputElement).classList.add(
      "Disabled"
    );
    if (document.querySelector("cf-input textarea")) {
      let textarea = document.querySelector(
        "cf-input textarea"
      ) as HTMLInputElement;
      // textarea.disabled = true;
      textarea.readOnly = true;
      textarea.value = "";
      textarea.placeholder = " ";
    }
    if (document.querySelector("cf-input input")) {
      let input = document.querySelector("cf-input input") as HTMLInputElement;
      // input.disabled = true;
      input.readOnly = true;
      input.value = "";
      input.placeholder = " ";
    }

    //Get formData and call handleSubmit to continue in parent component
    let formData = cf.getFormData(true);
    props.handleSubmit(formData);
  };
  //END OF CONVERSATIONAL FORM INITIALIZATION

  //Programmatically clicks cf-input-button when date is submitted
  const handleDateSubmit = (event:any) => {
    event.preventDefault();
    let inputButton = document.querySelector("cf-input-button") as HTMLInputElement;
    if (inputButton) {
      inputButton.click();
    }
  };

  let containerClasses = [classes.ChatContainer, "ChatStyle"];
  if (props.disableBackBtn) containerClasses.push("disableBackBtn");

  return (
    <div className={classes.container}>
      <div ref={ref} className={containerClasses.join(" ")}>
        <form id="DatePicker" className={classes.datePicker} onSubmit={handleDateSubmit} autoComplete="off">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              value={date}
              onChange={handleDateChange}
              openTo="year"
            />
          </LocalizationProvider>
        </form>
      </div>
    </div>
  );
};

export default ChatForm;
