import React from 'react';

import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import Button from '@mui/material/Button';

import classes from './PatientCompleteSessionFeedbackCard.module.css';

interface Props {
  handleStart: React.MouseEventHandler;
}

const PatientCompleteSessionFeedbackCard: React.FC<Props> = props => {

  return(

    <div className={classes.containerCompleteSessionFeedbackCard}>

      <div className={classes.header}>
        <FavoriteBorderOutlinedIcon />
        <div style={{flexGrow: 1}}>
          <h5>Therapeutic Relationship</h5>
          <div className={classes.timeToComplete}>
          <p>2 min</p>
          </div>
        </div>
        <Button onClick={props.handleStart} variant="outlined" color='inherit' size='small'>Start</Button>
      </div>

    </div>
  
  );

};

export default PatientCompleteSessionFeedbackCard;
