import React, {useState, useEffect} from 'react';

import { usePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';

import PatientAssessmentsInfoCard from './PatientAssessmentsInfoCard/PatientAssessmentsInfoCard';
import PatientNoTasksCard from './PatientNoTasksCard/PatientNoTasksCard';
import PatientCompleteSessionFeedbackCard from './PatientCompleteSessionFeedbackCard/PatientCompleteSessionFeedbackCard';
import PatientCompletePhq15Card from './PatientCompletePhq15Card/PatientCompletePhq15Card';
import PatientCompletePhq9Card from './PatientCompletePhq9Card/PatientCompletePhq9Card';
import PatientCompleteGad7Card from './PatientCompleteGad7Card/PatientCompleteGad7Card';
import PatientCompletePhqEatingCard from './PatientCompletePhqEatingCard/PatientCompletePhqEatingCard';
import PatientCompletePhqAlcoholCard from './PatientCompletePhqAlcoholCard/PatientCompletePhqAlcoholCard';

import classes from './PatientCompleteAssessments.module.css';


interface Props {
  handleOpenAssessment: Function;
  handleOpenFeedback: Function;
  handleChangeTasksDue: Function;
}

const PatientCompleteAssessments: React.FC<Props> = props => {

  const patientData = usePatientData();

  const [missingCalpas, setMissingCalpas] = useState<any>(null);
  const [missingGad7, setMissingGad7] = useState(false);
  const [missingPhq9, setMissingPhq9] = useState(false);
  const [missingPhq15, setMissingPhq15] = useState(false);
  const [missingPhqEating, setMissingPhqEating] = useState(false);
  const [missingPhqAlcohol, setMissingPhqAlcohol] = useState(false);

  //Update which assessments are due
  useEffect(() => {

    let today = new Date();

    //Find last fulfilled appointment
    const lastFulfilled = patientData.pastAppointments.find((appointment:any) => 
      (appointment.service === 'intake' || appointment.service === 'individual')
      && appointment.status === 'fulfilled' 
    )

    //If last fulfilled appointment doesn't have calpas and it has been over 28 days since the last calpas
    if ( lastFulfilled && !lastFulfilled.sessionFeedbackId
          && (!patientData.lastCalpas || patientData.lastCalpas.timeStamp < new Date().setDate(today.getDate() - 28)) 
        )
      setMissingCalpas(lastFulfilled);
    else
      setMissingCalpas(null);

    if ( !patientData.lastGad7 || patientData.lastGad7.timeStamp < new Date().setDate(today.getDate() - 14) )
      setMissingGad7(true);
    else
      setMissingGad7(false);

    if ( !patientData.lastPhq9 || patientData.lastPhq9.timeStamp < new Date().setDate(today.getDate() - 14) )
      setMissingPhq9(true);
    else
      setMissingPhq9(false);

    if ( !patientData.lastPhq15 || patientData.lastPhq15.timeStamp < new Date().setDate(today.getDate() - 28) )
      setMissingPhq15(true);
    else
      setMissingPhq15(false);

    if ( !patientData.lastPhqEating || patientData.lastPhqEating.timeStamp < new Date().setDate(today.getDate() - 90) )
      setMissingPhqEating(true);
    else
      setMissingPhqEating(false);

    if ( !patientData.lastPhqAlcohol || patientData.lastPhqAlcohol.timeStamp < new Date().setDate(today.getDate() - 180) )
      setMissingPhqAlcohol(true);
    else
      setMissingPhqAlcohol(false);

  }, [patientData]);

  //Update variable from parent component indicating if assessments are due
  useEffect(() => {
    if (
      missingCalpas
      || missingGad7
      || missingPhq9
      || missingPhq15
      || missingPhqEating
      || missingPhqAlcohol
    )
      props.handleChangeTasksDue(true)
    else
    props.handleChangeTasksDue(false)
  }, [missingCalpas, missingGad7, missingPhq9, missingPhq15, missingPhqEating, missingPhqAlcohol]);



  if (
    missingCalpas
    || missingGad7
    || missingPhq9
    || missingPhq15
    || missingPhqEating
    || missingPhqAlcohol

  ) {
    return(
      <div className={classes.containerCompleteAssessments} style={{marginTop: '24px'}}>
          
          <PatientAssessmentsInfoCard />
          
          { missingCalpas ?
                <PatientCompleteSessionFeedbackCard 
                  handleStart={() => props.handleOpenFeedback(missingCalpas.id)} 
                />
            : null
          }

          { missingGad7 ? 
              <PatientCompleteGad7Card handleStart={() => props.handleOpenAssessment("gad7")} />
          :null}

          { missingPhq9 ? 
              <PatientCompletePhq9Card handleStart={() => props.handleOpenAssessment("phq9")} />
          :null}

          { missingPhq15 ? 
              <PatientCompletePhq15Card handleStart={() => props.handleOpenAssessment("phq15")}/>
          :null}

          { missingPhqEating ? 
              <PatientCompletePhqEatingCard handleStart={() => props.handleOpenAssessment("phqEating")}  />
          :null}

          { missingPhqAlcohol ? 
              <PatientCompletePhqAlcoholCard handleStart={() => props.handleOpenAssessment("phqAlcohol")} />
          :null}

      </div>
    );
  }

  else {
    return (
      <PatientNoTasksCard />
    );
  }


};

export default PatientCompleteAssessments;
