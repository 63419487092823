import { useEffect, useState } from "react";
import { usePatientData, useUpdatePatientData } from '../ContextPatientData/ContextPatientData';
import { getPaymentMethods } from '../../containers/Models/stripe';


export default function useFetchPaymentMethods() {
  
  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();

  const [paymentMethods, setPaymentMethods] = useState<any>(null);

  useEffect(() => {

    if (patientData) {

      //Only fetch if data hasn't already been fetched
      if (!patientData.paymentMethods) {
        getPaymentMethods(patientData.stripeCustomerId).then(paymentMethodsData => {
          setPaymentMethods(paymentMethodsData.paymentMethods);
        });
      }
      else {
        setPaymentMethods(patientData.paymentMethods);
      }
    }
    
  }, [patientData]);


  //Add stripe payment methods to state after initial fetch
  useEffect(() => {
    if (paymentMethods && patientData && !patientData.paymentMethods) {
      let updatedPatientData = {...patientData};
      updatedPatientData.paymentMethods = [...paymentMethods];
      updateStatePatientData(updatedPatientData);
    }
  }, [patientData, paymentMethods, updateStatePatientData]);

  //SAMPLE PAYMENT METHOD OBJECT FROM STRIPE
  // {
  //   "id": "pm_1KWmqb2eZvKYlo2CpQFgS1gY",
  //   "object": "payment_method",
  //   "billing_details": {
  //     "address": {
  //       "city": null,
  //       "country": null,
  //       "line1": null,
  //       "line2": null,
  //       "postal_code": null,
  //       "state": null
  //     },
  //     "email": null,
  //     "name": null,
  //     "phone": null
  //   },
  //   "card": {
  //     "brand": "visa",
  //     "checks": {
  //       "address_line1_check": null,
  //       "address_postal_code_check": null,
  //       "cvc_check": "unchecked"
  //     },
  //     "country": "US",
  //     "exp_month": 8,
  //     "exp_year": 2023,
  //     "fingerprint": "Xt5EWLLDS7FJjR1c",
  //     "funding": "credit",
  //     "generated_from": null,
  //     "last4": "4242",
  //     "networks": {
  //       "available": [
  //         "visa"
  //       ],
  //       "preferred": null
  //     },
  //     "three_d_secure_usage": {
  //       "supported": true
  //     },
  //     "wallet": null
  //   },
  //   "created": 1645730369,
  //   "customer": null,
  //   "livemode": false,
  //   "metadata": {},
  //   "type": "card"
  // },

  return paymentMethods;

}