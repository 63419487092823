import {initializeApp} from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const config = {
  apiKey: "AIzaSyDTlgXYpq-R1sRtb_o1Nsh1y9TmLPg1ci4",
  authDomain: "allmindstherapy.firebaseapp.com",
  databaseURL: "https://allmindstherapy.firebaseio.com",
  projectId: "allmindstherapy",
  storageBucket: "allmindstherapy.appspot.com",
  messagingSenderId: "879455872015",
  appId: "1:879455872015:web:570215d2a4cdd1eb3b6f7a",
  measurementId: "G-S0MHDXTFJ6"
};

// let firebaseApp = "";
// if (getApps().length < 1) {
//   firebaseApp = initializeApp(config);
//   //Compile error: firebase.analytics();
// }

const firebaseApp = initializeApp(config);
const analytics = getAnalytics(firebaseApp);

export default firebaseApp;