import React, { useState, useEffect } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import useFetchProviderData from '../../../../../hooks/useFetchProviderData/useFetchProviderData';

import FindProvider from './FindProvider/FindProvider';
import PatientMatchingChat from './PatientMatchingChat/PatientMatchingChat';
import PatientPortalProviderProfile from './PatientPortalProviderProfile/PatientPortalProviderProfile';
import PatientRescheduleAppointment from './PatientRescheduleAppointment/PatientRescheduleAppointment';
import PatientScheduleSession from './PatientScheduleSession/PatientScheduleSession';
import PatientConfirmSchedule from './PatientConfirmSchedule/PatientConfirmSchedule';
import PatientSignConsent from './PatientSignConsent/PatientSignConsent';
import PatientPreIntake from './PatientPreIntake/PatientPreIntake';

import PatientChatSessionFeedback from '../../PatientPortalOutcomes/PatientPortalOutcomesSliders/PatientChatSessionFeedback/PatientChatSessionFeedback';
import PatientChatPhq15 from '../../PatientPortalOutcomes/PatientPortalOutcomesSliders/PatientChatPhq15/PatientChatPhq15';
import PatientChatPhq9 from '../../PatientPortalOutcomes/PatientPortalOutcomesSliders/PatientChatPhq9/PatientChatPhq9';
import PatientChatGad7 from '../../PatientPortalOutcomes/PatientPortalOutcomesSliders/PatientChatGad7/PatientChatGad7';
import PatientChatPhqEating from '../../PatientPortalOutcomes/PatientPortalOutcomesSliders/PatientChatPhqEating/PatientChatPhqEating';
import PatientChatPhqAlcohol from '../../PatientPortalOutcomes/PatientPortalOutcomesSliders/PatientChatPhqAlcohol/PatientChatPhqAlcohol';

import Slide from '@mui/material/Slide';

import classes from './PatientPortalHomeSliders.module.css';


interface Props {
  patientData: any; // See ContextPatientData for object structure
  appointmentsData: any; // See useFetchPatientAppointments for object structure
  selectedAppointmentId: string | null;
  showProviderProfile: boolean;
  showFindProvider: boolean;
  showMatchingChat: boolean;
  showScheduleSession: boolean;
  showConfirmSchedule: boolean;
  showScheduleConsultation: boolean;
  showRescheduleAppointment: boolean;
  showConsent: boolean;
  showPreIntake: boolean;
  handleCloseProviderProfile: Function;
  handleCloseRescheduleAppointment: React.MouseEventHandler;
  handleOpenFindProvider: Function;
  handleCloseFindProvider: Function;
  handleOpenMatchingChat: Function;
  handleCloseMatchingChat: Function;
  handleCloseScheduleSession: React.MouseEventHandler;
  handleCloseConfirmSchedule: React.MouseEventHandler;
  handleCloseScheduleConsultation: Function;
  handleCloseConsent: Function;
  handleClosePreIntake: Function;
  feedbackAppointmentId: string;
  showChatPhq15: boolean;
  showChatPhq9: boolean;
  showChatGad7: boolean;
  showChatPhqEating: boolean;
  showChatPhqAlcohol: boolean;
  handleCloseAssessment: Function;
  handleCloseFeedback: Function;
}

let stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeKey as string);

const PatientPortalHomeSliders: React.FC<Props> = props => {

  const patientData = props.patientData;
  const appointmentsData = props.appointmentsData;
  const ref = React.useRef<HTMLDivElement>(null);

  const providerData = useFetchProviderData(); 

  const [selectedAppointment, setSelectedAppointment] = useState<any>(null);

  useEffect(() => {
    if (patientData && appointmentsData) {
        let appointment = appointmentsData.find((appointment:any) => appointment.id === props.selectedAppointmentId);
        if (appointment) {
          setSelectedAppointment(appointment);
        }
    }
  }, [props.selectedAppointmentId, patientData, appointmentsData, selectedAppointment]);

  const handleScrollTop = () => {
    if (ref && ref.current) {
      ref.current.scrollTo(0,0);
    }   
  };

  if (patientData) {
    return(

      <React.Fragment>

        <Slide direction="up" in={props.showProviderProfile} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer}>
            <PatientPortalProviderProfile 
              providerData={providerData}
              handleCloseProviderProfile={props.handleCloseProviderProfile}
            />
          </div>
        </Slide>

        <Slide direction="up" in={props.showFindProvider} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer} ref={ref}>
            <FindProvider 
              handleCloseFindProvider={props.handleCloseFindProvider}
              handleOpenMatchingChat={props.handleOpenMatchingChat}
              handleScrollTop={handleScrollTop}
            />   
          </div>
        </Slide>

        <Slide direction="up" in={props.showMatchingChat} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer}>
            <PatientMatchingChat
              handleCloseMatchingChat={props.handleCloseMatchingChat}
              handleOpenFindProvider={props.handleOpenFindProvider}
            />   
          </div>
        </Slide>

        {selectedAppointment ? 
          <Slide direction="up" in={props.showRescheduleAppointment} mountOnEnter unmountOnExit>
            <div className={classes.slideContainer}>
              <Elements stripe={stripePromise}>
                <PatientRescheduleAppointment
                  appointment={selectedAppointment}
                  closeSchedule={props.handleCloseRescheduleAppointment}
                />
              </Elements>
            </div>
          </Slide>
        : null}

        {patientData.providerId && providerData ? 
            <Slide direction="up" in={props.showScheduleSession} mountOnEnter unmountOnExit>
              <div className={classes.slideContainer} ref={ref}>
                <PatientScheduleSession
                  providerData={providerData}
                  closeScheduleSession={props.handleCloseScheduleSession}
                  handleScrollTop={handleScrollTop}
                />
              </div>
            </Slide>
        : null}

        {selectedAppointment ? 
            <Slide direction="up" in={props.showConfirmSchedule} mountOnEnter unmountOnExit>
              <div className={classes.slideContainer}>
                <PatientConfirmSchedule 
                  appointment={selectedAppointment}
                  closeConfirmSchedule={props.handleCloseConfirmSchedule}
                />
              </div>
            </Slide>
        : null}

        <Slide direction="up" in={props.showConsent} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer}>
            <PatientSignConsent
              closeConsent={props.handleCloseConsent}
              handleScrollTop={handleScrollTop}
            />
          </div>
        </Slide>

        <Slide direction="up" in={props.showPreIntake} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer}>
            <PatientPreIntake
              handleCloseChat={props.handleClosePreIntake}
              handleScrollTop={handleScrollTop}
            />
          </div>
        </Slide>

        <Slide direction="up" in={props.feedbackAppointmentId ? true : false} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer}>
            <PatientChatSessionFeedback
              appointmentId={props.feedbackAppointmentId}
              handleCloseChat={() => props.handleCloseFeedback()}
            />   
          </div>
        </Slide>

        <Slide direction="up" in={props.showChatPhq15} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer}>
            <PatientChatPhq15
              handleCloseChat={() => props.handleCloseAssessment("phq15")}
            />   
          </div>
        </Slide>

        <Slide direction="up" in={props.showChatPhq9} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer}>
            <PatientChatPhq9
              handleCloseChat={() => props.handleCloseAssessment("phq9")}
            />   
          </div>
        </Slide>

        <Slide direction="up" in={props.showChatGad7} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer}>
            <PatientChatGad7
              handleCloseChat={() => props.handleCloseAssessment("gad7")}
            />   
          </div>
        </Slide>

        <Slide direction="up" in={props.showChatPhqEating} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer}>
            <PatientChatPhqEating
              handleCloseChat={() => props.handleCloseAssessment("phqEating")}
            />   
          </div>
        </Slide>

        <Slide direction="up" in={props.showChatPhqAlcohol} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer}>
            <PatientChatPhqAlcohol
              handleCloseChat={() => props.handleCloseAssessment("phqAlcohol")}
            />   
          </div>
        </Slide>

      </React.Fragment>
      
    );
  }
  else {
    return null;
  }

};

export default PatientPortalHomeSliders;
