import React from 'react';
import ProviderProfileInfo from './ProviderProfileInfo/ProviderProfileInfo';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import classes from './ProviderProfile.module.css';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

interface Props {
  provider: {
    id: string;
    firstName: string;
    lastName: string;
    pronouns: string;
    picUrl: string;
    dob: Date;
    ethnicities: Array<string>;
    licenseType: string;
    specialties: Array<string>;
    clientPopulations: Array<string>;
    languages: Array<string>;
    aboutMe: string;
    aboutClients: string;
    myApproach: string;
    myBeliefs: string;
  };
  coverageData?: any;
  patientGoals: any;
  isPatientLgbtq: boolean;
  loading: boolean;
  loadingCoverage?: boolean;
  showRate?: boolean;
  isTopPick?: boolean;
}

const ProviderProfile: React.FC<Props> = props => {
  return (
    <div className={classes.containerProviderProfile}>
      <div className={classes.header}>
        {props.loading ? <Skeleton width={200} height={64} /> : (
          <React.Fragment>
            {props.isTopPick ? <div className={classes.subHeader}><EmojiEventsOutlinedIcon /><p>Top pick</p></div> : null}
            <h1>{props.provider.firstName}</h1>
          </React.Fragment>
        )}
        {props.loading ? <Skeleton width={100} height={32} /> : <p>{"(" + props.provider.pronouns + ")"}</p>}
      </div>
      <Grid container spacing={2}>

        <Grid item xs={12} sm={6} lg={4}>
          <div className={classes.profilePic}>
            { props.loading ? 
                <Skeleton width="100%" height={380} variant="rectangular" /> 
              :
                <img src={props.provider.picUrl} alt={props.provider.firstName} />
            }
          </div>
        </Grid>
       
        <Grid item xs={12} sm={6} lg={4}>
          <div className={classes.card}>
            <h5>Get to know me</h5>
            {props.loading ? 
              <React.Fragment>
                <Skeleton width="100%" height={64} />
                <Skeleton width="90%" height={64} /> 
                <Skeleton width="95%" height={64} /> 
                <Skeleton width="40%" height={64} />  
              </React.Fragment>
            : <h3>{props.provider.aboutMe}</h3>}
          </div>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <ProviderProfileInfo 
            provider={props.provider} 
            coverageData={props.coverageData}
            patientGoals={props.patientGoals}
            isPatientLgbtq={props.isPatientLgbtq}
            loading={props.loading} 
            loadingCoverage={props.loadingCoverage}
            showRate={props.showRate ? props.showRate : false} 
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <div className={classes.card}>
            <h5>About my clients</h5>
            {props.loading ? 
              <React.Fragment>
                <Skeleton width="90%" height={64} />
                <Skeleton width="100%" height={64} /> 
                <Skeleton width="70%" height={64} />  
              </React.Fragment>
            : <h3>{props.provider.aboutClients}</h3>}
          </div>
        </Grid>
        
        <Grid item xs={12} sm={6} lg={4}>
          <div className={[classes.card, classes.fontSizeSmall].join(" ")}>
            <h5>My approach to therapy</h5>
            {props.loading ? 
              <React.Fragment>
                <Skeleton width="90%" height={32} />
                <Skeleton width="100%" height={32} /> 
                <Skeleton width="95%" height={32} />  
                <Skeleton width="20%" height={32} /> 
              </React.Fragment>
            : <p>{props.provider.myApproach}</p>}
          </div>
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <div className={[classes.card, classes.fontSizeSmall].join(" ")}>
            <h5>My personal beliefs</h5>
            {props.loading ? 
              <React.Fragment>
                <Skeleton width="100%" height={32} />
                <Skeleton width="100%" height={32} /> 
                <Skeleton width="90%" height={32} />  
                <Skeleton width="60%" height={32} /> 
              </React.Fragment>
            : <p>{props.provider.myBeliefs}</p>}
          </div>
        </Grid>


      </Grid>
    </div>
  );
};

export default ProviderProfile;
