import React, {useState, useEffect} from 'react';
import classes from './ProviderProfileInfo.module.css';
//External modules
import { differenceInYears } from 'date-fns'
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
//icons
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PersonPinCircleOutlinedIcon from '@mui/icons-material/PersonPinCircleOutlined';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import MilitaryTechOutlinedIcon from '@mui/icons-material/MilitaryTechOutlined';
//Other
import LinearProgress from '@mui/material/LinearProgress';

interface Props {
  provider: {
    id: string;
    firstName: string;
    lastName: string;
    pronouns: string;
    picUrl: string;
    dob: Date;
    ethnicities: Array<string>;
    licenseType: string;
    specialties: Array<string>;
    clientPopulations: Array<string>;
    languages: Array<string>;
    aboutMe: string;
    aboutClients: string;
    myApproach: string;
    myBeliefs: string;
    isLgbtq?: boolean;
    isTopRated?: boolean;
  };
  coverageData?: any;
  patientGoals: any;
  isPatientLgbtq: boolean;
  showRate?: boolean;
  loading: boolean;
  loadingCoverage?: boolean;
}

const ProviderProfileInfo: React.FC<Props> = props => {

  const [specialties, setSpecialties] = useState<any>([]);
  
  useEffect(() => {

    //Filter provider specialties to show those matching the patient's preferences
    if (props.provider && props.patientGoals) {
      let filteredSpecialties = [];
      filteredSpecialties = props.patientGoals.filter((value:string) => props.provider.specialties.includes(value));
      // If there are less than 3 matching specialties, show other specialties
      let i = 0;
      let count = props.provider.specialties.length < 5 ? props.provider.specialties.length : 5
      while (filteredSpecialties.length < count) {
        if (!filteredSpecialties.includes(props.provider.specialties[i]) && props.provider.specialties[i]) {
          filteredSpecialties.push(props.provider.specialties[i]);
        }
        i++;
      }
      setSpecialties(filteredSpecialties);
    }

  }, [props.patientGoals, props.provider]);

  //Takes an array with string elements and returns a parsed string sentence
  const getStringFromArray = (array:Array<string>) => {
      let string = '';
      array.forEach((element, i) => {
        //When iterating over ethnicity that is not last in array, add a comma and space to string
        if (i < array.length - 1) {
          string = string + element + ', ';
        }
        //When iterating over last ethnicity in array, do not include comma
        else {
          string = string + element;
        }
      });
      return string;
  };

  let coveredBy = null;
  let privatePayRate = 13000;
  let fee = privatePayRate;
  let feeElement = <p>{(fee / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) + ' per session'}</p>;
  let deductibleFee = null;
  let deductibleProgress = null;

  if (props.coverageData && !props.coverageData.error) {

    let coverageData = props.coverageData;

    if (coverageData && coverageData.isActive) {

      coveredBy = coverageData.payerName;

      //Compute insurance fee
      let deductible = coverageData.individual.inNetwork.deductibleTotal;
      let deductibleUsed = coverageData.individual.inNetwork.deductibleUsed;
      let famDeductible = coverageData.family.inNetwork.deductibleTotal;
      let famDeductibleUsed = coverageData.family.inNetwork.deductibleUsed;
      let copay = coverageData.individual.inNetwork.copay;
      let coinsurance = parseFloat(coverageData.individual.inNetwork.coinsurance) / 100.0;
      let billingFee = coverageData.billingFee;

      if (copay || coinsurance) {
        fee = copay + coinsurance * billingFee;
        feeElement = <p>{(fee / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) + ' per session'}</p>;
      }

      if (!copay && !coinsurance) {
        fee = 0;
        feeElement = <p>{(fee / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) + ' per session'}</p>;
      }

      if (
        deductible - deductibleUsed > 0
        && (!famDeductible || famDeductible - famDeductibleUsed > 0)
      ) {
        
        feeElement = 
        <React.Fragment>
          <p style={{textTransform: 'uppercase', fontSize: '0.8rem', color:  '#757575'}}>After deductible</p>
          <p>{(fee / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) + ' per session'}</p>
        </React.Fragment>

        deductibleFee = (
            <p>
              <small>{(privatePayRate / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})} before deductible</small>
            </p>
        );
        deductibleProgress = (
          <div className={classes.progressDeductible}>
            <p>Deductible progress</p>
            <p style={{textAlign: 'right'}}><small>{
              (deductibleUsed / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) 
              + " out of "
              + (deductible / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})
            }</small></p>
            <LinearProgress variant="determinate" value={deductibleUsed / deductible * 100} />
          </div>
        );
      }

    }

  }

  else if (props.coverageData && props.coverageData.error) {
    feeElement = <p>Amount due not available</p>;
    deductibleFee = <p><small>Please try again later or contact support@allminds.io.</small></p>
  }

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.headerContent}>
          <div className={classes.row}>
            <CakeOutlinedIcon />
            {props.loading ? 
              <Skeleton width={32} height={32} /> 
              : 
              <p>{differenceInYears(new Date(), props.provider.dob)}</p>}
          </div>
          <div className={classes.row}>
            <PersonPinCircleOutlinedIcon />
            {props.loading ? 
              <Skeleton width={100} height={32} /> 
              : 
              <p>{getStringFromArray(props.provider.ethnicities)}</p>}
          </div>
          <div className={classes.row}>
            <WorkOutlineOutlinedIcon />
            {props.loading ? 
              <Skeleton width={100} height={32} /> 
              : 
              <p>{props.provider.licenseType}</p>}
          </div>
        </div>
      </div>
      <div className={classes.content}>

        {props.provider && props.provider.isTopRated ? (
          <div className={classes.row}>
            <MilitaryTechOutlinedIcon />
            {props.loading ? 
              <Skeleton width="100%" height={32} /> 
              : 
              <div><p>Top rated</p><p><small>This provider has a proven track record of success with their clients.</small></p></div>}
          </div>
        ) : null}
        
        {props.provider && props.provider.isLgbtq && props.isPatientLgbtq ? (

          <div className={classes.row}>
            <div className={[classes.flag, classes.traditional].join(' ')} role="img" aria-label="Progress Pride Flag"></div>
            {props.loading ? 
              <Skeleton width="100%" height={32} /> 
              : 
              <p>LGBTQ+ Affiliated</p>}
          </div>

        ) : (null)}

        <div className={classes.row}>
          <StarBorderRoundedIcon />
          {props.loading ? 
            <Skeleton width="100%" height={32} /> 
            : 
            <div style={{display: "flex", flexWrap: "wrap", rowGap: '12px', columnGap: '8px'}}>
              {specialties.map((specialty:string) => <Chip key={'chip-' + specialty} label={specialty} color={props.patientGoals.includes(specialty) ? 'success' : 'default'} />)}
            </div>
          }
        </div>

        <div className={classes.row}>
          <PublicOutlinedIcon />
          {props.loading ? 
            <Skeleton width="40%" height={32} /> 
            : 
            <p>{getStringFromArray(props.provider.languages)}</p>}
        </div>

        {coveredBy ? (
          <div className={classes.row} style={{color: 'green'}}>
            <HealthAndSafetyOutlinedIcon />
            {props.loading ? 
              <Skeleton width="40%" height={32} /> 
              : 
              <div>
                <p>Covered by insurance</p>
                <p style={{textTransform: 'uppercase', fontSize: '0.8rem', color:  '#757575'}}>{coveredBy}</p>
              </div>
            }
          </div>
        ): null}



        {props.showRate ? 
          <div className={classes.row}>
            <SellOutlinedIcon />
            {props.loading || props.loadingCoverage ? 
              <Skeleton width="40%" height={32} /> 
              : 
              <div style={{width: '100%'}}>
                <div>
                  {feeElement}
                  {deductibleFee}
                </div>
                {deductibleProgress}
              </div>
            }
          </div>
        : null}
      </div>
    </div>
  );
};

export default ProviderProfileInfo;
