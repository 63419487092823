import React from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import PatientRescheduleAppointment from '../../PatientPortalHome/PatientPortalHomeSliders/PatientRescheduleAppointment/PatientRescheduleAppointment';
import PatientScheduleSession from '../../PatientPortalHome/PatientPortalHomeSliders/PatientScheduleSession/PatientScheduleSession';
import PatientConfirmSchedule from '../../PatientPortalHome/PatientPortalHomeSliders/PatientConfirmSchedule/PatientConfirmSchedule';
import PatientSignConsent from '../../PatientPortalHome/PatientPortalHomeSliders/PatientSignConsent/PatientSignConsent';

import Slide from '@mui/material/Slide';

import classes from './PatientPortalAppointmentsSliders.module.css'

interface Props {
  patientData: any; // See ContextPatientData for object structure
  selectedAppointment: {
    id: string;
    canvasId: string;
    start: Date;
    end: Date;
    service: string;
    status: string;
    type: string;
    providerId: string;
    providerCanvasId: string;
    providerName: string;
    providerPicUrl: string;
    meetingId: string;
  }|null;
  showReschedule: boolean;
  showScheduleSession: boolean;
  showConfirmSchedule: boolean;
  showConsent: boolean;
  handleCloseReschedule: React.MouseEventHandler;
  handleCloseScheduleSession: React.MouseEventHandler;
  handleCloseConfirmSchedule: React.MouseEventHandler;
  handleOpenConsent: Function;
  handleCloseConsent: Function;
}

let stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(stripeKey as string);

const PatientPortalAppointmentsSliders: React.FC<Props> = props => {

  const patientData = props.patientData;
  const ref = React.useRef<HTMLDivElement>(null);

  const handleScrollTop = () => {
    if (ref && ref.current) {
      ref.current.scrollTo(0,0);
    }   
  };


  return(

    <React.Fragment>

      <Slide direction="up" in={props.showReschedule} mountOnEnter unmountOnExit>
        <div className={classes.slideContainer}>
          <Elements stripe={stripePromise}>
            <PatientRescheduleAppointment
              appointment={props.selectedAppointment}
              closeSchedule={props.handleCloseReschedule}
            />
          </Elements>
        </div>
      </Slide>

      {patientData && patientData.providerData ? 
        <Slide direction="up" in={props.showScheduleSession} mountOnEnter unmountOnExit>
          <div className={classes.slideContainer} ref={ref}>
            <PatientScheduleSession
              providerData={patientData.providerData}
              closeScheduleSession={props.handleCloseScheduleSession}
              handleScrollTop={handleScrollTop}
            />
          </div>
        </Slide>
      : null}

      {props.selectedAppointment ? 
          <Slide direction="up" in={props.showConfirmSchedule} mountOnEnter unmountOnExit>
            <div className={classes.slideContainer}>
              <PatientConfirmSchedule 
                appointment={props.selectedAppointment}
                closeConfirmSchedule={props.handleCloseConfirmSchedule}
              />
            </div>
          </Slide>
      : null}

      <Slide direction="up" in={props.showConsent} mountOnEnter unmountOnExit>
        <div className={classes.slideContainer}>
          <PatientSignConsent
            closeConsent={props.handleCloseConsent}
            handleScrollTop={handleScrollTop}
          />
        </div>
      </Slide>

    </React.Fragment>

  );

};

export default PatientPortalAppointmentsSliders;
