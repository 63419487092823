import { parseISO, differenceInYears } from "date-fns";
import { getFirestore, collection, query, where, getDocs } from "firebase/firestore";
import firebaseApp from "../Data/Firestore";
const providerDb = process.env.REACT_APP_PROVIDER_DB;
const db = getFirestore(firebaseApp);

  async function getProviderList(payerId='', isMedicare = false) {
    const q = query(collection(db, providerDb));
    const querySnapshot = await getDocs(q);
    let providerData = null;
    let providerList = [];
    querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        providerData = doc.data();
        if (typeof providerData.dob !== 'string') {
            providerData.dob = providerData.dob.toDate();
        } else {
            providerData.dob = parseISO(providerData.dob);
        }

        if (
          providerData.included === true
          && !providerData.pauseReferrals
          && (!payerId || providerData.payerIds && providerData.payerIds.includes(payerId))
          // For Medicare patients only include psychologists and social workers
          && (
            !isMedicare 
            || providerData.licenseType.toUpperCase().includes('PHD')
            || providerData.licenseType.toUpperCase().includes('PSYD')
            || providerData.licenseType.toUpperCase().includes('LCSW')
            )
        )
          providerList.push(providerData);
      });

    return providerList;
  }


  async function getProviderById(id) {
    if (id === undefined || id === null || id === "")
    {
        //console.log("Provider Not Found. Invalid ID!");
        return null;
    }

    const q = query(collection(db, providerDb), where("id", "==", id));
    let providerData = null;
    await getDocs(q).then(querySnapshot => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            providerData = doc.data();
            //Convert all firebase dates to regular Date objects
            for (let key in providerData) {
                if (providerData[key] && providerData[key].toDate) {
                    providerData[key] = providerData[key].toDate();
                }
            }
            if (typeof providerData.dob === 'string') {
                providerData.dob = parseISO(providerData.dob);
            }
            providerData.modalities = providerData.modalities.split(", ");
            providerData.specialties = providerData.specialties.split(", ");
            return providerData;
        });
    })

    return providerData;
  }

  async function getProviderNameById(id) {
    if (id === undefined || id === null || id === "")
    {
        //console.log("Provider Not Found. Invalid ID!");
        return null;
    }

    const q = query(collection(db, providerDb), where("id", "==", id));
    let name = "";
    await getDocs(q).then(querySnapshot => {
        querySnapshot.forEach((doc) => {
            // doc.data() is never undefined for query doc snapshots
            let providerData = doc.data();
            name = providerData.firstName + " " + providerData.lastName;
            return name;
        });
    })

    return name;
  }

  function findIndicesOfMax(inp, count) {
    var outp = [];
    for (var i = 0; i < inp.length; i++) {
        outp.push(i); // add index to output array
        if (outp.length > count) {
            outp.sort(function(a, b) { return inp[b] - inp[a]; }); // descending sort the output array
            outp.pop(); // remove the last index (index of smallest element in output array)
        }
    }
    outp.sort(function(a, b) { return inp[b] - inp[a]; }); // descending sort the output array
    return outp;
  }

  const getMatchedProviderIds = async (patientData, payerId = '', isMedicare = false) => {
    let providerIds = [];
    //The function should return an array with therapist ids as below
    let topIndices = [];
    let matchScore = [];
    await getProviderList(payerId, isMedicare).then(providerList => {
      const arrLength = providerList.length;
      let i = 0;
      // Initialize matchScore array
      for (i = 0; i < arrLength; i++) {
        matchScore.push(0);
      }

      i = 0;
      providerList.forEach(provider => {
        
        // if (provider.gender === patientData.gender) {
        //   matchScore[i]++;
        // }

        if (provider.gender === patientData.therapyGenderPref) {
          if (patientData.therapyGenderPref == 'Man' || patientData.therapyGenderPref == 'Non-binary')
            matchScore[i] = matchScore[i] + 100;
          else 
          matchScore[i]++;
        }

        // Only match lgbtq if patient is lgbtq
        if (patientData.isLgbtq && provider.isLgbtq) {
          matchScore[i] = matchScore[i] + 100;
        }

        //Match isReligious
        // if (patientData.isReligious === provider.isReligious) {
        //   matchScore[i]++;
        // }

        //Match religion
        if (patientData.religion === provider.religion) {
          matchScore[i]++;
        }

        //Match qualified religions
        if (provider.religions.includes(patientData.religion)) {
          matchScore[i]++;
        }

        const filteredSpecialities = patientData.therapyGoals.filter((value) => provider.specialties.includes(value))
        matchScore[i] = matchScore[i] + filteredSpecialities.length;

        //agePreference
        const filteredAges = patientData.therapyAgePref.filter((value) => {
          let agePref = parseInt(value.split('s')[0]);
          let providerAge = differenceInYears(new Date(), provider.dob);
          if (agePref === 20 && providerAge > 19 && providerAge < 30 )
            return true;
          else if (agePref === 30 && providerAge > 29 && providerAge < 40 )
            return true;
          else if (agePref === 40 && providerAge > 39 && providerAge < 50 )
            return true;
          else if (agePref === 50 && providerAge > 49 && providerAge < 60 )
            return true;
          else if (agePref === 60 && providerAge > 59 )
            return true;
          else return false;
        })
        matchScore[i] = matchScore[i] + filteredAges.length;

        //clientAges
        const filteredClientAges = provider.clientAges.filter((value) => {
          let patientAge = differenceInYears(new Date(), patientData.dob);
          if (value === 19 && patientAge > 19 && patientAge < 30 )
            return true;
          else if (value === 30 && patientAge > 29 && patientAge < 40 )
            return true;
          else if (value === 40 && patientAge > 39 && patientAge < 50 )
            return true;
          else if (value === 50 && patientAge > 49 && patientAge < 65 )
            return true;
          else if (value === 65 && patientAge > 64 )
            return true;
          else return false;
        })
        matchScore[i] = matchScore[i] + filteredClientAges.length;

        //match ethnicities
        // const filteredEthnicities = patientData.ethnicities.filter((value) => provider.ethnicities.includes(value))
        // matchScore[i] = matchScore[i] + filteredEthnicities.length;

        //Match ethnicity preference
        const filteredEthnicityPref = patientData.therapyEthnicityPref.filter((value) => provider.ethnicities.includes(value))
        matchScore[i] = matchScore[i] + filteredEthnicityPref.length;

        //Match languages
        const filteredLanguagePref = patientData.therapyLanguagePref.filter((value) => value !== 'English' && provider.languages.includes(value))
        matchScore[i] = matchScore[i] + filteredLanguagePref.length * 100;

        //Multiply score by matching rank if prover has one
        if (provider.matchingMultiplier)
          matchScore[i] = matchScore[i] * provider.matchingMultiplier

        //console.log(provider.firstName, " ", matchScore[i]);
        i++;
        
      })
      let results = [];
      let count = providerList.length < 13 ? providerList.length : 12
      topIndices = findIndicesOfMax(matchScore, count);
      for (let i = 0; i < count; i++) {
        if (providerList[topIndices[i]].id !== patientData.providerId) {
          providerIds.push(providerList[topIndices[i]].id);
          results.push({
            name: providerList[topIndices[i]].firstName,
            score: matchScore[topIndices[i]]
          });
        }
      }
      // console.log("Matched Provider IDs: ", providerIds);
      //console.log(results);
      //FOR DEBUGGING ONLY: ADD JUAN ORDONEZ AS PROVIDER
      //providerIds[0] = 'pSlAXTLLpSB1jnjzT5DG';

      return providerIds;
    })

    return providerIds;
  };

  const updateProviderGCalSlots = async (patient, provider, eventId, calendarId, frequency, followOnStart, followOnEnd) =>
  {
    const server = process.env.REACT_APP_SERVERURL;
    const reqBody = JSON.stringify({
      eventId : eventId,
      calendarId : calendarId,
      start : followOnStart,
      end : followOnEnd,
      frequency: frequency,
      title : "Therapy Block for " + patient.firstName.charAt(0) + patient.lastName.charAt(0),
      description : "Block time for therapy for client. Session will be confirmed a week in advance",
      providerEmail : provider.email,
      clientEmail : patient.email,
    });

    return await fetch(server + "/update-event-slots", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      body: reqBody
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.error) {
          throw result;
        }
        return result;
      })
      .then(result => {
        //console.log("Got result! : ", result);
        return result;
      })
      .catch(error => {
        console.log("Error with calling Canvas API: ", error);
      });
  }

const blockProviderSlots = async (provider, patient, frequency, followOnStart, followOnEnd) => {
  /* Mock Data
  var eventObj = {
    'title': "Therapy Test Session Bi-Weekly",
    'description': "60 minute therapy session block created by API",
    'start': '2022-05-11T10:00:00-07:00',
    'end': '2022-05-11T10:30:00-07:00',
    'providerEmail': 'juan@allminds.io',
    'clientEmail': 'gmac@allminds.io',
    'frequency' : '2',
    'calendarId' : 'c_iisfjod5dr24majoslufo4pk7k@group.calendar.google.com'
}; */
    // console.log("Blocking Therapist Slots for: ", provider.firstName + " , " + patient.firstName, " , Date: ", followOnStart);
    const server = process.env.REACT_APP_SERVERURL;
    const reqBody = JSON.stringify({
      title : "Therapy Block for " + patient.firstName.charAt(0) + patient.lastName.charAt(0),
      description : "Block time for therapy for client. Session will be confirmed a week in advance",
      start : followOnStart,
      end : followOnEnd,
      providerEmail : provider.email,
      clientEmail : patient.email,
      frequency : frequency,
      calendarId : provider.calendarId
  });

  return await fetch(server + "/block-therapist-slots", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      body: reqBody
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.error) {
          throw result;
        }
        return result;
      })
      .then(result => {
        //console.log("Got result! : ", result);
        return result;
      })
      .catch(error => {
        console.log("Error with calling Canvas API: ", error);
      });
}

  const removeProviderGCalSlots = async (blockId, calendarId) =>
  {
    const server = process.env.REACT_APP_SERVERURL;
    const reqBody = JSON.stringify({
      eventId : blockId,
      calendarId : calendarId
    });

    return await fetch(server + "/remove-event-slots", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      body: reqBody
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.error) {
          throw result;
        }
        return result;
      })
      .then(result => {
        //console.log("Got result! : ", result);
        return result;
      })
      .catch(error => {
        console.log("Error with calling Canvas API: ", error);
      });
  }

  const getProviderGCalSlots = async (eventId, calendarId) =>
  {
    const server = process.env.REACT_APP_SERVERURL;
    const reqBody = JSON.stringify({
      eventId : eventId,
      calendarId : calendarId
    });

    return fetch(server + "/get-event-slots", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*"
      },
      body: reqBody
    })
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.error) {
          throw result;
        }
        return result;
      })
      .then(result => {
        //console.log("Got result! : ", result);
        return result;
      })
      .catch(error => {
        console.log("Error with calling Canvas API: ", error);
      });
  }

export {
    getProviderList,
    getProviderById,
    getProviderNameById,
    getMatchedProviderIds,
    blockProviderSlots,
    updateProviderGCalSlots,
    removeProviderGCalSlots,
    getProviderGCalSlots
};
