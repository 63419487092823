import React, { useState, useEffect } from 'react';
import {useLocation} from 'react-router-dom';
import { Helmet } from "react-helmet";

import { usePatientData } from '../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchPatientAppointments from '../../../../hooks/useFetchPatientAppointments/useFetchPatientAppointments';

import PatientPortalHomeContent from './PatientPortalHomeContent/PatientPortalHomeContent';
import PatientPortalHomeSliders from './PatientPortalHomeSliders/PatientPortalHomeSliders';

interface Props {
  handleShowNavBar: Function;
  handleHideNavBar: Function;
  handleEnableSwipe: Function;
  handleDisableSwipe: Function;
  landscapeUrl: string;
}

const PatientPortalHome: React.FC<Props> = props => {

  const location = useLocation();
  const patientData = usePatientData();
  const appointmentsData = useFetchPatientAppointments();

  const [showProviderProfile, setShowProviderProfile] = useState(false);
  const [showFindProvider, setShowFindProvider] = useState(false);
  const [showMatchingChat, setShowMatchingChat] = useState(false);
  const [showScheduleSession, setShowScheduleSession] = useState(false);
  const [showScheduleConsultation, setShowScheduleConsultation] = useState(false);
  const [showRescheduleAppointment, setShowRescheduleAppointment] = useState(false);
  const [showConfirmSchedule, setShowConfirmSchedule] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [showPreIntake, setShowPreIntake] = useState(false);

  //Booleans to show/hide assessments
  const [feedbackAppointmentId, setFeedbackAppointmentId] = useState<string>('');
  const [showChatPhq15, setShowChatPhq15] = useState(false);
  const [showChatPhq9, setShowChatPhq9] = useState(false);
  const [showChatGad7, setShowChatGad7] = useState(false);
  const [showChatPhqEating, setShowChatPhqEating] = useState(false);
  const [showChatPhqAlcohol, setShowChatPhqAlcohol] = useState(false);

  //Check if user is coming from account creation with pending session booking
  useEffect(() => {
    if (location.state && location.state.pendingBookSessionDate) {
      handleOpenScheduleSession();
    }
  }, []);
  
  const handleOpenProviderProfile = (providerId: string) => {
    setShowProviderProfile(true);
    props.handleHideNavBar();
    props.handleDisableSwipe(); 
  };

  const handleCloseProviderProfile = () => {
    setShowProviderProfile(false);
    props.handleShowNavBar();
    props.handleEnableSwipe(); 
  };

  const [selectedAppointmentId, setSelectedAppointmentId] = useState<string|null>(null);
  
  const handleOpenRescheduleAppointment = (appointmentId: string) => {
    if (selectedAppointmentId !== appointmentId) {
      setSelectedAppointmentId(appointmentId);
    }
    setShowRescheduleAppointment(true);
    props.handleHideNavBar();  
    props.handleDisableSwipe();   
  };

  const handleCloseRescheduleAppointment = () => {
    setShowRescheduleAppointment(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();      
  };

  const handleOpenFindProvider = () => {
    setShowFindProvider(true);
    props.handleHideNavBar();
    props.handleDisableSwipe(); 
  };

  const handleCloseFindProvider = () => {
    setShowFindProvider(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();  
  };

  const handleOpenMatchingChat = () => {
    setShowMatchingChat(true);
    props.handleHideNavBar();
    props.handleDisableSwipe(); 
  };

  const handleCloseMatchingChat = () => {
    setShowMatchingChat(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();  
  };

  const handleOpenScheduleSession = () => {
    setShowScheduleSession(true);
    props.handleHideNavBar();
    props.handleDisableSwipe();      
  };

  const handleCloseScheduleSession = () => {
    setShowScheduleSession(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();        
  };

  const handleOpenConfirmSchedule = (appointmentId: string) => {
    if (selectedAppointmentId !== appointmentId) {
      setSelectedAppointmentId(appointmentId);
    }
    setShowConfirmSchedule(true);
    props.handleHideNavBar();
    props.handleDisableSwipe();      
  };

  const handleCloseConfirmSchedule = () => {
    setShowConfirmSchedule(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();        
  };

  const handleOpenScheduleConsultation = () => {
    setShowScheduleConsultation(true);
    props.handleHideNavBar();
    props.handleDisableSwipe();      
  };

  const handleCloseScheduleConsultation = () => {
    setShowScheduleConsultation(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();        
  };

  const handleOpenConsent = () => {
    setShowConsent(true);
    props.handleHideNavBar();
    props.handleDisableSwipe();      
  };

  const handleCloseConsent = () => {
    setShowConsent(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();        
  };

  const handleOpenPreIntake = () => {
    setShowPreIntake(true);
    props.handleHideNavBar();
    props.handleDisableSwipe();      
  };

  const handleClosePreIntake = () => {
    setShowPreIntake(false);
    props.handleShowNavBar();
    props.handleEnableSwipe();        
  };

  const handleOpenAssessment = (assessment: string) => {

    if (assessment === 'phq15')
      setShowChatPhq15(true);
    else if (assessment === 'phq9')
      setShowChatPhq9(true);
    else if (assessment === 'gad7')
      setShowChatGad7(true);
    else if (assessment === 'phqEating')
      setShowChatPhqEating(true);
    else if (assessment === 'phqAlcohol')
      setShowChatPhqAlcohol(true);

    props.handleHideNavBar();
    props.handleDisableSwipe();

  };

  const handleCloseAssessment = (assessment: string) => {
    
    if (assessment === 'phq15')
      setShowChatPhq15(false);
    else if (assessment === 'phq9')
      setShowChatPhq9(false);
    else if (assessment === 'gad7')
      setShowChatGad7(false);
    else if (assessment === 'phqEating')
      setShowChatPhqEating(false);
    else if (assessment === 'phqAlcohol')
      setShowChatPhqAlcohol(false);

    props.handleShowNavBar();
    props.handleEnableSwipe();
  };

  const handleOpenFeedback = (appointmentId: string) => {
    setFeedbackAppointmentId(appointmentId);
    props.handleHideNavBar();
    props.handleDisableSwipe();
  };

  const handleCloseFeedback = () => {
    setFeedbackAppointmentId('');
    props.handleShowNavBar();
    props.handleEnableSwipe();
  };


  return(

    <React.Fragment>

      <Helmet>
        <title>
          Home - Allminds
        </title>
      </Helmet>

      <PatientPortalHomeContent
        patientData={patientData}
        appointmentsData={appointmentsData.upcoming}
        handleOpenProviderProfile={handleOpenProviderProfile}
        handleOpenFindProvider={handleOpenFindProvider}
        handleOpenRescheduleAppointment={handleOpenRescheduleAppointment}
        handleOpenScheduleSession={handleOpenScheduleSession}
        handleOpenScheduleConsultation={handleOpenScheduleConsultation}
        handleOpenConfirmSchedule={handleOpenConfirmSchedule}
        handleOpenConsent={handleOpenConsent}
        handleOpenPreIntake={handleOpenPreIntake}
        handleOpenAssessment={handleOpenAssessment}
        handleOpenFeedback={handleOpenFeedback}
        landscapeUrl={props.landscapeUrl}
      />

      <PatientPortalHomeSliders
        patientData={patientData}
        appointmentsData={appointmentsData.upcoming} 
        selectedAppointmentId={selectedAppointmentId}
        showProviderProfile={showProviderProfile}
        showFindProvider={showFindProvider}
        showMatchingChat={showMatchingChat}
        showScheduleSession={showScheduleSession}
        showConfirmSchedule={showConfirmSchedule}
        showScheduleConsultation={showScheduleConsultation}
        showRescheduleAppointment={showRescheduleAppointment}
        showConsent={showConsent}
        showPreIntake={showPreIntake}
        handleCloseProviderProfile={handleCloseProviderProfile}
        handleCloseRescheduleAppointment={handleCloseRescheduleAppointment}
        handleOpenFindProvider={handleOpenFindProvider}
        handleCloseFindProvider={handleCloseFindProvider}
        handleOpenMatchingChat={handleOpenMatchingChat}
        handleCloseMatchingChat={handleCloseMatchingChat}
        handleCloseScheduleSession={handleCloseScheduleSession}
        handleCloseScheduleConsultation={handleCloseScheduleConsultation}
        handleCloseConfirmSchedule={handleCloseConfirmSchedule}
        handleCloseConsent={handleCloseConsent}
        handleClosePreIntake={handleClosePreIntake}
        feedbackAppointmentId={feedbackAppointmentId}
        showChatPhq15={showChatPhq15}
        showChatPhq9={showChatPhq9}
        showChatGad7={showChatGad7}
        showChatPhqEating={showChatPhqEating}
        showChatPhqAlcohol={showChatPhqAlcohol}
        handleCloseAssessment={handleCloseAssessment}
        handleCloseFeedback={handleCloseFeedback}
      />

    </React.Fragment>

  );
};

export default PatientPortalHome;
