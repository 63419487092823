import React from 'react';
import classes from './Layout.module.css';

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = props => {
  return (
    <div className={classes.container}>
      <main className={classes.main}>{props.children}</main>
    </div>
  );
};

export default Layout;
