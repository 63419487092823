import React from 'react';

import useFetchProviderData from "../../../../../../hooks/useFetchProviderData/useFetchProviderData";
import useFetchPatientAppointments from '../../../../../../hooks/useFetchPatientAppointments/useFetchPatientAppointments';

import Button from '@mui/material/Button';
import ChairOutlinedIcon from '@mui/icons-material/ChairOutlined';
import classes from './PatientPortalHomeScheduleSessionCard.module.css';
import '../../../../../../globalCss/globalCssMuiButton.css';

interface Props {
  handleOpenScheduleSession: React.MouseEventHandler;
  handleOpenFindProvider: React.MouseEventHandler;
}

const PatientPortalHomeScheduleSessionCard: React.FC<Props> = props => {

  const providerData = useFetchProviderData();
  const upcomingAppts = useFetchPatientAppointments().upcoming;
  const pastAppts = useFetchPatientAppointments().past;

  //Check if patient has an upcoming appointment with the therapist
  let upcomingAppointment = null;
  let pastAppointment = null;
  if (upcomingAppts && providerData) {
    upcomingAppointment = upcomingAppts.find(
      (appointment:any) => appointment.providerId === providerData.id && (appointment.status === "fulfilled" || appointment.status === "booked" || appointment.status === "proposed" || appointment.status === "pending")
    );
  }
  if (pastAppts && providerData) {
    pastAppointment = pastAppts.find(
      (appointment:any) => appointment.providerId === providerData.id && (appointment.status === "fulfilled" || appointment.status === "booked" || appointment.status === "proposed" || appointment.status === "pending")
    ); 
  }

  return (
    <div className={classes.containerScheduleSessionCard}>
      <ChairOutlinedIcon fontSize="large" color="inherit" />
      <h5>Your therapist will see you now</h5>
      <p>It's time to embark on your mental health journey.</p>
      <div className={classes.scheduleBtns}>
        <Button onClick={props.handleOpenScheduleSession} variant="contained" size="large" fullWidth>Start therapy</Button>
        { 
          !pastAppointment && !upcomingAppointment && providerData ? 
            <Button onClick={props.handleOpenFindProvider} color="secondary" variant="text" fullWidth>
              Find a new therapist
            </Button>
          :
          null
        }
      </div>
    </div>
  );

};

export default PatientPortalHomeScheduleSessionCard;
