import React from 'react';
import ReactCalendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import '../../../globalCss/globalCssReactCalendar.css';

interface Props {
  value: Date;
  onChange: Function;
  calendarProps: any;
}

const Calendar: React.FC<Props> = props => {

  return (
    <ReactCalendar
      onChange={props.onChange}
      value={props.value}
      {...props.calendarProps}
    />
  );
};

export default Calendar;
