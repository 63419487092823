import React, {useState} from 'react';
import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import {updatePatientDob} from '../../../../../../containers/Models/PatientModel';
import { LoadingButton } from '@mui/lab';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import classes from '../PatientSettingsEditName/PatientSettingsEditName.module.css';
import '../../../../../../globalCss/globalCssMuiTextField.css';
import '../../../../../../globalCss/globalCssMuiButton.css';
import '../../../../../../globalCss/globalCssMuiCalendarPicker.css';

interface Props {
  handleClose: Function;
}

const PatientSettingsEditDob: React.FC<Props> = props => {

  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();

  const [date, setDate] = useState<Dayjs | null>(dayjs(patientData.dob));
  const [loading, setLoading] = useState(false);

  const handleDateChange = (e:any) => {
    setDate(e['$d'] ? e['$d'] : null);
  };

  const handleSubmit = () => {
    if (date !== patientData.dob) {
      setLoading(true);
      updatePatientDob(patientData.id, date);
      let updatedPatientData = {...patientData};
      updatedPatientData.dob = date;
      updateStatePatientData(updatedPatientData);
      setLoading(false);
      props.handleClose();
    }
  };

  return(
    <div className={classes.containerEditSetting}>
      <div className={classes.header}>
        <h2>Date of birth</h2>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          value={date}
          onChange={handleDateChange}
          openTo="year"
        />
      </LocalizationProvider>
      <LoadingButton onClick={handleSubmit} loading={loading} variant="contained" size='large' className={classes.btnSubmit}>Save</LoadingButton>
    </div>
  );
};

export default PatientSettingsEditDob;