import React, { useState } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import { canvasCreateQuesPhqAlcohol } from '../../../../../../containers/Models/CanvasQuestionnaires';
import { addPhqAlcoholToDb } from '../../../../../../containers/Models/assessmentModels/phqAlcoholModel';

import NavBarPatientChat from '../../../../../ui/ChatForm/NavBarPatientChat/NavBarPatientChat';
import ChatFormScreener from '../../../../../ui/ChatForm/ChatFormScreener';
import Skeleton from '@mui/material/Skeleton';

import classes from "../PatientChatPhq15/PatientChatPhq15.module.css";

interface Props {
  handleCloseChat: any;
}

const PatientChatPhqAlcohol: React.FC<Props> = props => {

  const analytics = getAnalytics();
  const patientData = usePatientData();
  const handleUpdateStatePatientData = useUpdatePatientData();
  const [disableBackBtn, setDisableBackBtn] = useState(false);

  const questions: any = {
    drinkAlcohol: "Do you ever drink alcohol (including beer or wine)?"
  };


  //Object with options for alcohol abuse questions and associated scoring value
  const options: any = {
    No: 0,
    Yes: 1
  };

  const intro = {
    message: 
      "Alcohol use can have a big impact on mental health, and sometimes people use alcohol to help cope with difficult emotions."
      + "&&These questions will help your provider understand how alcohol use may be impacting you.",
    option: "Sounds good"
  };

  const getScore = (formData: any) => {
    let score = 0;
    for (let key in formData) {
      if (key !== "intro" && key !== "end" && key !== "drinkAlcohol") {
        score = score + options[formData[key][0]];
      }
    } 
    return score
  };

  const getScoreResponse = (formData: any) => {
    
    let score = getScore(formData);

    let response =
      "Ok " +
      patientData.firstName +
      "!&&It looks like alcohol isn't a concern for you right now." +
      "&&You can talk to your provider in more detail about your results!" 
    
    if (formData.drinkAlcohol[0] === 'No')
      //User doesn't drink alcohol
      response = "Gotcha! We're done with this assessment then :)";
    
    else if (score > 0) {
      //User has alcohol use disorder
      response =
        "Thanks " +
        patientData.firstName +
        ".&&Based on your answers it seems like alcohol may be contributing to some problems." +
        "&&You can talk to your provider in more detail about strategies to help monitor or reduce alcohol use, if needed, as well as learn other coping strategies to manage challenging emotions." 
    }

    return response;
  };

  const questionCallback = (dto: any, success: any, error: any, cf: any) => {
    
    const createConditionalTags = (name: string, question: string) => {
      let tags = [
        {
          tag: "fieldset",
          name: name,
          "cf-input-placeholder": " ",
          "cf-questions": question,
          children: [
            {
              tag: "input",
              name: name,
              type: "radio",
              "cf-label": "No",
              value: "No"
            },
            {
              tag: "input",
              name: name,
              type: "radio",
              "cf-label": "Yes",
              value: "Yes"
            }
          ]
        }
      ];
      return tags;
    };

    const createTag = (name:string, question:string, answers:string[]) => {
      
      const createTagChildren = () => {
        let tagChildren: any = [];
        answers.forEach((answer: any) => {
          let child: any = {
            tag: "input",
            name: name,
            type: "radio",
            "cf-label": answer,
            value: answer
          };
          tagChildren.push(child);
        });
        return tagChildren;
      }
      let radioTag = {
        tag: "fieldset",
        name: name,
        "cf-input-placeholder": " ",
        "cf-questions": question,
        children: createTagChildren()
      }
      return radioTag
    };

    const handleGoToEnd = (data:any) => {
      handleSubmit(data);
      cf.addTags([createTag(
        "end",
        getScoreResponse(data),
        ["Exit"]
      )]);
    };

    let formData = cf.getFormData(true);

    //Add drinkingquestions if drinkAlcohol is "Yes"
    if (dto.tag.name === "drinkAlcohol") {
      
      if (dto.tag.value[0] === "Yes") {
        if (!formData.ignoredDoctor) {
          cf.addTags(
            createConditionalTags(
              "ignoredDoctor",
              "Have any of the following happened to you <b>more than once in the last 6 months</b>?" +
                "&&You drank alcohol even though a doctor suggested that you stop drinking because of a problem with your health."
            ),
            false,
            2
          );
        }
        if (!formData.workingIntoxicated) {
          cf.addTags(
            createConditionalTags(
              "workingIntoxicated",
              "You drank alcohol, were high from alcohol, or hung over while you were working, going to school, or taking care of children or other responsibilities."
            ),
            false,
            3
          );
        }
        if (!formData.missedResponsibilities) {
          cf.addTags(
            createConditionalTags(
              "missedResponsibilities",
              "You missed or were late for work, school, or other activities because you were drinking or hung over."
            ),
            false,
            4
          );
        }
        if (!formData.problemSocializing) {
          cf.addTags(
            createConditionalTags(
              "problemSocializing",
              "You had a problem getting along with other people while you were drinking."
            ),
            false,
            5
          );
        }
        if (!formData.drivingIntoxicated) {
          cf.addTags(
            createConditionalTags(
              "drivingIntoxicated",
              "You drove a car after having several drinks or after drinking too much."
            ),
            false,
            6
          );
        }
      }
      else {
        handleGoToEnd(formData);
      }
      success();

    } 

    else if (dto.tag.name === 'drivingIntoxicated') {
      handleGoToEnd(formData);
      success();
    }

    else if (dto.tag.name === 'end') {
      props.handleCloseChat();
    }

    else success();

  };

  //Function to be called after form is submitted
  const handleSubmit = (formData: any) => {

    const getSeverity = (score: number) => {
      if (score > 0) {
        return "Alcohol Abuse";
      }
      else return "Normal"; 

    };

    setDisableBackBtn(true);

    //Create screenerData object from formData
    const screenerData: any = {};
    for (let key in formData) {
      if (key !== "intro" && key !== "end") {
        screenerData[key] = {
          value: formData[key][0],
          score: options[formData[key][0]]
        };
      }
    }

    let score = getScore(formData);
    let severity = getSeverity(score);

    //Create object with phqAlcohol data
    let phqAlcoholData = {
      
      patientId: patientData.id,
      patientCanvasId: patientData.canvasId,
      providerId: patientData.providerData.id,
      providerCanvasId: patientData.providerData.canvasId,
      timeStamp: new Date(),

      drinkAlcohol: { value: screenerData.drinkAlcohol.value, score: screenerData.drinkAlcohol.score },
      //The questions below are skippable, so check to see if answer is available
      ignoredDoctor: screenerData.ignoredDoctor ?
                    { value: screenerData.ignoredDoctor.value, score: screenerData.ignoredDoctor.score }
                    : null,
      workingIntoxicated: screenerData.workingIntoxicated ?
                          { value: screenerData.workingIntoxicated.value, score: screenerData.workingIntoxicated.score }
                          : null,
      missedResponsibilities: screenerData.missedResponsibilities ?
                              { value: screenerData.missedResponsibilities.value, score: screenerData.missedResponsibilities.score }
                              : null,
      problemSocializing: screenerData.problemSocializing ? 
                          { value: screenerData.problemSocializing.value, score: screenerData.problemSocializing.score }
                          : null,
      drivingIntoxicated: screenerData.drivingIntoxicated ? 
                          { value: screenerData.drivingIntoxicated.value, score: screenerData.drivingIntoxicated.score }
                          : null,

      score: score,
      severity: severity

    };

    //Upload assessment to db
    addPhqAlcoholToDb(phqAlcoholData).then((res) => {
      //Upload questionnaire to Canvas
      canvasCreateQuesPhqAlcohol(phqAlcoholData);
    });

    //Track firebase event
    logEvent(analytics, 'phqAlcohol_completed');

    //Update state with next due date for assessment
    let updatedPatientData = {...patientData};
    updatedPatientData.lastPhqAlcohol = {
      id: '',
      timeStamp: phqAlcoholData.timeStamp
    };
    handleUpdateStatePatientData(updatedPatientData);

  };

  return (
    <React.Fragment>
      <NavBarPatientChat 
        handleClose={props.handleCloseChat} 
      />
      <div className={classes.containerChatAssessment}>
        {patientData ? 
          <ChatFormScreener
            questions={questions}
            options={options}
            intro={intro}
            questionCallback={questionCallback}
            disableBackBtn={disableBackBtn}
          />
        :
          <div className={classes.containerSkeleton}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={200} height={60} />
          </div>
        }
      </div>
    </React.Fragment>
  );

};

export default PatientChatPhqAlcohol;
