import React, { useState } from 'react';

import PatientPortalAppointmentsUpcoming from './PatientPortalAppointmentsUpcoming/PatientPortalAppointmentsUpcoming';
import PatientPortalAppointmentsHistory from './PatientPortalAppointmentsHistory/PatientPortalAppointmentsHistory';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import classes from './PatientPortalAppointmentsContent.module.css'


interface Props {
  appointmentsData: any; // See useFetchPatientAppointments for object structure
  handleOpenScheduleSession: React.MouseEventHandler;
  handleOpenReschedule: Function;
  handleOpenConfirmSchedule: Function;
  handleOpenConsent: Function;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <div>{children}</div>
      )}
    </div>
  );
};

const PatientPortalAppointmentsContent: React.FC<Props> = props => {

  const appointmentsData = props.appointmentsData;

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  
  return(
    <div className={classes.containerPatientPortalAppointmentsContent}>
      
      <div className={classes.inboxHeader}>
        {/*<h2>Schedule</h2>*/}
        <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth" className={classes.tabs} centered>
          <Tab 
            label="Upcoming"
            id="tab-upcoming" 
            aria-controls="tabpanel-upcoming"
          />
          <Tab 
            label="History"
            id="tab-history" 
            aria-controls="tabpanel-history"
          />
        </Tabs>
      </div>

      <TabPanel key={"tab-panel-upcoming"} value={tabIndex} index={0}>
        <PatientPortalAppointmentsUpcoming 
          appointmentsData={appointmentsData}
          handleOpenScheduleSession={props.handleOpenScheduleSession} 
          handleOpenReschedule={props.handleOpenReschedule}
          handleOpenConfirmSchedule={props.handleOpenConfirmSchedule}
          handleOpenConsent={props.handleOpenConsent}
        />
      </TabPanel>

      <TabPanel key={"tab-panel-history"} value={tabIndex} index={1}>
        <PatientPortalAppointmentsHistory />
      </TabPanel>

    </div>
  );

};

export default PatientPortalAppointmentsContent;
