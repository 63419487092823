import React from 'react';
import Alert from '@mui/material/Alert';


interface Props {
}

const TreatmentHoldAlert: React.FC<Props> = props => {

  return(
    <Alert severity="error">
      <h5>Care on hold</h5>
      <div>
        <p>Your account is on hold. Please check your email for more info, or contact <strong>support@allminds.io</strong>.</p>
      </div>
    </Alert>
  );

};

export default TreatmentHoldAlert;
