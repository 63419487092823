import React, {useState} from 'react';

import { usePatientData } from "../../../../../../../hooks/ContextPatientData/ContextPatientData";

import ReviewSessionDetailsCard from '../ReviewSessionDetailsCard/ReviewSessionDetailsCard';
import PatientScheduleSessionPayment from '../PatientScheduleSessionPayment/PatientScheduleSessionPayment';
import PatientSessionSelectPayment from '../PatientSessionSelectPayment/PatientSessionSelectPayment';

import SliderModal from '../../../../../../ui/SliderModal/SliderModal';
import ErrorSnackbar from '../../../../../../ui/ErrorSnackbar/ErrorSnackbar';

import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';


import classes from './PatientReviewBooking.module.css';



interface Props {
  providerData: any;
  confirmedDate: Date;
  frequency: number;
  paymentMethod: any;
  setPaymentMethod: any;
  handleBookAppointment: any;
  loadingSubmit: boolean;
  error: any;
  setError: any;

}

const PatientReviewBooking: React.FC<Props> = props => {

  const patientData = usePatientData();

  //Start logic payment methods
  const [showEditPayment, setShowEditPayment] = useState(false);
  const handleOpenEditPayment = () => {
    setShowEditPayment(true);
  };
  const handleCloseEditPayment = () => {
    setShowEditPayment(false);
  };
  //End logic payment methods

  return(
    <div className={classes.containerReviewBooking}>

      <h4>Submit your request</h4>

      <Alert severity="warning" style={{marginBottom: '32px'}}>
        Your request hasn't been submitted yet - please review details below and finish requesting your booking.
      </Alert>

      <ReviewSessionDetailsCard
        providerName={props.providerData.firstName + ' ' + props.providerData.lastName}
        providerPicUrl={props.providerData.picUrl}
        startDate={props.confirmedDate}
        endDate={new Date(props.confirmedDate.getTime() + 3600000)}
        frequency={props.frequency}
      />

      <PatientScheduleSessionPayment
        paymentMethod={props.paymentMethod}
        defaultPaymentId={patientData.stripeDefaultPaymentId}
        handleSetPaymentMethod={props.setPaymentMethod}
        handleOpenEditPayment={handleOpenEditPayment}
      />

      <LoadingButton 
        onClick={props.handleBookAppointment}
        className={classes.btnBook} 
        variant="contained" 
        size="large" 
        fullWidth
        loading={props.loadingSubmit}
        disabled={!props.paymentMethod}
      >
        Request to book
      </LoadingButton>

      <ErrorSnackbar open={props.error} setOpen={props.setError} />

      <div style={{marginLeft: '-16px'}}>
        <SliderModal isOpen={showEditPayment} handleClose={handleCloseEditPayment}>
          <PatientSessionSelectPayment 
            handleClose={handleCloseEditPayment} 
            paymentMethod={props.paymentMethod} 
            handleSetPaymentMethod={props.setPaymentMethod}
          />
        </SliderModal>
      </div>

    </div>
  );

};

export default PatientReviewBooking;
