import React from 'react';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import classes from './PatientAssessmentsInfoCard.module.css';
import '../../../../../../../globalCss/globalCssMuiButton.css';

interface Props {
}

const PatientAssessmentsInfoCard: React.FC<Props> = props => {

  return(
    <div className={classes.container}>     
        <div style={{flexGrow: 1}}>
            <h5>Mental health check-in</h5>
            <p><small>These brief check-ins will help your provider better understand how you are doing in-between sessions, and will help you see how your symptoms change over time.</small></p>
        </div>
        <InsightsOutlinedIcon fontSize='large' />
    </div>
  );
};

export default PatientAssessmentsInfoCard;
