import { useEffect, useState } from "react";
import { usePatientData, useUpdatePatientData } from '../ContextPatientData/ContextPatientData';
import { getCoverageById } from "../../containers/Models/coverageModel";
import { getEligibility } from "../../containers/Models/eligibility";

export default function useFetchCoverageData() {

  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();

  const [coverageData, setCoverageData] = useState<any>(null);

  //Fetch coverage data if state doesn't have it
  useEffect(() => {
    if (patientData) {
      //Check that this is an insurance patient
      if (patientData.coverageId) {
        //If state doesn't have coverage data start fetch
        if (!patientData.coverageData) {
          let data:any = null;
          //First get coverage data from db
          getCoverageById(patientData.coverageId)
            //Then get eligibility data from claimmd
            .then(coverageData => {  
              data = {...coverageData};
              return getEligibility(coverageData!.memberId, coverageData!.payer, patientData.dob)
            })
            //Then merge coverage and eligibility data as one object
            .then((eligibilityData:any) => {
              if (eligibilityData && !eligibilityData.error) {
                //Set eligibility inactive if coverage authorization has expired
                if (data.authExpDate && data.authExpDate.getTime() < new Date().getTime())
                  eligibilityData.isActive = false;
                let mergedData = {...data, ...eligibilityData};
                setCoverageData(mergedData);
              }
              //Handle errors fetching eligibility
              else {
                setCoverageData(eligibilityData);
              }
            })
        }
        //If state already has data then use that
        else {
          setCoverageData(patientData.coverageData);
        }
      }
      // // For self-pay patients
      // else {
      //   setCoverageData(null);
      //   if (setCoverageFetched)
      //     setCoverageFetched(true);
      // }
    }
  }, [patientData]);

  //Add canvas provider data to state after initial fetch
  useEffect(() => {
    if (coverageData && patientData && !patientData.coverageData) {
      let updatedPatientData = {...patientData};
      updatedPatientData.coverageData = {...coverageData};
      updateStatePatientData(updatedPatientData);
    }
  }, [patientData, coverageData, updateStatePatientData]);

  return coverageData;
}

