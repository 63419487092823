import React, { useState } from 'react';
import { format } from 'date-fns';
import linkifyHtml from 'linkify-html';

import Avatar from '@mui/material/Avatar';
import Collapse from '@mui/material/Collapse';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';

import classes from './MessageOutgoing.module.css'


interface Props {
  messageData: {
    resourceType: string;
    sent: string;
    received: string;
    recipient: Array<{
      reference: string
    }>;
    sender: {
      reference: string
    };
    payload: Array<{
      contentString: string
    }>;
  }
  isFirstMessage: boolean;
  isLastMessage: boolean;
  delivered: boolean;
  senderName: string;
}

const MessageOutgoing: React.FC<Props> = props => {

  const [showDate, setShowDate] = useState(false);
  const linkifyOptions = { target: "_blank" };

  const handleToggleDate = () => {
    if (showDate)
      setShowDate(false);
    else 
      setShowDate(true);
  };

  let bubbleStyle = {
    borderRadius: 
      props.isFirstMessage && !props.isLastMessage ? '24px 0px 24px 24px'
      : !props.isFirstMessage && props.isLastMessage ? '24px 24px 0px 24px'
      : !props.isFirstMessage && !props.isLastMessage ? '24px 0px 0px 24px'
      : '24px'
  };

  let margins = {
    marginTop: !props.isLastMessage ? '4px' : '16px',
    marginRight: props.isFirstMessage ? '8px' : '45px'
  } 
  if (props.messageData.payload) {

    return(
      <div className={classes.containerMessageOutgoing}>

        <div className={classes.chatContent} style={margins}>
          <div className={classes.chatBubbleWrapper}>
            {!props.delivered ? <AccessTimeOutlinedIcon /> : null}
            <div className={classes.chatBubble} onClick={handleToggleDate} style={bubbleStyle}>
              <div dangerouslySetInnerHTML={{ __html: linkifyHtml(props.messageData.payload[0].contentString, linkifyOptions) }} />
            </div>
          </div>
          <Collapse in={showDate}>
            <p className={classes.messageDate}>
              {format(new Date(props.messageData.sent), 'MMM dd, p')}
            </p>
          </Collapse>
        </div>

        {props.isFirstMessage ? 
          <Avatar alt="Your initials" sx={{ width: 37, height: 37, bgcolor: '#2979FF' }}>{props.senderName[0]}</Avatar>
        : null}

      </div>
    );

  }

  else
    return null;

};

export default MessageOutgoing;
