//require("dotenv").config();

const twilioSendText = async (phone: string, message: string) => {
  const server = process.env.REACT_APP_SERVERURL;
  return await fetch(server + "/send-sms", {
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      to: phone,
      message: message
    })
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(error => {
      console.log(error);
    });
};

const twilioLookUp = async (phone: string) => {
  //If the number is valid, it should return the number in E.164 format so i tmay be stored in the db
  //If number is invalid throw error
  const server = process.env.REACT_APP_SERVERURL;
  const isValidated = await fetch(server + "/lookup", {
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({
      phone: phone
    })
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      if (result.phoneNumber) return result.phoneNumber;
      else return false;
    })
    .catch(error => {
      console.log("Error: ", error);
    });

  return isValidated;
};

export {
  twilioSendText, 
  twilioLookUp 
};