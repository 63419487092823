import React from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

import classes from './ConsentAlert.module.css';


interface Props {
  openConsent: Function;
}

const ConsentAlert: React.FC<Props> = props => {

  return(
    <div className={classes.containerConsentAlert}>
      <div>
        <h5>Consent document</h5>
        <p><small>Review this document in its entirety. It explains what therapy is and how our policies work.</small></p>
      </div>
      <Button 
        onClick={() => props.openConsent()} 
        className={classes.btn} 
        color="secondary" 
        aria-label="Start signing"
        variant="contained"
      >
        View & Sign
      </Button>
    </div>
  );

};

export default ConsentAlert;