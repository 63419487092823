const sendPatientConsentForms = async (email:string, firstName: string, lastName: string) => {
  //console.log("Calling pandaDoc sendPatientConsentForms");
  const server = process.env.REACT_APP_SERVERURL;

  const reqBody = JSON.stringify({
    email: email,
    firstName: firstName,
    lastName: lastName,
  });
     

  return fetch(server + "/pandadoc-send-consent-forms", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("sendPatientConsentForms result: ", result);
      return result;
    })
    .catch(error => {
      console.log("sendPatientConsentForms error: ", error);
      throw error;
    });
};

const getDocLink = async (patientId: string) => {
  //console.log("Calling pandaDoc sendPatientConsentForms");
  const server = process.env.REACT_APP_SERVERURL;

  const reqBody = JSON.stringify({
    patientId: patientId
  });
     

  return fetch(server + "/pandadoc-create-document-link", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      console.log("getDocLink result: ", result);
      return result;
    })
    .catch(error => {
      console.log("getDocLink error: ", error);
      throw error;
    });
};

export {
  sendPatientConsentForms,
  getDocLink
};