import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import '../../../globalCss/globalCssMuiTextField.css';

interface Props {
  password: string;
  handleSetPassword: Function;
  handleSetPasswordIsValid?: Function;
  required?: boolean;
  label? : string;
}


const TextFieldPassword: React.FC<Props> = props => {

  const [showPassword, setShowPassword] = useState(false);
  const [passwordHelper, setPasswordHelper] = useState("");
  const [passwordError, setPasswordError] = useState(false);

  const handleChangePassword = (e:React.ChangeEvent<HTMLInputElement>) => {
    props.handleSetPassword(e.target.value);
    let [isValid, errors]:any= validatePassword(e.target.value);
    if (!isValid && props.handleSetPasswordIsValid) {
      setPasswordHelper(errors[0]);
      setPasswordError(true);
      props.handleSetPasswordIsValid(false);    
    }
    else if (props.handleSetPasswordIsValid) {
      setPasswordHelper("");
      setPasswordError(false);
      props.handleSetPasswordIsValid(true);      
    }
  };

  const togglePassword = () => {
    if (showPassword) setShowPassword(false);
    else setShowPassword(true);
  };


  //Validate password
  const validatePassword = (password:string) => {
    let isValid = true;
    let errors = [];

    if (password.length < 8) {
      errors.push("Your password must be at least eight characters");
      isValid = false;
    }
    if (password.search(/[a-z]/i) < 0) {
      errors.push("Your password must contain at least one letter.");
      isValid = false;
    }
    if (password.search(/[0-9]/) < 0) {
      errors.push("Your password must contain at least one digit.");
      isValid = false;
    }
    if (password.search(/[!@#$%^&*() =+_-]/) < 0) {
        errors.push(
          "Your password must contain at least one symbol in this list !@#$%^&*()=+_- or a space."
        );
        isValid = false;
    }

    return [isValid, errors];
  };


  return(
    <TextField
      label={props.label ? props.label : "Password"} 
      type={showPassword ? 'text' : 'password'}
      value={props.password}
      error={props.handleSetPasswordIsValid && passwordError && props.password.length > 0}
      color={props.handleSetPasswordIsValid && !passwordError && props.password.length > 0 ? "success" : "primary"}
      helperText={passwordHelper}
      onChange={handleChangePassword}
      required={props.required ? true : false}
      variant="outlined" 
      fullWidth 
      InputProps={{
        endAdornment:             
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={togglePassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>,
      }}
    />
  );
};

export default TextFieldPassword;

