import React from 'react';

import Button from '@mui/material/Button';

import classes from './PatientPaymentMethodCard.module.css';
import '../../../../../../../../globalCss/globalCssMuiButton.css';


interface Props {
  label: string;
  value?: string;
  clicked: React.MouseEventHandler;
}

const PatientPaymentMethodCard: React.FC<Props> = props => {

  return(

    <div className={classes.containerPaymentMethodCard}>

      <div className={classes.header}>
        <div>
          <h5>{props.label}</h5> 
          {props.value ? 
            <p>{props.value}</p> 
          : null}
        </div>
        <Button onClick={props.clicked} variant="outlined" color="secondary" size="small">Change</Button>
      </div>

    </div>
  
  );

};

export default PatientPaymentMethodCard;
