import firebaseApp from "../../Data/Firestore";
import { 
  getFirestore, 
  addDoc, 
  updateDoc, 
  collection, 
  doc
} from "firebase/firestore";

const db = getFirestore(firebaseApp);
const preIntakeDb = process.env.REACT_APP_PRE_INTAKE_DB;
const patientDb = process.env.REACT_APP_PATIENT_DB;

const addPreIntakeToDb = async (data:{
    patientId: string,
    patientCanvasId: string,
    providerId: string,
    providerCanvasId: string,
    timeStamp: Date,                // addedOn
    reasonsSeekingTherapy: string,
    therapyGoals: string,
    preferredName: string,
    localAddress: string,
    emergencyContactName: string,
    emergencyContactNumber: string,
    primaryPhysicianName: string,
    primaryPhysicianNumber: string,
    psychiatristName: string,
    psychiatristNumber: string,
    ongoingPsychiatricMedication: boolean,
    ongoingPsychiatricMedicationDetails: string,
    psychiatricMedicationTakenInPast: boolean,
    pastPsychiatricMedicationDetails: string,
    chronicHealthCondition: boolean,
    chronicHealthConditionDetails: string,
    seriousAccidentOrInjury: boolean,
    seriousAccidentOrInjuryDetails: string,
    diagnosedWithMentalHealthCondition: boolean,
    diagnosedMentalHealthDetails: string,
    takenPastMentalHealthTherapy: boolean,
    reasonForPastMentalHealthTherapy: string,
    hospitalizedForPsychiatricReason: boolean,
    whenHospitalizedForPsychiatricTherapy: string,
    maritalStatus: string,
    numberOfChildren: string,
    familyHistoryOfMentalHealth: boolean,
    familyHistoryOfMentalHealthDetails: string,
    employmentStatus: string,
    employmentDetails: string,
    jobSatisfaction: string,
    educationLevel: string,
    // Fields added that were missing for portability
    // To Notes Db
    claimId: string,              // Confirm if needed
    id: string,
    isDeleted: boolean,
    isLocked: boolean,
    occupation: string,
    type: "PI",
}) => {

  let newDocId = '';
    //Write preIntake to db
    const docRef = await addDoc(collection(db, preIntakeDb!), data).then(ref => {
      newDocId = ref.id;
      updateDoc(ref, {id: newDocId });
      return newDocId;
    }).then((newDocId) => {
      const patientDoc = doc(db, patientDb!, data.patientId);
      updateDoc(patientDoc, {preIntakeId:newDocId});
    });

  return newDocId;
}

export {
  addPreIntakeToDb
};
