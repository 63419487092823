import { format } from 'date-fns';

const canvasCreateQuestionnaire = async (questionnaireData: {
    questionnaireCode: string
    patientCanvasId: string,
    providerCanvasId: string,
    timeStamp: Date,
    responses: Array<{questionCode: string, answers: Array<{code: string, value: string}>}>
}) => {
  //console.log("Calling Canvas Create Questionnaire with: ", questionnaireData);
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = JSON.stringify({
      questionnaireCode: questionnaireData.questionnaireCode,
      patientId: questionnaireData.patientCanvasId,
      providerId: questionnaireData.providerCanvasId,
      timeStamp: questionnaireData.timeStamp,
      responses: questionnaireData.responses,
  });

  return fetch(server + "/canvas-create-questionnaire", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("canvasCreateQuestionnaire result: ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasCreateQuestionnaire error: ", error);
    });
};

const canvasCreateQuesCalpas12 = async (data: {
      patientId: string,
      patientCanvasId: string,
      providerId: string,
      providerCanvasId: string,
      appointmentId: string,
      appointmentDate: Date,
      timeStamp: Date,

      pcWorthwhile: { value: string, score: number },
      pwcSharing: { value: string, score: number },
      tuiAccepted: { value: string, score: number },
      pwcFeelings: { value: string, score: number },
      wscWorkingTogether: { value: string, score: number },
      tuiDedication: { value: string, score: number },
      pcResentment: { value: string, score: number },
      wscGoals: { value: string, score: number },
      pcProblems: { value: string, score: number },
      wscExpectations: { value: string, score: number },
      pwcProblems: { value: string, score: number },
      tuiProblems: { value: string, score: number },

      scorePwc: number,
      scorePc: number,
      scoreWsc: number,
      scoreTui: number,
      scoreTotal: number
}) => {
  
  let questionnaireCode = 'ques-calpasp12'; //must match questionnaire code in Canvas

  let responseCodes: any = {
    1: 'ques-calpasp12-01-00', // "Not at all"
    2: 'ques-calpasp12-01-01', // "A little bit"
    3: 'ques-calpasp12-01-02', // "Somewhat"
    4: 'ques-calpasp12-01-03', // "Moderately"
    5: 'ques-calpasp12-01-04', // "Quite a bit"
    6: 'ques-calpasp12-01-05', // "Quite a lot"
    7: 'ques-calpasp12-01-06' // "Very much so"
  };

  let questionnaireData = {

    questionnaireCode: questionnaireCode, //must match questionnaire code in Canvas
    patientCanvasId: data.patientCanvasId,
    providerCanvasId: data.providerCanvasId,
    timeStamp: data.timeStamp,

    responses:[
      {
        questionCode: 'ques-calpasp12-00', //Date of session
        answers: [{code: 'ques-calpasp12-00-00', value: format(data.appointmentDate, 'MM/dd/yyyy')}]
      },
      {
        questionCode: 'ques-calpasp12-01', //Did you feel that even if you might have moments of doubt, confusion, or mistrust, that overall therapy is worthwhile?
        answers: [{code: responseCodes[data.pcWorthwhile.score], value: data.pcWorthwhile.value}]
      },
      {
        questionCode: 'ques-calpasp12-02', //When important things come to mind, how often did you find yourself keeping them to yourself rather than sharing them with your therapist?
        answers: [{code: responseCodes[data.pwcSharing.score], value: data.pwcSharing.value}]
      },      
      {
        questionCode: 'ques-calpasp12-03', //Did you feel accepted and respected by your therapist for who you are?
        answers: [{code: responseCodes[data.tuiAccepted.score], value: data.tuiAccepted.value}]
      },
      {
        questionCode: 'ques-calpasp12-04', //How much did you hold back your feelings during this session?
        answers: [{code: responseCodes[data.pwcFeelings.score], value: data.pwcFeelings.value}]
      },
      {
        questionCode: 'ques-calpasp12-05', //Did you feel that you were working together with your therapist, that the two of you were joined in a struggle to overcome your problems?
        answers: [{code: responseCodes[data.wscWorkingTogether.score], value: data.wscWorkingTogether.value}]
      },
      {
        questionCode: 'ques-calpasp12-06', //During this session, how dedicated was your therapist to helping you overcome your difficulties?
        answers: [{code: responseCodes[data.tuiDedication.score], value: data.tuiDedication.value}]
      },
      {
        questionCode: 'ques-calpasp12-07', //How much did you resent the time, cost, or other demands of your therapy?
        answers: [{code: responseCodes[data.pcResentment.score], value: data.pcResentment.value}]
      },
      {
        questionCode: 'ques-calpasp12-08', //Did you feel that your therapist understood what you hoped to get out of this session?
        answers: [{code: responseCodes[data.wscGoals.score], value: data.wscGoals.value}]
      },
      {
        questionCode: 'ques-calpasp12-09', //How much did you find yourself thinking that therapy was not the best way to get help with your problems?
        answers: [{code: responseCodes[data.pcProblems.score], value: data.pcProblems.value}]
      },
      {
        questionCode: 'ques-calpasp12-10', //Did the treatment you received in this session match with your ideas about what helps people in therapy?
        answers: [{code: responseCodes[data.wscExpectations.score], value: data.wscExpectations.value}]
      },
      {
        questionCode: 'ques-calpasp12-11', //Did you have the impression that you were unable to deepen your understanding of what is bothering you?
        answers: [{code: responseCodes[data.pwcProblems.score], value: data.pwcProblems.value}]
      },
      {
        questionCode: 'ques-calpasp12-12', //How much did your therapist help you gain a deeper understanding of your problems?
        answers: [{code: responseCodes[data.tuiProblems.score], value: data.tuiProblems.value}]
      },
    ]

  };

  await canvasCreateQuestionnaire(questionnaireData).then((result) => {
    return result;
  })
  .catch(error => {
    console.log("Error with canvasCreateQuestGad7: ", error);
  });

};

const canvasCreateQuesGad7 = async (data: {
      patientId: string,
      patientCanvasId: string,
      providerId: string,
      providerCanvasId: string,
      timeStamp: Date,
      nervous: { value: string, score: number },
      alwaysWorried: { value: string, score: number },
      worriedTooMuch: { value: string, score: number },
      troubleRelaxing: { value: string, score: number },
      restless: { value: string, score: number },
      irritable: { value: string, score: number },
      afraid: { value: string, score: number },
      score: number,
      severity: string
}) => {
  
  let questionnaireCode = 'ques-gad7'; //must match questionnaire code in Canvas

  let responseCodes: any = {
    0: 'LA6568-5', // "Not at all"
    1:'LA6569-3', // "Several days"
    2:'LA6570-1', // "More than half the days"
    3: 'LA6571-9' // "Nearly every day"
  };

  let severityCodes: any = {
    'Normal': 'LA6626-1', 
    'Mild': 'LA6752-5', 
    'Moderate': 'LA6751-7', 
    'Severe': 'LA6750-9'   
  };

  let questionnaireData = {

    questionnaireCode: questionnaireCode, //must match questionnaire code in Canvas
    patientCanvasId: data.patientCanvasId,
    providerCanvasId: data.providerCanvasId,
    timeStamp: data.timeStamp,

    responses:[
      {
        questionCode: 'ques-gad7-00', //Date completed
        answers: [{code: 'ques-gad7-00-00', value: format(data.timeStamp, 'MM/dd/yyyy')}]
      },
      {
        questionCode: '70274-6', //Assessment score
        answers: [{code: '70274-6', value: data.score.toString()}]
      },
      {
        questionCode: '94025-4', //Score interpretation
        answers: [{code: severityCodes[data.severity], value: data.severity}]
      },      
      {
        questionCode: '69725-0', //Feeling nervous, anxious or on edge?
        answers: [{code: responseCodes[data.nervous.score], value: data.nervous.value}]
      },
      {
        questionCode: '68509-9', //Not being able to control or stop worrying?
        answers: [{code: responseCodes[data.alwaysWorried.score], value: data.alwaysWorried.value}]
      },
      {
        questionCode: '69733-4', //Worrying too much about different things?
        answers: [{code: responseCodes[data.worriedTooMuch.score], value: data.worriedTooMuch.value}]
      },
      {
        questionCode: '69734-2', //Trouble relaxing?
        answers: [{code: responseCodes[data.troubleRelaxing.score], value: data.troubleRelaxing.value}]
      },
      {
        questionCode: '69735-9', //Being so restless that it is hard to sit still?
        answers: [{code: responseCodes[data.restless.score], value: data.restless.value}]
      },
      {
        questionCode: '69689-8', //Becoming easily annoyed or irritable?
        answers: [{code: responseCodes[data.irritable.score], value: data.irritable.value}]
      },
      {
        questionCode: '69736-7', //Being afraid as if something awful might happen?
        answers: [{code: responseCodes[data.afraid.score], value: data.afraid.value}]
      }
    ]

  };

  await canvasCreateQuestionnaire(questionnaireData).then((result) => {
    return result;
  })
  .catch(error => {
    console.log("Error with canvasCreateQuestGad7: ", error);
  });

};

const canvasCreateQuesPhq9 = async (data: {
      patientId: string,
      patientCanvasId: string,
      providerId: string,
      providerCanvasId: string,
      timeStamp: Date,
      uninterested: { value: string, score: number }, 
      depressed: { value: string, score: number },
      sleepIssues: { value: string, score: number },
      lowEnergy: { value: string, score: number },
      eatingIssues: { value: string, score: number },
      feelingOfFailure: { value: string, score: number },
      lowFocus: { value: string, score: number },
      movementIssues: { value: string, score: number },
      selfHarmThoughts: { value: string, score: number },
      score: number,
      severity: string
}) => {
  
  let questionnaireCode = 'ques-phq9'; //must match questionnaire code in Canvas

  let responseCodes: any = {
    0: 'LA6568-5', // "Not at all"
    1:'LA6569-3', // "Several days"
    2:'LA6570-1', // "More than half the days"
    3: 'LA6571-9' // "Nearly every day"
  };

  let severityCodes: any = {
    'Normal': 'LA6626-1', 
    'Mild': 'LA6752-5', 
    'Moderate': 'LA6751-7', 
    'Moderately Severe': 'LA6750-9',
    'Severe': 'LA13958-6'   
  };

  let questionnaireData = {

    questionnaireCode: questionnaireCode, //must match questionnaire code in Canvas
    patientCanvasId: data.patientCanvasId,
    providerCanvasId: data.providerCanvasId,
    timeStamp: data.timeStamp,

    responses:[
      {
        questionCode: 'ques-phq9-00', //Date completed
        answers: [{code: 'ques-phq9-00-00', value: format(data.timeStamp, 'MM/dd/yyyy')}]
      },
      {
        questionCode: '44261-6', //Assessment score
        answers: [{code: '44261-6', value: data.score.toString()}]
      },
      {
        questionCode: '94023-9', //Score interpretation
        answers: [{code: severityCodes[data.severity], value: data.severity}]
      },      
      {
        questionCode: '44250-9', //Little interest or pleasure in doing things?
        answers: [{code: responseCodes[data.uninterested.score], value: data.uninterested.value}]
      },
      {
        questionCode: '44255-8', //Feeling down, depressed, or hopeless?
        answers: [{code: responseCodes[data.depressed.score], value: data.depressed.value}]
      },
      {
        questionCode: '44259-0', //Trouble falling or staying asleep, or sleeping too much?
        answers: [{code: responseCodes[data.sleepIssues.score], value: data.sleepIssues.value}]
      },
      {
        questionCode: '44254-1', //Feeling tired or having little energy?
        answers: [{code: responseCodes[data.lowEnergy.score], value: data.lowEnergy.value}]
      },
      {
        questionCode: '44251-7', //Poor appetite or overeating?
        answers: [{code: responseCodes[data.eatingIssues.score], value: data.eatingIssues.value}]
      },
      {
        questionCode: '44258-2', //Feeling bad about yourself-or that you are a failure or have let yourself or your family down?
        answers: [{code: responseCodes[data.feelingOfFailure.score], value: data.feelingOfFailure.value}]
      },
      {
        questionCode: '44252-5', //Trouble concentrating on things, such as reading the newspaper or watching television?
        answers: [{code: responseCodes[data.lowFocus.score], value: data.lowFocus.value}]
      },
      {
        questionCode: '44253-3', //Moving or speaking so slowly that other people could have noticed. Or the opposite-being so fidgety or restless that you have been moving around a lot more than usual?
        answers: [{code: responseCodes[data.movementIssues.score], value: data.movementIssues.value}]
      },
      {
        questionCode: '44260-8', //Thoughts that you would be better off dead, or of hurting yourself in some way?
        answers: [{code: responseCodes[data.selfHarmThoughts.score], value: data.selfHarmThoughts.value}]
      }
    ]

  };

  await canvasCreateQuestionnaire(questionnaireData).then((result) => {
    return result;
  })
  .catch(error => {
    console.log("Error with canvasCreateQuestPhq9: ", error);
  });

};

const canvasCreateQuesPhq15 = async (data: {
      patientId: string,
      patientCanvasId: string,
      providerId: string,
      providerCanvasId: string,
      timeStamp: Date,
      stomachPain: { value: string, score: number },
      backPain: { value: string, score: number },
      bodyPain: { value: string, score: number },
      sexPain: { value: string, score: number },
      headache: { value: string, score: number },
      chestPain: { value: string, score: number },
      dizziness: { value: string, score: number },
      faintingSpells: { value: string, score: number },
      heart: { value: string, score: number },
      breath: { value: string, score: number },
      digestion: { value: string, score: number },
      nausea: { value: string, score: number },
      tired: { value: string, score: number },
      sleep: { value: string, score: number },
      menstrualCramps: { value: string, score: number } | null,
      score: number,
      severity: string
}) => {
  
  let questionnaireCode = 'ques-phq15'; //must match questionnaire code in Canvas

  let responseCodes: any = {
    0: 'LA18334-5', // "Not bothered"
    1:'LA18335-2', // "Bothered a little"
    2:'LA18336-0', // "Bothered a lot"
  };

  let severityCodes: any = {
    'Normal': 'LA6626-1', 
    'Mild': 'LA6752-5', 
    'Moderate': 'LA6751-7', 
    'Severe': 'LA6750-9'   
  };

  let questionnaireData = {

    questionnaireCode: questionnaireCode, //must match questionnaire code in Canvas
    patientCanvasId: data.patientCanvasId,
    providerCanvasId: data.providerCanvasId,
    timeStamp: data.timeStamp,
    responses:[]

  };

  let responses = [
    {
      questionCode: 'ques-phq15-00', //Date completed
      answers: [{code: 'ques-phq15-00-00', value: format(data.timeStamp, 'MM/dd/yyyy')}]
    },
    {
      questionCode: '70273-8', //Assessment score
      answers: [{code: '70273-8', value: data.score.toString()}]
    },
    {
      questionCode: '94028-8', //Score interpretation
      answers: [{code: severityCodes[data.severity], value: data.severity}]
    },      
    {
      questionCode: '69671-6', //Stomach pain
      answers: [{code: responseCodes[data.stomachPain.score], value: data.stomachPain.value}]
    },
    {
      questionCode: '69672-4', //Back pain
      answers: [{code: responseCodes[data.backPain.score], value: data.backPain.value}]
    },
    {
      questionCode: '69673-2', //Pain in your arms, legs, or joints (knees, hips, etc.)
      answers: [{code: responseCodes[data.bodyPain.score], value: data.bodyPain.value}]
    },
    {
      questionCode: '69717-7', //Pain or problems during sexual intercourse
      answers: [{code: responseCodes[data.sexPain.score], value: data.sexPain.value}]
    },
    {
      questionCode: '69675-7', //Headaches
      answers: [{code: responseCodes[data.headache.score], value: data.headache.value}]
    },
    {
      questionCode: '69676-5', //Chest pain
      answers: [{code: responseCodes[data.chestPain.score], value: data.chestPain.value}]
    },
    {
      questionCode: '69677-3', //Dizziness
      answers: [{code: responseCodes[data.dizziness.score], value: data.dizziness.value}]
    },
    {
      questionCode: '69678-1', //Fainting spells
      answers: [{code: responseCodes[data.faintingSpells.score], value: data.faintingSpells.value}]
    },
    {
      questionCode: '69679-9', //Feeling your heart pound or race
      answers: [{code: responseCodes[data.heart.score], value: data.heart.value}]
    },
    {
      questionCode: '69680-7', //Shortness of breath
      answers: [{code: responseCodes[data.breath.score], value: data.breath.value}]
    },
    {
      questionCode: '69681-5', //Constipation, loose bowels, or diarrhea
      answers: [{code: responseCodes[data.digestion.score], value: data.digestion.value}]
    },
    {
      questionCode: '69682-3', //Nausea, gas, or indigestion
      answers: [{code: responseCodes[data.nausea.score], value: data.nausea.value}]
    },
    {
      questionCode: '69731-8', //Feeling tired or having low energy
      answers: [{code: responseCodes[data.tired.score], value: data.tired.value}]
    },
    {
      questionCode: '69732-6', //Trouble sleeping
      answers: [{code: responseCodes[data.sleep.score], value: data.sleep.value}]
    }
  ];

  //Add menstrual cramps question if it's there
  if (data.menstrualCramps) responses.push({
    questionCode: '69674-0', //Menstrual cramps or other problems with your periods
    answers: [{code: responseCodes[data.menstrualCramps.score], value: data.menstrualCramps.value}]
  });

  (questionnaireData as any).responses = responses;

  await canvasCreateQuestionnaire(questionnaireData).then((result) => {
    return result;
  })
  .catch(error => {
    console.log("Error with canvasCreateQuestPhq15: ", error);
  });

};

const canvasCreateQuesPhqAlcohol = async (data: {
      patientId: string,
      patientCanvasId: string,
      providerId: string,
      providerCanvasId: string,
      timeStamp: Date,
      drinkAlcohol: { value: string, score: number },
      ignoredDoctor: { value: string, score: number } | null,
      workingIntoxicated: { value: string, score: number } | null,
      missedResponsibilities: { value: string, score: number } | null,
      problemSocializing: { value: string, score: number } | null,
      drivingIntoxicated: { value: string, score: number } | null,
      score: number,
      severity: string
}) => {
  
  let questionnaireCode = 'ques-phq-alcohol'; //must match questionnaire code in Canvas

  let responseCodes: any = {
    0: 'LA32-8', // "No"
    1:'LA33-6', // "Yes"
  };

  let severityCodes: any = {
    'Normal': '1129201000000101', 
    'Alcohol Abuse': '15167005'
  };

  let questionnaireData = {

    questionnaireCode: questionnaireCode, //must match questionnaire code in Canvas
    patientCanvasId: data.patientCanvasId,
    providerCanvasId: data.providerCanvasId,
    timeStamp: data.timeStamp,
    responses: []

  };

  //Build array of responses for questionnaire data
  let responses = [
    {
      questionCode: 'ques-phq-alcohol-00', //Date completed
      answers: [{code: 'ques-phq-alcohol-00-00', value: format(data.timeStamp, 'MM/dd/yyyy')}]
    },
    {
      questionCode: 'ques-phq-alcohol-01', //Score interpretation
      answers: [{code: severityCodes[data.severity], value: data.severity}]
    },      
    {
      questionCode: '69721-9', //Do you ever drink alcohol (including beer or wine)?
      answers: [{code: responseCodes[data.drinkAlcohol.score], value: data.drinkAlcohol.value}]
    }
  ];

  if (data.ignoredDoctor) responses.push({
    questionCode: '69697-1', //You drank alcohol even though a doctor suggested that you stop drinking because of a problem with your health.
    answers: [{code: responseCodes[data.ignoredDoctor.score], value: data.ignoredDoctor.value}]
  });
  if (data.workingIntoxicated) responses.push({
    questionCode: '69698-9', //You drank alcohol, were high from alcohol, or hung over while you were working, going to school, or taking care of children or other responsibilities.
    answers: [{code: responseCodes[data.workingIntoxicated.score], value: data.workingIntoxicated.value}]
  });
  if (data.missedResponsibilities) responses.push({
    questionCode: '69699-7', //You missed or were late for work, school, or other activities because you were drinking or hung over.
    answers: [{code: responseCodes[data.missedResponsibilities.score], value: data.missedResponsibilities.value}]
  });
  if (data.problemSocializing) responses.push({
    questionCode: '69700-3', //You had a problem getting along with other people while you were drinking.
    answers: [{code: responseCodes[data.problemSocializing.score], value: data.problemSocializing.value}]
  });
  if (data.drivingIntoxicated) responses.push({
    questionCode: '69701-1', //You drove a car after having several drinks or after drinking too.
    answers: [{code: responseCodes[data.drivingIntoxicated.score], value: data.drivingIntoxicated.value}]
  }); 

  (questionnaireData as any).responses = responses;

  await canvasCreateQuestionnaire(questionnaireData).then((result) => {
    return result;
  })
  .catch(error => {
    console.log("Error with canvasCreateQuestPhqAlcohol: ", error);
  });

};

const canvasCreateQuesPhqEating = async (data: {
      patientId: string,
      patientCanvasId: string,
      providerId: string,
      providerCanvasId: string,
      timeStamp: Date,
      control: { value: string, score: number }, 
      bingeEating: { value: string, score: number } | null,
      bingeFrequency: { value: string, score: number } | null,
      laxatives: { value: string, score: number } | null,
      exercise: { value: string, score: number } | null,
      fasted: { value: string, score: number } | null,
      vomit: { value: string, score: number } | null,
      bulFrequency: { value: string, score: number } | null,
      score: number,
      severity: string
}) => {
  
  let questionnaireCode = 'ques-phq-eating'; //must match questionnaire code in Canvas

  let responseCodes: any = {
    0: 'LA32-8', // "No"
    1:'LA33-6', // "Yes"
  };

  let severityCodes: any = {
    'Eating Normal': '289149001', 
    'Bulimia Nervosa': '78004001',
    'Binge Eating Disorder': '439960005',
  };

  let questionnaireData = {

    questionnaireCode: questionnaireCode, //must match questionnaire code in Canvas
    patientCanvasId: data.patientCanvasId,
    providerCanvasId: data.providerCanvasId,
    timeStamp: data.timeStamp,
    responses: []

  };

  //Build array of responses for questionnaire data
  let responses = [
    {
      questionCode: 'ques-phq-eating-00', //Date completed
      answers: [{code: 'ques-phq-eating-00-00', value: format(data.timeStamp, 'MM/dd/yyyy')}]
    },
    {
      questionCode: 'ques-phq-eating-01', //Score interpretation
      answers: [{code: severityCodes[data.severity], value: data.severity}]
    },      
    {
      questionCode: '69690-6', //Do you often feel that you can't control what or how much you eat?
      answers: [{code: responseCodes[data.control.score], value: data.control.value}]
    }
  ];

  if (data.bingeEating) responses.push({
    questionCode: '69691-4', //Do you often eat, within any 2-hour period, what most people would regard as an unusually large amount of food?
    answers: [{code: responseCodes[data.bingeEating.score], value: data.bingeEating.value}]
  });
  if (data.bingeFrequency) responses.push({
    questionCode: '69692-2', //Has this been as often, on average, as twice a week for the last 3 months?
    answers: [{code: responseCodes[data.bingeFrequency.score], value: data.bingeFrequency.value}]
  });
  if (data.vomit) responses.push({
    questionCode: '69693-0', //Made yourself vomit?
    answers: [{code: responseCodes[data.vomit.score], value: data.vomit.value}]
  });
  if (data.laxatives) responses.push({
    questionCode: '69694-8', //Took more than twice the recommended dose of laxatives?
    answers: [{code: responseCodes[data.laxatives.score], value: data.laxatives.value}]
  });
  if (data.fasted) responses.push({
    questionCode: '69695-5', //Fasted -- not eaten anything at all for at least 24 hours?
    answers: [{code: responseCodes[data.fasted.score], value: data.fasted.value}]
  });
  if (data.exercise) responses.push({
    questionCode: '69696-3', //Exercised for more than an hour specifically to avoid gaining weight after binge eating?
    answers: [{code: responseCodes[data.exercise.score], value: data.exercise.value}]
  }); 
  if (data.bulFrequency) responses.push({
    questionCode: '69720-1', //If you checked "YES" to any of these ways of avoiding gaining weight, were any as often, on average, as twice a week?
    answers: [{code: responseCodes[data.bulFrequency.score], value: data.bulFrequency.value}]
  }); 

  (questionnaireData as any).responses = responses;

  await canvasCreateQuestionnaire(questionnaireData).then((result) => {
    return result;
  })
  .catch(error => {
    console.log("Error with canvasCreateQuestPhqEating: ", error);
  });

};

const canvasCreateQuesPreIntake = async (data: {
  patientId: string,
  patientCanvasId: string,
  providerId: string,
  providerCanvasId: string,
  timeStamp: Date,
  reasonsSeekingTherapy: string,
  therapyGoals: string,
  preferredName: string,
  localAddress: string,
  emergencyContactName: string,
  emergencyContactNumber: string,
  primaryPhysicianName: string,
  primaryPhysicianNumber: string,
  psychiatristName: string,
  psychiatristNumber: string,
  ongoingPsychiatricMedication: boolean,
  ongoingPsychiatricMedicationDetails: string,
  psychiatricMedicationTakenInPast: boolean,
  pastPsychiatricMedicationDetails: string,
  chronicHealthCondition: boolean,
  chronicHealthConditionDetails: string,
  seriousAccidentOrInjury: boolean,
  seriousAccidentOrInjuryDetails: string,
  diagnosedWithMentalHealthCondition: boolean,
  diagnosedMentalHealthDetails: string,
  takenPastMentalHealthTherapy: boolean,
  reasonForPastMentalHealthTherapy: string,
  hospitalizedForPsychiatricReason: boolean,
  whenHospitalizedForPsychiatricTherapy: string,
  maritalStatus: string,
  numberOfChildren: string,
  familyHistoryOfMentalHealth: boolean,
  familyHistoryOfMentalHealthDetails: string,
  employmentStatus: string,
  employmentDetails: string,
  jobSatisfaction: string,
  educationLevel: string
}) => {

let questionnaireCode = 'ques-preintake'; //must match questionnaire code in Canvas

let questionnaireData = {

questionnaireCode: questionnaireCode, //must match questionnaire code in Canvas
patientCanvasId: data.patientCanvasId,
providerCanvasId: data.providerCanvasId,
timeStamp: data.timeStamp,

responses:[
  {
    questionCode: 'ques-preintake-00', //What are some of the reasons you seeking therapy now?
    answers: [{code: 'ques-preintake-00-00', value: data.reasonsSeekingTherapy ? data.reasonsSeekingTherapy : ''}]
  },
  {
    questionCode: 'ques-preintake-01', //What do you hope to gain from your therapy experience?
    answers: [{code: 'ques-preintake-01-00', value: data.therapyGoals ? data.therapyGoals : ''}]
  },
  {
    questionCode: 'ques-preintake-02', //Preferred name
    answers: [{code: 'ques-preintake-02-00', value: data.preferredName ? data.preferredName : ''}]
  },
  {
    questionCode: 'ques-preintake-03', //Local Address
    answers: [{code: 'ques-preintake-03-00', value: data.localAddress ? data.localAddress : ''}]
  },
  {
    questionCode: 'ques-preintake-04', //Emergency Contact Name
    answers: [{code: 'ques-preintake-04-00', value: data.emergencyContactName ? data.emergencyContactName : ''}]
  },
  {
    questionCode: 'ques-preintake-05', //Emergency Contact number
    answers: [{code: 'ques-preintake-05-00', value: data.emergencyContactNumber ? data.emergencyContactNumber : ''}]
  },
  {
    questionCode: 'ques-preintake-06', //Name of primary care physician
    answers: [{code: 'ques-preintake-06-00', value: data.primaryPhysicianName ? data.primaryPhysicianName : 'NA'}]
  },
  {
    questionCode: 'ques-preintake-07', //Number of primary care physician
    answers: [{code: 'ques-preintake-07-00', value: data.primaryPhysicianNumber ? data.primaryPhysicianNumber : 'NA'}]
  },
  {
    questionCode: 'ques-preintake-08', //Name of psychiatrist
    answers: [{code: 'ques-preintake-08-00', value: data.psychiatristName ? data.psychiatristName : 'NA'}]
  },
  {
    questionCode: 'ques-preintake-09', //Number of psychiatrist
    answers: [{code: 'ques-preintake-09-00', value: data.psychiatristNumber ? data.psychiatristNumber : 'NA'}]
  },
  {
    questionCode: 'ques-preintake-10', //Are you currently taking psychiatric medication?
    answers: [{
      code: data.ongoingPsychiatricMedication ? 'ques-preintake-10-00' : 'ques-preintake-10-01', 
      value: data.ongoingPsychiatricMedication ? 'Yes' : 'No'
    }]
  },
  {
    questionCode: 'ques-preintake-11', //If yes, please list the medication and dosage.
    answers: [{code: 'ques-preintake-11-00', value: data.ongoingPsychiatricMedicationDetails ? data.ongoingPsychiatricMedicationDetails : ''}]
  },
  {
    questionCode: 'ques-preintake-12', //Have you ever taken psychiatric medication?
    answers: [{
      code: data.psychiatricMedicationTakenInPast ? 'ques-preintake-12-00' : 'ques-preintake-12-01', 
      value: data.psychiatricMedicationTakenInPast ? 'Yes' : 'No'
    }]
  },
  {
    questionCode: 'ques-preintake-13', //If yes, please list the medication and dosage.
    answers: [{code: 'ques-preintake-13-00', value: data.pastPsychiatricMedicationDetails ? data.pastPsychiatricMedicationDetails : ''}]
  },
  {
    questionCode: 'ques-preintake-14', //Are you currently managing a chronic health condition?
    answers: [{
      code: data.chronicHealthCondition ? 'ques-preintake-14-00' : 'ques-preintake-14-01', 
      value: data.chronicHealthCondition ? 'Yes' : 'No'
    }]
  },
  {
    questionCode: 'ques-preintake-15', //If yes, please describe the condition.
    answers: [{code: 'ques-preintake-15-00', value: data.chronicHealthConditionDetails ? data.chronicHealthConditionDetails : ''}]
  },
  {
    questionCode: 'ques-preintake-16', //Have you ever been in a serious accident or been seriously injured?
    answers: [{
      code: data.seriousAccidentOrInjury ? 'ques-preintake-16-00' : 'ques-preintake-16-01', 
      value: data.seriousAccidentOrInjury ? 'Yes' : 'No'
    }]
  },
  {
    questionCode: 'ques-preintake-17', //If yes, please describe.
    answers: [{code: 'ques-preintake-17-00', value: data.seriousAccidentOrInjuryDetails ? data.seriousAccidentOrInjuryDetails : ''}]
  },
  {
    questionCode: 'ques-preintake-18', //Have you ever been diagnosed with a mental health condition?
    answers: [{
      code: data.diagnosedWithMentalHealthCondition ? 'ques-preintake-18-00' : 'ques-preintake-18-01', 
      value: data.diagnosedWithMentalHealthCondition ? 'Yes' : 'No'
    }]
  },
  {
    questionCode: 'ques-preintake-19', //If yes, please describe the condition.
    answers: [{code: 'ques-preintake-19-00', value: data.diagnosedMentalHealthDetails ? data.diagnosedMentalHealthDetails : ''}]
  },
  {
    questionCode: 'ques-preintake-20', //Have you ever been in therapy in the past??
    answers: [{
      code: data.takenPastMentalHealthTherapy ? 'ques-preintake-20-00' : 'ques-preintake-20-01', 
      value: data.takenPastMentalHealthTherapy ? 'Yes' : 'No'
    }]
  },
  {
    questionCode: 'ques-preintake-21', //If yes, please describe the reason for seeking treatment.
    answers: [{code: 'ques-preintake-21-00', value: data.reasonForPastMentalHealthTherapy ? data.reasonForPastMentalHealthTherapy : ''}]
  },
  {
    questionCode: 'ques-preintake-22', //Have you ever been hospitalized for a psychiatric reason?
    answers: [{
      code: data.hospitalizedForPsychiatricReason ? 'ques-preintake-22-00' : 'ques-preintake-22-01', 
      value: data.hospitalizedForPsychiatricReason ? 'Yes' : 'No'
    }]
  },
  {
    questionCode: 'ques-preintake-23', //If yes, how recently?
    answers: [{code: 'ques-preintake-23-00', value: data.whenHospitalizedForPsychiatricTherapy ? data.whenHospitalizedForPsychiatricTherapy : ''}]
  },
  {
    questionCode: 'ques-preintake-24', //Marital status
    answers: [{
      code: 
        data.maritalStatus == 'Never married-Single' ? 'ques-preintake-24-00' 
        : data.maritalStatus == 'Never married-In relationship' ? 'ques-preintake-24-01'
        : data.maritalStatus == 'Married' ? 'ques-preintake-24-02' 
        : data.maritalStatus == 'Divorced' ? 'ques-preintake-24-03' 
        : data.maritalStatus == 'Separated' ? 'ques-preintake-24-04' 
        : data.maritalStatus == 'Widowed' ? 'ques-preintake-24-05'
        : '',
      value: data.maritalStatus
    }]
  },
  {
    questionCode: 'ques-preintake-25', //Number of children
    answers: [{code: 'ques-preintake-25-00', value: data.numberOfChildren}]
  },
  {
    questionCode: 'ques-preintake-26', //Do you have a family history of mental health conditions?
    answers: [{
      code: data.familyHistoryOfMentalHealth ? 'ques-preintake-26-00' : 'ques-preintake-26-01', 
      value: data.familyHistoryOfMentalHealth ? 'Yes' : 'No'
    }]
  },
  {
    questionCode: 'ques-preintake-27', //If yes, please describe the conditions.
    answers: [{code: 'ques-preintake-27-00', value: data.familyHistoryOfMentalHealthDetails ? data.familyHistoryOfMentalHealthDetails : ''}]
  },
  {
    questionCode: 'ques-preintake-28', //Employment status
    answers: [{
      code: 
        data.employmentStatus == 'Employed-part-time' ? 'ques-preintake-28-00' 
        : data.employmentStatus == 'Employed-full-time' ? 'ques-preintake-28-01'
        : data.employmentStatus == 'Unemployed' ? 'ques-preintake-28-02' 
        : data.employmentStatus == 'Disability/leave status' ? 'ques-preintake-28-03' 
        : '',
      value: data.employmentStatus
    }]
  },
  {
    questionCode: 'ques-preintake-29', //If employed, what do you do for work?
    answers: [{code: 'ques-preintake-29-00', value: data.employmentDetails ? data.employmentDetails : 'NA'}]
  },
  {
    questionCode: 'ques-preintake-30', //How satified are you with your job?
    answers: [{
      code: 
        data.jobSatisfaction == 'Very satisfied' ? 'ques-preintake-30-00' 
        : data.jobSatisfaction == 'Somewhat satisfied' ? 'ques-preintake-30-01'
        : data.jobSatisfaction == 'Neutral' ? 'ques-preintake-30-02' 
        : data.jobSatisfaction == 'Disatisfied' ? 'ques-preintake-30-03' 
        : data.jobSatisfaction == 'Very disatisfied' ? 'ques-preintake-30-04' 
        : 'ques-preintake-30-05',
      value: data.jobSatisfaction ? data.jobSatisfaction : 'NA'
    }]
  },
  {
    questionCode: 'ques-preintake-31', //Highest level of education?
    answers: [{
      code: 
        data.educationLevel == 'Below 8' ? 'ques-preintake-31-00' 
        : data.educationLevel == '9' ? 'ques-preintake-31-01'
        : data.educationLevel == '10' ? 'ques-preintake-31-02' 
        : data.educationLevel == '11' ? 'ques-preintake-31-03' 
        : data.educationLevel == '12' ? 'ques-preintake-31-04' 
        : data.educationLevel == 'Some college' ? 'ques-preintake-31-05' 
        : data.educationLevel == 'Graduated college' ? 'ques-preintake-31-06' 
        : data.educationLevel == "Master's or professional degree" ? 'ques-preintake-31-07' 
        : data.educationLevel == 'Doctorate' ? 'ques-preintake-31-08' 
        : '',
      value: data.educationLevel
    }]
  },
]

};

await canvasCreateQuestionnaire(questionnaireData).then((result) => {
return result;
})
.catch(error => {
console.log("Error with canvasCreateQuestGad7: ", error);
});

};

export {
  canvasCreateQuestionnaire,
  canvasCreateQuesCalpas12,
  canvasCreateQuesGad7,
  canvasCreateQuesPhq9,
  canvasCreateQuesPhq15,
  canvasCreateQuesPhqAlcohol,
  canvasCreateQuesPhqEating,
  canvasCreateQuesPreIntake
};