import React from 'react';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import classes from './PatientNextAppointmentPendingCard.module.css';

interface Props {
}

const PatientNextAppointmentPendingCard: React.FC<Props> = props => {

  return(
    <div className={classes.containerPatientNextAppointmentPendingCard}>
      <EventRepeatIcon fontSize="large" color="inherit" />
      <p>Your provider will book your next appointment shortly.</p>
    </div>
  );

};

export default PatientNextAppointmentPendingCard;
