import React, { useState } from 'react';

import { usePatientData } from "../../../../../../../hooks/ContextPatientData/ContextPatientData";
import useFetchCoverageData from "../../../../../../../hooks/useFetchCoverageData/useFetchCoverageData";
import ProviderProfile from '../../../../../StartTherapy/SelectProvider/ProviderProfiles/ProviderProfile/ProviderProfile';
import ProviderProfileBookSession from '../../../../../StartTherapy/SelectProvider/ProviderProfiles/ProviderProfileBookSession/ProviderProfileBookSession';
import PatientScheduleConsultation from '../../PatientScheduleConsultation/PatientScheduleConsultation';
import PatientScheduleSession from '../../PatientScheduleSession/PatientScheduleSession';

import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';

import '../../../../../../../globalCss/globalCssMuiButton.css';
import classes from './FindProviderProfile.module.css';

interface Props {
  providerData: {
    canvasId: string;
    id: string;
    firstName: string;
    lastName: string;
    pronouns: string;
    picUrl: string;
    dob: Date;
    ethnicities: Array<string>;
    licenseType: string;
    specialties: Array<string>;
    clientPopulations: Array<string>;
    languages: Array<string>;
    aboutMe: string;
    aboutClients: string;
    myApproach: string;
    myBeliefs: string;
  };
  handleCloseProviderProfile: React.MouseEventHandler;
  handleCloseFindProvider: React.MouseEventHandler;
  showBackBtn?: boolean;
  handleScrollTop: Function;
}

const FindProviderProfile: React.FC<Props> = props => {

  const [showScheduleSession, setShowScheduleSession] = useState(false);
  const [showScheduleConsultation, setShowScheduleConsultation] = useState(false);
  
  // const [openDialog, setOpenDialog] = useState(false);

  const patientData = usePatientData();
  const coverageData = useFetchCoverageData();

  const handleOpenScheduleConsultation = () => {
    setShowScheduleConsultation(true);
    props.handleScrollTop();
  };

  const handleCloseScheduleConsultation = () => {
    setShowScheduleConsultation(false);
    props.handleScrollTop();
  };

    const handleOpenScheduleSession = () => {
    setShowScheduleSession(true);
    props.handleScrollTop();
  };

  const handleCloseScheduleSession = () => {
    setShowScheduleSession(false);
    props.handleScrollTop();
  };


  if (!showScheduleConsultation && !showScheduleSession) {
    return(
      <div className={classes.containerFindProviderProfile}>

        <IconButton onClick={props.handleCloseProviderProfile} className={classes.closeBtn} color="inherit" aria-label="close">
          {props.showBackBtn ? <ArrowBackRoundedIcon /> : <CloseRoundedIcon />}
        </IconButton>
        <ProviderProfile 
          provider={props.providerData} 
          coverageData={coverageData}
          patientGoals={patientData.therapyGoals}
          isPatientLgbtq={patientData.isLgbtq}
          loading={props.providerData ? false : true} 
          showRate 
        />
        {props.providerData ?
          <ProviderProfileBookSession
            providerData={props.providerData}
            openScheduleSession={handleOpenScheduleSession}
            openScheduleConsultation={handleOpenScheduleConsultation}
            coverageData={coverageData}
          />   
        : null}

      </div>
    );
  }

  else if (showScheduleConsultation) {
    return (
      <PatientScheduleConsultation 
        providerData={props.providerData}
        handleCloseScheduleConsultation={handleCloseScheduleConsultation}
        handleCloseFindProvider={props.handleCloseFindProvider}
        showBackBtn
      />
    );
  }

  else if (showScheduleSession) {
    return (
      <PatientScheduleSession
        providerData={props.providerData}
        closeScheduleSession={handleCloseScheduleSession}
        closeFindProvider={props.handleCloseFindProvider}
        handleScrollTop={props.handleScrollTop}
        showBackBtn
      />
    );
  }

  else {
    return null;
  }

};

export default FindProviderProfile;