import React, { useState, useEffect } from 'react';

import { usePatientData } from '../../../hooks/ContextPatientData/ContextPatientData';
import useFetchProviderData from '../../../hooks/useFetchProviderData/useFetchProviderData';
import useFetchPatientAppointments from '../../../hooks/useFetchPatientAppointments/useFetchPatientAppointments';
import useWindowDimensions from '../../../hooks/useWindowDimensions/useWindowDimensions';

import NavBarPatientPortal from './NavBarPatientPortal/NavBarPatientPortal';
import PatientPortalHome from './PatientPortalHome/PatientPortalHome';
import PatientPortalAppointments from './PatientPortalAppointments/PatientPortalAppointments';
import PatientPortalInbox from './PatientPortalInbox/PatientPortalInbox';
import PatientPortalSettings from './PatientPortalSettings/PatientPortalSettings';
import SwipeableViews from 'react-swipeable-views';
import classes from './PatientPortal.module.css'

import landscapePng1 from '../../../assets/png/landscape01.png';
import landscapePng2 from '../../../assets/png/landscape02.png';
import landscapePng3 from '../../../assets/png/landscape03.png';
import landscapePng4 from '../../../assets/png/landscape04.png';
import landscapePng5 from '../../../assets/png/landscape05.png';

interface Props {
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
          <div>{children}</div>
      )}
    </div>
  );
};

const PatientPortal: React.FC<Props> = props => {

  const patientData = usePatientData();
  const appointments = useFetchPatientAppointments();
  const providerData = useFetchProviderData();

  const [tabIndex, setTabIndex] = useState(0);
  const [disableSwipeableViews, setDisableSwipeableViews] = useState(false);

  const [landscapeUrl, setLandscapeUrl] = useState('');

  useEffect(() => {
    const landscapes = [landscapePng1, landscapePng2, landscapePng3, landscapePng4, landscapePng5];
    setLandscapeUrl(landscapes[Math.floor(Math.random()*landscapes.length)]);
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleSwipe = (index: number) => {
    setTabIndex(index);
  };

  const handleDisableSwipe = () => {
    setDisableSwipeableViews(true);
  };

  const handleEnableSwipe = () => {
    setDisableSwipeableViews(false);
  };

  const [showNavBar, setShowNavBar] = useState(true);
  
  const handleShowNavBar = () => {
    setShowNavBar(true);
  };

  const handleHideNavBar = () => {
    setShowNavBar(false);
  };

  const height = useWindowDimensions().height;

  return(
    <React.Fragment>
      {showNavBar ? 
        <NavBarPatientPortal
          tabIndex={tabIndex}
          handleTabChange={handleTabChange}
          showInboxBadge={patientData && patientData.alerts.newMessage}
        />
      : null
      }
      <div className={classes.container}>
        <SwipeableViews
          index={tabIndex}
          onChangeIndex={handleSwipe}
          disabled={disableSwipeableViews}
          containerStyle={{
              transition: 'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s',
              height: height,
              position: 'relative'
          }}
        >

          <TabPanel key={"tab-panel-provider"} value={tabIndex} index={0}>
            <PatientPortalHome 
              handleShowNavBar={handleShowNavBar}
              handleHideNavBar={handleHideNavBar} 
              handleEnableSwipe={handleEnableSwipe}
              handleDisableSwipe={handleDisableSwipe}
              landscapeUrl={landscapeUrl}
            />
          </TabPanel>

          <TabPanel key={"tab-panel-appointments"} value={tabIndex} index={1}>
            <PatientPortalAppointments 
              handleShowNavBar={handleShowNavBar}
              handleHideNavBar={handleHideNavBar} 
              handleEnableSwipe={handleEnableSwipe}
              handleDisableSwipe={handleDisableSwipe}
            />
          </TabPanel>

          <TabPanel key={"tab-panel-inbox"} value={tabIndex} index={2}>
            <PatientPortalInbox
              handleShowNavBar={handleShowNavBar}
              handleHideNavBar={handleHideNavBar} 
              handleEnableSwipe={handleEnableSwipe}
              handleDisableSwipe={handleDisableSwipe}
            />
          </TabPanel>

          <TabPanel key={"tab-panel-settings"} value={tabIndex} index={3}>
            <PatientPortalSettings
              handleShowNavBar={handleShowNavBar}
              handleHideNavBar={handleHideNavBar} 
              handleEnableSwipe={handleEnableSwipe}
              handleDisableSwipe={handleDisableSwipe}
            />
          </TabPanel>

        </SwipeableViews>
      </div>
    </React.Fragment>
  );
};

export default PatientPortal;

