// Creates a new patient in Canvas and return new Canvas ID for that patient
const canvasAddPatient = async (patientData) => {
  //console.log("Calling Canvas Add Patient with: ", patientData);
  // Check if there's a valid address
  let street = "";
  if (patientData.street !== undefined || patientData.street !== null) {
    street = patientData.street;
  }
  let city = "";
  if (patientData.city !== undefined || patientData.city !== null) {
    city = patientData.city;
  }
  let zip = "";
  if (patientData.zip !== undefined || patientData.zip !== null) {
    zip = patientData.zip;
  }
  let state = "";
  if (patientData.state !== undefined || patientData.state !== null) {
    state = patientData.state;
  }
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = JSON.stringify({
      id: patientData.id,
      dob: patientData.dob,
      phone: patientData.phone,
      email: patientData.email,
      firstName: patientData.firstName,
      lastName: patientData.lastName,
      birthSex: patientData.gender,
      isLgbtq: patientData.isLgbtq,
      ethnicities: patientData.ethnicities,
      religion: patientData.religion,
      therapyGoals: patientData.therapyGoals,
      coverageId: patientData.coverageId,
      address: "",
      street: street,
      city: city,
      state: state,
      zip: zip,

  });

  return fetch(server + "/canvas-create-patient", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("canvasAddPatient result: ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasAddPatient error: ", error);
      throw error;
    });
};

// Creates a new patient in Canvas and return new Canvas ID for that patient
const canvasUpdatePatient = async (patientData) => {
  //console.log("Calling Canvas Add Patient with: ", patientData);
  // Check if there's a valid address
  let street = "";
  if (patientData.street !== undefined || patientData.street !== null) {
    street = patientData.street;
  }
  let city = "";
  if (patientData.city !== undefined || patientData.city !== null) {
    city = patientData.city;
  }
  let zip = "";
  if (patientData.zip !== undefined || patientData.zip !== null) {
    zip = patientData.zip;
  }
  let state = "";
  if (patientData.state !== undefined || patientData.state !== null) {
    state = patientData.state;
  }

  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = JSON.stringify({
      id: patientData.id,
      canvasId: patientData.canvasId,
      dob: patientData.dob,
      phone: patientData.phone,
      email: patientData.email,
      firstName: patientData.firstName,
      lastName: patientData.lastName,
      birthSex: patientData.gender,
      isLgbtq: patientData.isLgbtq,
      ethnicities: patientData.ethnicities,
      religion: patientData.religion,
      therapyGoals: patientData.therapyGoals,
      address: "",
      street: street,
      state: state,
      city: city,
      zip: zip
  });

  return fetch(server + "/canvas-update-patient", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("canvasUpdatePatient result: ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasUpdatePatient error: ", error);
      throw error;
    });
};

const canvasCreateAppointment = async (appointmentData) => {
  //console.log("Calling Canvas Create Appointment with: ", appointmentData);
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = JSON.stringify({
      startTime: appointmentData.start,
      endTime: appointmentData.end,
      practitionerId: appointmentData.providerCanvasId,
      patientId: appointmentData.patientCanvasId,
      service: appointmentData.service,
      meetingId: appointmentData.meetingId,
      newTherapyTime: appointmentData.newTherapyTime
  });

  return fetch(server + "/canvas-create-appointment", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("canvasCreateAppointment result: ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasCreateAppointment error: ", error);
      throw error;
    });
};

const canvasUpdateAppointment = async (appointmentData) => {
  // console.log("Calling Canvas Update Appointment with: ", appointmentData);
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = JSON.stringify({
      appointmentId: appointmentData.canvasId,
      startTime: appointmentData.start,
      endTime: appointmentData.end,
      practitionerId: appointmentData.providerCanvasId,
      patientId: appointmentData.patientCanvasId,
      status: appointmentData.status,
      service: appointmentData.service,
      meetingId: appointmentData.meetingId,
      newTherapyTime: appointmentData.newTherapyTime
  });

  return fetch(server + "/canvas-update-appointment", {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("canvasUpdateAppointment result: ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasUpdateAppointment error: ", error);
      throw error;
    });
};

const canvasCreateNextPatientAppointment = async (appointmentData) => {
  //console.log("Calling Canvas Create Appointment with: ", appointmentData);
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = JSON.stringify({
      appointment: appointmentData,
  });

  return fetch(server + "/canvas-create-next-patient-appointment", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("canvasCreateNextPatientAppointment result: ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasCreateNextPatientAppointment error: ", error);
      throw error;
    });
};

const canvasGetProviderSlots = async (providerId, duration, days) => {

  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = JSON.stringify({
      providerId: providerId,
      duration: duration,
      days: days
  });

  return await fetch(server + "/canvas-get-availability", {
  //return await fetch("http://e4f2-98-207-203-38.ngrok.io" + "/canvas-create-appointment", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => {throw new Error(error)})
      else  
        return response.json();
    })
    .then(result => {
      //console.log("canvasGetProviderSlots result : ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasGetProviderSlots error: ", error);
      throw error;
    });
};

const canvasGetPatientMessages = async (patientCanvasId, providerCanvasId) => {
  //console.log("Get messages for: Patient ID: ", patientCanvasId, "    Provider Id: ", providerCanvasId);
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = JSON.stringify({
      patientId: patientCanvasId,
      providerId: providerCanvasId
  });

  return await fetch(server + "/canvas-get-patient-messages", {
    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => {throw new Error(error)})
      else  
        return response.json();
    })
    .then(result => {
      //console.log("Got result! : ", result);
      let messages = [];
      result.messages.forEach(message => {
        if (message.resource.payload) 
          messages.push(message);
      });
      return {messages};
    })
    .catch(error => {
      console.log("Error with calling Canvas API for Messages: ", error);
    });
};

const canvasCreatePatientMessage = async (patientCanvasId, providerCanvasId, message) => {
  //console.log("Calling Canvas Create Patient Message with: ", message);
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = JSON.stringify({
      patientId: patientCanvasId,
      providerId: providerCanvasId,
      payloadString: message
  });

  return await fetch(server + "/canvas-create-patient-message", {

    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => {throw new Error(error)})
      else  
        return response.json();
    })
    .then(result => {
      //console.log("Got result! : ", result);
      return result;
    })
    .catch(error => {
      console.log("Error with calling Canvas API Create Patient Message: ", error);
    });
};

const canvasCreateTask = async (patientCanvasId, providerCanvasId, appointmentCanvasId, appointmentDate, description, note) => {
  
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = {
      patientId: patientCanvasId,
      providerId: providerCanvasId,
      appointmentId: appointmentCanvasId,
      description: description,
      dueDate: appointmentDate
  };
  if (note) {
    reqBody.note = note;
  }

  return fetch(server + "/canvas-create-task", {

    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: JSON.stringify(reqBody)
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("canvasCreateTask result: ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasCreateTask error: ", error);
      throw error;
    });
};

const canvasCloseTaskByAppointment = async (patientCanvasId, providerCanvasId, appointmentCanvasId) => {
  
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = {
      patientId: patientCanvasId,
      providerId: providerCanvasId,
      appointmentId: appointmentCanvasId,
      canceled: true
  };

  return fetch(server + "/canvas-complete-task-by-appointment", {

    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: JSON.stringify(reqBody)
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("canvasCloseTaskByAppointment result: ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasCloseTaskByAppointment  error: ", error);
      throw error;
    });
};

const canvasCloseTaskByPatient = async (patientCanvasId, providerCanvasId) => {
  
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = {
      patientId: patientCanvasId,
      providerId: providerCanvasId,
      canceled: true
  };

  return fetch(server + "/canvas-complete-task-by-patient", {

    method: "post",
    headers: {
      "Content-Type": "application/json", 
      "Access-Control-Allow-Origin": "*"
    },
    body: JSON.stringify(reqBody)
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      //console.log("canvasCloseTaskByPatient result: ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasCloseTaskByPatient  error: ", error);
      throw error;
    });
};

// Creates a new patient in Canvas and return new Canvas ID for that patient
const canvasCreateCoverage = async (coverageData) => {
  //console.log("Calling Canvas Add Patient with: ", patientData);
  const server = process.env.REACT_APP_SERVERURL;
  /* MOCK DATA
  reqBody = {
    subscriberId: "e4044021b9444992822827546cc7e31c", // max2conversion@dispostable.com
    patientId: "e4044021b9444992822827546cc7e31c",
    relationship: "self",
    periodStart: Date,
    periodEnd: Date,
    payerId: "",
    payerName: ""
  }*/
  const reqBody = JSON.stringify({
      subscriberId: coverageData.subscriberId,
      patientId: coverageData.patientId,
      relationship: coverageData.relationship,
      periodStart: coverageData.periodStart,
      periodEnd: coverageData.periodEnd,
      payerId: coverageData.payerId,
      payerName: coverageData.payerName
  });

  return fetch(server + "/canvas-create-coverage", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      return result;
    })
    .catch(error => {
      console.log("canvasCreateCoverage error: ", error);
      throw error;
    });
};

// Creates a new Eligibility Request in Canvas
const canvasCreateEligibilityRequest = async (eligibilityData) => {
    console.log("Calling Canvas Eligibility Req with: ", eligibilityData);
    const server = process.env.REACT_APP_SERVERURL;
    
    let reqBody = JSON.stringify({
      patientId: eligibilityData.patientId,
      dateCreated: eligibilityData.dateCreated,
      coverageId: eligibilityData.coverageId,
    });
  /* VALIDATED with MOCK DATA: 
    const reqBody = JSON.stringify({
      patientId: "ff7820548ae64e4395b41dd27f837649",
      dateCreated: "2022-10-01",
      coverageId: "e0d57458-93d3-420f-92d5-cafb4cf6d7c3"
    })*/
  
    return fetch(server + "/canvas-create-eligibility", {
      method: "post",
      headers: {
        "Content-Type": "application/json"
      },
      body: reqBody
    })
      .then(response => {
        if (!response.ok)
          return response.text().then(error => { throw new Error(error) })
        else  
          return response.json();
      })
      .then(result => {
        console.log("Got Eligibility Req: ", result);
        return result;
      })
      .catch(error => {
        console.log("canvasCreateCoverage error: ", error);
        throw error;
      });
};

// Creates a new Eligibility Request in Canvas
const canvasGetEligibilityData = async (eligibilityReqId) => {
  console.log("Calling Canvas Eligibility Req with: ", eligibilityReqId);
  const server = process.env.REACT_APP_SERVERURL;
  const reqBody = JSON.stringify({
    eligibilityId: eligibilityReqId
  });

  /* VALIDATED with MOCK DATA: 
  const reqBody = JSON.stringify({
    eligibilityId: "28be5802-38a2-491a-8ddb-3e4cf9ae3c6a"
  }); */

  return fetch(server + "/canvas-get-eligibility", {
    method: "post",
    headers: {
      "Content-Type": "application/json"
    },
    body: reqBody
  })
    .then(response => {
      if (!response.ok)
        return response.text().then(error => { throw new Error(error) })
      else  
        return response.json();
    })
    .then(result => {
      console.log("Got Eligibility Data: ", result);
      return result;
    })
    .catch(error => {
      console.log("canvasCreateCoverage error: ", error);
      throw error;
    });
};

export {
  canvasAddPatient,
  canvasUpdatePatient,
  canvasCreateAppointment,
  canvasUpdateAppointment,
  canvasCreateNextPatientAppointment,
  canvasGetProviderSlots,
  canvasGetPatientMessages,
  canvasCreatePatientMessage,
  canvasCreateTask,
  canvasCloseTaskByAppointment,
  canvasCloseTaskByPatient,
  canvasCreateCoverage,
  canvasCreateEligibilityRequest,
  canvasGetEligibilityData
};