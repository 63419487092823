import React, {useState} from 'react';
import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchPaymentMethods from '../../../../../../hooks/useFetchPaymentMethods/useFetchPaymentMethods';
import { detachPaymentMethod } from '../../../../../../containers/Models/stripe';
import { updatePatientDefaultPayment } from '../../../../../../containers/Models/PatientModel';

import PatientSettingsPaymentMethodCard from './PatientSettingsPaymentMethodCard/PatientSettingsPaymentMethodCard';
import StripeAddPaymentMethod from '../../../../../ui/StripeAddPaymentMethod/StripeAddPaymentMethod';

import Button from '@mui/material/Button';
import '../../../../../../globalCss/globalCssMuiButton.css';

import classes from '../PatientSettingsEditName/PatientSettingsEditName.module.css';

interface Props {
  handleClose: Function;
}

const PatientSettingsEditPaymentMethod: React.FC<Props> = props => {

  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();
  const paymentMethods = useFetchPaymentMethods();

  const [showAddPayment, setShowAddPayment] = useState(false);

  const handleShowAddPayment = () => {
    setShowAddPayment(true);
  };
  const handleHideAddPayment = () => {
    setShowAddPayment(false);
  };

  const handleFinishAddPayment = (newStripePaymentMethod:any) => {
    handleHideAddPayment();
  };

  const handleRemove = (paymentMethodId: string) => {
    
    //REMOVE PAYMENT METHOD IN STRIPE
    detachPaymentMethod(paymentMethodId).then(() => {
      let updatedPatientData = {...patientData};
      let updatedPaymentMethods = [...paymentMethods];
     
      //Find index of payment method to be removed
      let indexToRemove = updatedPaymentMethods.findIndex((paymentMethod: any) => paymentMethod.id === paymentMethodId);
    
      if (indexToRemove > -1) {
        // Remove payment method from array
        updatedPaymentMethods.splice(indexToRemove, 1); 
        updatedPatientData.paymentMethods = updatedPaymentMethods;
        //If card removed was default, set next available card as default
        if (paymentMethodId === patientData.stripeDefaultPaymentId) {
          let newDefaultPaymentId = "";
          if (updatedPaymentMethods.length > 0) {
            newDefaultPaymentId = updatedPaymentMethods[0].id;
          }
          updatePatientDefaultPayment(patientData.id, newDefaultPaymentId); // update db
          updatedPatientData.stripeDefaultPaymentId = newDefaultPaymentId; // update state
        }
        updateStatePatientData(updatedPatientData);
      }
    });

  };

  const handleSetDefault = (paymentMethodId: string) => {
    
    //Update db with new default payment method id
    updatePatientDefaultPayment(patientData.id, paymentMethodId).then(() => {
      //Update state
      let updatedPatientData = {...patientData};
      updatedPatientData.stripeDefaultPaymentId = paymentMethodId;
      updateStatePatientData(updatedPatientData);
    });
    
  };

  if (patientData && paymentMethods) {

    return(
      <div className={classes.containerEditSetting}>
        <div className={classes.header}>
          <h2>Payment methods</h2>
        </div>
        {
          paymentMethods.map((paymentMethod: any) => 
            <div className={classes.contentElement} key={paymentMethod.id}>
              <PatientSettingsPaymentMethodCard 
                cardBrand={paymentMethod.card.brand}
                cardLast4={paymentMethod.card.last4}
                cardExpMonth={paymentMethod.card.exp_month}
                cardExpYear={paymentMethod.card.exp_year}
                isDefault={paymentMethod.id === patientData.stripeDefaultPaymentId } 
                handleRemove={() => handleRemove(paymentMethod.id)}
                handleSetDefault={() => handleSetDefault(paymentMethod.id)} 
                paymentMethods={paymentMethods}             
              />
            </div>
          )
        }

        {showAddPayment || paymentMethods.length === 0 ? (
          <div style={{marginTop: '48px'}}>
            <h4>New payment method</h4>
            <StripeAddPaymentMethod callback={handleFinishAddPayment} showSetDefault />
          </div>
        ) : (
          <Button 
            onClick={handleShowAddPayment} 
            variant="outlined" 
            color="secondary"
            size='large' 
            style={{marginTop: '32px'}}
          >
              Add new payment method
          </Button>
        )}

      </div>
    );

  }

  else return null;

};

export default PatientSettingsEditPaymentMethod;