import React from 'react';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import classes from './PatientNoTasksCard.module.css';

interface Props {
}

const PatientNoTasksCard: React.FC<Props> = props => {

  return(
    <div className={classes.containerPatientNoTasksCard}>
      <div style={{flexGrow: 1}}>
        <h5>No assessments due</h5>
        <p>We'll let you know when it's time for the next ones!</p>
      </div>
      <InsightsOutlinedIcon fontSize='large' color="inherit" />
    </div>
  );

};

export default PatientNoTasksCard;
