import React from 'react';

import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import Backdrop from '@mui/material/Backdrop';

import '../../../globalCss/globalCssMuiIconButton.css';
import classes from './SliderModal.module.css';

interface Props {
  isOpen: boolean;
  handleClose: React.MouseEventHandler
}

const SliderModal: React.FC<Props> = props => {

  return(

    <React.Fragment>

      <Slide direction="up" in={props.isOpen} mountOnEnter unmountOnExit>

          <div className={classes.containerSlider}>

            <IconButton onClick={props.handleClose} className={classes.closeBtn} color="inherit" aria-label="close">
              <CloseRoundedIcon />
            </IconButton>

            <div className={classes.contentSlider}>
              {props.children}
            </div>
            
          </div>

      </Slide>

      <Backdrop
        open={props.isOpen}
        onClick={props.handleClose}
        className={classes.backdrop}
      />

    </React.Fragment>

  );
};

export default SliderModal;