import React from 'react';

import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import classes from './PatientSettingsPaymentMethodCard.module.css';
import '../../../../../../../globalCss/globalCssMuiPaper.css';
import '../../../../../../../globalCss/globalCssMuiMenu.css';

interface Props {
  cardBrand: string;
  cardLast4: string;
  cardExpMonth: number;
  cardExpYear: number;
  isDefault: boolean;
  handleRemove: Function;
  handleSetDefault: Function;
  paymentMethods: any;
}

const PatientSettingsPaymentMethodCard: React.FC<Props> = props => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRemove = () => {
    props.handleRemove();
    handleCloseMenu();
  };

  const handleSetDefault = () => {
    props.handleSetDefault();
    handleCloseMenu();
  };


  return(
    <div className={classes.containerPaymentMethodCard}>
      <h5>
        {props.cardBrand + ' • • • • ' + props.cardLast4}
        {props.isDefault ? <Chip label="DEFAULT" size="small" style={{marginLeft: '12px'}} /> : null}

      </h5>
      <p>Expiration: {props.cardExpMonth + '/' + props.cardExpYear}</p>
      
      <IconButton 
        onClick={handleOpenMenu}
        aria-label="open menu" 
        aria-controls={open ? 'cardMenu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className={classes.btnMenu}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="cardMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {!props.isDefault ? 
          <MenuItem onClick={handleSetDefault}>Set Default</MenuItem>
        : null}
        <MenuItem onClick={handleRemove} disabled={props.paymentMethods.length < 2}>Remove</MenuItem>
      </Menu>

    </div>
  );

};

export default PatientSettingsPaymentMethodCard;