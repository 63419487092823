import React from 'react';

import PatientSettingsEditName from './PatientSettingsEditName/PatientSettingsEditName';
import PatientSettingsEditDob from './PatientSettingsEditDob/PatientSettingsEditDob';
import PatientSettingsEditPhone from './PatientSettingsEditPhone/PatientSettingsEditPhone';
// import PatientSettingsEditInsurance from './PatientSettingsEditInsurance/PatientSettingsEditInsurance';
import PatientSettingsEditPaymentMethod from './PatientSettingsEditPaymentMethod/PatientSettingsEditPaymentMethod';
import PatientSettingsEditPassword from './PatientSettingsEditPassword/PatientSettingsEditPassword';
import SliderModal from '../../../../ui/SliderModal/SliderModal';


interface Props {
  patientData: any;
  paymentMethods: Array<any>;
  showEditName: boolean;
  showEditDob: boolean
  showEditPhone: boolean
  showEditInsurance: boolean
  showEditPaymentMethod: boolean
  showEditPassword: boolean
  handleCloseSlider: Function;
}

const PatientPortalSettingsSliders: React.FC<Props> = props => {

  if (props.patientData && props.paymentMethods) {
    return(

      <React.Fragment>

        <SliderModal isOpen={props.showEditName} handleClose={() => props.handleCloseSlider('editName')} >
          <PatientSettingsEditName handleClose={() => props.handleCloseSlider('editName')} />
        </SliderModal>

        <SliderModal isOpen={props.showEditDob} handleClose={() => props.handleCloseSlider('editDob')} >
          <PatientSettingsEditDob handleClose={() => props.handleCloseSlider('editDob')} />
        </SliderModal>

        <SliderModal isOpen={props.showEditPhone} handleClose={() => props.handleCloseSlider('editPhone')} >
          <PatientSettingsEditPhone handleClose={() => props.handleCloseSlider('editPhone')} />
        </SliderModal>

        {/*<SliderModal isOpen={props.showEditInsurance} handleClose={() => props.handleCloseSlider('editInsurance')} >
          <PatientSettingsEditInsurance 
            coverageData={props.coverageData}
            handleClose={() => props.handleCloseSlider('editInsurance')} 
          />
        </SliderModal>*/}

        <SliderModal isOpen={props.showEditPaymentMethod} handleClose={() => props.handleCloseSlider('editPaymentMethod')}>
          <PatientSettingsEditPaymentMethod 
            handleClose={() => props.handleCloseSlider('editPaymentMethod')}
          />
        </SliderModal>

        <SliderModal isOpen={props.showEditPassword} handleClose={() => props.handleCloseSlider('editPassword')} >
          <PatientSettingsEditPassword handleClose={() => props.handleCloseSlider('editPassword')}  />
        </SliderModal>

      </React.Fragment>
      
    );
  }
  else {
    return null;
  }

};

export default PatientPortalSettingsSliders;
