import React from 'react';
import { format, parseISO } from 'date-fns';

import Chip from '@mui/material/Chip';

import classes from './PatientPastAppointmentCard.module.css'

interface Props {
  appointment: {
    id: string;
    start: Date;
    service: string;
    status: string;
    providerId: string;
    providerName: string;
    amountPaid: number;
  };
}

const PatientPastAppointmentCard: React.FC<Props> = props => {

  let start = props.appointment.start;
  if (typeof props.appointment.start !== 'string') {
    if ('seconds' in props.appointment.start)
      start = (props.appointment.start as any).toDate();
  } else {
      start = parseISO(props.appointment.start);
  }

  return(
    <div className={classes.containerPatientAppointmentCard}>
      <div>
        <h5>{format(start, 'MM/dd/yy, p')}</h5>
        <p>
          {
            ( props.appointment.service === "consultation" ? "Consultation"
              : props.appointment.service === "intake" ? "Therapy"
              : props.appointment.service === "individual" ? "Therapy"
              : props.appointment.service )
            + " - "  + props.appointment.providerName
          }
        </p>
      </div>

      <div className={classes.amountPaid}>
        <h5>{props.appointment.amountPaid ? (props.appointment.amountPaid / 100).toLocaleString("en-US", {style:"currency", currency:"USD"}) : null }</h5>
        {
          props.appointment.status === 'fulfilled' ?
            <Chip label="Fulfilled" size="small" color="success" />
          : props.appointment.status === 'noshow' ?
            <Chip label="No-show" size="small" color="error" />
          : props.appointment.status === 'cancelled' ?
            <Chip label="Canceled" size="small" color="error" />
          : null
        }
      </div>

    </div>
  );
};

export default PatientPastAppointmentCard;
