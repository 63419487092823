import React from 'react';
import Button from '@mui/material/Button';

import classes from './PreIntakeCard.module.css';


interface Props {
  openPreIntake: Function;
}

const PreIntakeCard: React.FC<Props> = props => {

  return(
    <div className={classes.containerPreIntakeCard}>
      <div>
        <h5>Intake form</h5>
        <p><small>Complete this form to help you provider prepare for your first appointment.</small></p>
      </div>
      <Button 
        onClick={() => props.openPreIntake()} 
        className={classes.btn} 
        color="secondary" 
        aria-label="Start signing"
        variant="contained"
      >
        Start
      </Button>
    </div>
  );

};

export default PreIntakeCard;