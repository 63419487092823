import React, { useState } from 'react';
import { format, parseISO, differenceInMinutes } from 'date-fns';

import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchProviderData from '../../../../../../hooks/useFetchProviderData/useFetchProviderData';
import { canvasUpdateAppointment } from '../../../../../../containers/Models/Canvas';
import {sgProviderSessionConfirmedByPatient, sgProviderTherapyDeclinedByPatient} from '../../../../../../containers/Models/SgEmailModel';
import { twilioSendText } from '../../../../../../containers/Models/twilio';
import AvatarSquare from '../../../../../ui/AvatarSquare/AvatarSquare';

import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Chip from '@mui/material/Chip';

import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import PhoneEnabledOutlinedIcon from '@mui/icons-material/PhoneEnabledOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import classes from './UpcomingAppointmentCard.module.css';
import '../../../../../../globalCss/globalCssMuiButton.css';
import '../../../../../../globalCss/globalCssMuiChip.css';


interface Props {
  appointment: {
    id: string;
    start: Date;
    end: Date;
    service: string;
    status: string;
    type: string;
    providerId: string;
    providerName: string;
    providerPicUrl: string;
    newTherapyTime: boolean;
    meetingId: string;
  }|undefined,
  handleOpenRescheduleAppointment: Function;
  handleOpenConfirmSchedule: Function;
  handleOpenConsent: Function;
  handleOpenPreIntake: Function;
  areTasksDue?: boolean;
}

const UpcomingAppointmentCard: React.FC<Props> = props => {

  const patientData = usePatientData();
  const providerData = useFetchProviderData();
  const updateStatePatientData = useUpdatePatientData();

  const [loadingAcceptProviderRequest, setLoadingAcceptProviderRequest] = useState(false);
  const [loadingDeclineProviderRequest, setLoadingDeclineProviderRequest] = useState(false);

  // Join Zoom meeting
  const openMeeting = (meetingId: any) => {
    //If user hasn't signed consent forms redirect to consent form
    if (!patientData.consentId) {
      props.handleOpenConsent();
    }
    //If user has not completed pre-intake and the next appointment is an intake
    else if (!patientData.preIntakeId && props.appointment && props.appointment.service == 'intake') {
      //Send alerts to provider
      let initials = patientData.firstName.charAt(0) + patientData.lastName.charAt(0);
      twilioSendText(patientData.providerData.phone, "Allminds: Your client " + initials + " is completing their pre-intake. They will connect to your appointment in a few minutes.");      
      props.handleOpenPreIntake();
    }
    else {
      window.open(meetingId, '_blank');
    }
  }

  //Accept new schedule requests or single appointment changes
  const handleAcceptProviderRequest = () => {
    
    if (props.appointment && props.appointment.status === 'pending') {

      //For new schedule requests
      if (props.appointment.newTherapyTime) {
        props.handleOpenConfirmSchedule(props.appointment.id);
      }

      // For single appointment reschedules
      else {
        setLoadingAcceptProviderRequest(true);

        let updatedPatientData = {...patientData};
        let updatedAppointments = [...updatedPatientData.appointments];
        let updatedAppointment = {...props.appointment}

        updatedAppointment.status = 'booked';

        canvasUpdateAppointment(updatedAppointment).then(() => {
          //Send alerts to provider
          twilioSendText(patientData.providerData.phone, "Allminds: Your client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") confirmed their rescheduled appointment. See details on allminds.canvasmedical.com.");
          sgProviderSessionConfirmedByPatient(patientData.providerData.email, patientData.firstName, patientData.providerData.firstName, updatedAppointment.start);
          //Update state
          updatedAppointment.newTherapyTime = false;
          let indexToChange = updatedAppointments.findIndex(appointment => appointment.id === updatedAppointment.id);
          if (indexToChange > -1) {
            updatedAppointments[indexToChange] = updatedAppointment;
          }
          updatedPatientData.appointments = updatedAppointments;          
          updateStatePatientData(updatedPatientData);
          setLoadingAcceptProviderRequest(false);
        });
      }

    }

  };

  //Decline new schedule requests
  const handleDeclineProviderRequest = () => {

    if (props.appointment && props.appointment.status === 'pending' && props.appointment.newTherapyTime) {

      setLoadingDeclineProviderRequest(true);

      let updatedPatientData = {...patientData};
      let updatedAppointments = [...updatedPatientData.appointments];
      let updatedAppointment = {...props.appointment}

      updatedAppointment.status = 'cancelled';

      canvasUpdateAppointment(updatedAppointment).then(() => {
        //Send alerts to provider
        twilioSendText(patientData.providerData.phone, "Allminds: Your client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") declined their proposed schedule. You can offer a different time on allminds.canvasmedical.com.");
        sgProviderTherapyDeclinedByPatient(patientData.providerData.email, patientData.firstName, patientData.providerData.firstName, updatedAppointment.start);
        //Update state to rerender components
        updatedAppointment.newTherapyTime = false;
        let indexToChange = updatedAppointments.findIndex(appointment => appointment.id === updatedAppointment.id);
        if (indexToChange > -1) {
          updatedAppointments[indexToChange] = updatedAppointment;
        }
        updatedPatientData.appointments = updatedAppointments;
        updatedPatientData.therapySlotsBlockId = "";
        updatedPatientData.therapySlotsBlockLink = "";
        updatedPatientData.therapyFollowDate = null;
        updatedPatientData.therapyFrequency = 0;
        updateStatePatientData(updatedPatientData);
        setLoadingDeclineProviderRequest(false);
      });

    }

  };

  if (props.appointment) {
    let start = props.appointment.start;
    let end = props.appointment.end;
    if (typeof props.appointment.start !== 'string') {
      if ('seconds' in props.appointment.start)
        start = (props.appointment.start as any).toDate();
    } else {
      console.log("WARN: Start time was string!");
      start = parseISO(props.appointment.start);
    }
    if (typeof props.appointment.end !== 'string') {
      if ('seconds' in props.appointment.end)
        end = (props.appointment.end as any).toDate();
    } else {
        end = parseISO(props.appointment.end);
    }
  
    return(
      <div className={classes.container}>
        <div className={classes.header}>
          <AvatarSquare imgUrl={props.appointment.providerPicUrl} alt={props.appointment.providerName} />
          <div>
            <h5>{props.appointment.providerName}</h5>
            <p>{
                props.appointment.service === "consultation" ? "Consultation"
              : props.appointment.service === "intake" ? "Individual Therapy"
              : props.appointment.service === "individual" ? "Individual Therapy"
              : props.appointment.service
            }</p>
          </div>
        </div>

        <div className={classes.timeslot}>

          <div className={classes.chipsContainer}>
            <Chip 
              label={
                patientData.treatmentHold ? 'Care on hold'
                : props.appointment.status === 'proposed' ? "Unconfirmed" 
                : props.appointment.status === 'booked' ? "Confirmed" 
                : props.appointment.status === 'cancelled' ? "Canceled" 
                : props.appointment.status === 'pending' ? "Requested by therapist"
                : props.appointment.status === 'fulfilled' ? "Fulfilled"
                : props.appointment.status} 
              icon={
                patientData.treatmentHold ? <EventBusyOutlinedIcon />
                : props.appointment.status === 'booked' ? <DoneRoundedIcon /> 
                : props.appointment.status === 'cancelled' ? <EventBusyOutlinedIcon /> 
                : props.appointment.status === 'fulfilled' ? <DoneRoundedIcon /> 
                : undefined} 
              color={
                patientData.treatmentHold ? "error"
                : props.appointment.status === 'proposed' ? "primary" 
                : props.appointment.status === 'booked' ? "success" 
                : props.appointment.status === 'cancelled' ? "error" 
                : props.appointment.status === 'pending' ? "error"
                : "primary"}
                size="small"
              className={classes.chip} 
            />
            
            {props.appointment.newTherapyTime ? (
              <Chip
                label="New meeting time"
                icon={<AccessTimeOutlinedIcon />}
                color="warning" 
                size="small"
                className={classes.chip} 
              />
            ) : (null)}

            {props.areTasksDue 
              || !patientData.consentId
              || (!patientData.preIntakeId && props.appointment && props.appointment.service == 'intake') ? (
              <Chip
                label="Tasks pending"
                icon={<WarningAmberIcon />}
                color="warning" 
                size="small"
                className={classes.chip} 
              />
            ) : (null)}
          </div>

          <div className={classes.row}>
            <div>
              <h5>
                {
                  format(start, 'iii, LLL dd / ')
                  +format(start, 'p') 
                  + " - " 
                  + format(end, 'p')
                }
              </h5>
              {
                patientData.therapyFrequency === 1 ? <p>Repeats every week</p>
                : patientData.therapyFrequency === 2 ? <p>Repeats every two weeks</p>
                : patientData.therapyFrequency === 3 ? <p>Repeats every three weeks</p>
                : patientData.therapyFrequency === 4 ? <p>Repeats every four weeks</p>
                : null
              }
            </div>
            {props.appointment.status === "pending" ? 
              <div className={classes.timeslotBtns}>
                <LoadingButton 
                  onClick={handleAcceptProviderRequest}
                  loading={loadingAcceptProviderRequest}
                  variant="outlined" 
                  size="small" 
                  color="inherit"
                >
                  Accept
                </LoadingButton>
                {props.appointment.newTherapyTime ? 
                  <LoadingButton 
                    onClick={handleDeclineProviderRequest}
                    loading={loadingDeclineProviderRequest}
                    variant="outlined" 
                    size="small" 
                    color="inherit"
                  >
                    Decline
                  </LoadingButton>
                :
                  <Button 
                    onClick={() => props.handleOpenRescheduleAppointment(props.appointment!.id)}
                    variant="outlined" 
                    size="small" 
                    color="inherit"
                  >
                    Change
                  </Button>
                }
              </div>
            :
            <div className={classes.timeslotBtns}>  
              {props.appointment.type === "video" ? 
                <Button 
                  onClick={() => openMeeting(props.appointment!.meetingId)}
                  className={classes.videoBtn} 
                  startIcon={<VideocamOutlinedIcon />}
                  size="small" 
                  aria-label="connect to video"
                  variant="contained"
                  disabled={patientData.treatmentHold || differenceInMinutes(props.appointment!.start, new Date()) > 10}
                  // disabled={patientData.treatmentHold || props.areTasksDue || differenceInMinutes(props.appointment!.start, new Date()) > 10}
                > 
                  Join
                </Button>      
              :
                <div className={classes.phoneMessage}>
                  <PhoneEnabledOutlinedIcon fontSize='small' />
                  <p>Your therapist will call you directly</p>
                </div>
              }
              {
                (props.appointment.newTherapyTime && (props.appointment.status === 'proposed' || props.appointment.status === 'booked'))
                || !props.appointment.newTherapyTime ? 
                <Button 
                  onClick={() => props.handleOpenRescheduleAppointment(props.appointment!.id)} 
                  color="inherit" 
                  startIcon={<EventOutlinedIcon />}
                  size="small" 
                  aria-label="Modify appointment"
                  variant="outlined"
                >
                  Modify
                </Button>
              :null}

            </div>
            }
          </div>

        </div>

        {props.appointment.service === 'consultation' ?
          <React.Fragment>
            <h5>What to expect</h5>
            <p>Use your consultation to ask questions and get to know your therapist. You'll be able to start therapy once you feel ready.</p>
          </React.Fragment>
        : null}
      </div>
    );
  }

  else {
    return (
      <div className={[classes.container, classes.containerNoAppointments].join(' ')}>
        <EventBusyOutlinedIcon fontSize="large" color="inherit" />
        <p>You have no upcoming appointments.</p>
      </div>
    );
  }
};

export default UpcomingAppointmentCard;
