import React, { useEffect, useRef } from "react";
import {
  ConversationalForm,
  EventDispatcher,
  FlowEvents
} from "conversational-form";

import avatarPng from '../../../assets/png/logoBlueBg.png';
import iconUndo from '../../../assets/svg/icon_undo.svg';


import classes from './ChatForm.module.css';
import './ChatFormStyling.css';

interface Props {
  //Object with screener questions
  questions: any;
  //Array with string options for screener questions
  options: string[];
  //Optional function to run after every step
  questionCallback?: any;
  //Object with intro message for screener
  intro: any;
  disableBackBtn?: boolean
}

const Screener: React.FC<Props> = props => {
  const ref = useRef<HTMLDivElement>(null);

  //START OF CONVERSATIONAL FORM INITIALIZATION
  let cf: any = null;

  //This function creates the tag options for each screener question.
  //Needs string array options (with option labels) and tagName to link options with the right screener question.
  const getTagOptions = (tagName: string) => {
    let tagOptions: any = [];
    for (let option in props.options) {
      tagOptions.push({
        tag: "input",
        name: tagName,
        type: "radio",
        "cf-label": option,
        value: option
      });
    }
    return tagOptions;
  };

  //This functions creates tags for all screener questions and appends them to the array of form fields
  const createScreenerTags = (formFields: any[]) => {
    for (let tagName in props.questions) {
      formFields.push({
        tag: "fieldset",
        name: tagName,
        "cf-input-placeholder": " ",
        "cf-questions": props.questions[tagName],
        children: getTagOptions(tagName)
      });
    }
  };

  const formFields = [
    {
      tag: "fieldset",
      name: "intro",
      "cf-input-placeholder": " ",
      "cf-questions": props.intro.message,
      children: [
        {
          tag: "input",
          name: "intro",
          type: "radio",
          "cf-label": props.intro.option,
          value: props.intro.option
        }
      ]
    }
  ];

  createScreenerTags(formFields);

  useEffect(() => {
    // Set-up event listener to trigger at the start of every form step
    var dispatcher = new EventDispatcher();
    dispatcher!.addEventListener(
      FlowEvents.FLOW_UPDATE,
      (event: any) => {

        let inputWrapper = document.querySelector(
          ".inputWrapper"
        ) as HTMLInputElement;

        //Disable input when question has radio tags
        if (event.detail.tag.elements) {
          inputWrapper.classList.add("disableInput", "disableInputButton", "hideInput");
        } 

      },
      false
    );

    //Initialize the conversational form
    cf = ConversationalForm.startTheConversation({
      options: {
        theme: "blue",
        flowStepCallback: flowCallback,
        submitCallback: submitCallback,
        preventAutoFocus: true,
        userImage: iconUndo,
        robotImage: avatarPng,
        eventDispatcher: dispatcher,
        showProgressBar: true
        //loadExternalStyleSheet: false,
      },
      tags: formFields.slice(0, formFields.length)
    });

    ref.current!.appendChild(cf.el);

    return () => {
      cf.remove();
      dispatcher!.removeEventListener(FlowEvents.FLOW_UPDATE);
    };
  }, []);

  //this function runs right after every step of the flow
  const flowCallback = (dto: any, success: any, error: any) => {
    if (props.questionCallback) props.questionCallback(dto, success, error, cf);
    else success();
  };

  const submitCallback = () => {
    // console.log("end");
  };
  //END OF CONVERSATIONAL FORM INITIALIZATION

  let containerClasses = [classes.ChatContainer, "ChatStyle"];
  if (props.disableBackBtn) containerClasses.push("disableBackBtn");

  return (
    <div className={classes.container}>
      <div
        ref={ref}
        className={containerClasses.join(" ")}
      ></div>
    </div>
  );
};

export default Screener;
