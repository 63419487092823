import React, {useEffect} from 'react';

import { usePatientData } from "../../../../../../../hooks/ContextPatientData/ContextPatientData";
import useFetchPaymentMethods from '../../../../../../../hooks/useFetchPaymentMethods/useFetchPaymentMethods';

import PatientPaymentMethodCard from './PatientPaymentMethodCard/PatientPaymentMethodCard';
import StripeAddPaymentMethod from '../../../../../../ui/StripeAddPaymentMethod/StripeAddPaymentMethod';

import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';

import classes from './PatientScheduleSessionPayment.module.css';

interface Props {
  paymentMethod: any;
  defaultPaymentId: string;
  handleSetPaymentMethod: Function;
  handleOpenEditPayment: React.MouseEventHandler;
}

const PatientScheduleSessionPayment: React.FC<Props> = props => {

    const patientData = usePatientData();
    const paymentMethods = useFetchPaymentMethods();
    const paymentMethod = props.paymentMethod;
    const defaultPaymentId = props.defaultPaymentId;
    const handleSetPaymentMethod = props.handleSetPaymentMethod;

    //Select default payment method for booking
    useEffect(() => {
      if (paymentMethods && !paymentMethod) {

        let defaultPayment = paymentMethods.find((payment:any) => payment.id === defaultPaymentId);
        
        if (!defaultPayment && paymentMethods.length > 0) 
          defaultPayment = paymentMethods[0];
        
        if (defaultPayment)
          handleSetPaymentMethod(defaultPayment);

      }
    }, [paymentMethods, paymentMethod, defaultPaymentId, handleSetPaymentMethod]);

    if (paymentMethods && paymentMethods.length === 0) {
      return(
        <div className={classes.containerAddPayment}>
          <h5 style={{marginBottom: '16px'}}>Add payment method</h5>
          {patientData && patientData.coverageId ? 
            <Alert severity="info" style={{marginBottom: '24px'}}>
              A payment method is needed on file. You won't be charged if you have full coverage.
            </Alert>
          : null}
          <StripeAddPaymentMethod />
        </div>
      );
    }

    else if (paymentMethod) {
      return(
        <div className={classes.containerPaymentMethod}>
          <PatientPaymentMethodCard
            label="Payment method"
            value={paymentMethod.card.brand + " • • • • " + paymentMethod.card.last4} 
            clicked={props.handleOpenEditPayment} 
          />
        </div>
      );
    }

    else {
      return(
        <div className={classes.containerPaymentMethod}>
          <Skeleton variant="rectangular" height={88} />
        </div>
      );
    }

};

export default PatientScheduleSessionPayment;
