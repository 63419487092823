import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './globalCss/globalCssMuiAlert.css';
import './globalCss/globalCssMuiButton.css';
import './globalCss/globalCssMuiCalendarPicker.css';
import './globalCss/globalCssMuiChip.css';
import './globalCss/globalCssMuiDialog.css';
import './globalCss/globalCssMuiFab.css';
import './globalCss/globalCssMuiIconButton.css';
import './globalCss/globalCssMuiMenu.css';
import './globalCss/globalCssMuiMenuItem.css';
import './globalCss/globalCssMuiPaper.css';
import './globalCss/globalCssMuiSkeleton.css';
import './globalCss/globalCssMuiTabs.css';
import './globalCss/globalCssMuiTextField.css';
import './globalCss/globalCssMuiRadio.css';
import './globalCss/globalCssMuiSelect.css';
import './globalCss/globalCssReactCalendar.css';
import './globalCss/globalCssReactPhoneInput.css';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
