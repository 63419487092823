import React, {useState, useEffect} from 'react';
import { getAnalytics, logEvent } from "firebase/analytics";
import { format, isSameDay } from 'date-fns';

import { updatePatientProvider } from '../../../../../../containers/Models/PatientModel';
import useFetchProviderData from "../../../../../../hooks/useFetchProviderData/useFetchProviderData";
import { createAppointmentObject, addAppointmentToDb } from '../../../../../../containers/Models/AppointmentModel';
import { canvasCreateAppointment, canvasCreateTask, canvasCloseTaskByPatient } from '../../../../../../containers/Models/Canvas';
import { twilioSendText } from '../../../../../../containers/Models/twilio';
import { sgPatientConsultationRequested, sgProviderConsultationRequested } from '../../../../../../containers/Models/SgEmailModel';
import { usePatientData, useUpdatePatientData } from "../../../../../../hooks/ContextPatientData/ContextPatientData";
import useFetchOpenProviderSlots from '../../../../../../hooks/useFetchOpenProviderSlots/useFetchOpenProviderSlots';

import ReviewConsultationDetailsCard from './ReviewConsultationDetailsCard/ReviewConsultationDetailsCard';
import PatientRescheduleAppointmentCard from '../PatientRescheduleAppointment/PatientRescheduleAppointmentCard/PatientRescheduleAppointmentCard';
import Calendar from '../../../../../ui/Calendar/Calendar';
import TimeSlots from '../../../../../ui/TimeSlots/TimeSlots';
import ErrorSnackbar from '../../../../../ui/ErrorSnackbar/ErrorSnackbar';

import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import classes from './PatientScheduleConsultation.module.css';
import '../../../../../../globalCss/globalCssMuiButton.css';


interface Props {
  providerData: any;
  handleCloseScheduleConsultation: any;
  handleCloseFindProvider?: any;
  showBackBtn?: boolean;
}

const PatientScheduleConsultation: React.FC<Props> = props => {
  
  const analytics = getAnalytics();
  const patientData = usePatientData();
  const providerData = useFetchProviderData();
  const updateStatePatientData = useUpdatePatientData();

  let today = new Date();
  let maxDate = new Date();
  //Set minDate to 4 hours after today
  let minDate = new Date(today.getTime() + 60 * 60 * 4 * 1000);
  //Set maxDate to 14 days after original appointment day
  maxDate.setDate(maxDate.getDate() + 14);
  let availabilityDays = 14;
  const availableSlots = useFetchOpenProviderSlots(props.providerData.canvasId, availabilityDays, 15, 30);

  //Set initial date for calendar once availableSlots are fetched
  useEffect(() => {
    if (availableSlots) {
      let minDate = new Date(new Date().getTime() + 60 * 60 * 4 * 1000);
      let firstAvailableDate = availableSlots.find((slot:any) => new Date(slot.resource.start) > minDate)
      if (firstAvailableDate)
        setDate(new Date(firstAvailableDate.resource.start));
    }
  }, [availableSlots]);

  const handleDisableDate = ({activeStartDate, date, view }:any) => availableSlots && !availableSlots.find(
    (slot:any) => isSameDay(date, new Date(slot.resource.start)) && new Date(slot.resource.start) > minDate
  );

  const calendarProps = {
    minDate: minDate,
    maxDate: maxDate,
    tileDisabled: handleDisableDate
  };

  const [date, setDate] = useState(new Date()); //Controls the active date in the calendar ui
  const [confirmedDate, setConfirmedDate] = useState<null|Date>(null);// New date for appointment to be chosen by user
  const [bookedAppointment, setBookedAppointment] = useState<any>(null); 
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState(false);

  // Returns appointments array from patientData with a new appointment
  const getUpdatedAppointments = (newAppointment: {
    start: Date,
    end: Date,
    service: string,
    type: string,
    status: string,
    providerId: string,
    providerName: string,
    providerPicUrl: string  
  }) => {
    if (patientData) {
      let updatedAppointments = [...patientData.appointments];
      updatedAppointments.push(newAppointment);
      //Sort array by date of appointment
      updatedAppointments.sort((a,b) => (a.start > b.start) ? -1 : ((b.start > a.start) ? 1 : 0))

      return updatedAppointments;
    }
  };

  const handleConfirmDate = (date: Date) => {
    setConfirmedDate(date);
  };

  const handleBookAppointment = () => {
    if (confirmedDate) {
      setLoadingSubmit(true);
      //Create a new appointment object
      let appointment = {
        start: confirmedDate,
        end: new Date(confirmedDate.getTime() + 900000),
        service: 'consultation',
        status: 'proposed',
        type: 'phone',
        providerId: props.providerData.id,
        providerCanvasId: props.providerData.canvasId,
        //providerCanvasId: '1e3391f2c4c14e1f8a392aa68f7c5f7b', //Hardcode Juan Ordonez canvas id
        providerName: props.providerData.firstName + ' ' + props.providerData.lastName,
        providerPicUrl: props.providerData.picUrl,
        patientId: patientData.id,
        patientCanvasId: patientData.canvasId,
        patientEmail: patientData.email
      };

      const appointmentObj = createAppointmentObject(appointment);
      
      //Create appointment in canvas
      canvasCreateAppointment(appointmentObj)

      //Add appointment to db
      .then(canvasAppointmentId => {
        appointmentObj.canvasId = canvasAppointmentId.appointmentId;
        return addAppointmentToDb(appointmentObj);
      })

      //Update provider in patient db
      .then(() => {
        //If patient has a current provider
        if (patientData.providerId) {
          //Close existing canvas tasks for provider
          canvasCloseTaskByPatient(patientData.canvasId, providerData.canvasId);
          //Send text alert to provider
          twilioSendText(providerData.phone, "Allminds: Your referral (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") has changed providers. No further action is required.");
        }
        //Update provider field in patient db
        return updatePatientProvider(patientData.id, props.providerData.id);
      })

      //Send notifications and update state
      .then(() => {

        //Track firebase event
        logEvent(analytics, 'consultation_booked');

        let providerName = props.providerData.firstName + ' ' + props.providerData.lastName;
        //Send email to patient
        sgPatientConsultationRequested(patientData.email, patientData.firstName, patientData.phone, providerName, appointmentObj.start); 
        //Create canvas task for provider
        let description = 'Consultation requested: ' + format(appointmentObj.start, 'MM/dd/yy, p') ;
        let note = 'Confirm consultation in your schedule, or request a different time.'
        canvasCreateTask(patientData.canvasId, props.providerData.canvasId, appointmentObj.canvasId, appointmentObj.start, description, note);
        //Send alerts to provider
        twilioSendText(props.providerData.phone, "Allminds: You have a new consultation request. Please confirm or reject asap on allminds.canvasmedical.com");
        sgProviderConsultationRequested(props.providerData.email, patientData.firstName, props.providerData.firstName, appointmentObj.start);
        //Update state
        let updatedAppointments = getUpdatedAppointments(appointmentObj);
        let updatedPatientData = {...patientData};
        updatedPatientData.appointments = updatedAppointments;
        updatedPatientData.providerId = props.providerData.id
        updatedPatientData.providerData = {...props.providerData};
        updateStatePatientData(updatedPatientData);
        setBookedAppointment(appointment);
        setLoadingSubmit(false);
      })

      .catch(error => {
        console.log("Error scheduling consultation: ", error);
        setLoadingSubmit(false);
        setError(true);
      });
    }
  };

  const handleClose = () => {
    props.handleCloseScheduleConsultation();
    if (props.handleCloseFindProvider) {
      props.handleCloseFindProvider();
    }
  };


  //Ask user to select date and time
  if (!confirmedDate) {
    return(
      <div className={classes.containerScheduleAppointment}>

        <div className={classes.header}>
          <IconButton onClick={props.handleCloseScheduleConsultation} className={classes.closeBtn} aria-label="start over">
            {props.showBackBtn ? <ArrowBackRoundedIcon /> : <CloseRoundedIcon />}
          </IconButton>
          <h4>Schedule a consultation</h4>
          {patientData.providerId && props.providerData.id !== patientData.providerId ? 
            <Alert variant="outlined" severity="warning" style={{marginBottom: '64px'}}>
              Once you book an appointment with a new provider, you will no longer be able to see your current therapist.
            </Alert>
          :
            <Alert variant="outlined" severity="info" style={{marginBottom: '64px'}}>
              This is an opportunity to ask questions and get to know your therapist better. It's a free 15 min phone call without commitment.
            </Alert>
          }
        </div>

        <Grid container spacing={{ xs: 1, sm: 2, md: 4, lg: 8 }}>
          <Grid item xs={12} sm={7} md={6}>
            <div className={classes.calendarContainer} style={!availableSlots ? {pointerEvents: "none"} : {}}>
              {availableSlots ? 
                <Calendar 
                  value={date}
                  onChange={setDate}
                  calendarProps={calendarProps} 
                />
              :
                <Stack spacing={2}>
                  <Skeleton variant="rectangular" height={44} />
                  <Skeleton variant="rectangular" height={285} />
                </Stack>            
              }
            </div>
          </Grid>
          <Grid item xs={12} sm={5} md={6}>
            <div className={classes.timeSlotsContainer}>
              <h4>{format(date, 'EEEE, LLLL dd')}</h4>
              {!availableSlots ? 
                <Stack spacing={2}>
                  <Skeleton variant="rectangular" height={44} />
                  <Skeleton variant="rectangular" height={44} />
                  <Skeleton variant="rectangular" height={44} />
                </Stack>
                :
                <TimeSlots 
                  slots={availableSlots} 
                  selectedDate={date}
                  onClickConfirm={handleConfirmDate}
                />
              }
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  //After ask user to review and confirm booking
  else if (!bookedAppointment) {
    return(
      <div className={classes.containerScheduleAppointment}>
        <div className={classes.header}>
          <IconButton onClick={() => setConfirmedDate(null)} className={classes.closeBtn} aria-label="start over">
            <ArrowBackRoundedIcon />
          </IconButton>
        </div>
        <ReviewConsultationDetailsCard
          providerName={props.providerData.firstName + ' ' + props.providerData.lastName}
          providerPicUrl={props.providerData.picUrl}
          startDate={confirmedDate}
          endDate={new Date(confirmedDate.getTime() + 900000)}
        />
         <LoadingButton 
            onClick={handleBookAppointment}
            className={classes.btnBook} 
            variant="contained" 
            size="large" 
            fullWidth
            loading={loadingSubmit}
          >
            Book consultation
          </LoadingButton>
          <ErrorSnackbar open={error} setOpen={setError} />
      </div>
    );
  } 
  //After user confirms new time
  else if (bookedAppointment) {
    return(
      <div className={classes.containerScheduleAppointment}>
        <div className={classes.header}>
          <IconButton onClick={handleClose} className={classes.closeBtn} aria-label="start over">
            <CloseRoundedIcon />
          </IconButton>
          <h2>Consultation requested</h2>
          <p>Your appointment isn't confirmed yet. We'll let you know when your therapist accepts your consultation.</p>
          <PatientRescheduleAppointmentCard appointment={bookedAppointment} />
        </div>
      </div>
    );
  }
  else return null;
};

export default PatientScheduleConsultation;
