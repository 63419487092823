import React, { useState, useEffect } from 'react';
import { getAnalytics, logEvent } from "firebase/analytics";
import { format, isSameDay, differenceInMinutes } from 'date-fns'
import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import useFetchPatientMessages from '../../../../../../hooks/useFetchPatientMessages/useFetchPatientMessages';
import { canvasCreatePatientMessage } from '../../../../../../containers/Models/Canvas';
import { twilioSendText } from '../../../../../../containers/Models/twilio';
import { sgProviderNewMessageFromPatient } from '../../../../../../containers/Models/SgEmailModel';
import { updatePatientAlerts } from '../../../../../../containers/Models/PatientModel';

import MessageIncoming from './MessageIncoming/MessageIncoming';
import MessageOutgoing from './MessageOutgoing/MessageOutgoing';

import TextareaAutosize from '@mui/material/TextareaAutosize';
import IconButton from '@mui/material/IconButton';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import Skeleton from '@mui/material/Skeleton';

import classes from './PatientInboxMessages.module.css'


interface Props {
}

const PatientInboxMessages: React.FC<Props> = props => {

  const analytics = getAnalytics();
  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();
  //Fetch messages from canvas on first render
  const messagesData = useFetchPatientMessages();

  const [messageValue, setMessageValue] = useState("");
  const [outbox, setOutbox] = useState<any>([]);
  const [deliveredMessage, setDeliveredMessage] = useState<any>(null);
  
  //Remove new message badge after messages are loaded
  useEffect(() => {
    if (patientData && messagesData && patientData.alerts.newMessage) {
      let updatedPatientData = {...patientData};
      let updatedAlerts = {...updatedPatientData.alerts};
      updatedAlerts.newMessage = false;
      //Update db
      updatePatientAlerts(patientData.id, updatedAlerts);
      updatedPatientData.alerts = updatedAlerts;
      //Update state
      updateStatePatientData(updatedPatientData);
    }
  }, [patientData, messagesData, updateStatePatientData]);

  //Find and remove delivered message from outbox
  useEffect(() => {
    if (deliveredMessage) {
      let updatedOutbox = [...outbox]
      let messageToRemove = updatedOutbox.findIndex((message:any) => message.resource.sent === deliveredMessage.resource.sent);
      if (messageToRemove > -1) {
        updatedOutbox.splice(messageToRemove, 1);
        setOutbox(updatedOutbox);
      }   
    }
  }, [deliveredMessage, outbox]);

  const handleEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    //Send message on enter press, new line on shift+enter
    if(e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      handleSend();
    }
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessageValue(e.target.value);
  };

  const handleSend = () => {

    const updateStateMessages = (messagesList:Array<any>) => {
      let updatedPatientData = {...patientData};
      updatedPatientData.messages = messagesList;
      updateStatePatientData(updatedPatientData);   
    };

    //Check that message isn't just empty spaces
    if (messageValue.trim().length) {

      //Create placeholder message object to update UI
      let sentDate = new Date();
      let sender = 'Patient/' + patientData.canvasId;
      let payload = messageValue;

      let messageData = {
        resource: {
          id: sentDate.toString(),
          sent: sentDate.toString(),
          sender: {
            reference: sender
          },
          payload: [
            {
              contentString: payload
            }
          ],
        }
      }

      //Update state with new message
      let updatedMessages = [...messagesData];    
      updatedMessages.unshift(messageData);
      updateStateMessages(updatedMessages);

      //Add message to outbox
      let updatedOutbox = [...outbox]
      updatedOutbox.push(messageData);
      setOutbox(updatedOutbox)

      //Create message in canvas
      canvasCreatePatientMessage(patientData.canvasId, patientData.providerData.canvasId, messageValue)
        .then(() => {
          //Track firebase event
          logEvent(analytics, 'message_sent');
          setDeliveredMessage(messageData);
          //Send a text alert to provider if last message was sent over 10min ago
          if ( 
            updatedMessages[1].resource.sender.reference !== 'Patient/' + patientData.canvasId
            || differenceInMinutes(sentDate, new Date(updatedMessages[1].resource.sent)) > 10 
          ) {
            twilioSendText(patientData.providerData.phone, "Allminds: Your client (" + patientData.firstName.charAt(0) + patientData.lastName.charAt(0) + ") has sent you a new message. Click here to view it: allminds.canvasmedical.com.");
            sgProviderNewMessageFromPatient(patientData.providerData.email, patientData.providerData.firstName, patientData.canvasId);
          }
        });
      
      setMessageValue("");


    }

  };

  if (messagesData) {

    return(
      <div className={classes.containerPatientInbox}>
        
        <div className={classes.containerTextArea}>
          <TextareaAutosize
            value={messageValue}
            onChange={handleChange}
            onKeyDown={handleEnterPress}
            className={classes.textArea}
            aria-label="Type a message"
            placeholder="Type a message"
          />
          <IconButton onClick={handleSend} className={classes.sendBtn} disabled={!messageValue.trim().length} aria-label="send">
            <ArrowUpwardRoundedIcon />
          </IconButton>
        </div>

        <div className={classes.containerMessages}>
          {
            messagesData.map((message:any, i:number) => {
              
              let isFirstMessage = 
                i > 0 
                && message.resource.sender.reference === messagesData[i - 1].resource.sender.reference 
                && isSameDay(new Date(message.resource.sent), new Date(messagesData[i - 1].resource.sent))
                ? false : true;

              let isLastMessage = 
                i < messagesData.length - 1 
                && message.resource.sender.reference === messagesData[i + 1].resource.sender.reference 
                && isSameDay(new Date(message.resource.sent), new Date(messagesData[i + 1].resource.sent))
                ? false : true

              if (message.resource.sender.reference === 'Patient/' + patientData.canvasId) {
                return(
                  <React.Fragment key={"message-" + message.resource.id}>
                    <MessageOutgoing
                      messageData={message.resource} 
                      isFirstMessage={isFirstMessage}
                      isLastMessage={isLastMessage}
                      senderName={patientData.firstName}
                      delivered={!outbox.find((outboxMessage:any) => outboxMessage.resource.sent === message.resource.sent) ? true : false}
                    />
                    {isLastMessage && !isSameDay(new Date(message.resource.sent), new Date(messagesData[i + 1].resource.sent)) ?
                      <p className={classes.date}>{format(new Date(message.resource.sent), 'iii, LLLL dd')}</p>
                      :
                      null
                    }
                  </React.Fragment>
                )
              }
              else if (message.resource.sender.reference === 'Practitioner/' + patientData.providerData.canvasId) {
                return(
                  <React.Fragment key={"message-" + message.resource.id}>
                    <MessageIncoming 
                      messageData={message.resource} 
                      isFirstMessage={isFirstMessage}
                      isLastMessage={isLastMessage}
                      senderName={patientData.providerData.firstName}
                      senderPhotoUrl={patientData.providerData.picUrl}
                    />
                    {i === messagesData.length - 1 || (isLastMessage && !isSameDay(new Date(message.resource.sent), new Date(messagesData[i + 1].resource.sent))) ?
                      <p className={classes.date}>{format(new Date(message.resource.sent), 'iii, LLLL dd')}</p>
                      :
                      null
                    }
                  </React.Fragment>
                )
              }
              else {
                return null;
              }
            })
          }
        </div>

      </div>
    );

  }

  else {

    return(
      <div className={classes.containerPatientInbox}>
        
        <div className={classes.containerTextArea}>
          <TextareaAutosize
            value={messageValue}
            onChange={handleChange}
            onKeyDown={handleEnterPress}
            className={classes.textArea}
            aria-label="Type a message"
            placeholder="Type a message"
            disabled
          />
          <IconButton onClick={handleSend} className={classes.sendBtn} disabled={!messageValue.trim().length} aria-label="send">
            <ArrowUpwardRoundedIcon />
          </IconButton>
        </div>

        <div className={classes.containerMessages}>
          <Skeleton variant="rectangular" height="100%" width="100%" style={{marginTop: '16px'}}/>
        </div>

      </div>
    );

  }

};

export default PatientInboxMessages;
