import React, { useState } from "react";
import { getAnalytics, logEvent } from "firebase/analytics";

import { usePatientData, useUpdatePatientData } from '../../../../../../hooks/ContextPatientData/ContextPatientData';
import { canvasCreateQuesPhqEating } from '../../../../../../containers/Models/CanvasQuestionnaires';
import { addPhqEatingToDb } from '../../../../../../containers/Models/assessmentModels/phqEatingModel';

import NavBarPatientChat from '../../../../../ui/ChatForm/NavBarPatientChat/NavBarPatientChat';
import ChatFormScreener from '../../../../../ui/ChatForm/ChatFormScreener';
import Skeleton from '@mui/material/Skeleton';

import classes from "../PatientChatPhq15/PatientChatPhq15.module.css";

interface Props {
  handleCloseChat: any;
}

const PatientChatPhqEating: React.FC<Props> = props => {

  const analytics = getAnalytics();
  const patientData = usePatientData();
  const handleUpdateStatePatientData = useUpdatePatientData();
  const [disableBackBtn, setDisableBackBtn] = useState(false);

  const questions: any = {
    control:
      "Do you often feel that you can’t control what or how much you eat?",
  };


  //Object with options for eating disorder questions and associated scoring value
  const options: any = {
    No: 0,
    Yes: 1
  };

  const intro = {
    message: 
    "Mental health can have an impact on your eating behaviors, such as eating too much or too little." +
    "&&The following questions will help your provider get a better sense of what your eating patterns look like.",
    option: "Start"
  };

  const getScore = (formData: any) => {

    if (formData.control[0] === 'Yes' && formData.bingeEating[0] === 'Yes' && formData.bingeFrequency[0] === 'Yes' && formData.bulFrequency && formData.bulFrequency[0] === 'Yes') {
      //Bulimia nervosa
      return 1;
    } else if (formData.control[0] === 'Yes' && formData.bingeEating[0] === 'Yes' && formData.bingeFrequency[0] === 'Yes') {
      //Binge eating disorder
      return 2;
    }
    else {
      return 0
    }
  };

  const getScoreResponse = (formData: any) => {
    let score = getScore(formData);
    let response = "";
    
    if (score === 0)
      response =
        "Thanks for sharing " +
        patientData.firstName +
        "!&&It looks like eating isn't a concern for you right now." + 
        "&&You can talk to your provider in more detail about your results!" 
    else if (score === 1)
      response =
        "Thanks for sharing " +
        patientData.firstName +
        ".&&Based on your responses it seems like eating may be an area of potential concern for you." +
        "&&You and your provider can talk through strategies to help out with any eating concerns you have.";
    else
      response =
        "Thanks for sharing " +
        patientData.firstName +
        ".&&Based on your responses it seems like eating may be an area of potential concern for you." +
        "&&You and your provider can talk through strategies to help out with any eating concerns you have.";

    return response;
  };

  const questionCallback = (dto: any, success: any, error: any, cf: any) => {
    
    const createConditionalTags = (name: string, question: string) => {
      let tags = [
        {
          tag: "fieldset",
          name: name,
          "cf-input-placeholder": " ",
          "cf-questions": question,
          children: [
            {
              tag: "input",
              name: name,
              type: "radio",
              "cf-label": "No",
              value: "No"
            },
            {
              tag: "input",
              name: name,
              type: "radio",
              "cf-label": "Yes",
              value: "Yes"
            }
          ]
        }
      ];
      return tags;
    };

    const createTag = (name:string, question:string, answers:string[]) => {
      
      const createTagChildren = () => {
        let tagChildren: any = [];
        answers.forEach((answer: any) => {
          let child: any = {
            tag: "input",
            name: name,
            type: "radio",
            "cf-label": answer,
            value: answer
          };
          tagChildren.push(child);
        });
        return tagChildren;
      }
      let radioTag = {
        tag: "fieldset",
        name: name,
        "cf-input-placeholder": " ",
        "cf-questions": question,
        children: createTagChildren()
      }
      return radioTag
    };

    const handleGoToEnd = (data:any) => {
      handleSubmit(data);
      cf.addTags([createTag(
        "end",
        getScoreResponse(data),
        ["Exit"]
      )]);
    };

    let formData = cf.getFormData(true);

    //Add bingeEating question if conditions are met
    if (dto.tag.name === "control") {
      if (dto.tag.value[0] === "Yes" && !formData.bingeEating) {
        cf.addTags(
          createConditionalTags(
            "bingeEating",
            "Do you often eat, <b>within any 2-hour period</b>, what most people would regard as an unusually large amount of food?"
          ),
          true
        );
      }
      else {
        handleGoToEnd(formData);
      }
      success();
    }

    //Add bingeFrequency question if conditions are met
    else if (dto.tag.name === "bingeEating") {
      if (dto.tag.value[0] === "Yes" && !formData.bingeFrequency) {
        cf.addTags(
          createConditionalTags(
            "bingeFrequency",
            "Has this been as often, on average, as twice a week for the last 3 months?"
          ),
          true
        );
      }
      else {
        handleGoToEnd(formData);
      }
      success();
    }
    
    //Add bulimia questions if bingeFrequency is "Yes"
    else if (dto.tag.name === "bingeFrequency") {
      if (dto.tag.value[0] === 'Yes') {
        if (!formData.vomit) {
          cf.addTags(
            createConditionalTags(
              "vomit",
              "In the <b>last 3 months</b> have you <b>often</b> done any of the following in order to avoid gaining weight:" +
                "&&Made yourself vomit?"
            ),
            false,
            4
          );
        }
        if (!formData.laxatives) {
          cf.addTags(
            createConditionalTags(
              "laxatives",
              "Took more than twice the recommended dose of laxatives?"
            ),
            false,
            5
          );
        }
        if (!formData.fasted) {
          cf.addTags(
            createConditionalTags(
              "fasted",
              "Fasted – not eaten anything at all for at least 24 hours?"
            ),
            false,
            6
          );
        }
        if (!formData.exercise) {
          cf.addTags(
            createConditionalTags(
              "exercise",
              "Exercised for more than an hour specifically to avoid gaining weight after binge eating?"
            ),
            false,
            7
          );
        }
      }
      else {
        handleGoToEnd(formData);
      }
    success();
    }

    //Add bulFrequency question if assessment conditions are met
    else if (dto.tag.name === "exercise") {
      if (
        (
          formData.vomit[0] === "Yes" ||
          formData.laxatives[0] === "Yes" ||
          formData.fasted[0] === "Yes" ||
          dto.tag.value[0] === "Yes"
        )
        && !formData.bulFrequency
      ) {
        cf.addTags(
          createConditionalTags(
            "bulFrequency",
            "Were any of these ways of avoiding gaining weight as often, on average, as twice a week?"
          )
        );
      } else {
        handleGoToEnd(formData);
      }
      success();
    } 

    else if (dto.tag.name === "bulFrequency") {
      handleGoToEnd(formData);
      success();
    }

    else if (dto.tag.name === 'end') {
      props.handleCloseChat();
    }

    else success();

  };

  //Function to be called after form is submitted
  const handleSubmit = (formData: any) => {

    const getSeverity = (score: number) => {
      if (score === 0) {
        return "Eating Normal";
      } else if (score === 1) {
        return "Bulimia Nervosa";
      } else {
        return "Binge Eating Disorder";
      }
    };

    setDisableBackBtn(true);

    //Create screenerData object from formData
    const screenerData: any = {};
    for (let key in formData) {
      if (key !== "intro" && key !== "end") {
        screenerData[key] = {
          value: formData[key][0],
          score: options[formData[key][0]]
        };
      }
    }

    let score = getScore(formData);
    let severity = getSeverity(score);

    //Create object with phqEating data
    let phqEatingData = {
      
      patientId: patientData.id,
      patientCanvasId: patientData.canvasId,
      providerId: patientData.providerData.id,
      providerCanvasId: patientData.providerData.canvasId,
      timeStamp: new Date(),

      control: { value: screenerData.control.value, score: screenerData.control.score }, 
      //The questions below are skippable, so check to see if answer is available
      bingeEating: screenerData.bingeEating ?
                    { value: screenerData.bingeEating.value, score: screenerData.bingeEating.score }
                    : null,
      bingeFrequency: screenerData.bingeFrequency ?
                    { value: screenerData.bingeFrequency.value, score: screenerData.bingeFrequency.score }
                    : null,
      laxatives: screenerData.laxatives ?
                    { value: screenerData.laxatives.value, score: screenerData.laxatives.score }
                    : null,
      exercise: screenerData.exercise ?
                    { value: screenerData.exercise.value, score: screenerData.exercise.score }
                    : null,
      fasted: screenerData.fasted ?
                    { value: screenerData.fasted.value, score: screenerData.fasted.score }
                    : null,
      vomit: screenerData.vomit ?
                    { value: screenerData.vomit.value, score: screenerData.vomit.score }
                    : null,
      bulFrequency: screenerData.bulFrequency ?
                    { value: screenerData.bulFrequency.value, score: screenerData.bulFrequency.score }
                    : null,

      score: score,
      severity: severity

    };

    //Upload assessment to db
    addPhqEatingToDb(phqEatingData).then((res) => {
      //Upload questionnaire to Canvas
      canvasCreateQuesPhqEating(phqEatingData);
    });

    //Track firebase event
    logEvent(analytics, 'phqEating_completed');

    //Update state with next due date for assessment
    let updatedPatientData = {...patientData};
    updatedPatientData.lastPhqEating = {
      id: '',
      timeStamp: phqEatingData.timeStamp
    };
    handleUpdateStatePatientData(updatedPatientData);

  };

  return (
    <React.Fragment>
      <NavBarPatientChat 
        handleClose={props.handleCloseChat} 
      />
      <div className={classes.containerChatAssessment}>
        {patientData ? 
          <ChatFormScreener
            questions={questions}
            options={options}
            intro={intro}
            questionCallback={questionCallback}
            disableBackBtn={disableBackBtn}
          />
        :
          <div className={classes.containerSkeleton}>
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={200} height={60} />
          </div>
        }
      </div>
    </React.Fragment>
  );

};

export default PatientChatPhqEating;
