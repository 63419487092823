import { useEffect, useState } from "react";
import { usePatientData, useUpdatePatientData } from '../ContextPatientData/ContextPatientData';
import {getAppointmentsByPatientId} from '../../containers/Models/AppointmentModel';
export default function useFetchPatientAppointments() {

  const patientData = usePatientData();
  const updateStatePatientData = useUpdatePatientData();

  const [upcomingAppointments, setUpcomingAppointments] = useState<any>(null);
  const [pastAppointments, setPastAppointments] = useState<any>(null);

  //Fetch appointments if state doesn't have them
  useEffect(() => {
    if (patientData) {
      if (!patientData.appointments && !patientData.pastAppointments) {
        //Get sorted array of patient appointments, in chronological order
        getAppointmentsByPatientId(patientData.id).then(appointmentsData => {
          let today = new Date();
          //Find first appointment that is happening in the future
          let index = appointmentsData.findIndex( appointment => appointment.end > today);

          if (index > -1) {
            //Separate past and upcoming appointments,  reverse past appointments
            setPastAppointments(appointmentsData.slice(0, index).reverse());
            setUpcomingAppointments(appointmentsData.slice(index));
          }
          else {
            //Set all appointments in the past, in reverse chronological order
            setPastAppointments(appointmentsData.reverse());
            setUpcomingAppointments([]);
          }
        });
      }
      else {
        // setAppointments(patientData.appointments);
        setUpcomingAppointments(patientData.appointments);
        setPastAppointments(patientData.pastAppointments);
      }
    }
  }, [patientData]);

  //Add canvas appointments data to state after initial fetch
  useEffect(() => {
    if (
      pastAppointments 
      && upcomingAppointments 
      && patientData 
      && JSON.stringify(patientData.appointments) !== JSON.stringify(upcomingAppointments)
      && JSON.stringify(patientData.pastAppointments) !== JSON.stringify(pastAppointments)
    ) {
      let updatedPatientData = {...patientData};
      updatedPatientData.appointments = [...upcomingAppointments];
      updatedPatientData.pastAppointments = [...pastAppointments];
      updateStatePatientData(updatedPatientData);
    }
  }, [patientData, pastAppointments, upcomingAppointments, updateStatePatientData]);

  // return appointments;
  return {upcoming: upcomingAppointments, past: pastAppointments};
}