import { getFirestore, addDoc, updateDoc, collection, 
    doc, query, where, getDocs } from "firebase/firestore";
import firebaseApp from "../Data/Firestore";
const patientDb = process.env.REACT_APP_PATIENT_DB;
const db = getFirestore(firebaseApp);

function createPatientObject(intakeData, providerData) {
    
  let patient = {};
  
  patient.firstName = intakeData.firstName;
  patient.lastName = intakeData.lastName;
  patient.email = intakeData.email;
  patient.phone = intakeData.phone;
  patient.dob = intakeData.dob;
  patient.address = "";
  patient.state = intakeData.state;

  patient.coverageId = intakeData.coverageId ? intakeData.coverageId : '';
  patient.city = intakeData.city ? intakeData.city : '';
  patient.zip = intakeData.zip ? intakeData.zip : '';
  patient.street = intakeData.street ? intakeData.street : '';

  patient.gender = intakeData.gender;
  patient.isLgbtq = intakeData.isLgbtq;
  patient.ethnicities = intakeData.ethnicities;
  patient.isReligious = intakeData.isReligious;
  patient.religion = intakeData.religion;

  // Patient Goals & Preferences
  patient.therapyGoals = intakeData.therapyGoals;
  patient.therapyGenderPref = intakeData.genderPref;
  patient.therapyAgePref = intakeData.agePref;
  patient.therapyEthnicityPref = intakeData.ethnicityPref;
  patient.therapyLanguagePref = intakeData.languagePref;

  // Populate this ID by documentID that gets created on adding a new patient
  patient.id = "";
  patient.canvasId = "";

  patient.therapyFrequency = 0; //  0 = no therapy, 1 = once a week, 2 = every 2 weeks, 3 = every 3 weeks, 4 = every 4 weeks
  patient.therapyFollowDate = null; // Date object controlling when therapy sessions are set to happen
  patient.therapySlotsBlockLink = "";
  patient.therapySlotsBlockId = "";
  patient.txPlanId = "";
  patient.consentId = "";
  patient.preIntakeId = "";

  //Provider assigned to client
  patient.providerId = providerData.id;
  //REMOVED OLD FORMAT WHEN CONSULTATION WERE REMOVED 03/27/2023
  // patient.provider = {
  //   id: providerData.id,
  //   status: "pendingApproval"
  // };

  patient.alerts = {
    newMessage: true
  };

  patient.stripeCustomerId = '';
  patient.stripeDefaultPaymentId = '';

  //Array of appointment IDs that were canceled late, used for insurance clients
  patient.lateCancels = [];

  //Amount to charge patient for private pay sessions, in cents
  patient.privatePayRate = 13000;

  //Booleans for patient notifications
  patient.notifications = {};
  //IDs of therapists seen
  patient.therapistsSeen = [];
  patient.numSessions = 0;
  patient.treatmentHold = false;
  patient.excludeNoshows = false;
  
  return patient;
}

const addPatientDataToDb = async (patientData) => {
  // Check if the patient already exists in the Db.
  // console.log("Patient Data: ", patientData);
  return addDoc(collection(db, patientDb), patientData).then(async ref => {
    await updateDoc(ref, {id: ref.id });
    return ref.id;
  });
}

async function updatePatientCanvasId(patientId, patientCanvasId) {
  // console.log("Patient ID: ", patientId, " CanvasPatientID: ", patientCanvasId.patientId);
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {canvasId: patientCanvasId.patientId});
}

async function updatePatientFutureTherapyVars(patientId, frequency, followOnDate, slotLink, slotId) {
  // console.log("Patient ID: ", patientId, " frequency: ", frequency, " ,  followOnDate: ", followOnDate, "ID: ", slotId);
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {
      therapyFrequency: frequency,
      therapyFollowDate: followOnDate,
      therapySlotsBlockLink: slotLink,
      therapySlotsBlockId: slotId
    });
}

async function updatePatientDob(patientId, date) {
  // console.log("Patient ID: ", patientId, " Date: ", date);
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {dob: date});
}

async function updatePatientPhone(patientId, phone) {
  // console.log("Patient ID: ", patientId, " Phone: ", phone);
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {phone: phone});
}

async function updatePatientName(patientId, firstName, lastName) {
  // console.log("Patient ID: ", patientId, " First Name: ", firstName, " Last Name: ", lastName);
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {firstName: firstName, lastName: lastName});
}

async function updatePatientAddress(city, state, street, zip, patientId) {
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {city: city, state: state, street: street, zip: zip});
}

async function updatePatientProvider(patientId, providerId) {
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {providerId: providerId});
}

async function removePatientProvider(patientId) {
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {provider: null});
}

async function updatePatientDefaultPayment(patientId, paymentId) {
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {stripeDefaultPaymentId: paymentId});
}

async function updatePatientAlerts(patientId, alertsObj) {
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {alerts: alertsObj});
}

async function addPatientTreatmentHold(patientId) {
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {
    treatmentHold: true,
  });
}

async function addPatientLateCancel(patientId, lateCancels) {
  const docRef = doc(db, patientDb, patientId);
  updateDoc(docRef, {lateCancels: lateCancels});
}

async function getPatientByEmail(email) {
  if (email === undefined || email === null || email === "")
  {
      console.log("Patient Not Found!");
      return null;
  }

  const q = query(collection(db, patientDb), where("email", "==", email));
  const querySnapshot = await getDocs(q);
  let patientData = null;
  querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      patientData = doc.data();
      convertFirebaseDates(patientData);
      return patientData;
    });

  return patientData;
}

async function getPatientById(patientId) {
  if (patientId === undefined || patientId === null || patientId === "")
  {
      console.log("Patient Not Found!");
      return null;
  }

  const q = query(collection(db, patientDb), where("id", "==", patientId));
  const querySnapshot = await getDocs(q);
  let patientData = null;
  querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      patientData = doc.data();
      convertFirebaseDates(patientData);
      //console.log(doc.id, " => ", patientData);
      return patientData;
    });

  return patientData;
}

//Convert all firebase dates to regular Date objects
const convertFirebaseDates = (obj) => {
  for (let key in obj) {
    if (obj[key] && obj[key].toDate) {
        obj[key] = obj[key].toDate();
    }
    if (typeof obj[key] === 'object' && obj[key] !== null) {
        convertFirebaseDates(obj[key])
    }
  }    
};

export {
  createPatientObject,
  addPatientDataToDb,
  getPatientByEmail,
  getPatientById,
  updatePatientCanvasId,
  updatePatientDob,
  updatePatientPhone,
  updatePatientName,
  updatePatientAddress,
  updatePatientProvider,
  removePatientProvider,
  updatePatientDefaultPayment,
  updatePatientAlerts,
  updatePatientFutureTherapyVars,
  addPatientLateCancel,
  addPatientTreatmentHold
};