import React from 'react';
import classes from './AvatarSquare.module.css';

interface Props {
  imgUrl: string;
  alt: string;
  width?: number;
  height?: number;
}

const AvatarSquare: React.FC<Props> = props => {
  
  let styles:any = {};
  if (props.width) {
    styles.width = props.width + 'px';
    styles.minWidth = props.width + 'px';
  }
  if (props.height) {
    styles.height = props.height + 'px';
    styles.minHeihgt = props.height + 'px';
  }

  return(
    <div className={classes.container} style={styles}>
      <img src={props.imgUrl} alt={props.alt} />
    </div>
  );
};

export default AvatarSquare;